import moment from 'moment';

import localization from 'moment/locale/ru';
moment().locale('ru', localization)

import $ from "jquery";
import User from "@/user";

export default {
    methods: {

        ac(check) {
            if (User.currentUser.user.role_type === 'adm') {
                return true;
            } else {
                let access = User.currentUser.user.user_access || {};
                let res = false;

                check = check.split('.');

                if (check.length === 3 && access[check[0]]) {
                    return access[check[0]][check[1]][check[2]]
                }

                $.each(check, function (k,v) {
                    if (access[v]) {
                        $.each(access[v], function (k2, v2) {
                            if (check.length === 1 && v2) {
                                res = true;
                            }
                            if (check.length === 2 && typeof v2 == "boolean") {
                                return access[check[0]][check[1]];
                            }
                            if (check.length === 2 && typeof v2 == "object") {
                                $.each(access[check[0]][check[1]], function (k3, v3) {
                                    if (check.length === 2 && v3) {
                                        res = true;
                                    }
                                })
                            }
                        })
                    }
                })
                return res;
            }
        },

        formatNumber(num, def = '0', digits = 2) {
            if (!num) {
                return def;
            }

            return (+num.toFixed(digits)).toLocaleString('en-US');
        },

        declination(n, t) {
            let cases = [2, 0, 1, 1, 1, 2];

            return t[ (n%100>4 && n%100<20)? 2:cases[(n%10<5)?n%10:5] ];
        },

        formatPhone(p) {
            let pattern = /(\d)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;

            return p ? p.replace(pattern, '+7 ($2) $3-$4-$5') : '-';
        },

        formatNumberSign(number, def, digits) {
            number = +number.toFixed(4);
            var sign = (number > 0) ? '+' : '';
            var result = this.formatNumber(number, def, digits);
            return sign + result;
        },

        formatUSD(amount, digits = 2) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: digits
            });

            return amount ? formatter.format(amount) : formatter.format(0);
        },

        formatRUB(amount, digits = 2) {
            var formatter = new Intl.NumberFormat('ru-RU', {
                style: 'currency',
                currency: 'RUB',
                minimumFractionDigits: digits
            });

            return amount ? formatter.format(amount) : formatter.format(0);
        },

        convertDate(date, format = 'DD.MM.YYYY в HH:mm') {
            return moment(date).format(format);
        },
        convertTime(time1, time2) {
            time1 = time1.split(':')
            time2 = time2.split(':')
            return 'с ' + time1[0] + ':' + time1[1] + ' до ' + time2[0] + ':' + time2[1];
        },

        prepareDuration(duration) {
            let d = Math.floor((duration / 60) / 60 / 24);
            let h = Math.floor(duration / 60 / 60) - (d * 24);
            let m = Math.floor(duration / 60) - (h * 60) - (d * 60 * 24);

            return (d ? d + ' дн. ' : '') + (h ? h + ' час. ' : '') + (m ? m + ' мин. ' : '');
        },

        prepareDurationH(duration) {
            let h = Math.floor(duration / 60 / 60);
            return h;
        },

        dateDiffAsDay(date) {
            return moment().diff(moment(date), 'days');
        },

        formatName(name) {
          let res = name.split(' ');

          return res.length ? res[0] + ' ' + (res[1] ? res[1].split('')[0] : '') + ' ' + (res[2] ? res[2].split('')[0] : '') : name
        },
        formatAccountType(accountType) {
            if (accountType === 'staff') {
                return 'staff';
            } else if (accountType === 'adm') {
                return 'Admin';
            } else if (accountType === 'client') {
                return 'Client';
            }
        },

        capitalizeWords(words) {
            return words
              .trim().split('_').join(' ')
              .split(' ')
              .map(this.capitalizeWord)
              .join(' ');
        },

        capitalizeWord(word) {
            return word.slice(0, 1).toUpperCase() + word.slice(1);
        },

        setError(error) {
            if (error.code) {
                if (error.elements) {
                    $.each(error.elements, (key, val) => {

                        if (this.$root.$children) {
                            this.$root.$children.forEach((component) => {
                                if (component.name === key) {
                                    component.setError(val);
                                }
                            });
                        }
                    });
                } else {

                    if ( this.$root.$children ) {
                        this.$root.$children.forEach((component) => {
                            let key = !error.elements ? error.error_field : error.error_message;
                            let val = !error.elements ? error.error_message : error.entity;
                            if (component.name === key) {
                                component.setError(val);
                            }
                        });
                    }

                }

                return;
            }

            $.each(error, (key, val) => {
                if (this.$root.$children) {
                    this.$root.$children.forEach((component) => {
                        if (component.name === key) {
                            component.setError(val);
                        }
                    });
                }
            });
        },

        clearError() {
            if ( this.$root.$children ) {
                this.$root.$children.forEach((component) => {
                    if (typeof component.clearError === 'function') {
                        component.clearError();
                    }
                });
            }
        },
    }
}

