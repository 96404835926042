<template>
  <div class="form">
    <div class="form-left">
      <Input name="account_title" text="ФИО" required="true" v-model.trim="data.account.account_title" />
      <Input name="user_phone" mask="+#(###)###-##-##" text="Телефон" required="true" v-model.trim="data.user.user_phone" />

      <template v-if="!data.user.user_id">
        <Input name="user_password" text="Пароль" required="true" v-model.trim="data.user.user_password" />
      </template>



    </div>


    <div class="form-submit">

      <input type="hidden" name="account_id" v-model="data.user.account_id" />
      <input type="hidden" name="user_id" :value="data.user.user_id" />

      <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
    </div>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import User from "@/user";


export default {
  mixins: [mixins],

  components: {
    Input
  },


  created() {
    if (this.$route.params.id > 0) {
      this.fetchData();
    }
  },

  data() {
    return {
      data: {
        account: {
          account_id: null,
          account_title: null,
          account_award_percent: 0,
          account_award_amount: 0,
          manager_id: User.currentUser.account.account_id || 0
        },
        user: {
          user_id: null,
          account_id: null,
          user_phone: null,
          user_password: null
        }
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null,
      account_id: User.currentUser.account.account_id || 0,
    }
  },

  methods: {

    async fetchData() {
      let urls = {
        'account': `/accounts/${this.$route.params.id}`,
        'user': `/users/by_account/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);
    },


    async save() {
      this.clearError();

      let data = {
        user_phone: this.data.user.user_phone.match(/[\d]/g).join(''),
        account_id: this.data.user.account_id,
        user_password: this.data.user.user_password,
        account_title: this.data.account.account_title,
        account_type: 'partner',
        account_award_percent: this.data.account.account_award_percent,
        account_award_amount: this.data.account.account_award_amount,
        manager_id: User.currentUser.account.account_id || 0,
      };

      let list = [];
      list.push(Ajax.post(`/settings/staff/save/${this.data.user.user_id || '0'}`, data));


      let [userResult] = await Promise.all(list);

      if (userResult.error) {

        if (userResult.error.account_id) {
          this.data.user.account_id = userResult.error.account_id;
        }
        this.setError(userResult.error);
      }


      if (userResult.response) {
        if (this.$route.params.id === 'edit') {
          this.$router.push({ path: '/manager/partners/' + userResult.response.account_id + '/general/' });
          this.$toast.success({content:`Партнёр "${this.data.account.account_title}" добавлен.`});
        } else {
          this.$toast.success({content:`Партнёр "${this.data.account.account_title}" обновлён.`});
        }
      }

    },

  }
}
</script>
