<template>
  <div>
    <Report ref="report" url="settings/staff/report" :def-params="{order: {field: 'account_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd ref="report.OrderTd" text="№" field="account_id"  width="50" />
              <OrderTd text="ФИО" field="account_title" />
              <OrderTd text="Телефон" field="user_phone" />
              <OrderTd text="Должность" field="account_post" />
              <OrderTd text="Подразделение" field="account_subdivision" />
              <OrderTd text="Статус" field="account_status" width="100" />
              <td>Дополнительно</td>
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="account_id" />
              <InputFilter name="account_title" filter-type="like" />
              <InputFilter name="user_phone" filter-type="like" />
              <SelectFilter name="account_post" :options="data.posts" track-by="post_id" label="post_title" />
              <SelectFilter name="account_subdivision" :options="data.subdivision" track-by="subdivision_id" label="subdivision_title" />
              <SelectFilter name="account_status" :options="[['active', 'Активен'], ['paused', 'Заблокирован']]" track-by="0" label="1" />
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="10">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.account_id}}</td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.account_title">
                    <div>
                      <router-link :to="'/adm/settings/staff/edit/' + row.account_id">{{row.account_title}}</router-link>
                    </div>
                  </div>
                </td>

                <td>
                  <div class="row-wrap" v-bind:data-title="row.user_phone">
                    <div>
                      {{formatPhone(row.user_phone)}}
                    </div>
                  </div>
                </td>

                <td>
                  {{data.postsTitle[row.account_post]}}
                </td>

                <td>
                  {{data.subdivisionTitle[row.account_subdivision]}}
                </td>

                <td>
                  <div class="status" v-bind:class="`status_${row.account_status}`">
                    {{data.status[row.account_status]}}
                  </div>
                </td>

                <td>
                  -
                </td>

                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <a href="#" v-on:click.prevent="impersonate(row.user_id)">Войти</a>
                      <hr>
                      <router-link :to="'/adm/settings/staff/edit/' + row.account_id">
                        <template v-if="ac('settings.settings_staff.edit')">
                          Редактировать
                        </template>
                        <template v-else>
                          Просмотр
                        </template>
                      </router-link>
                      <a href="#" v-on:click.prevent="changeStatus(row.account_id, 'active')" v-if="row.account_status !== 'active' && ac('settings.settings_staff.status')">Разблокировать сотрудника</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.account_id, 'paused')" v-if="row.account_status !== 'paused' && ac('settings.settings_staff.status')">Заблокировать сотрудника</a>
                      <a href="#" v-if="ac('settings.settings_staff.delete')" v-on:click.prevent="changeStatus(row.account_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../../helpers/mixins.js';
import Ajax from '../../../../../helpers/ajax';
import Loading from '../../../../../components/report/Loading';
import Report from '../../../../../components/report/Report';
import Pager from '../../../../../components/Pager';
import InputFilter from '../../../../../components/report/InputFilter';
import SelectFilter from '../../../../../components/report/SelectFilter';
import OrderTd from '../../../../../components/report/OrderTd';
import User from "@/user";

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  created() {
    this.loadPosts();
    this.loadSubdivision();
  },

  data() {
    return {
      tab: 'list',
      data: {
        status: {
          'active': 'Активен',
          'paused': 'Заблокирован'
        },
        posts: [],
        postsTitle: [],
        subdivision: [],
        subdivisionTitle: []
      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {

    impersonate(user_id) {
      User.impersonate(user_id)
      window.location.reload()
    },

    isTab(tab) {
      return this.tab === tab;
    },

    async loadPosts() {
      let result = await Ajax.get('/account/get-post');
      if (result.response) {

        this.data.posts = result.response;

        result.response.forEach((item) => {
          this.data.postsTitle[item.post_id] = item.post_title;
        });

      }
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/accounts/${id}/status`, {status: status});
        }
      } else {
        await Ajax.post(`/accounts/${id}/status`, {status: status});
      }

      this.getReport().reload();

    },
  }
}
</script>
