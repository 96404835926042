<template>
  <div>
    <Report v-if="period" ref="report" url="a/report_money_total" :def-params="{order: {field: 'order_date', order: 'desc'}, filters: {order_date: 'bw|' + period, excursion_publication: 'eq|enabled'}, group: 'order_point'}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>

        <div class="table-filter position-relative">
          <div>
            <label class="input-placeholder">Экс / допы</label>
            <SelectFilter name="excursion_publication" :options="[['enabled', 'Экскурсии'],['disabled', 'Допы']]" track-by="0" label="1" />
          </div>
          <div>
            <Select v-model="chartOptions.chart.type" text="Вид" id="group_by" name="group_by" :options="[['line', 'Линии'], ['bar', 'Колонки'], ['radar', 'Радар']]" track-by="0" label="1"  v-on:change="changeType" />
          </div>
          <div>
            <Select v-model="group_by" text="Отчёт по" id="group_by" name="group_by" :options="[['order_seller', 'кассирам'], ['order_point', 'точкам продаж']]" track-by="0" label="1"  v-on:change="changeGroupByDate" />
          </div>

          <div>
            <AutosuggestMulti
                v-if="data.subdivision_values.length"
                ref="subdivision_id"
                v-model="subdivision"
                :value="subdivision"
                name="subdivision_id"
                text="Подразделение"
                url=""
                :data="data.subdivision_values"
                v-on:input="changeSubdivisionIds"
            />
          </div>
          <div>
            <AutosuggestMulti
                ref="account_id"
                v-model="data.account"
                :value="data.account"
                name="account_id"
                text="Кассир"
                url="/accounts/suggest?type=staff&post=2&limit=40&sub=3,4"
                v-on:input="changeAccountIds"
            />
          </div>
          <div>
            <AutosuggestMulti
                ref="point"
                v-model="point_data"
                :value="point_data"
                name="point_data"
                text="Точка продажи"
                url="/misc/point"
                v-on:input="changePointIds"
            />
          </div>

          <div>
            <label class="input-placeholder">Период</label>
            <PeriodPickerFilter2 :allow-future="true" name="order_date" v-model="period" />
          </div>

          <div style="padding: 30px 0 0">
            <a v-on:click.prevent="showCart = !showCart">
              {{ showCart ? 'Скрыть график' : 'Показать график' }}
            </a>
          </div>
        </div>


        <div id="chart" v-if="showCart">
          <VueApexCharts v-if="reportDone && chartOptions.chart.type === 'line'" height="350" :options="chartOptions" :series="series"></VueApexCharts>
          <VueApexCharts v-if="reportDone && chartOptions.chart.type === 'bar'" height="350" :options="chartOptions" :series="series"></VueApexCharts>
          <VueApexCharts v-if="reportDone && chartOptions.chart.type === 'radar'" height="350" :options="chartOptions" :series="series"></VueApexCharts>
        </div>

        <div class="table-scrollable scroll-on">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>

            <tr class="sticky">
              <OrderTd v-if="group_by === 'order_seller'" text="Кассир" field="account_title" />
              <OrderTd v-if="group_by === 'order_point'" text="Точка продаж" field="point_title" />

              <td v-for="(date, index) in dates" v-bind:key="index" class="text-right">
                <b>{{convertDate(date, 'DD')}}</b><br>
                {{days[convertDate(date, 'd')]}}
              </td>
              <td class="text-right">
                <b>Итог</b>
              </td>

            </tr>

            </thead>
            <tbody>
            <Loading />
            <tr v-if="report && report.rows_count === 0">
              <td colspan="16">
                Не найдено.
              </td>
            </tr>

            <template v-if="report && report.rows_count > 0">

              <tr v-for="(row, index) in report.rows" v-bind:key="index">

                <td v-if="group_by === 'order_seller'">
                  <div class="row-wrap" v-bind:data-title="row.excursion_title">
                    <div>
                      <b>{{ formatName(row.account_title) }}</b>
                    </div>
                  </div>
                </td>

                <td v-if="group_by === 'order_point'">
                  <div class="row-wrap">
                    <div v-if="row.order_point">
                      <b>{{row.point_title}}</b><br>
                      {{ formatName(row.account_title) }}
                    </div>
                    <div v-if="row.order_point === 0">
                      <b>Продажи с сайта</b>
                    </div>
                    <div v-if="row.order_point !== 0 && !row.order_point">
                      <b>Без точки продаж</b>
                    </div>
                  </div>
                </td>

                <td v-for="(date, index) in dates" v-bind:key="index" class="text-right">
                  {{ formatRUB(getValueByDate(date, row, 'order_pay_sum_total')) }}
                  <div class="row-desc">
                    {{ getValueByDate(date, row, 'account_title') }}
                  </div>
                </td>
                <td class="text-right">
                   <b>{{ formatRUB(row.summary.order_pay_sum_total || 0) }}</b>
                </td>
              </tr>

              <tr class="summary" v-if="report.additional_rows">
                <td></td>
                <td v-for="(date, index) in dates" v-bind:key="index" class="text-right">
                  <b>
                    {{ formatRUB( getDaySummary(date) ) }}
                  </b>
                </td>
                <td class="text-right">
                  <b>{{ formatRUB(report.summary.order_pay_sum_total) }}</b>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>

import VueApexCharts from 'vue3-apexcharts'

import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import Select from '../../../../components/Select';
import OrderTd from '../../../../components/report/OrderTd';
import PeriodPickerFilter2 from "@/components/report/PeriodPickerFilter";

import moment from 'moment';
import AutosuggestMulti from "@/components/AutosuggestMulti";
import SelectFilter from "@/components/report/SelectFilter";



export default {
  mixins: [mixins],

  components: {
    VueApexCharts,
    OrderTd,
    Loading,
    Pager,
    Select,
    AutosuggestMulti,
    PeriodPickerFilter2,
    SelectFilter,
    Report
  },


  created() {
    this.loadSubdivision();

    let dateArray = [];
    let currentDate = new Date().setDate(new Date().getDate()-14);
    let i = 0;

    while (i < 14) {
      dateArray.push(new Date (currentDate));
      currentDate = moment(currentDate).add(1, 'days');
      i++;
    }

    this.dates = dateArray;
    this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');

  },

  watch: {
    period() {

      if (!this.period) {
        let dateArray = [];
        let currentDate = new Date().setDate(new Date().getDate()-14);
        let i = 0;

        while (i < 14) {
          dateArray.push(new Date (currentDate));
          currentDate = moment(currentDate).add(1, 'days');
          i++;
        }

        this.dates = dateArray;
        this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');
      }

      let period = this.period.split(';')
      let currentDate = new Date();
      let countDays = 14;

      if (period.length) {
        currentDate = new Date(period[0]);

        let endDate = new Date(period[1]);
        countDays = moment(endDate).diff(moment(currentDate), 'days') + 1
      }

      let dateArray = [];
      let i = 0;

      while (i < countDays) {
        dateArray.push(new Date(currentDate));
        currentDate = moment(currentDate).add(1, 'days');

        i++;
      }

      this.dates = dateArray;
    }
  },

  data() {
    return {
      showCart: false,
      reportDone: false,

      series: [],

      chartOptions: {
        chart: {
          type: 'line',
          height: 350,
          animations: {
            enabled: false
          },
          toolbar: {
            show: true
          }
        },

        dataLabels: {
          enabled: false
        },

        stroke: {
          width: 2,
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e8edf0',
          row: {
            colors: ['#e8edf0', 'transparent'],
            opacity: 0.5
          },
        },

        xaxis: {
          categories: [],
          type: 'datetime',

          labels: {
            formatter: function(val, timestamp) {
              return moment(new Date(timestamp)).format("DD MMM YYYY")
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return val
            }
          },

        },

        legend: {
          position: 'right',
          horizontalAlign: 'left',
          offsetX: -10
        },

        tooltip: {
          followCursor: true,
          inverseOrder: true,
          x: {
            format: "YYYY-MM-DD",
          },
          y: {
            formatter: (val) => {
              return this.formatRUB(val);
            },
          },
          items: {
            display: 'flex',
          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: -100,

          }
        },
      },


      point_data: [],
      subdivision: [
        {value: 3, label: 'Анапа'},
        {value: 4, label: 'Пионерский проспект'}
      ],
      group_by: 'order_point',
      group_by_date: 'day',
      period: null,
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: [],
        subdivision_values: [],
        account: [],
      },
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null
    }
  },

  methods: {
    changeType() {

    },
    setDate(){
      this.chartOptions.xaxis.categories = []
      this.chartOptions.xaxis.labels.categories = []
      this.dates.forEach(i => {
        this.chartOptions.xaxis.categories.push(moment(i).format('YYYY-MM-DD'))
      })
    },

    changeSubdivisionIds() {

      /*
      let params = {}

      if (this.subdivision.length) {
        params = {
          filters: {
            subdivision_id: 'ins|' + this.subdivision.map(i => i.value).join(',')
          },
          group: this.group_by,
          page: 1
        };
      } else {
        params = {filters: {}, group: this.group_by, page: 1};
      }

      if (this.data.account.length) {
        params.filters.order_seller = 'ins|' + this.data.account.map(i => i.value).join(',')
      }
      if (this.point_data.length) {
        params.filters.order_point = 'ins|' + this.point_data.map(i => i.value).join(',')
      }

      this.getReport().changeReportParams(params, true);


       */
    },

    changeAccountIds() {
      let params = {}
      if (this.data.account.length) {
        params = {
          filters: {
            order_seller: 'ins|' + this.data.account.map(i => i.value).join(',')
          },
          group: this.group_by,
          page: 1
        };
      } else {
        params = {filters: {}, group: this.group_by, page: 1};
      }

      if (this.subdivision.length) {
        params.filters.subdivision_id = 'ins|' + this.subdivision.map(i => i.value).join(',')
      }
      if (this.point_data.length) {
        params.filters.order_point = 'ins|' + this.point_data.map(i => i.value).join(',')
      }

      this.getReport().changeReportParams(params, true);
    },
    changePointIds(){
      let params = {}
      if (this.point_data && this.point_data.length) {
        params = {
          filters: {
            order_point: 'ins|' + this.point_data.map(i => i.value).join(',')
          },
          group: this.group_by,
          page: 1
        };
      } else {
        params = {filters: {}, group: this.group_by, page: 1};
      }

      if (this.subdivision.length) {
        params.filters.subdivision_id = 'ins|' + this.subdivision.map(i => i.value).join(',')
      }
      if (this.data.account.length) {
        params.filters.order_seller = 'ins|' + this.data.account.map(i => i.value).join(',')
      }

      this.getReport().changeReportParams(params, true);
    },

    getValueByDate(date, row, key) {
      let res = row.dates[this.convertDate(date, 'YYYY-MM-DD')];

      if (res) {
        if ( key === 'account_title' ) {

          if (row.account_id !== res['account_id']) {
            return this.formatName(res[key])
          } else {
            return ''
          }
        } else {
          return res[key]
        }

      } else {
        return  ''
      }
    },

    getDaySummary(date) {
      let result = this.report.additional_rows[0].find(i => i.order_date == this.convertDate(date, 'YYYY-MM-DD'));

      return  result ? result.order_pay_sum_total : 0;
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivision_values.push({value: item.subdivision_id, label: item.subdivision_title});
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    changeGroupByDate() {
      let params = {filters: { compare_date: null }, group: this.group_by, page: 1};

      this.getReport().changeReportParams(params);
    },


    reportLoaded(report) {
      if (report && report.response) {
        let newRows = []
        report.response.rows.forEach(a => {

          if (this.group_by === 'order_seller') {
            if (newRows.find(b => b.account_id === a.account_id)) {
              newRows.find(b => b.account_id === a.account_id).dates[this.convertDate(a.order_date, 'YYYY-MM-DD')] = a

              let summary = newRows.find(b => b.account_id === a.account_id).summary;
              newRows.find(b => b.account_id === a.account_id).summary = {
                order_pay_sum_total: summary.order_pay_sum_total + a.order_pay_sum_total
              }

            } else {
              let day = {}
              day[this.convertDate(a.order_date, 'YYYY-MM-DD')] = a
              newRows.push({
                account_id: a.account_id,
                account_title: a.account_title,
                point_title: a.point_title,
                order_point: a.order_point,
                dates: day,
                summary: {
                  order_pay_sum_total: a.order_pay_sum_total
                }
              })
            }
          } else {
            if (newRows.find(b => b.order_point === a.order_point)) {
              newRows.find(b => b.order_point === a.order_point).dates[this.convertDate(a.order_date, 'YYYY-MM-DD')] = a

              let summary = newRows.find(b => b.order_point === a.order_point).summary;
              newRows.find(b => b.order_point === a.order_point).summary = {
                order_pay_sum_total: summary.order_pay_sum_total + a.order_pay_sum_total
              }

            } else {
              let day = {}

              day[this.convertDate(a.order_date, 'YYYY-MM-DD')] = a

              newRows.push({
                account_id: a.account_id,
                account_title: a.account_title,
                point_title: a.point_title,
                order_point: a.order_point,
                dates: day,
                summary: {
                  order_pay_sum_total: a.order_pay_sum_total
                }
              })
            }
          }

        })
        this.series = []

        newRows.forEach((v, k) => {
          this.series.push({
            name: this.group_by === 'order_seller' ? this.formatName(v.account_title) : v.point_title,
            data: []
          })

          this.dates.forEach(i => {
            this.series[k].data.push({
              x: moment(i).format('YYYY-MM-DD'),
              y: 0
            })
          })

          Object.entries(v.dates).forEach(i => {
            this.series[k].data.map(t => {
              if (t.x === this.convertDate(i[1].order_date, 'YYYY-MM-DD')) {
                t.y = i[1].order_pay_sum_total
              }
            })
          })

        })

        this.setDate()
        report.response.rows = newRows

        this.reportDone = true

        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
