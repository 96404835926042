<template>
    <div class="input" v-bind:class="{ 'error': !!inputError }">
      <label v-bind:for="id" class="input-placeholder">{{text}}</label>
        <textarea
            v-on:input="$emit('update:modelValue', inputValue)"
            v-bind:class="{ 'empty': isEmpty, required: isRequired }"
            v-model="inputValue"
            v-bind:name="name"
            v-bind:id="id"></textarea>
        <div class="msg-error" v-if="inputError">{{inputError}}</div>
    </div>
</template>

<script>
    export default {
        name: 'Textarea',

        props: {
            name: String,
            text: String,
            value: {
              default: ''
            },
            modelValue: {
              default: ''
            },
            required: String,
            filterType: {
                type: String,
                default: 'eq'
            }
        },

        computed: {
            isEmpty() {
                return !this.inputValue
            }
        },
      emits: ['update:modelValue'],

      watch: {
        modelValue() {
          this.inputValue = this.modelValue;
          this.$emit('update:modelValue', this.inputValue)
        }
      },

      created() {
        if (!this.$root.$children) {
          this.$root.$children = [this];
        } else {
          this.$root.$children.push(this);
        }
      },

        data () {
            return {
                id: `inp_${this.name.toLowerCase()}`,
                inputValue: this.modelValue || '',
                inputError: '',
                isRequired: this.isTrue(this.required),
                filterTypeValue: this.filterType,
            }
        },

        methods: {
            clear() {
                this.inputValue = '';
            },

            isTrue(str) {
                return (str) && (['1', 't', 'true', 'y', 'yes'].indexOf(str) > -1);
            },

            getValue() {
                return this.inputValue;
            },

            setValue(val) {
                this.inputValue = val;
            },

            setError(val) {
                this.inputError = val;
            },

            clearError() {
                this.inputError = '';
            },

            getFilterType() {
                return this.filterTypeValue;
            }
        }
    }
</script>