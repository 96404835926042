<template>
  <div>

    <div class="line-tabs" id="tabs">
      <router-link to="/adm/settings/staff/list" :class="{current: isTab('list')}">Все сотрудники</router-link>
      <router-link to="/adm/settings/staff/schedule" :class="{current: isTab('schedule')}">Расписание</router-link>
      <router-link to="/adm/settings/staff/bonus" :class="{current: isTab('bonus')}">Бонусы</router-link>
    </div>

    <List v-if="isTab('list')" />
    <Schedule v-if="isTab('schedule')" />
    <Bonus v-if="isTab('bonus')" />

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import List from "@/pages/adm/settings/tabs/staff/List";
import Schedule from "@/pages/adm/settings/tabs/staff/Schedule";
import Bonus from "@/pages/adm/settings/tabs/staff/Bonus";



export default {
  mixins: [mixins],

  components: {
    Schedule,
    Bonus,
    List

  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab2 || 'list';
    }
  },

  data() {
    return {
      tab: this.$route.params.tab2 || 'list',
    }
  },

  methods: {
    isTab(tab) {
      return this.tab === tab;
    },
  }
}
</script>
