<template>

    <div class="content">
      <h1 class="content-title">
        <router-link :to="'/adm/orders'">Заказы</router-link>
        <i></i>
        Заказ от {{ convertDate(data.order.order_date) }} <span>{{ data.order.order_id }}</span>

        <b v-if="data.order.order_partial_pay && data.order.order_pay_sum != data.order.order_partial_sum" :class="`status status_paused`">
          Ожидает полной оплаты
        </b>
        <b v-else :class="`status status_${data.order.order_status}`">{{status[data.order.order_status]}}</b>
      </h1>


      <div class="line-tabs" id="tabs">
        <a  class="current">Общая информация</a>
      </div>

      <div class="line-tab-con">
        <div class="form">
          <div class="form-left">

           <div class="input-group">
             <Input name="flight_id" text="Рейс" required="true" v-model.trim="data.order.flight_id" />
             <div>
               <label for="inp_flight_id" class="input-placeholder"> </label>
               <button class="btn btn-primary" v-on:click="reloadFlight">Обновить информацию о рейсе</button>
             </div>
           </div>
            <Input name="order_tickets" text="Изначально назначенные места" required="true" v-model.trim="data.order.order_tickets" />
            <Input name="order_transfer" text="Места после пересадки" required="true" v-model.trim="data.order.order_transfer" />

            <Input
                name="order_date_ot"
                text="Дата и время отправления"
                v-model="data.order.order_date_ot"
            />

            <div class="window-form-s">
              <div class="schedule" style="width: 100%;display: flex;flex-wrap: nowrap;">
                <div class="item" style="flex: 258px 0 0;margin-right: 20px" v-for="(row, index) in schedule.calendar" v-bind:key="index">
                  <div class="cal-tour">
                    <div class="my_cal_box">
                      <div class="title">
                        <b>{{row.mount}} {{row.year}}</b>
                      </div>
                      <div><b>Пн</b></div>
                      <div><b>Вт</b></div>
                      <div><b>Ср</b></div>
                      <div><b>Чт</b></div>
                      <div><b>Пт</b></div>
                      <div><b>Сб</b></div>
                      <div><b>Вс</b></div>

                      <template v-for="(row, index) in row.days">

                        <template v-if="row.times">
                          <button
                              v-bind:key="index"
                              :disabled="!row.times.length"
                              class="time-on"
                              v-on:click="
                          times = row.times;
                          activeTime = false;
                          activeDate = row.date;
                          "
                              :class="{active: activeDate === row.date}"
                              :data-time="activeDate === row.date ? (times = row.times) : ''"
                          >
                            {{row.day || ''}}
                          </button>
                        </template>

                        <template v-else>
                          <div v-bind:key="index">
                            {{row.day || ''}}
                          </div>
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <div class="times" v-if="times">
                <div
                    v-for="(t, i) in times"
                    v-bind:key="i"
                    v-on:click="
                  activeTime = convertDate(t[0], 'HH:mm');
                  activeFlight = t[1].flight_id;
                  this.data.order.flight_id = t[1].flight_id;
                  reloadFlight()

              "
                    :class="{
                    active: activeTime === convertDate(t[0], 'HH:mm')
              }"
                >
                  {{convertDate(t[0], 'HH:mm')}}
                </div>
              </div>

            </div>

            <Input name="order_title" text="Экскурсия" required="true" v-model.trim="data.order.order_title" />

            <Input name="order_address" text="Адрес" required="true" v-model.trim="data.order.order_address" />

            <AutosuggestMulti v-if="data.order.place_data" :multiple="false" ref="excursion_place" v-model="data.order.place_data_set" :value="data.order.place_data_set" name="excursion_place" text="Место посадки" :url="'/misc/place?ids=' + data.order.place_data.map(i => {return i.value}).join(',')" v-on:input="changePlaceIds" />

            <Input name="order_place_time" mask="##:##" text="Время посадки" required="true" v-model.trim="data.order.order_place_time" />

            <Input name="order_client_title" text="ФИО" required="true" v-model.trim="data.order.order_client_title" />
            <Input name="order_client_phone" mask="+#(###)###-##-##" text="Телефон" required="true" v-model="data.order.order_client_phone" />

            <Input name="order_client_title" text="ИД платежа Юкасса" required="true" v-model.trim="data.order.order_pay_resp_id" />
            <Select
                text="Оплата"
                class="mb-0"
                id="pay_type"
                name="pay_type"
                required="true"
                :options="[['cash', 'Наличными кассиру'], ['card', 'Картой кассиру'], ['online', 'Онлайн']]"
                track-by="0"
                label="1"
                v-model="data.order.order_pay_type"
            />

            <Input name="order_seller" text="№ продавца" type="number" required="true" v-model.trim="data.order.order_seller" />



            <div class="input">
              <label class="input-placeholder">Транспорт</label>
              <b>
                {{data.order.transport_data ? data.order.transport_data[0].label : '-'}}
              </b>
            </div>

            <Textarea name="order_comment" text="Примечание" required="true" v-model.trim="data.order.order_comment" />
          </div>
          <div class="form-right mw500">
            <div class="peoples">
              <div>
                <b>{{count}}</b>
                Всего
              </div>
              <div>
                <b>{{data.order.order_adult}}</b>
                Взрослые
              </div>
              <div>
                <b>{{data.order.order_children_2}}</b>
                Дети {{data.order.excursion_age_children_s+1}}-{{data.order.excursion_age_children_e}} лет
              </div>
              <div>
                <b>{{data.order.order_children_1}}</b>
                Дети {{data.order.excursion_age_children_free_s}}-{{data.order.excursion_age_children_free_e+1}} лет<br> с местом
              </div>
              <div>
                <b>{{data.order.order_children_free}}</b>
                Дети {{data.order.excursion_age_children_free2_s}}-{{data.order.excursion_age_children_free2_e+1}} лет<br> без места
              </div>
            </div>

            <hr>
            <div class="peoples">
              <div>
                <b>{{formatRUB(data.order.order_sum_all)}}</b>
                Всего
              </div>
              <div>
                <b style="white-space: nowrap">
                  <template v-if="data.order.order_partial_pay && data.order.order_pay_sum != data.order.order_partial_sum">
                    {{formatRUB(data.order.order_partial_sum)}} из
                  </template>
                  {{formatRUB(data.order.order_pay_sum)}}
                </b>
                Оплачено
              </div>
              <div>
                <b>{{formatRUB(data.order.order_sum_all - data.order.order_sum_pre)}}</b>
                Гиду
              </div>
              <div>
                <b>{{formatRUB(data.order.order_sum_sale)}}</b>
                Скидка
              </div>
            </div>

            <hr>

            <div class="input-group">
              <Input name="order_adult" type="number" text="Взрослые" required="true" v-model.trim="data.order.order_adult" />
              <Input name="order_children_2" type="number" :text="`Дети ${data.order.excursion_age_children_s+1}-${data.order.excursion_age_children_e}`" required="true" v-model.trim="data.order.order_children_2" />

              <Input name="order_children_1" type="number" :text="`Дети ${data.order.excursion_age_children_free_s}-${data.order.excursion_age_children_free_e+1} с местом`" required="true" v-model.trim="data.order.order_children_1" />
              <Input name="order_children_free" type="number" :text="`Дети ${data.order.excursion_age_children_free2_s}-${data.order.excursion_age_children_free2_e+1} без места`" required="true" v-model.trim="data.order.order_children_free" />
            </div>

            <hr>

            <div class="input-group">
              <Input name="order_pay_sum" text="Предоплата" required="true" type="number" icon2="₽" v-model.trim="data.order.order_pay_sum" v-on:input="changePaySum" />

              <Input v-if="data.order.excursion_prepay_allow === 'true'" name="order_children_1" text="Всего" required="true" type="number" icon2="₽" v-model.trim="data.order.order_sum_all" />

              <Input name="order_sum_sale" text="Скидка" required="true" type="number" icon2="₽" v-model.trim="data.order.order_sum_sale" />
            </div>

            <div class="form-hr">
              <div>
                <div class="checkbox">
                  <label>
                    <input
                        type="checkbox"
                        name="order_partial_pay"
                        value="true"
                        v-model="data.order.order_partial_pay"
                    >
                    <span>частичная оплата</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="input-group">
              <Input name="order_pay_sum" text="Оплачено итого" required="true" type="number" icon2="₽" v-model.trim="data.order.order_partial_sum" />
              <Input name="order_pay_sum" text="Оплачено кассиру наличкой" required="true" type="number" icon2="₽" v-model.trim="data.order.order_partial_sum_save" />
            </div>

            <hr>

            <div class="mb-20">
              <label
                  class="status input-placeholder mb-20"
                  :class="`status_${count === newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).length ? 'order' : count < newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).length ? 'waiting_for_capture' : 'pending'}`">
                Выбранные места ({{newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).length}} / {{count}})
              </label>
             <div>
               <template v-if="newTickets.find(x => x === true)">
                 {{newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).join(', ') }}
               </template>
               <template v-else>
                 Места не выбраны
               </template>
             </div>
            </div>

            <div class="input-group order">
              <div>
                <div class="place">
                  <div>
                    <template  v-for="(index) in 80">
                      <label
                          class="place-item"
                          v-if="(index) % 4 && (index+1) % 4"
                          v-bind:key="index"
                      >
                        <input type="checkbox" v-model="newTickets[index]" :disabled="data.order.tickets && (data.order.tickets.find(x => x === index))" :value="index" :id="'p1' + index" />
                        <i class="place-item_cont">
                          {{index}}
                        </i>
                      </label>
                    </template>

                  </div>
                  <div>
                    <template v-for="(index) in 82">
                      <label
                          class="place-item"
                          v-if="(index-1) % 4 && (index-2) % 4"
                          v-bind:key="index" >
                        <input type="checkbox" v-model="newTickets[index]" :disabled="data.order.tickets && (data.order.tickets.find(x => x === index ))" :value="index" :id="'p1' + index" />
                        <i class="place-item_cont">
                          {{index}}
                        </i>
                      </label>
                    </template>
                  </div>
                </div>
              </div>

              <div>
                <div class="place">
                  <div>
                    <template  v-for="(index) in 78">
                      <label
                          class="place-item"
                          v-if="(index) % 4 && (index+1) % 4"
                          v-bind:key="index"
                      >
                        <input
                            type="checkbox"
                            v-model="newTickets[index+82]"
                            :disabled="data.order.tickets && (data.order.tickets.find(x => x === (index+82)))" :value="index + 82" :id="'p2' + (index + 82)" />
                        <i class="place-item_cont">
                          {{index+82}}
                        </i>
                      </label>
                    </template>

                  </div>
                  <div>
                    <template v-for="(index) in 78">
                      <label
                          class="place-item"
                          v-if="(index-1) % 4 && (index-2) % 4"
                          v-bind:key="index"
                      >
                        <input type="checkbox" v-model="newTickets[index+82]" :disabled="data.order.tickets && (data.order.tickets.find(x => x === (index+82) ))" :value="index + 82" :id="'p2' + (index + 82)" />
                        <i class="place-item_cont">
                          {{index+82}}
                        </i>
                      </label>
                    </template>
                  </div>
                </div>
              </div>
              <div>
                <div class="place">

                </div>
              </div>
            </div>
          </div>



          <div class="form-submit" v-if="ac('orders.edit')">
            <button :disabled="saved || !newTickets.find(x => x === true)" class="btn btn-primary" v-on:click.prevent="save">
              <template v-if="saved">
                Сохраняю
              </template>
              <template v-else>
                Сохранить
              </template>
            </button>
            <span v-if="!newTickets.find(x => x === true)">
              Места не выбраны
            </span>
            <div class="checkbox" v-if="data.order.order_status === 'waiting_for_capture'">
              <label>
                <input type="checkbox" name="pay_success" v-model="pay_success" value="1">
                <span>Подтвердить оплату</span>
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>

</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import moment from "moment";

import Input from "../../../components/Input";

import Select from "../../../components/Select";
import AutosuggestMulti from "@/components/AutosuggestMulti";
import Textarea from "@/components/Textarea";

import $ from "jquery";

export default {
  mixins: [mixins],

  components: {
    Textarea,
    AutosuggestMulti,
    Input,
    Select
  },


  created() {

    if (this.$route.params.id > 0) {
      this.fetchData();
    }

  },

  data() {
    return {
      times: null,
      activeTime: false,
      flightData: null,
      activeEx: null,
      activeFlight: null,
      activeDate: null,

      schedule: {
        schedule_all: [],
        calendar: [],
        times: [
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ]
        ],
        mount: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ],
        days: [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье'
        ],
        schedule_types: [
          {
            id: '',
            title: 'Выбрать'
          },
          {
            id: 'all',
            title: 'Ежедневно'
          },
          {
            id: 'reply_day',
            title: 'Каждый X день недели'
          }
        ]
      },
      data: {
        order: {
          flight_id: null,
          order_date_ot: null,
          order_pay_type: null,
          order_place_time: null,
          order_id: null,
          order_client_title: null,
          order_client_phone: null,
          order_client_mail: null,
          order_date: null,
          order_place: null,
          transport_id: null,
          transport_data: null
        },
      },
      pay_success: false,
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован',
        'order': 'Оплачен',
        'cancel': 'Отменён',
        'cancel_success': 'Возврат',
        'waiting_for_capture': 'Оплачен, не подтверждён',
        'pending': 'Ожидает оплаты'
      },
      statusOption: [
        {
          id: 'order',
          label: 'Подтвердить оплату'
        },
        {
          id: 'cancel',
          label: 'Отменить заказ'
        }
      ],
      newTickets: [],
      error: null,
      report: null,
      count: 1,
      saved: false
    }
  },

  methods: {
    createCalendar() {

      this.promoData = null

      this.schedule.calendar = [];


      let sm = parseInt(moment().format('M'));
      let em = parseInt(moment().add(3, 'months').format('M'));
      let y = parseInt(moment().format('YYYY'));

      for (let i = 0; i <= (em - sm); i++) {
        let mount = sm + i;
        let mountDate = moment().day(-1).month(mount - 1);

        let days = [];
        let countDays = mountDate.daysInMonth();
        let firstDay = mountDate.startOf('month').isoWeekday()-1

        for (let i = 0; i <= countDays + firstDay; i++) {

          let day = i - firstDay;

          let date = y + '-' + (mount < 10 ? '0' + mount : mount) + '-' + (day < 10 ? '0' + day : day);
          let timeData = Object.entries(this.activeEx.dates).filter(i => i[0].indexOf(date) > -1)

          days[i-1] = {
            day: day > 0 ? day : false,
            date: date,
            mount: mount,
            weekend: (mountDate.day(i).isoWeekday() > 5),
            times: timeData
          }
        }

        this.schedule.calendar[i] = {
          mount: this.schedule.mount[mount - 1],
          year: y,
          days: days
        }
      }

    },

    changePaySum() {
      if (this.data.order.order_partial_pay && this.data.order.order_partial_sum_old === this.data.order.order_pay_sum_old) {
        this.data.order.order_partial_sum = this.data.order.order_pay_sum
      }
    },
    changePlaceIds(val) {
      this.data.order.order_place = val.value;

      if (this.data.order.place_time && this.data.order.place_time['t' + val.value]) {
        let i = this.data.order.place_time['t' + val.value];
        let time = 0
        if (i.indexOf('-') > -1) {
          time = moment(this.data.order.order_date_ot).subtract(i.replace('-', ''), 'minutes').format('HH:mm')
        } else {
          time = moment(this.data.order.order_date_ot).add(i, 'minutes').format('HH:mm')
        }

        this.data.order.order_place_time = time

      }
    },
    changeTransportIds(val) {
      this.data.order.transport_id = val;
    },

    async fetchData() {
      let urls = {
        'order': `/order/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);

      if (this.data.order.order_place) {
        if (!this.data.order.order_place_time) {
          this.changePlaceIds({value: this.data.order.order_place})
        }
      } else {
        this.data.order.order_place_time = this.data.order.order_place_time ? this.data.order.order_place_time : moment(this.data.order.order_date_ot).format('HH:mm')
      }

      this.data.order.order_date_ot_b = this.data.order.order_date_ot

      this.data.order.order_date_ot = moment(this.data.order.order_date_ot).format('YYYY-MM-DD HH:mm')

      this.data.order.order_client_title = this.data.order.order_client_title || this.data.order.account_title
      this.data.order.order_client_phone = this.data.order.order_client_phone || this.data.order.user_phone
      this.data.order.order_client_mail = this.data.order.order_client_mail || this.data.order.user_login

      this.data.order.order_partial_sum_old = this.data.order.order_partial_sum
      this.data.order.order_pay_sum_old = this.data.order.order_pay_sum

      this.data.order.order_partial_pay = this.data.order.order_partial_pay ? true : false


      if (this.data.order.tickets) {
       // this.data.order.tickets.push(1, 2)
      } else {
        this.data.order.tickets = []
      }

      this.count =
          this.data.order.order_adult +
          (this.data.order.order_children_1 || 0) +
          (this.data.order.order_children_2 || 0) +
          (this.data.order.order_children_3 || 0) +
          (this.data.order.order_children_free || 0)

      if (this.data.order.order_tickets) {

        let a = this.data.order.order_tickets.replaceAll(' ', '')
        a = a.split(',')

        for (let i = 1; i < 160; i++) {
          if (a.indexOf(i+'') > -1) {
            this.newTickets[i] = true
          }
        }



      } else {
        let count = this.count;

        for (let i = 1; i < 160; i++) {
          if (!this.data.order.tickets.find(x => x === i)) {

            if (count === 1) {
              this.newTickets[i] = true
              return;
            }

            if (count > 1) {
              let free = [];
              let tmp = 0;
              let tmpIndex = 0;
              let type = i % 2 === 0;

              while (tmp < count) {
                let r = i + tmp;
                if (!type) {
                  if (tmp !== 0) {
                    r = (tmp) % 2 === 0 ? (i + tmp) + tmp: (i + tmp);
                    if (free.length && r < free[tmpIndex - 1]) {
                      r = free[tmpIndex - 1] + 1;
                    } else {
                      if (free.length && r - 2 === free[tmpIndex - 2]) {
                        r = r + 2;
                      }
                    }
                  }
                } else {
                  if (tmp !== 0) {
                    r = (tmp) % 2 === 0 ? (i + tmp) : (i + tmp) + (tmp > 3 ? 0 : 2);
                    if (free.length && r < free[tmpIndex - 1]) {
                      r = free[tmpIndex - 1] + (free[tmpIndex - 1] % 2 === 0 ? 3 : 1);
                    } else {
                      if (free.length && r - 2 === free[tmpIndex - 2]) {
                        r = r + 2;
                      }
                    }
                  }
                }
                if (!this.data.order.tickets.find(x => x === r)) {
                  free[tmpIndex++] = r;
                }
                tmp++;
              }

              if (free.length === count) {

                let resCount = 0;
                while (resCount < free.length) {
                  this.newTickets[free[resCount++]] = true
                }
                return;
              }
            }
          }
        }
      }
      this.activeEx = this.data
      this.activeTime = this.convertDate(this.data.order.order_date_ot, 'HH:mm')
      this.activeFlight = this.data.order.flight_id

       this.activeDate = this.convertDate(this.data.order.order_date_ot, 'YYYY-MM-DD')
      //'ex':

      let ur = {
        'ex': `flights/report?filters%5Bschedule_date%5D=bw%7C${moment().format('YYYY-MM-DD')}%2000%3A00%3A00%3B${moment().add(14, 'days').format('YYYY-MM-DD')}%2023%3A59%3A59&filters%5Bexcursion_publication%5D=eq%7Cenabled&filters%5Bexcursion_id%5D=eq%7C${this.data.order.excursion_id}&order%5Bfield%5D=excursion_id&order%5Border%5D=desc&page=1&limit=100`,
      };

      this.data.ax = await Ajax.getBulk(ur);


      $.each(this.data.ax.ex.rows, function (k, v) {

        if (v.dates && v.dates.length) {
          let newDates = {}
          $.each(v.dates, (t, date) => {
            let keys = Object.keys(date[0]);

            if (keys && keys.length) {
              if (newDates[keys[0]]) {
                newDates[keys[0]].tickets = newDates[keys[0]].tickets.concat(date[0][keys[0]].tickets)
                newDates[keys[0]].count = newDates[keys[0]].tickets.length
              } else {
                newDates[keys[0]] = date[0][keys[0]]
                if (date[0][keys[0]].tickets[0] === 0) {
                  newDates[keys[0]].tickets = []
                }
                newDates[keys[0]].count = newDates[keys[0]].tickets ? newDates[keys[0]].tickets.length : 0
              }
            }

          })

          this.data.ax.ex.rows[k].dates = newDates;

        }

      }.bind(this))



      if (this.data.ax.ex) {
        this.activeEx = this.data.ax.ex.rows?.[0]
      }


      this.createCalendar();
    },

    async reloadFlight() {
      let flightData = await Ajax.get(`/flight/${this.data.order.flight_id}`);

      if (flightData && flightData.response) {

        let urls = {
          'reserved': `/flights/reserved_seats?fl=${this.data.order.flight_id}&tr=${new Date().getTime()}&total=1`,
          'excursion': `/excursion/${flightData.response.excursion_id}`
        };

        this.flightData = await Ajax.getBulk(urls);

        if (this.flightData && this.flightData.reserved && this.flightData.excursion) {
          this.data.order.order_date_ot = this.convertDate(flightData.response.schedule_date, 'YYYY-MM-DD HH:mm')
          this.data.order.order_title = this.flightData.excursion.excursion_title
          this.data.order.excursion_id = this.flightData.excursion.excursion_id
          this.newTickets = []
          this.data.order.tickets = this.flightData.reserved.rs;
        }

      }

    },

    async save() {
      if (this.ac('orders.edit')) {
        this.saved = true;
        this.clearError();

        let data = {
          flight_id: this.data.order.flight_id,
          order_tickets: this.newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).join(', '),

         // order_tickets: this.data.order.order_tickets,

          order_client_phone: this.data.order.order_client_phone.length ? this.data.order.order_client_phone.match(/[\d]/g).join('') : '',
          order_client_title: this.data.order.order_client_title,
          order_date_ot: this.data.order.order_date_ot !== this.data.order.order_date_ot_b ? moment(this.data.order.order_date_ot).format('YYYY-MM-DD HH:mm') : moment(this.data.order.order_date_ot).format('YYYY-MM-DD HH:mm'),
          order_id: this.data.order.order_id,
          excursion_id: this.data.order.excursion_id,
          order_place_time: this.data.order.order_place_time,
          order_comment: this.data.order.order_comment,
          order_title: this.data.order.order_title,
          transport_id: this.data.order.transport_id ? this.data.order.transport_id.value : '',
          order_place: this.data.order.order_place,
          order_seller: this.data.order.order_seller,
          pay_success: this.pay_success,
          order_type: true,
          order_pay_type: this.data.order.order_pay_type,

          order_adult:  this.data.order.order_adult,

          order_children_1:  this.data.order.order_children_1,
          order_children_2:  this.data.order.order_children_2,
          order_children_free:  this.data.order.order_children_free,

          order_pay_sum:  this.data.order.order_pay_sum,
          order_sum_sale:  this.data.order.order_sum_sale,
          order_sum_all:  this.data.order.excursion_prepay_allow === 'true' ? this.data.order.order_sum_all : this.data.order.order_pay_sum,
          order_sum_pre:  this.data.order.order_pay_sum,
        };

        if (this.data.order.order_partial_pay) {
          data.order_partial_pay = this.data.order.order_partial_pay
          data.order_partial_sum = this.data.order.order_partial_sum
          data.order_partial_sum_save = this.data.order.order_partial_sum_save
        }


        let list = [];
        list.push(Ajax.post(`/order/save/${this.data.order.order_id || '0'}`, data));

        let [userResult] = await Promise.all(list);

        if (userResult.error) {
          this.setError(userResult.error);
        } else {
          this.fetchData()
        }

        if (userResult.response) {
          if (this.$route.params.id === 'edit') {
            this.$router.push({ path: '/adm/orders/' + userResult.response.order_id + '' });
            this.$toast.success({content:`Заказ №${this.data.order.order_id} добавлен.`});
          } else {
            this.$toast.success({content:`Заказ №${this.data.order.order_id} обновлён.`});
          }
        }

        setTimeout(function () {
          this.saved = false
        }.bind(this), 2000)
      }
    },

  }
}
</script>

<style>
.time-on:disabled {
  background: #fff;
}
.time-on.active {
  background: var(--buttonPrimary);
}
.times {
  padding: 10px 0 30px;
  display: flex;
}
.schedule > .item {
  padding-bottom: 0;
}
.times > div {
  border-radius: 10px;
  padding: 5px 12px;
  cursor: pointer;
}
.times > div.active {
  background: var(--buttonPrimary);
}
</style>
