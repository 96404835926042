<template>
  <div class="content">

    <h1 class="content-title">
      <router-link :to="'/adm/q'">Вопросы пользователей</router-link>
      <i></i>

      <template v-if="$route.params.id > 0">
        {{ data.q.q_name }} <span>{{ convertDate(data.q.q_date) }}</span> <span>{{ data.q.q_id }}</span>
      </template>

    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="getTabLink('general')" v-bind:class="{current: isTab('general')}">Общая информация</router-link>
    </div>

    <div class="line-tab-con">


      <div class="form">
        <div class="form-left">
          <Input required="true" name="q_name" v-model="data.q.q_name" text="ФИО" />
          <Input required="true" name="q_phone" v-model="data.q.q_phone" text="ФИО" />
          <Input required="true" name="q_mail" v-model="data.q.q_mail" text="ФИО" />

          <Textarea name="q_message" v-model="data.q.q_message"></Textarea>
        </div>
      </div>

    </div>

  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Input from "@/components/Input";
import Textarea from "@/components/Textarea";


export default {
  mixins: [mixins],

  components: {
    Textarea,
    Input
  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab || 'general';
      this.fetchData();
    }
  },

  created() {

    if (this.$route.params.id > 0) {
      this.fetchData();
    }

  },



  data() {
    return {
      data: {

        q: {
          q_message: null,
          q_id: null,
          q_name: null,
          q_mail: null,
          q_phone: null,
        },
      },
      tab: this.$route.params.tab || 'general',
      error: null,
      report: null
    }
  },

  methods: {

    getTabLink(tab) {
      return `/adm/q/${this.$route.params.id}/${tab}`;
    },

    isTab(tab) {
      return this.tab === tab;
    },

    async fetchData() {
      let urls = {
        'q': `/q/${this.$route.params.id}`
      };

      this.data = await Ajax.getBulk(urls);
    },

  }
}
</script>
