<template>
    <div class="content">
      <div class="content-header">
        <h1 class="content-title">
          Вопросы пользователей
        </h1>
      </div>

      <Report ref="report" url="q/report" :def-params="{order: {field: 'q_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <Loading />
        <div v-if="error">
          {{error}}
        </div>
        <div class="full-width-content table-wrapper" v-else>
          <div class="table-scrollable">
            <table class="table" cellpadding="0" cellspacing="0" width="100%">
              <thead>
              <tr>
                <OrderTd text="№" field="q_id"  width="50" />
                <OrderTd text="ФИО" field="q_name" />
                <OrderTd text="Телефон" field="q_phone" />
                <OrderTd text="Почта" field="q_mail" />
                <OrderTd text="Дата создания" field="q_date" />
                <OrderTd text="Обновлено" field="ts" />
                <OrderTd text="Статус" field="q_status" width="100" />
                <td></td>
              </tr>
              <tr class="filter-row">
                <InputFilter name="q_id" />
                <InputFilter name="q_name" filter-type="like" />
                <InputFilter name="q_phone" filter-type="like" />
                <InputFilter name="q_mail" filter-type="like" />
                <PeriodPickerFilter name="q_date" />
                <PeriodPickerFilter name="ts" />
                <SelectFilter name="account_status" :options="[['new', 'Новый'], ['showed', 'Просмотрен'], ['answered', 'Отвечено']]" track-by="0" label="1" />
                <td></td>
              </tr>
              </thead>
              <tbody v-if="report">
              <tr v-if="report.rows_count === 0">
                <td colspan="8">
                  Не найдено.
                </td>
              </tr>

              <template v-else>
                <tr v-for="(row, index) in report.rows" v-bind:key="index">
                  <td>{{row.q_id}}</td>
                  <td>
                    <div class="row-wrap" v-bind:data-title="row.q_name">
                      <div>
                        <router-link :to="'/adm/q/' + row.q_id">{{row.q_name}}</router-link>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="row-wrap" v-bind:data-title="row.q_phone">
                      <div>
                        {{formatPhone(row.q_phone)}}
                      </div>
                    </div>
                  </td>
                  <td>
                    {{row.q_mail}}
                  </td>
                  <td>{{convertDate(row.q_date)}}</td>
                  <td>{{convertDate(row.ts)}}</td>

                  <td>
                    <div class="status" v-bind:class="`status_${row.q_status}`">
                      {{data.status[row.q_status]}}
                    </div>
                  </td>


                  <td>
                    <div class="nav-right">
                      <a class="nav"></a>
                      <div class="nav-con">
                        <router-link :to="'/adm/q/' + row.q_id">
                          <template v-if="ac('q.edit')">
                            Редактировать
                          </template>
                          <template v-else>
                            Просмотр
                          </template>
                        </router-link>
                        <a href="#" v-on:click.prevent="changeStatus(row.q_id, 'new')" v-if="row.q_status !== 'new' && ac('q.status')">Изменить статус на "новый"</a>
                        <a href="#" v-on:click.prevent="changeStatus(row.q_id, 'showed')" v-if="row.q_status !== 'showed' && ac('q.status')">Изменить статус на "просмотрено"</a>
                        <a href="#" v-on:click.prevent="changeStatus(row.q_id, 'answered')" v-if="row.q_status !== 'answered' && ac('q.status')">Изменить статус на "отвечено"</a>

                        <a href="#" v-if="ac('q.delete')"  v-on:click.prevent="changeStatus(row.q_id, 'deleted')">Удалить</a>
                      </div>
                    </div>
                  </td>
                </tr>

              </template>
              </tbody>
            </table>
          </div>

          <Pager />
        </div>
      </Report>
    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Loading from '../../../components/report/Loading';
import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import InputFilter from '../../../components/report/InputFilter';
import SelectFilter from '../../../components/report/SelectFilter';
import PeriodPickerFilter from '../../../components/report/PeriodPickerFilter';
import OrderTd from '../../../components/report/OrderTd';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    PeriodPickerFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  data() {
    return {
      data: {
        status: {
          'new': 'Новый',
          'showed': 'Просмотрен',
          'answered': 'Отвечено'
        },
      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/q/save/${id}`, {
            q_id: id,
            q_status: status
          });
        }
      } else {
        await Ajax.post(`/q/save/${id}`, {
          q_id: id,
          q_status: status
        });
      }

      this.getReport().reload();

    },

  }
}
</script>
