<template>
  <div class="content">

    <h1 class="content-title">
      <router-link :to="'/adm/delivery'">Заявки на доставку</router-link>
      <i></i>

      <template v-if="$route.params.id > 0">
        {{ data.delivery.delivery_id }}
      </template>
      <template v-else>
        новая заявка
      </template>
    </h1>

    <div class="line-tabs" id="tabs">
      <router-link :to="getTabLink('general')" v-bind:class="{current: isTab('general')}">Общая информация</router-link>
    </div>

    <div class="line-tab-con">

      <div class="form" v-if="$route.params.id === 'new'">

        <div class="form-left">

          <AutosuggestMulti
              :multiple="false"
              ref="excursion_point"
              v-model="data.delivery.point_data"
              name="excursion_point"
              text="Точка продажи"
              url="/misc/point"
              v-on:input="changePointIds"
          />

          <AutosuggestMulti
              :multiple="false"
              ref="excursion_point"
              v-model="data.delivery.seller_data"
              name="excursion_point"
              :text="`Кассир`"
              :url="`/accounts/suggest?type=staff&sub=3`"
              v-on:input="changeSellerIds"
          />

          <Report ref="report" url="delivery_product/report" :def-params="{order: {field: 'delivery_product_id', order: 'desc'}, filters: {delivery_product_status: 'eq|active'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
            <Loading />
            <div v-if="error">
              {{error}}
            </div>
            <div  v-else>
              <div>
                <table cellpadding="0" cellspacing="0" width="100%">
                  <thead>
                  <tr>
                    <td>Наименование</td>
                    <td>Кол-во</td>
                  </tr>

                  </thead>
                  <tbody v-if="report">
                  <tr v-if="report.rows_count === 0">
                    <td colspan="8">
                      Не найдено.
                    </td>
                  </tr>

                  <template v-else>
                    <tr v-for="(row, index) in report.rows" v-bind:key="index">
                      <td>
                        <div class="radio">
                          <label>
                            <input type="checkbox" name="showAll" v-model="data.delivery.product_data[row.delivery_product_id]" v-on:change="setProductData">
                            <span></span>
                            <div>{{row.delivery_product_title}}</div>
                          </label>
                        </div>
                      </td>
                      <td>
                        <Input required="true" type="number" name="delivery_product_title" text="" v-model="data.delivery.product_data_count[row.delivery_product_id]" v-on:change="setProductData" />
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>

              <Pager />
            </div>
          </Report>

        </div>
        <div class="form-submit">
          <input type="hidden" name="delivery_id" v-model.number="data.delivery.delivery_id" />
          <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
        </div>

      </div>
      <div class="form" v-else>
        <div class="form-left">
          <Input required="true" name="r" text="Кассир" v-model="data.delivery.account_title" disabled="true" />
          <Input required="true" name="r2" text="Точка продажи" v-model="data.delivery.point_title" disabled="true" />
          <table class="table" cellspacing="0" cellpadding="0" width="100%">
            <thead>
            <tr>
              <td>Наименование</td>
              <td>Кол-во</td>
              <td>Статус</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in data.delivery.items" v-bind:key="index">
              <td>{{item.delivery_product_title}}</td>
              <td>{{item.delivery_item_count}}</td>
              <td>{{statuses[item.delivery_item_status]}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import AutosuggestMulti from "@/components/AutosuggestMulti";
import Loading from "@/components/report/Loading";
import Pager from "@/components/Pager";
import Report from "@/components/report/Report";
import Input from "@/components/Input";

export default {
  mixins: [mixins],

  components: {
    AutosuggestMulti,
    Loading,
    Pager,
    Report,
    Input
  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab || 'general';
      this.fetchData();
    }
  },

  created() {
    if (this.$route.params.id > 0) {
      this.fetchData();
    }
  },



  data() {
    return {
      statuses: {
        new: 'Ожидает',
        done: 'Собран',
      },
      data: {
        delivery: {
          delivery_id: null,
          point_data: null,
          seller_data: null,
          product_data: {},
          product_data_count: {},
          products: []
        },
      },
      tab: this.$route.params.tab || 'general',
      error: null,
      report: null
    }
  },

  methods: {

    setProductData() {
      this.data.delivery.products = [];
      Object.entries(this.data.delivery.product_data).forEach(i => {
        if (i[1]) {
          this.data.delivery.products.push({
            delivery_product_id: i[0],
            delivery_product_count: this.data.delivery.product_data_count[i[0]]
          })
        }
      })
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }
    },

    changePointIds(val) {
      if (val) {
        this.data.delivery.point_id = val && val.value ? val.value : val;
        if (val && val.place) {
          this.place_data = val.place[0]
        } else {
          this.place_data = null
        }
      }
    },

    changeSellerIds(val){
      if (val) {
        this.data.delivery.account_id = val && val.value ? val.value : val;
      }
    },


    changeAccountIds(){
      this.show = false

      setTimeout(() => {
        this.show = true
      }, 1)
    },

    async save() {
      this.clearError();

      let data = this.data.delivery;

      let list = [];

      delete data['delivery_status'];

      list.push(Ajax.post(`/delivery/save/${this.data.delivery.delivery_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }
      if (response.response) {

        if (this.$route.params.id === 'new') {
          this.$router.push({ path: '/adm/delivery/' + response.response.delivery_id });
          this.$toast.success({content:`Заявка "${response.response.delivery_id}" добавлена.`});
        } else {
          this.$toast.success({content:`Заявка "${response.response.delivery_id}" обновлена.`});
        }
      } else {
        this.$toast.error({content:`Произошла какая-то ошибка:(`});
      }

    },

    getTabLink(tab) {
      return `/adm/delivery/${this.$route.params.id}/${tab}`;
    },

    isTab(tab) {
      return this.tab === tab;
    },

    async fetchData() {
      let urls = {
        'delivery': `/delivery/${this.$route.params.id}`
      };

      this.data = await Ajax.getBulk(urls);
    },

  }
}
</script>

<style>
table .input {
  margin: 0;
}
table .input label {
  display: none;
}
</style>