<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/site/blog'">Блог</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.post.blog_post_title }} <span>{{ data.post.blog_post_id }}</span>
      </template>

      <template v-else>
        Новый пост
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <a v-on:click="setTab('general')" v-bind:class="{current: tab === 'general'}">Общая информация</a>
      <a v-on:click="setTab('seo')" v-bind:class="{current: tab === 'seo'}">SEO</a>
    </div>

    <div class="form">

      <template v-if="tab === 'general'">
        <div class="form-left_w100">
          <label class="input-placeholder">Содержимое</label>
          <VueEditor
              useCustomImageHandler
              @image-added="handleImageAdded"
              v-model="data.post.blog_post_text"
          >
            <div id="toolbar-container">
              <button class="ql-bold" data-toggle="tooltip" data-placement="bottom" title="Bold"></button>
              <button class="ql-italic" data-toggle="tooltip" data-placement="bottom" title="Add italic text <cmd+i>"></button>
            </div>
          </VueEditor>
        </div>
        <div class="form-right">
          <Input name="blog_post_title" text="Название" required="true" v-model.trim="data.post.blog_post_title" />
          <Input name="blog_post_url" text="URL" required="true" v-model.trim="data.post.blog_post_url" />
          <Select
              id="blog_post_category"
              text="Категория"
              name="blog_post_category"
              required="true"
              :options="data.category"
              track-by="blog_category_id"
              label="blog_category_title"
              v-model="data.post.blog_post_category"
          />
          <label class="input-placeholder">Привью</label>
          <img class="w100" v-if="data.post.blog_post_image" :src="domain+'/img/blog/list/' + data.post.blog_post_image">

          <input type="file" id="blog_post_image" accept=".jpg, .jpeg, .png, .webp" @change="prepareInputImage" />
          <label for="blog_post_image" class="btn btn-middle" :class="imgLoader ? 'label-loader' : ''">Выбрать изображение</label>

        </div>

      </template>

      <template v-if="tab === 'seo'">
        <div class="form-left">
          <Input name="blog_post_seo_title" text="SEO Title" required="true" v-model.trim="data.post.blog_post_seo_title" />
          <Textarea name="blog_post_seo_desc" text="SEO Description" v-model.trim="data.post.blog_post_seo_desc" />
          <Textarea name="postblog_post_seo_keyword" text="SEO keywords" v-model.trim="data.post.blog_post_seo_keyword" />
        </div>
      </template>

      <div class="form-submit" v-if="ac('site.site_blog.edit')">
        <input type="hidden" name="blog_post_id" v-model.number="data.post.blog_post_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>

<script>

import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";

import Select from "../../../../components/Select";
import { VueEditor } from "vue3-editor";
import $ from 'jquery';

export default {
  mixins: [mixins],

  components: {
    Input,
    Select,
    Textarea,
    VueEditor
  },

  created() {
    this.fetchData();
  },

  data() {
    return {
      imgLoader: false,
      data: {
        category: [],
        post: {
          blog_post_id: null,
          blog_post_title: null,
          blog_post_text: '',
          blog_post_category: null,
          blog_post_url: null,
          blog_post_seo_title: null,
          blog_post_seo_desc: null,
          blog_post_seo_keyword: null,
          blog_post_image: null
        },
      },
      tab: 'general',
      error: null,
      report: null,
      tooltip: {
        'ql-bold': 'Жирный',
        'ql-italic': 'Курсив',
        'ql-underline': 'Нижнее подчёркивание',
        'ql-strike': 'Перечёркнутый',
        'ql-align': 'Слева',
        'center': 'по центру',
        'right': 'Справа',
        'justify': 'Заполнить',
        'ql-blockquote': 'Кавычки',
        'ql-code-block': 'Код',
        'ordered': 'Нумерованный список',
        'bullet': 'Список',
        'check': 'Список выбора',
        '-1': 'Убрать отступ слева',
        '+1': 'Добавить отступ слева',
        'ql-link': 'Ссылка',
        'ql-image': 'Изображение',
        'ql-video': 'Видео',
        'ql-clean': 'Очистить стили',
        'ql-color': 'Цвет текста',
        'ql-background': 'Цвет заливки текста'
      }
    }
  },

  methods: {

    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('file_category', 'post_main');
      formData.append('file_name', new Date().getTime());

      let list = [];

      list.push(Ajax.post(`/settings/excursion/upload/`, formData));

      let [response] = await Promise.all(list);

      if (response && response.response) {

        const url = '../_site/vue/public/img/blog/preview/' + response.response.filename;
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      }
    },

    async prepareInputImage(event) {

      if (event.target.files) {
        this.imgLoader = true;

        let formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('file_category', 'post_main');
        formData.append('file_name', new Date().getTime());

        let list = [];

        list.push(Ajax.post(`/settings/excursion/upload/`, formData));

        let [response] = await Promise.all(list);

        if (response && response.response) {

          this.data.post.blog_post_image = response.response.filename;
          this.imgLoader = false;
        }

      }
    },

    async fetchData() {
      let urls = {
        'category': `/blog-category/all`,
      };


      if (this.$route.params.id) {
        urls.post = `/blog/${this.$route.params.id}`;
      }

      this.data.tmp = await Ajax.getBulk(urls);

      if (this.$route.params.id) {
        this.data.post = this.data.tmp.post;
      }

      if (this.data.tmp.category) {
        this.data.category = this.data.tmp.category
      }

      this.data.post.blog_post_seo_title = this.data.post.blog_post_seo_title ? this.data.post.blog_post_seo_title : this.data.post.blog_post_title;
      this.data.post.blog_post_seo_desc = this.data.post.blog_post_seo_desc ? this.data.post.blog_post_seo_desc : (this.data.post.blog_post_text ? this.data.post.blog_post_text.replace(/<(.|\n)*?>/g, '').slice(0, 155) : '');


      $('.ql-formats button, span.ql-color-picker').each(function (k,v) {
        let title = '';
        if (v.value) {
          title = this.tooltip[v.value]

        } else {
          title = this.tooltip[v.classList[0]]
        }

        $(v).attr('data-tp', title)
        $(v).on('mouseenter', function () {
          $(this).attr('data-tp', title)
        })

      }.bind(this))

    },
    setTab(tab) {
      this.tab = tab;
    },
    async save() {
      this.clearError();

      this.data.post.blog_post_seo_title = this.data.post.blog_post_seo_title ? this.data.post.blog_post_seo_title : this.data.post.blog_post_title;
      this.data.post.blog_post_seo_desc = this.data.post.blog_post_seo_desc ? this.data.post.blog_post_seo_desc : (this.data.post.blog_post_text ? this.data.post.blog_post_text.replace(/<(.|\n)*?>/g, '').slice(0, 155) : '');

      let data = this.data.post;

      let list = [];

      delete data['blog_post_status'];

      list.push(Ajax.post(`/site/blog/save/${this.data.post.blog_post_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {

        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.data.post.blog_post_id = response.response.blog_post_id;
          this.$router.push({ path: '/adm/site/blog/edit/' + response.response.blog_post_id });
          this.$toast.success({content:`Пост "${this.data.post.blog_post_title}" добавлен.`});
        } else {
          this.$toast.success({content:`Пост "${this.data.post.blog_post_title}" обновлён.`});
        }
      }
    },
  }
}
</script>

<style>
  #blog_post_image {
    display: none;
  }
</style>