<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/settings/transport'">Транспорт</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.transport.transport_title }} <span>{{ data.transport.transport_id }}</span>
      </template>

      <template v-else>
        Новый транспорт
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>

    <div class="form">
      <div class="form-left">
        <Input name="transport_title" text="Наименование" required="true" v-model.trim="data.transport.transport_title" />
        <Input name="transport_number" text="Гос. номер" required="true" v-model.trim="data.transport.transport_number" />

        <AutosuggestMulti v-model="data.transport.account_data" :value="data.transport.account_data" ref="account_id" name="account_id" text="Водители" url="/accounts/suggest?type=staff" v-on:input="changeAccountIds" />

        <Select
            id="subdivision_id"
            text="Подразделение"
            name="subdivision_id"
            required="true"
            :options="subdivision"
            track-by="subdivision_id"
            label="subdivision_title"
            v-model="data.transport.subdivision_id"
        />

        <Select
            id="transport_floor"
            text="Этажность"
            name="transport_floor"
            required="true"
            :options="[[1, '1 этаж'], [2, '2 этажа']]"
            track-by="0"
            label="1"
            v-model="data.transport.transport_floor"
        />

        <div class="input-group">
         <div>
            <Input name="transport_place1" text="Кол-во мест 1 этажа" required="true" v-model.trim.number="data.transport.transport_place1" />
         </div>
         <div>
           <Input v-if="data.transport.transport_floor > 1" name="transport_place2" text="Кол-во мест 2 этажа" v-model.trim.number="data.transport.transport_place2" />
         </div>
        </div>


        <template v-if="separator.length">
          <div v-for="(v, i) in separator" class="input-group schedule_time" v-bind:key="i" :class="i > 0 ? 'schedule_time-middle' : ''">
            <Input name="separator_value[]" v-on:input="floorFix(i)" :text="i === 0 ? 'Настройки столов и проходов' : ''" required="true" v-model.trim.number="separator[i].value" />
            <Select
                text=""
                name="separator_type[]"
                v-model="separator[i].type"
                :options="[
                   ['table', 'Стол'],
                   ['exit', 'Выход'],
                   ['sofa', 'Задний диван']
                ]"
                trackBy="0"
                label="1"
            />
            <div>
              <label class="input-placeholder"></label>
              <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="changeSeparator(i)">{{i === 0 ? '+' : '-'}}</a>
            </div>
          </div>
        </template>
      </div>



      <div class="form-right">

        <div class="input-group">
          <div v-if="data.transport.transport_floor > 1">
            <label class="input-placeholder">Места 2 этажа</label>
            <div class="place">
              <div>
                <template v-for="(index) in data.transport.transport_place2">
                  <label
                      v-if="(index) % 4 && (index+1) % 4"
                      class="place-item"
                      :class="{
                        exit_margin: separator.find(s => ((s.value === index + 3 || s.value === index + 4 ) && s.type === 'exit')),
                        reverse: separator.find(s => ((s.value === index + 3 || s.value === index + 4 ) && s.type === 'table')),
                        table_m: separator.find(s => s.value === index && s.type === 'table'),

                        sofa_part1: separator.find(s => s.value === (index) && s.type === 'sofa'),
                        sofa_part2: separator.find(s => s.value === (index)-1 && s.type === 'sofa'),
                        sofa_part5: separator.find(s => s.value === (index)-4 && s.type === 'sofa'),
                        exit: separator.find(s => s.value === index && s.type === 'exit')
                      }"
                      v-bind:key="index"
                  >
                    <input type="checkbox" v-model="data.transport.transport_place2_reserve" :value="index" :id="'r1' + index" />
                    <i class="place-item_cont">
                      {{index}}
                      <input type="text" v-model="data.transport.transport_place_title[index]" />
                    </i>
                  </label>
                </template>

              </div>

              <div>

                <template v-for="(index) in data.transport.transport_place2">
                  <label
                      v-if="(index-1) % 4 && (index-2) % 4"
                      class="place-item"
                      :class="{
                        exit_margin: separator.find(s => (( s.value === index + 3 || s.value === index + 4 ) && s.type === 'exit')),
                        reverse: separator.find(s => (( s.value === index + 3 || s.value === index + 4 ) && s.type === 'table')),
                        table_m: separator.find(s => s.value === index && s.type === 'table'),
                        sofa_part3: separator.find(s => s.value === (index)-2 && s.type === 'sofa'),
                        sofa_part4: separator.find(s => s.value === (index)-3 && s.type === 'sofa'),
                        sofa_part5: separator.find(s => s.value === (index)-4 && s.type === 'sofa'),
                        exit: separator.find(s => s.value === index && s.type === 'exit')
                      }"
                      v-bind:key="index"
                  >
                    <input type="checkbox" v-model="data.transport.transport_place2_reserve" :value="index" :id="'r1' + index" />
                    <i class="place-item_cont">
                      {{index}}
                      <input type="text" v-model="data.transport.transport_place_title[index]"  />
                    </i>
                  </label>
                </template>
              </div>
            </div>
          </div>
          <div>

            <label class="input-placeholder">Места 1 этажа</label>
            <div class="place">
              <div>
                <template v-for="(index) in data.transport.transport_place1">
                  <label
                      v-if="(index) % 4 && (index+1) % 4"
                      class="place-item"
                      :class="{
                          sofa_part1: separator.find(s => s.value === (index) + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) && s.type === 'sofa'),
                          sofa_part2: separator.find(s => s.value === (index) + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0)-1 && s.type === 'sofa'),
                          sofa_part5: separator.find(s => s.value === (index) + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0)-4 && s.type === 'sofa'),
                          exit_margin: separator.find(s => ((s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) + 3 || s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) + 4 ) && s.type === 'exit')),
                          reverse: separator.find(s => ((s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) + 3 || s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) + 4 ) && s.type === 'table')),
                          table_m: separator.find(s => s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) && s.type === 'table'),
                          exit: separator.find(s => s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) && s.type === 'exit')
                      }"
                      v-bind:key="index"
                  >
                    <input type="checkbox" v-model="data.transport.transport_place2_reserve" :value="index" :id="'p1' + index" />
                    <i class="place-item_cont">
                      {{index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0)}}
                      <input type="text" v-model="data.transport.transport_place_title[index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0)]" />
                    </i>
                  </label>
                </template>
              </div>
              <div>

                <template v-for="(index) in data.transport.transport_place1">
                  <label
                      v-if="(index-1) % 4 && (index-2) % 4"
                      class="place-item"
                      :class="{
                        sofa_part3: separator.find(s => s.value === (index) + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0)-2 && s.type === 'sofa'),
                        sofa_part4: separator.find(s => s.value === (index) + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0)-3 && s.type === 'sofa'),

                        exit_margin: separator.find(s => ((s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) + 3 || s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) + 4 ) && s.type === 'exit')),
                        reverse: separator.find(s => ((s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) + 3 || s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) + 4 ) && s.type === 'table')),
                        table_m: separator.find(s => s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) && s.type === 'table'),
                        exit: separator.find(s => s.value === index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0) && s.type === 'exit')
                      }"
                      v-bind:key="index"
                  >
                    <input type="checkbox" v-model="data.transport.transport_place2_reserve" :value="index" :id="'p1' + index" />
                    <i class="place-item_cont">
                      {{index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0)}}
                      <input type="text" v-model="data.transport.transport_place_title[index + (data.transport.transport_floor > 1 ? data.transport.transport_place2 : 0)]" />
                    </i>
                  </label>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-submit" v-if="ac('settings.settings_transport.edit')">
        <input type="hidden" name="transport_id" v-model.number="data.transport.transport_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import AutosuggestMulti from '../../../../components/AutosuggestMulti';



export default {
  mixins: [mixins],

  components: {
    Input,
    Select,
    AutosuggestMulti
  },


  created() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    this.loadSubdivision();
  },

  data() {
    return {
      separator: [{
        value: null,
        type: 'table'
      }],
      data: {
        transport: {
          transport_id: null,
          transport_title: null,
          transport_floor: 1,
          transport_place1_reserve: [],
          transport_place2_reserve: [],
          transport_place_title: [],
          subdivision_id: null,
          account_id: null,
          account_data: [],
          transport_separator: []
        },
        account: {
          account_id: null,
          account_title: null,
          account_post: null,
          account_subdivision: null
        },
        user: {
          user_id: null,
          account_id: null,
          user_phone: null,
          user_password: null
        }
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      subdivision: [],
      error: null,
      report: null
    }
  },

  methods: {

    floorFix(e){
      if (this.data.transport.transport_floor > 1 && this.data.transport.transport_place2 % 2 && this.separator[e].value > this.data.transport.transport_place2) {
        if (this.separator[e].value % 2) {
          this.separator[e].value--
        }
      }
    },

    changeSeparator(i) {
      if (i === 0) {
        this.separator[this.separator.length] = {
          value: '',
          type: 'table'
        }
       /* this.$set(this.separator, this.separator.length, {
          value: '',
          type: 'table'
        })*/

      } else {
        this.separator.splice(i, 1)
      }
    },
    changeAccountIds(val) {
      this.data.transport.account_id = val.map(({value}) => value);
    },

    async fetchData() {
      let urls = {
        'transport': `/transport/${this.$route.params.id}`,
      };


      this.data = await Ajax.getBulk(urls);
      if (!this.data.transport.transport_place_title) {
        this.data.transport.transport_place_title = [];
      }

      if (!this.data.transport.transport_place1_reserve) {
        this.data.transport.transport_place1_reserve = [];
      }
      if (!this.data.transport.transport_place2_reserve) {
        this.data.transport.transport_place2_reserve = [];
      }
      if (this.data.transport.transport_separator) {
        this.separator = this.data.transport.transport_separator.map((d) => {
          if (d.type !== 'service') {
            if (d.value) {
              d.value = parseInt(d.value)
            } else {
              d.value = ''
            }

            return d
          } else {
            return false
          }
        })

        if (!this.separator[this.separator.length-1]) {
          this.separator.splice(this.separator.length-1, 1)
        }

        this.separator.map((i, d) => {
          if (i.type !== 'service') {
            this.floorFix(d)
          }
        })

      }
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {
        this.subdivision = result.response;
      }
    },


    async save() {
      this.clearError();

      let data = this.data.transport;


      if (!this.separator[this.separator.length-1]) {
        this.separator.splice(this.separator.length-1, 1)
      }

      data.transport_separator = this.separator

      let list = [];

      delete data['transport_status'];

      list.push(Ajax.post(`/settings/transport/save/${this.data.transport.transport_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/transport/edit/' + response.response.transport_id });
          this.$toast.success({content:`Транспорт "${this.data.transport.transport_title}" добавлен.`});
        } else {
          this.$toast.success({content:`Транспорт "${this.data.transport.transport_title}" обновлён.`});
        }

        if (this.data.transport.transport_separator) {
          this.separator = this.data.transport.transport_separator.map((d) => {
            if (d.type !== 'service') {
              if (d.value && d.value.length) {
                d.value = parseInt(d.value)
              } else {
                d.value = 0
              }
              return d
            } else {
              return false
            }
          })

          if (!this.separator[this.separator.length-1]) {
            this.separator.splice(this.separator.length-1, 1)
          }


          this.separator.map((i, d) => {
            if (i.type !== 'service') {
              this.floorFix(d)
            }
          })

        }
      }

    },

  }
}
</script>
