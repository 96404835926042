<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/settings/price_type'">Типы цен</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.price_type.price_type_title }} <span>{{ data.price_type.price_type_id }}</span>
      </template>

      <template v-else>
        Новый тип цен
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>

    <div class="form">
      <div class="form-left">
        <Input name="price_type_title" text="Наименование" required="true" v-model.trim="data.price_type.price_type_title" />
      </div>

      <div class="form-submit" v-if="ac('settings.settings_price_type.edit')">
        <input type="hidden" name="price_type_id" v-model.number="data.price_type.price_type_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";

export default {
  mixins: [mixins],

  components: {
    Input
  },


  created() {

    if (this.$route.params.id) {
      this.fetchData();
    }

  },

  data() {
    return {
      data: {
        price_type: {
          price_type_id: null,
          price_type_title: null,
          price_type_geo: [],
          price_type_address: null,
          subdivision_id: null
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null
    }
  },

  methods: {

    async fetchData() {
      let urls = {
        'price_type': `/price_type/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);
    },


    async save() {
      this.clearError();


      let data = this.data.price_type;

      let list = [];

      delete data['price_type_status'];

      list.push(Ajax.post(`/settings/price_type/save/${this.data.price_type.price_type_id || '0'}`, data));


      let [response] = await Promise.all(list);

      if (response.error) {

        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/price_type/edit/' + response.response.price_type_id });
          this.$toast.success({content:`Тип цен "${this.data.price_type.price_type_title}" добавлен.`});
        } else {
          this.$toast.success({content:`Тип цен "${this.data.price_type.price_type_title}" обновлён.`});
        }
      }

    },

  }
}
</script>
