<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/settings/point'">Точки продаж</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.point.point_title }} <span>{{ data.point.point_id }}</span>
      </template>

      <template v-else>
        Новая точка продаж
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>

    <div class="form">
      <div class="form-left">
        <Input name="point_title" text="Наименование" required="true" v-model.trim="data.point.point_title" />
        <Input name="point_address" text="Адрес" required="true" v-model.trim="data.point.point_address" />
        <Input name="point_geo" text="Координаты" required="true" v-model.trim="data.point.point_geo" />

        <AutosuggestMulti
            v-if="data.point"
            :multiple="true"
            ref="point_place"
            v-model="data.point.place_data"
            :value="data.point.place_data"
            name="point_place"
            text="Места посадки"
            url="/misc/place"
            v-on:input="changePlaceIds"
        />

        <Select
            id="subdivision_id"
            text="Подразделение"
            name="subdivision_id"
            required="true"
            :options="subdivision"
            track-by="subdivision_id"
            label="subdivision_title"
            v-model="data.point.subdivision_id"
        />

      </div>

      <div class="form-submit" v-if="ac('settings.settings_point.edit')">
        <input type="hidden" name="point_id" v-model.number="data.point.point_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import AutosuggestMulti from '../../../../components/AutosuggestMulti';
import Select from "../../../../components/Select";

export default {
  mixins: [mixins],

  components: {
    Input,
    AutosuggestMulti,
    Select
  },


  created() {

    if (this.$route.params.id) {
      this.fetchData();
    }

    this.loadSubdivision();
  },

  data() {
    return {
      data: {
        point: {
          point_id: null,
          point_title: null,
          point_geo: [],
          point_address: null,
          subdivision_id: null,
          point_place: null,
          place_data: null
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      subdivision: [],
      error: null,
      report: null
    }
  },

  methods: {

    changePlaceIds(val) {
      this.data.point.point_place = val.map(({value}) => value);
    },

    async fetchData() {
      let urls = {
        'point': `/point/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {
        this.subdivision = result.response;
      }
    },

    async save() {
      this.clearError();

      let gps = this.data.point.point_geo;
      this.data.point.point_geo = (typeof gps === 'string') ? gps.split(',') : gps;

      let data = this.data.point;
      let list = [];

      delete data['point_status'];

      list.push(Ajax.post(`/settings/point/save/${this.data.point.point_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {

        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/point/edit/' + response.response.point_id });
          this.$toast.success({content:`Точка продаж "${this.data.point.point_title}" добавлена.`});
        } else {
          this.$toast.success({content:`Точка продаж "${this.data.point.point_title}" обновлена.`});
        }
      }
    },
  }
}
</script>

