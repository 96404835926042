<template>
  <div>
    <Report ref="report" url="finance/bonus/report" :def-params="{order: {field: 'order_seller', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />

      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>

        <div class="table-scrollable">
          <table class="table bonus-report" cellpadding="0" cellspacing="0" width="100%">
            <thead>

            <tr>
              <OrderTd text="№" field="order_seller" width="100" />
              <OrderTd text="Продавец" field="account_title" />
              <OrderTd class="ar" text="Взрослые" field="adult" />
              <OrderTd class="ar" text="Дети" field="child" />
              <OrderTd class="ar" text="Всего людей" field="total_people" />
              <OrderTd class="ar" text="Бонус" field="bonus" />
              <OrderTd class="ar" text="Мега бонус" field="bonus_bonus" />
              <OrderTd class="ar" text="Итого" field="total_bonus" />
            </tr>
            <tr>
              <InputFilter name="order_seller" />
              <InputFilter name="account_title" filter-type="like" />
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            </thead>
            <tbody v-if="report">
              <tr v-if="report.rows_count === 0">
                <td colspan="8">
                  Не найдено.
                </td>
              </tr>
            </tbody>
            <template v-if="report && report.rows_count > 0">
             <tbody>
             <tr>
               <td></td>
               <td></td>
               <td>
                 <b>{{report.summary.trx_adult}}</b>
               </td>
               <td>
                 <b>{{report.summary.trx_child}}</b>
               </td>
               <td>
                 <b>{{report.summary.trx_total_people}}</b>
               </td>
               <td class="ar">
                 <b>{{formatRUB(report.summary.trx_bonus)}}</b>
               </td>
               <td class="ar">
                 <b>{{formatRUB(report.summary.trx_mega_bonus)}}</b>
               </td>
               <td class="ar">
                 <b>{{formatRUB(report.summary.trx_total_bonus)}}</b>
               </td>
             </tr>
             </tbody>
              <tbody v-for="(row, index) in report.rows" v-bind:key="index">
                <tr :class="{open_row: row.showDetail}">
                  <td>
                    {{row.order_seller}}
                  </td>
                  <td>
                    <a v-on:click.prevent="row.showDetail = !row.showDetail">{{row.account_title}}</a>
                  </td>
                  <td>
                    {{row.adult}}
                  </td>
                  <td>
                    {{row.child}}
                  </td>
                  <td>
                    {{row.total_people}}
                  </td>

                  <td class="ar">
                    {{formatRUB(row.bonus)}}
                  </td>
                  <td class="ar">
                    {{formatRUB(row.mega_bonus)}}
                  </td>
                  <td class="ar">
                    <b>{{formatRUB(row.total_bonus)}}</b>
                  </td>
                </tr>

                <tr class="bonus-report" v-show="row.showDetail">
                  <td colspan="8">
                    <table  width="100%" cellpadding="0" cellspacing="0">
                      <tbody v-for="(r, index) in row.detail_bonus" v-bind:key="index">
                      <tr>
                        <td width="100">{{ r.bonus_id }}</td>
                        <td><a v-on:click.prevent="r.showDetail = !r.showDetail">{{ r.excursion_title }}</a></td>
                        <td>
                          {{r.adult}}
                        </td>
                        <td>
                          {{r.child}}
                        </td>
                        <td>
                          {{r.adult + r.child}}
                        </td>
                        <td class="ar">
                          {{formatRUB(r.bonus)}}
                        </td>
                        <td class="ar">
                          {{formatRUB(r.mega_bonus)}}
                        </td>
                        <td class="ar">
                          <b>{{formatRUB(r.bonus + r.mega_bonus)}}</b>
                        </td>
                      </tr>
                      <tr v-for="(d, index) in r.detail" v-show="r.showDetail" v-bind:key="index">
                        <td>{{ convertDate(d.schedule_date) }}</td>
                        <td>{{ d.flight_id }}</td>
                        <td>
                          {{d.adult}}
                        </td>
                        <td>
                          {{d.child}}
                        </td>
                        <td>
                          {{d.adult + d.child}}
                        </td>
                        <td class="ar">
                          {{formatRUB(d.bonus)}}
                        </td>
                        <td class="ar">
                          {{formatRUB(d.mega_bonus)}}
                        </td>
                        <td class="ar">
                          <b>{{formatRUB(d.bonus + d.mega_bonus)}}</b>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>


              </tbody>

            </template>

          </table>
        </div>

        <Pager />
      </div>
    </Report>

    <Modal ref="editSalary">
      <h2>
        Изменение начисления №{{ salary.salary_id }}
      </h2>

      <Input name="account_title" text="Продавец" v-model="salary.account_title" readonly="true" />

      <Input type="number" name="order_id" text="Заказ" v-model="salary.order_id" />

      <Input type="number" name="salary_amount" text="Сумма" v-model="salary.salary_amount" icon2="₽" />

      <div class="form-submit">
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
        <a href="#" class="btn btn-middle" v-on:click.prevent="$refs.editSalary.hide()">Закрыть</a>
      </div>

    </Modal>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';

import InputFilter from "@/components/report/InputFilter";

import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import OrderTd from '../../../../components/report/OrderTd';
import Modal from "@/components/Modal";
import Input from "@/components/Input";

export default {
  mixins: [mixins],

  components: {
    OrderTd,
    InputFilter,
    Modal,
    Input,
    Loading,
    Pager,
    Report
  },


  data() {
    return {
      types: {
        staff: 'Кассир',
        partner: 'Партнёр'
      },
      salary: {
        salary_id: null,
        salary_amount: 0
      },
      subdivision: [
        {value: 3, label: 'Анапа'},
        {value: 4, label: 'Проспект'}
      ],

      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: [],
        subdivision_values: [],
        account: [],

      },
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null
    }
  },

  methods: {

    async save() {
      let res = await Ajax.post(`/salary/save/${this.salary.salary_id}`, this.salary);

      if (res.response) {
        this.$toast.success({content: `Обновлено`});
        this.$refs.editSalary.hide()
        this.$refs.report.reload()
      }
      if (res.error) {
        this.$toast.error({content: `Ошибка`});
        this.setError(res.error);
      }

    },


    editAmount(salary){
      this.salary = salary;
      this.$refs.editSalary.show()
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivision_values.push({value: item.subdivision_id, label: item.subdivision_title});
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {

        report.response.rows.forEach((i) => {

          let gb = []
          i.detail.forEach((d) => {
            if (gb.find(i => i.bonus_id === d.bonus_id)) {
              gb.find(i => i.bonus_id === d.bonus_id).detail.push(d)
            } else {
              gb.push({
                bonus_id: d.bonus_id,
                excursion_title: d.excursion_title,
                adult: 0,
                child: 0,
                total_people: 0,
                bonus: 0,
                mega_bonus: 0,
                total_bonus: 0,
                detail: [d]
              })
            }
          })

          gb.map(q => {
            q.detail.map(t => {
              q.adult = q.adult + t.adult
              q.child = q.child + t.child
              q.total_people = q.total_people + t.total_people
              q.bonus = q.bonus + t.bonus
              q.mega_bonus = q.mega_bonus + t.mega_bonus
              q.total_bonus = q.total_bonus + t.total_bonus
            })
          })

          i.detail_bonus = gb


        })


        this.report = report.response;




        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
