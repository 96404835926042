<template>
  <div>

      <h1 class="content-title">
        <router-link :to="'/adm/settings/staff'">Сотрудники</router-link>
        <i></i>

        <template v-if="$route.params.id">
          {{ data.account.account_title }} <span>{{ data.account.account_id }}</span>
        </template>

        <template v-else>
          Новый сотрудник
        </template>
      </h1>

      <div class="line-tabs" id="tabs">
        <a href="#" v-on:click.prevent="tab = 'general'" :class="{current: isTab('general')}">Общая информация</a>
      </div>

    <div class="form" v-if="isTab('general')">
      <div class="form-left">
        <Input name="account_title" text="ФИО" required="true" v-model.trim="data.account.account_title" />
        <Input mask="+#(###)###-##-##" name="user_phone" text="Телефон" required="true" v-model.trim="data.user.user_phone" />

        <template v-if="!$route.params.id">
          <Input name="user_password" text="Пароль" required="true" v-model.trim="data.user.user_password" />
        </template>


        <Select
            id="account_subdivision"
            text="Подразделение"
            name="account_subdivision"
            required="true"
            :options="subdivision"
            track-by="subdivision_id"
            label="subdivision_title"
            v-model="data.account.account_subdivision"
        />

        <Select
            id="account_post"
            text="Должность"
            name="account_post"
            required="true"
            :options="posts"
            track-by="post_id"
            label="post_title"
            v-model="data.account.account_post"
            v-on:input="setAccess"
        />


        <Access ref="access" v-on:input="getAccess" />

      </div>

      <div class="form-right">
        <div class="full-width-content table-wrapper" v-if="$route.params.id && data.account.account_post === 2">


          <div class="table-scrollable scroll-on scroll_height">
            <table class="table" cellpadding="0" cellspacing="0">
              <thead class="sticky">
              <tr>
                <td width="20">№</td>
                <td>Неделя</td>
                <td width="200">Экскурсии</td>
                <td width="200">Остальное</td>
              </tr>
              </thead>

              <tbody>
              <tr
                  class="row"
                  v-for="(d, i) in plan.weeks"
                  v-bind:key="i"
                  :class="{
                current_week: d.current_week
              }"
              >
                <td>{{d.weekNumber}}</td>
                <td>{{convertDate(d.start, 'DD.MM.YYYY')}} - {{convertDate(d.end, 'DD.MM.YYYY')}}</td>
                <td>
                  <Input type="number" text="" name="plan_excursion" icon2="₽" v-model="d.plan_excursion" />
                </td>
                <td>
                  <Input type="number" text="" name="plan_other" icon2="₽" v-model="d.plan_other" />
                </td>
              </tr>

              </tbody>

            </table>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div class="table-filter" v-if="$route.params.id && data.account.account_post === 2">
          <div>
            <Select
                id="plan_period"
                text=""
                name="plan_period"
                required="true"
                :options="[
                    [2023, '2023 год'],
                    [2024, '2024 год'],
                    [2025, '2025 год'],
                    [2026, '2026 год'],
                    [2027, '2027 год']
                    ]"
                track-by="0"
                add_empty="false"
                label="1"
                v-model="plan.period"
            />
          </div>
        </div>

      </div>

      <div class="form-submit" v-if="ac('settings.settings_staff.edit')">
        <input type="hidden" name="account_id" v-model="data.user.account_id" />
        <input type="hidden" name="user_id" :value="data.user.user_id" />

        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>




  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import Access from "../../../../components/Access";
import moment from "moment";

export default {
  mixins: [mixins],

  components: {
    Input,
    Access,
    Select,
  },

  created() {

    if (this.$route.params.id) {
      this.fetchData();
    }

    this.loadPosts();
    this.loadSubdivision();

  },

  data() {
    return {
      plan: {
        period: new Date().getFullYear(),
        weeks: []
      },
      period: null,
      tab: 'general',
      data: {
        account: {
          account_id: null,
          account_title: null,
          account_post: null,
          account_subdivision: null,
          weekly: null
        },
        user: {
          user_id: null,
          account_id: null,
          user_phone: null,
          user_password: null,
          user_access: null
        }
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      posts: [],
      subdivision: [],
      error: null,
      report: null
    }
  },

  watch: {
    'plan.period'() {
      this.setWeeks();
    }
  },

  methods: {

    setWeeks() {
      let weeks = [];

      let currentDay = moment([this.plan.period, 1]).startOf('year');
      let dayOfWeek = moment(currentDay).day();
      let lastDay = moment([this.plan.period, 1]).endOf('year');
      let weeksInYear = moment(`${this.plan.period}-01-01`).isoWeeksInYear();
      let daysToAdd = 7 - dayOfWeek;
      let currentDate = moment();

      for (let weekNumber = 1; weekNumber < weeksInYear + 1; weekNumber++) {
        let endOfWeek = moment(currentDay).add(daysToAdd, 'days');

        if (moment(endOfWeek).year() !== this.plan.period) {
          endOfWeek = lastDay;
        }
        let userRow = null

        if (this.data.user && this.data.user.weekly) {
          userRow = this.data.user.weekly.find(i => {
           if ( moment(i.plan_date).toDate() >= currentDay.toDate() && moment(i.plan_date).toDate() <= endOfWeek.toDate()) {
             return i
           }
          })
        }

        weeks.push({
          weekNumber,
          start: currentDay.toDate(),
          end: endOfWeek.toDate(),
          current_week: currentDate >= currentDay.toDate() && currentDate <= endOfWeek.toDate(),
          plan_excursion: userRow ? userRow.plan_excursion : 0,
          plan_other: userRow ? userRow.plan_other : 0
        });
        currentDay = endOfWeek.add(1, 'day');
        daysToAdd = 6;
      }

      this.plan.weeks = weeks
    },

    getAccess(value) {
      this.data.user.user_access = value
    },
    setAccess(id) {
      let r = this.posts.find(i => i.post_id === parseInt(id));
      if (r && r.post_access) {
        this.$refs.access.setValue(r.post_access)
      }
    },

    async fetchData() {
      let urls = {
        'account': `/accounts/${this.$route.params.id}`,
        'user': `/users/by_account/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);

      if (this.data.user.user_access) {
        this.$refs.access.setValue(this.data.user.user_access)
      }

      this.setWeeks();

    },

    async loadPosts() {
      let result = await Ajax.get('/account/get-post');
      if (result.response) {
        this.posts = result.response;
      }
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {
        this.subdivision = result.response;
      }
    },

    async save() {
      this.clearError();

      this.$refs.access.getValue();

      let data = {

        user_phone: this.data.user.user_phone.match(/[\d]/g).join(''),
        account_id: this.data.user.account_id,
        user_password: this.data.user.user_password,
        user_access: this.data.user.user_access,
        account_title: this.data.account.account_title,
        account_post: this.data.account.account_post,
        account_subdivision: this.data.account.account_subdivision

      };


      if ( this.data.account.account_post === 2 ) {
        data.weekly = []

        this.plan.weeks.forEach(i => {
          data.weekly.push({
            plan_date: moment(i.start).format('YYYY-MM-DD 00:00:00'),
            plan_excursion: i.plan_excursion,
            plan_other: i.plan_other
          })
        })

      }

      let list = [];

      list.push(Ajax.post(`/settings/staff/save/${this.data.user.user_id || '0'}`, data));


      let [userResult] = await Promise.all(list);

      if (userResult.error) {

        if (userResult.error.account_id) {
          this.data.user.account_id = userResult.error.account_id;
        }
        this.setError(userResult.error);
      }

      if (userResult.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/staff/edit/' + userResult.response.account_id });
          this.$toast.success({content:`Сотрудник "${this.data.account.account_title}" добавлен.`});
        } else {
          this.$toast.success({content:`Сотрудник "${this.data.account.account_title}" обновлён.`});
        }
      }

    },

    isTab(tab) {
      return this.tab === tab;
    },


  }
}
</script>
