import User from "../user";
import $ from "jquery";

export default {

    async get(url, signal = null) {
        if (url.indexOf('/') === 0) {
            url = url.substr(1);
        }

        let resp = await fetch(`${process.env.VUE_APP_SERVER_URL}/${url}`, this.getParams('GET', null, signal));

        if (resp.status === 401) {
            if (User.isLoggedIn()) {
                await User.loadCurrentUser();
            }

            global.router.replace('/');

            return {error: {code: 401, message: 'Unauthorized'}};
        } else {
            return this.updateBalance(await resp.json());
        }
    },

    async getBulk(urls) {
        let keys = Object.keys(urls);
        let list = keys.map((key) => {
            return this.get(urls[key]);
        });

        let resp = await Promise.all(list);
        let result = {};
        for(let i = 0; i < resp.length; i++) {
            if (resp[i].response) {
                result[keys[i]] = resp[i].response;
            } else {
                throw new Error(`Response error: ${JSON.stringify(resp[0].error)}`);
            }
        }

        return result;
    },

    async post(url, data) {
        if (url.indexOf('/') === 0) {
            url = url.substr(1);
        }

        let resp = await fetch(`${process.env.VUE_APP_SERVER_URL}/${url}`, this.getParams('POST', data));

        if (resp.status === 401) {
            if (User.isLoggedIn()) {
                await User.loadCurrentUser();
            }

            return {error: {code: 401, message: 'Unauthorized'}};
        } else {
            return this.updateBalance(await resp.json());
        }
    },

    async report(url, params = {}, signal = null, force = false) {
        let link = `${url}?${$.param(params)}`;

        if (force) {
            link += '&force=1';
        }

        try {
            let resp = await this.get(link, signal);
            if ((resp.error) || (!resp.response)) {
                return resp;
            }

            if (resp.response.reportId) {
                return await this.awaitReport(resp.response.reportId, signal);
            }

            return resp;
        } catch (e) {
            if (e.name === 'AbortError') {
                return null;
            }

            return {error: {code: 500}};
        }
    },

    async awaitReport(reportId, signal) {
        let resp = await this.get(`common/report/?reportId=${reportId}`, signal);
        if ((resp.error) || (!resp.response)) {
            return resp;
        }

        if (resp.response.ready) {
            return {response: resp.response.report};
        } else {
            await delay(300, signal);
            return await this.awaitReport(reportId, signal);
        }
    },

    updateBalance(res) {
        if (typeof res.balance_current !== 'undefined') {
            User.updateBalance(res.balance_current);
        }

        return res;
    },

    getParams(method = 'GET', data = null, signal = null) {
        let params = {
            method: method,
            credentials: 'include',
            mode: 'cors',
            cache: 'no-cache',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json'
            }
        };

        if (signal) {
            params.signal = signal;
        }

        if (method === 'POST') {
            if (data instanceof FormData) {
               // params.headers['Content-Type'] = 'multipart/form-data';
                params.body = data;
            } else {
                params.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                params.body = $.param(data);
            }
        }

        return params;
    }
}

function delay(ms, signal) {
    return new Promise((resolve, reject) => {
        if (signal) {
            signal.addEventListener('abort', reject);
        }

        setTimeout(resolve, ms);
    });
}