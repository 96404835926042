<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/settings/subdivision'">Подразделения</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.subdivision.subdivision_title }} <span>{{ data.subdivision.subdivision_id }}</span>
      </template>

      <template v-else>
        Новое подразделение
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>

    <div class="form">
      <div class="form-left">
        <Input name="subdivision_title" text="Наименование" required="true" v-model.trim="data.subdivision.subdivision_title" />


      </div>


      <div class="form-submit" v-if="ac('settings.settings_subdivision.edit')">
        <input type="hidden" name="subdivision_id" v-model.number="data.subdivision.subdivision_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";

export default {
  mixins: [mixins],

  components: {
    Input,
  },


  created() {

    if (this.$route.params.id) {
      this.fetchData();
    }
    
  },

  data() {
    return {
      data: {
        subdivision: {
          subdivision_id: null,
          subdivision_title: null,
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null
    }
  },

  methods: {

    async fetchData() {
      let urls = {
        'subdivision': `/subdivision/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);

    },

    async save() {
      this.clearError();

      let data = this.data.subdivision;

      let list = [];

      delete data['subdivision_status'];

      list.push(Ajax.post(`/settings/subdivision/save/${this.data.subdivision.subdivision_id || '0'}`, data));


      let [response] = await Promise.all(list);

      if (response.error) {

        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/subdivision/edit/' + response.response.subdivision_id });
          this.$toast.success({content:`Подразделение "${this.data.subdivision.subdivision_title}" добавлено.`});
        } else {
          this.$toast.success({content:`Подразделение "${this.data.subdivision.subdivision_title}" обновлено.`});
        }
      } else {
        this.$toast.error({content:`Произошла какая-то ошибка:(`});
      }

    },

  }
}
</script>
