<template>
<div>
  <Report
      ref="report"
      url="salary/report"
      :def-params="{
        order: {
           field: 'salary_id', order: 'desc'
        },
          filters: {
          't.account_id': 'eq|' + $route.params.id
        }
      }"
      v-on:loading="reportLoading"
      v-on:done="reportLoaded"
  >
    <Loading />
    <div v-if="error">
      {{error}}
    </div>

    <div class="full-width-content table-wrapper" v-else>
      <div class="peoples" v-if="report && report.summary">
        <div class="prices-item">
          <b>{{formatRUB(report.summary.trx_income_available )}}</b>
          <div>
            Баланс
          </div>
        </div>

        <div class="prices-item">
          <b>{{formatRUB(report.summary.trx_income_freeze)}}</b>
          <div>
            Ожидает зачисления
          </div>
        </div>


        <div class="prices-item">
          <b>{{formatRUB(report.summary.trx_income_available/2 )}}</b>
          <div>
            Профит
          </div>
        </div>

        <div class="prices-item">
          <b>{{formatRUB(report.summary.trx_income_freeze/2)}}</b>
          <div>
            Ожидание профита
          </div>
        </div>

        <div class="prices-item">
          <b>{{formatRUB(report.summary.trx_outcome)}}</b>
          <div>
            Выведено за всё время
          </div>
        </div>
      </div>
      <div class="table-scrollable">
        <table class="table" cellpadding="0" cellspacing="0" width="100%">
          <thead>
          <tr>
            <td>№</td>
            <td>Кампания</td>
            <td>Дата продажи</td>
            <td>Дата зачисления</td>
            <td>Сумма</td>
            <td>Профит</td>
          </tr>
          </thead>
          <tbody v-if="report">
          <tr v-if="report.rows_count === 0">
            <td colspan="9">
              Не найдено.
            </td>
          </tr>

          <template v-else>
            <tr v-for="(row, index) in report.rows" v-bind:key="index">
              <td>{{row.salary_id}}</td>
              <td>
                {{row.campaign_title}}
              </td>

              <td>
                {{convertDate(row.ts, 'DD.MM.YYYY HH:mm')}}
              </td>

              <td>
                {{convertDate(row.salary_freeze_date, 'DD.MM.YYYY HH:mm')}}
              </td>


              <td>
                {{row.salary_amount}}₽
              </td>
              <td>
                {{row.salary_amount/2}}₽
              </td>


            </tr>

          </template>
          </tbody>
        </table>
      </div>

      <Pager />
    </div>
  </Report>
</div>
</template>



<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';


export default {
  mixins: [mixins],

  components: {

    Loading,
    Pager,
    Report
  },


  created() {
    this.loadSubdivision();
  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активен',
          'paused': 'Заблокирован',
          'order': 'Оплачен',
          'cancel': 'Отменён',
          'cancel_success': 'Возврат',
          'waiting_for_capture': 'Оплачен, не подтверждён',
          'pending': 'Ожидает оплаты'
        },


        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: [],
        places: [],
        places_f: [],
        point: [],
        point_f: [],

      },

      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      let place = await Ajax.get('/misc/place?query=');
      let point = await Ajax.get('/misc/point?query=');

      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }

      if (place) {
        this.data.places_f = place

        place.forEach((item) => {
          this.data.places[item.value] = item.label;
        });
      }

      if (point) {
        this.data.point_f = [];

        this.data.point_f.push({label: 'Сайт', value: 0})


        point.forEach((item) => {
          this.data.point_f.push(item)
          this.data.point[item.value] = item.label;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
