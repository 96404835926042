<template>
    <td :class="{ sort: !!field }" v-bind:width="width">
        <div :class="{ sort_box: checkbox }">
            <div class="checkbox" v-if="checkbox">
                <label>
                    <input type="checkbox" v-bind:name="sort">
                    <span></span>
                </label>
            </div>
            <a href="" class="dashed" v-on:click="changeOrder" v-bind:data-sort="sort" v-if="!icon && !tooltip">
                <span>{{text}}</span>
            </a>
            <a href="" class="dashed icon tooltip" v-on:click="changeOrder" v-bind:data-sort="sort" v-if="icon">
                <img v-bind:src="icon">
                <span>
                    {{text}}
                </span>
            </a>
            <a href="" class="dashed tooltip" v-on:click="changeOrder" v-bind:data-sort="sort" v-if="tooltip">
                {{text}}
                <span>
                    {{tooltip}}
                </span>
            </a>
        </div>
    </td>
</template>

<script>
    export default {
        name: 'OrderTd',

        props: {
            field: String,
            title: String,
            text: String,
            icon: String,
            tooltip: String,
            width: String,
            checkbox: String,
        },

        data () {
            return {
                sort: ''
            }
        },

      created() {
        if (!this.$root.$children) {
          this.$root.$children = [this];
        } else {
          this.$root.$children.push(this);
        }
      },

        methods: {
            changeOrder(e) {
                e.preventDefault();
                this.emitter.emit("changeOrderTd", this.field);
            }
        }
    }
</script>
