<template>
  <div>
    <h1 class="content-title">
      <router-link :to="'/adm/settings/excursions'">Экскурсии</router-link>
      <i></i>
      <template v-if="$route.params.id && $route.path.indexOf('copy') === -1">
        {{ data.excursion.excursion_title }} <span>{{ data.excursion.excursion_id }}</span>
      </template>

      <template v-else>
        Новая экскурсия
      </template>
    </h1>

    <div class="line-tabs" id="tabs">
      <a v-on:click="setTab('general')" v-bind:class="{current: tab === 'general'}">Общая информация</a>
      <a v-on:click="setTab('schedule')" v-bind:class="{current: tab === 'schedule'}">Расписание</a>
      <a v-on:click="setTab('description')" v-bind:class="{current: tab === 'description'}">Описание</a>
      <a v-on:click="setTab('images')" v-bind:class="{current: tab === 'images'}">Медиа</a>
      <a v-on:click="setTab('seo')" v-bind:class="{current: tab === 'seo'}">SEO</a>
      <a v-on:click="setTab('sales')" v-bind:class="{current: tab === 'sales'}">Продажи</a>
    </div>

    <div class="form">

      <template v-if="tab === 'general'">
        <div class="form-left">
          <Input name="excursion_title" text="Наименование" required="true" v-model.trim="data.excursion.excursion_title" />
          <Input name="excursion_alias" text="Адрес" required="true" v-model.trim="data.excursion.excursion_alias" />

          <AutosuggestMulti
              ref="excursion_city"
              v-if="load"
              v-model="data.excursion.city_data"
              :value="data.excursion.city_data"
              :multiple="true"
              name="excursion_city"
              text="Город"
              url=""
              :data="[{value: 1, label: 'Анапа'}, {value: 2, label: 'Витязево'}, {value: 3, label: 'Джемете'}]"
          />

            <div class="input-group">
              <Input name="excursion_duration_d" :icon2="declination(duration.d, duration.d_title)" text="Длительность экскурсии" required="true" v-model="duration.d" />
              <Input name="excursion_duration_h" :icon2="declination(duration.h, duration.h_title)" text="" required="true" v-model="duration.h" />
              <Input name="excursion_duration_m" :icon2="declination(duration.m, duration.m_title)" text="" required="true" v-model="duration.m" />
            </div>

          <AutosuggestMulti
              ref="excursion_place"
              v-if="load"
              v-model="data.excursion.place_data"
              :value="data.excursion.place_data"
              :multiple="true"
              name="excursion_place"
              text="Места посадки"
              url="/misc/place"
          />

          <label class="input-placeholder mb-20">Время отправления (+ минут от времени отправления)</label>

          <div v-for="(p, i) in data.excursion.place_data" v-bind:key="i">
            <div class="input-group">
              <div v-bind:data-v="!data.excursion.place_time['t'+p.value] ? data.excursion.place_time['t'+p.value] = 0 : 0">
                <Input name="place_time[]" v-bind:value="0" :text="p.label" v-model="data.excursion.place_time['t'+p.value]" />
              </div>
              <div>
              </div>
            </div>
          </div>

          <AutosuggestMulti
              ref="object_id"
              v-model="data.excursion.object_data"
              :value="data.excursion.object_data"
              name="object_id"
              text="Объекты"
              url="/misc/objects"
              v-if="load"
          />

          <Select
              id="subdivision_id"
              text="Подразделение"
              name="subdivision_id"
              required="true"
              :options="subdivision"
              track-by="subdivision_id"
              label="subdivision_title"
              v-model="data.excursion.subdivision_id"
          />

          <AutosuggestMulti
              v-if="load"
              ref="category_id"
              v-model="data.excursion.category_data"
              :value="data.excursion.category_data"
              name="category_id"
              text="Категории"
              url="/misc/category?type=category,direction"

          />
          <AutosuggestMulti
              v-if="load"
              ref="category_id_free"
              v-model="data.excursion.category_free_data"
              :value="data.excursion.category_free_data"
              name="category_id_free"
              text="Бесплатные услуги"
              url="/misc/category?type=free_service"

          />

          <div class="input-group">
            <Input name="excursion_refund" text="Отмена заказа, за" :icon2="declination(data.excursion.excursion_refund, ['час', 'часа', 'часов'])" required="true" v-model.trim="data.excursion.excursion_refund" />
            <div></div>
            <div></div>
          </div>

          <AutosuggestMulti
              ref="excursion_link"
              v-model="data.excursion.link_data"
              :value="data.excursion.link_data"
              name="excursion_link"
              text="Связанные экскурсии"
              url="/misc/excursions"
              v-if="load"
          />

          <div class="input-group">
            <Input name="excursion_sort_a" text="Приоритет" required="true" v-model.trim="data.excursion.excursion_sort_a" />
            <div></div>
            <div></div>
          </div>

        </div>

        <div class="form-right">
          <div>
            <div class="form-hr">
              <div>
                Полный тариф
              </div>
            </div>
            <div class="input-group">
              <Input name="excursion_title" icon2="₽" text="Стоимость" required="true" v-model.trim.number="data.excursion.excursion_price_adult" />
              <Input name="excursion_seller_amount_adult" icon2="₽" text="Возн. кассира" required="true" v-model.trim.number="data.excursion.excursion_seller_amount_adult" />
              <Select
                  id="excursion_seller_amount_adult_type"
                  text=""
                  name="excursion_seller_amount_adult_type"
                  required="true"
                  :options="[['percent', '%'], ['sum', '₽']]"
                  track-by="0"
                  label="1"
                  v-model="data.excursion.excursion_seller_amount_adult_type"
              />

              <Input name="excursion_partner_amount_adult" icon2="₽" text="Возн. партнёра" required="true" v-model.trim.number="data.excursion.excursion_partner_amount_adult" />
              <Select
                  id="excursion_partner_amount_adult_type"
                  text=""
                  name="excursion_partner_amount_adult_type"
                  required="true"
                  :options="[['percent', '%'], ['sum', '₽']]"
                  track-by="0"
                  label="1"
                  v-model="data.excursion.excursion_partner_amount_adult_type"
              />
            </div>
            <div class="form-hr">
              <div>
                Детский тариф 1 (0+) - с местом
              </div>
            </div>
            <div class="input-group">
              <Input name="excursion_title" icon2="₽" text="Стоимость" required="true" v-model.trim.number="data.excursion.excursion_price_children_free" />
              <Input name="excursion_seller_amount_children_free" icon2="₽" text="Возн. кассира" required="true" v-model.trim.number="data.excursion.excursion_seller_amount_children_free" />
              <Select
                  id="excursion_seller_amount_children_free_type"
                  text=""
                  name="excursion_seller_amount_children_free_type"
                  required="true"
                  :options="[['percent', '%'], ['sum', '₽']]"
                  track-by="0"
                  label="1"
                  v-model="data.excursion.excursion_seller_amount_children_free_type"
              />

              <Input name="excursion_partner_amount_children_free" icon2="₽" text="Возн. партнёра" required="true" v-model.trim.number="data.excursion.excursion_partner_amount_children_free" />
              <Select
                  id="excursion_partner_amount_children_free_type"
                  text=""
                  name="excursion_partner_amount_children_free_type"
                  required="true"
                  :options="[['percent', '%'], ['sum', '₽']]"
                  track-by="0"
                  label="1"
                  v-model="data.excursion.excursion_partner_amount_children_free_type"
              />
            </div>
            <div class="input-group">
              <Input name="excursion_age_children_free_s" text="Возраст, с" required="true" v-model.trim.number="data.excursion.excursion_age_children_free_s" />
              <Input name="excursion_age_children_free_e" text="Возраст, до - включительно" required="true" v-model.trim.number="data.excursion.excursion_age_children_free_e" />
            </div>
            <div class="form-hr">
              <div>
                Детский тариф 2 - с местом
              </div>
            </div>
            <div class="input-group">
              <Input name="excursion_title" icon2="₽" text="Стоимость" required="true" v-model.trim.number="data.excursion.excursion_price_children" />
              <Input name="excursion_seller_amount_children" icon2="₽" text="Возн. кассира" required="true" v-model.trim.number="data.excursion.excursion_seller_amount_children" />
              <Select
                  id="excursion_seller_amount_children_type"
                  text=""
                  name="excursion_seller_amount_children_type"
                  required="true"
                  :options="[['percent', '%'], ['sum', '₽']]"
                  track-by="0"
                  label="1"
                  v-model="data.excursion.excursion_seller_amount_children_type"
              />

              <Input name="excursion_partner_amount_children" icon2="₽" text="Возн. партнёра" required="true" v-model.trim.number="data.excursion.excursion_partner_amount_children" />
              <Select
                  id="excursion_partner_amount_children_type"
                  text=""
                  name="excursion_partner_amount_children_type"
                  required="true"
                  :options="[['percent', '%'], ['sum', '₽']]"
                  track-by="0"
                  label="1"
                  v-model="data.excursion.excursion_partner_amount_children_type"
              />
            </div>
            <div class="input-group">
              <Input name="excursion_age_children_s" text="Возраст, с" required="true" v-model.trim.number="data.excursion.excursion_age_children_s" />
              <Input name="excursion_age_children_e" text="Возраст, до - включительно" required="true" v-model.trim.number="data.excursion.excursion_age_children_e" />
            </div>
          </div>

          <div class="form-hr">
            <div>
              Детский тариф 3 (0+) - без места
            </div>
          </div>
          <div class="checkbox mb14">
            <label>
              <input ref="excursion_price_children_free2_allow" type="checkbox" name="excursion_price_children_free2_allow" value="on" v-model="data.excursion.excursion_price_children_free2_allow">
              <span>Разрешить продажу билетов без места</span>
            </label>
          </div>

          <template v-if="data.excursion.excursion_price_children_free2_allow">
            <div class="input-group">
             <div>
               <Input name="excursion_title" icon2="₽" text="Стоимость" required="true" v-model.trim.number="data.excursion.excursion_price_children_free2" />
             </div>

              <Input name="excursion_seller_amount_children_free2" icon2="₽" text="Возн. кассира" required="true" v-model.trim.number="data.excursion.excursion_seller_amount_children_free2" />
              <Select
                  id="excursion_seller_amount_children_free2_type"
                  text=""
                  name="excursion_seller_amount_children_free2_type"
                  required="true"
                  :options="[['percent', '%'], ['sum', '₽']]"
                  track-by="0"
                  label="1"
                  v-model="data.excursion.excursion_seller_amount_children_free2_type"
              />

              <Input name="excursion_partner_amount_children_free2" icon2="₽" text="Возн. партнёра" required="true" v-model.trim.number="data.excursion.excursion_partner_amount_children_free2" />
              <Select
                  id="excursion_partner_amount_children_free2_type"
                  text=""
                  name="excursion_partner_amount_children_free2_type"
                  required="true"
                  :options="[['percent', '%'], ['sum', '₽']]"
                  track-by="0"
                  label="1"
                  v-model="data.excursion.excursion_partner_amount_children_free2_type"
              />
            </div>
            <div class="input-group">
              <Input name="excursion_age_children_free2_s" text="Возраст, с" required="true" v-model.trim.number="data.excursion.excursion_age_children_free2_s" />
              <Input name="excursion_age_children_free2_e" text="Возраст, до - включительно" required="true" v-model.trim.number="data.excursion.excursion_age_children_free2_e" />
            </div>
            <hr>
          </template>

          <div class="checkbox mb14">
            <label>
              <input ref="excursion_prepay_allow" type="checkbox" name="excursion_prepay_allow" value="true" v-model="data.excursion.excursion_prepay_allow">
              <span>Частичная оплата</span>
            </label>
          </div>

          <template v-if="data.excursion.excursion_prepay_allow">
            <Input name="excursion_title" icon2="₽" text="Предоплата за полный тариф" required="true" v-model.trim.number="data.excursion.excursion_prepay_adult" />
            <Input name="excursion_title" icon2="₽" text="Предоплата за детский тариф (6 - 12 лет)" required="true" v-model.trim.number="data.excursion.excursion_prepay_children" />
            <Input name="excursion_title" icon2="₽" text="Предоплата за детский тариф (0 - 6 лет) - с местом" required="true" v-model.trim.number="data.excursion.excursion_prepay_children_free" />
            <Input v-if="data.excursion.excursion_price_children_free2_allow" name="excursion_title" icon2="₽" text="Детский тариф (0 - 6 лет) - без места" required="true" v-model.trim.number="data.excursion.excursion_prepay_children_free2" />
          </template>

          <hr>
          <div class="input-group">
            <Input name="excursion_sale_amount" icon2="₽" text="Скидка при покупке на сайте" required="true" v-model.trim.number="data.excursion.excursion_sale_amount" />
            <Select
                id="excursion_sale_type"
                text=""
                name="excursion_sale_type"
                required="true"
                :options="[['percent', '%'], ['sum', '₽']]"
                track-by="0"
                label="1"
                v-model="data.excursion.excursion_sale_type"
            />
          </div>



          <template v-if="data.excursion.excursion_prepay_allow">
            <Select
                id="excursion_sale_prepay_type"
                text="Скидка распространяется на:"
                name="excursion_sale_type"
                required="true"
                :options="[['all', 'стоимость экскурсии'], ['prepay', 'предоплату'], ['other', 'на остаточный платёж']]"
                track-by="0"
                label="1"
                v-model="data.excursion.excursion_sale_prepay_type"
            />
          </template>

          <div class="checkbox mb14">
            <label>
              <input ref="excursion_disable_promo" type="checkbox" name="excursion_disable_promo" value="on" v-model="data.excursion.excursion_disable_promo">
              <span>Запретить применение скидок</span>
            </label>
          </div>

        </div>

      </template>

      <template v-if="tab === 'description'">
        <div class="form-left form-left_w100">
          <label class="input-placeholder">Описание</label>
          <VueEditor v-model="data.excursion.excursion_desc" />
        </div>
        <div class="form-right">
          <label class="input-placeholder">FAQ</label>
          <div class="ex-tab-list">
            <div class="ex-tab-list_item" v-for="(tab, index) in ex_tabs" v-bind:key="index">
              <a class="ex-tab-list_remove" v-on:click.prevent="removeFaq(index)">Удалить</a>
              <Input :name="'ex_tab_title_' + index" text="Заголовок" v-model.trim="ex_tabs[index].title" />
              <label class="input-placeholder">Описание</label>
              <VueEditor v-model.trim="ex_tabs[index].desc" />
            </div>
            <a href="#" class="btn btn-primary" v-on:click.prevent="addFaq">Добавить</a>

          </div>
        </div>


      </template>

      <template v-if="tab === 'images'">

        <div class="images">

          <input type="file" id="excursion_images" accept=".jpg, .jpeg, .png, .webp" @change="prepareInputImages" />
          <label for="excursion_images" class="btn btn-middle" :class="imgLoader ? 'label-loader' : ''">Выбрать основное изображения</label>

          <div class="images-list_wrap">
            <div class="images-main">
              <div class="images-list_item images-main_item" v-if="data.excursion.excursion_images && data.excursion.excursion_images.length">
                <img :src="domain+'/img/excursion/header/' + data.excursion.excursion_images[0]">
              </div>
            </div>

            <input type="file" id="excursion_images2" multiple accept=".jpg, .jpeg, .png, .webp" @change="prepareInputImages2" />
            <label v-if="data.excursion.excursion_images && data.excursion.excursion_images.length" for="excursion_images2" class="btn btn-middle" :class="imgLoader2 ? 'label-loader' : ''">Выбрать изображения для галереи</label>

            <div class="images-list">
              <template v-for="(image, index) in data.excursion.excursion_images">
                <div class="images-list_item" :key="'file' + index" v-if="index > 0 && image.indexOf('<iframe') === -1">
                  <img :src="domain+'/img/excursion/photo/preview/' + image">
                  <a class="remove-image" v-on:click.prevent="removeImage(index)"></a>
                </div>
              </template>
            </div>

            <div class="images-list">
              <template v-for="(image, index) in data.excursion.excursion_images">
                <div class="images-list_item images-list_item__video" :key="'file' + index" v-if="index > 0 && image.indexOf('<iframe') > -1">
                  <div class="video-wrapper" v-html="image"></div>
                  <a class="remove-image" v-on:click.prevent="removeImage(index)"></a>
                </div>
              </template>
            </div>

            <div class="form-left">
              <div class="input-group">
                <Input name="media_link" text="Код для вставки видео" required="true" v-model.trim="media_link" />
                <div>
                  <label class="input-placeholder"></label>
                  <a class="btn btn-middle btn-add" v-on:click.prevent="data.excursion.excursion_images.push(media_link);media_link = ''">+</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>

      <template v-if="tab === 'schedule'">
        <div class="form-left">

          <PeriodPicker
              name="excursion_start_date"
              text="Дата начала сезона"
              @update:modelValue="(res) => {data.excursion.excursion_start_date = res; createCalendar()}"
              v-model="data.excursion.excursion_start_date"
          />

          <PeriodPicker
              name="excursion_end_date"
              text="Дата окончания сезона"
              @update:modelValue="(res) => {data.excursion.excursion_end_date = res; createCalendar()}"
              v-model="data.excursion.excursion_end_date"
          />

          <label class="input-placeholder">Время отправления</label>

          <div class="checkbox mt14 mb14">
            <label>
              <input ref="excursion_time_type" type="checkbox" name="excursion_time_type" value="different" v-model="data.excursion.excursion_time_type">
              <span>Разное время отправление</span>
            </label>
          </div>

          <template v-if="data.excursion.excursion_time_type">
            <div v-for="(row, index) in schedule.days" v-bind:key="index">
              <div class="input-group schedule_time" v-for="(time, i) in schedule.times[index]" v-bind:key="i" :class="i > 0 ? 'schedule_time-middle' : ''">
                <Input :min="0" type="number" :max="23" name="schedule_h" :icon2="declination(schedule.times[index][i].h, duration.h_title)" :text="i === 0 ? row : ''" required="true" v-model="schedule.times[index][i].h" @input="createCalendar()" />
                <Input :min="0" type="number" :max="59" name="schedule_m" :icon2="declination(schedule.times[index][i].m, duration.m_title)" text="" required="true" v-model="schedule.times[index][i].m" @input="createCalendar()" />
                <div>
                  <label class="input-placeholder"></label>
                  <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="i === 0 ? schedule.times[index].push({h: '', m: '00'}) : schedule.times[index].splice(i, 1); createCalendar()">{{i === 0 ? '+' : '-'}}</a>
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div class="input-group schedule_time" v-for="(time, i) in schedule.times[0]" v-bind:key="i" :class="i > 0 ? 'schedule_time-middle' : ''">
              <Input :min="0" type="number" :max="23" name="schedule_h" :icon2="declination(schedule.times[0][i].h, duration.h_title)" text="" required="true" v-model="schedule.times[0][i].h" @input="createCalendar()" />
              <Input :min="0" type="number" :max="59" name="schedule_m" :icon2="declination(schedule.times[0][i].m, duration.m_title)" text="" required="true" v-model="schedule.times[0][i].m" @input="createCalendar()" />
              <div>
                <label class="input-placeholder"></label>
                <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="i === 0 ? schedule.times[0].push({h: '', m: '00'}) : schedule.times[0].splice(i, 1); createCalendar()">{{i === 0 ? '+' : '-'}}</a>
              </div>
            </div>
          </template>


        </div>
        <!--div class="form-right">

          <div class="schedule">
            <div class="item" v-for="(row, index) in schedule.calendar" v-bind:key="index">
              <div class="cal-tour">
                <div class="my_cal_box">
                  <div class="title">
                    <b>{{row.mount}} {{row.year}}</b>
                  </div>
                  <div><b>Пн</b></div>
                  <div><b>Вт</b></div>
                  <div><b>Ср</b></div>
                  <div><b>Чт</b></div>
                  <div><b>Пт</b></div>
                  <div><b>Сб</b></div>
                  <div><b>Вс</b></div>

                  <div
                      v-for="(row, i) in row.days"
                      v-bind:key="i"
                      :class="{'day': row.day, 'weekend': row.weekend, 'green_day': schedule_dates[row.date], 'time-on': schedule_dates[row.date]}"
                  >
                   <span>
                     {{row.day || ''}}
                     <div class="radio" v-if="row.day">
                       <label>
                         <input type="checkbox" name="schedules" :value="row.date" :checked="schedule_dates[row.date]" v-on:change="setDate(row.date)">
                         <span></span>
                       </label>
                     </div>
                   </span>
                    <div class="tooltip-time" v-if="schedule_dates[row.date] && schedule_dates[row.date].times">
                      <div v-for="(t, i) in schedule_dates[row.date].times" v-bind:key="i">
                        <div>{{t}}</div>
                        <a class="remove" v-on:click.prevent="removeTime(row.date, i)"></a>
                      </div>
                      <a class="add" v-on:click.prevent="selectedDate = row.date">Добавить</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div-->
      </template>

      <template v-if="tab === 'seo'">
        <div class="form-left">
          <Input name="excursion_seo_title" text="SEO Title" required="true" v-model.trim="data.excursion.excursion_seo_title" />
          <Textarea name="excursion_seo_desc" text="SEO Description" v-model.trim="data.excursion.excursion_seo_desc" />
          <Textarea name="excursion_seo_keyword" text="SEO keywords" v-model.trim="data.excursion.excursion_seo_keyword" />
        </div>
      </template>

      <template v-if="tab === 'sales'">
        <div>
          <div class="input-group">
            <Input name="count_sets" text="Начало продаж с" required="true" v-model="data.excursion.excursion_start_sale" />
            <div class="d-flex flex-align-center count-btn">
              <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="data.excursion.excursion_start_sale > 0 ? data.excursion.excursion_start_sale-- : false">-</a>
              <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="data.excursion.excursion_start_sale++">+</a>
            </div>
          </div>
          <div>
            <Select
                id="excursion_publication"
                text="Публикация на сайте"
                name="excursion_publication"
                required="true"
                :options="[['enabled', 'Включить'], ['disabled', 'Выключить']]"
                track-by="0"
                label="1"
                v-model="data.excursion.excursion_publication"
            />
          </div>
          <div>
            <Input name="excursion_bage" text="Bage" required="true" v-model="data.excursion.excursion_bage" />
          </div>
        </div>
      </template>

      <div class="form-submit" v-if="ac('settings.settings_excursions.edit')">
        <input type="hidden" name="excursion_id" v-model.number="data.excursion.excursion_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

    <template v-if="selectedDate">
      <div class="window-wrap show">
        <div class="window">
          <h2>
            Добавить рейс на дату <span>{{selectedDate}}</span>
          </h2>

          <Input v-mask="'##:##'" name="new_time" text="Время отправления" required="true" v-model="new_time" ref="newTime" />

          <div class="form-submit">
            <a href="#" class="btn btn-primary" v-on:click.prevent="addTime">Сохранить</a>
            <a href="#" class="btn btn-middle" v-on:click.prevent="selectedDate = null; new_time = null">Закрыть</a>
          </div>

        </div>
      </div>
    </template>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import Select from "../../../../components/Select";

import AutosuggestMulti from '../../../../components/AutosuggestMulti';

import PeriodPicker from "../../../../components/PeriodPicker";
import moment from "moment";
import { VueEditor } from "vue3-editor";
import Textarea from "@/components/Textarea";
import $ from "jquery";

import CyrillicToTranslit from 'cyrillic-to-translit-js';
const cyrillicToTranslit = new CyrillicToTranslit();

export default {
  mixins: [mixins],

  components: {
    Textarea,
    Input,
    Select,
    VueEditor,
    AutosuggestMulti,
    PeriodPicker
  },


  watch: {

    'data.excursion.excursion_title'() {

      let url = cyrillicToTranslit.transform(this.data.excursion.excursion_title, '_').toLowerCase();
      let alias = this.data.excursion.excursion_alias ? this.data.excursion.excursion_alias.split('/') : ''

      url = url.replace(/[^a-z0-9]/g, '_').replace(/_+/, '_');

      if (!this.$route.params.id) {

        if (this.data.excursion.category_id.length) {
          let category_url = this.data.category.find(c => c.category_id === this.data.excursion.category_id[0]).category_url
          url = category_url + '/' + url;
        } else {
          if (alias.length > 1) {
            url = alias[0] + '/' + url;
          }
        }

        this.data.excursion.excursion_alias = url.substr(0, 128);
      }
    },

    'tab'() {
      if (this.tab === 'description') {
        setTimeout(function () {
          $('.ql-formats button, span.ql-color-picker').each(function (k,v) {
            let title = '';
            if (v.value) {
              title = this.tooltip[v.value]

            } else {
              title = this.tooltip[v.classList[0]]
            }

            $(v).attr('data-tp', title)
            $(v).on('mouseenter', function () {
              $(this).attr('data-tp', title)
            })

          }.bind(this))
        }.bind(this), 100)
      }
    },
    'data.excursion.excursion_time_type'() {
      this.createCalendar()
    },
    'schedule.times'() {
      this.createCalendar()
    }

  },

  created() {
    if (this.$route.params.id) {
      this.fetchData();
    } else {
      this.getCategory();
    }

    this.loadSubdivision();

  },

  data() {
    return {
      load: false,
      media_link: '',
      data: {
        excursion: {
          place_time: {},
          excursion_city: null,
          excursion_publication: 'enabled',
          excursion_start_sale: 10,
          excursion_price_children_free2_allow: true,
          excursion_price_children_free2: 0,
          excursion_prepay_adult: 0,
          excursion_prepay_allow: false,
          excursion_disable_promo: false,
          excursion_prepay_children: 0,
          excursion_prepay_children_free: 0,
          excursion_sort_a: 0,
          excursion_prepay_children_free2: 0,
          excursion_sale_amount: 0,
          excursion_sale_type: 'percent',
          excursion_sale_prepay_type: 'all',
          excursion_id: null,
          excursion_title: null,
          excursion_alias: null,
          excursion_duration: 0,
          account_id: [],
          flights_id: [],
          excursion_place: [],
          object_id: [],
          link_data: [],
          excursion_link: [],
          category_id: [],
          category_id_free: [],
          category_data: [],
          category_free_data: [],
          excursion_tabs: [],
          excursion_images: [],
          subdivision_id: null,
          excursion_start_date: null,
          excursion_end_date: null,
          excursion_time_type: false,
          excursion_time1: [],
          excursion_time2: [],
          excursion_time3: [],
          excursion_time4: [],
          excursion_time5: [],
          excursion_time6: [],
          excursion_time7: [],
          excursion_price_adult: 0,
          excursion_price_children: 0,
          excursion_price_children_free: 0,
          excursion_desc: null,
          excursion_seo_title: null,
          excursion_refund: 24,
          excursion_seo_desc: null,
          excursion_seo_keyword: null,

          excursion_seller_amount: 10,
          excursion_seller_amount_type: 'percent',

          excursion_seller_amount_adult: 0,
          excursion_seller_amount_adult_type: 'percent',
          excursion_seller_amount_children: 0,
          excursion_seller_amount_children_type: 'percent',
          excursion_seller_amount_children_free: 0,
          excursion_seller_amount_children_free_type: 'percent',
          excursion_seller_amount_children_free2: 0,
          excursion_seller_amount_children_free2_type: 'percent',

          excursion_partner_amount_adult: 10,
          excursion_partner_amount_adult_type: 'percent',
          excursion_partner_amount_children: 10,
          excursion_partner_amount_children_type: 'percent',
          excursion_partner_amount_children_free: 10,
          excursion_partner_amount_children_free_type: 'percent',
          excursion_partner_amount_children_free2: 10,
          excursion_partner_amount_children_free2_type: 'percent',
        },
        category: []
      },
      selectedDate: null,
      new_time: null,
      schedule_dates: {},
      schedule: {
        schedule_all: [],
        calendar: [],
        times: [
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ]
        ],
        mount: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь'
        ],
        days: [
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
          'Воскресенье'
        ],
        schedule_types: [
          {
            id: '',
            title: 'Выбрать'
          },
          {
            id: 'all',
            title: 'Ежедневно'
          },
          {
            id: 'reply_day',
            title: 'Каждый X день недели'
          }
        ]
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      duration: {
        d: 0,
        d_title: ['день', 'дня', 'дней'],
        h: 0,
        h_title: ['час', 'часа', 'часов'],
        m: 0,
        m_title: ['минута', 'минуты', 'минут']
      },
      subdivision: [],
      tab: 'general',
      error: null,
      images: [],
      report: null,
      imgLoader: false,
      imgLoader2: false,
      ex_tabs: [
        {
          title: 'Кому подойдет?',
          desc: ''
        },
        {
          title: 'Что взять с собой?',
          desc: ''
        }
      ],
      tooltip: {
        'ql-bold': 'Жирный',
        'ql-italic': 'Курсив',
        'ql-underline': 'Нижнее подчёркивание',
        'ql-strike': 'Перечёркнутый',
        'ql-align': 'Слева',
        'center': 'по центру',
        'right': 'Справа',
        'justify': 'Заполнить',
        'ql-blockquote': 'Кавычки',
        'ql-code-block': 'Код',
        'ordered': 'Нумерованный список',
        'bullet': 'Список',
        'check': 'Список выбора',
        '-1': 'Убрать отступ слева',
        '+1': 'Добавить отступ слева',
        'ql-link': 'Ссылка',
        'ql-image': 'Изображение',
        'ql-video': 'Видео',
        'ql-clean': 'Очистить стили',
        'ql-color': 'Цвет текста',
        'ql-background': 'Цвет заливки текста'
      }
    }
  },


  methods: {
    addTime() {
      let time = this.new_time

      if (time && time.length === 5) {
        time = time.split(':')
        if (time[0] <= 23 && time[1] <= 59) {

          if (this.schedule_dates[this.selectedDate].times.indexOf(this.new_time) > -1) {
            this.$refs.newTime.setError('Такое время отправления уже есть в этом рейсе')
          } else {
            this.schedule_dates[this.selectedDate].times.push(this.new_time)
            let new_times = this.schedule_dates[this.selectedDate].times
            this.schedule_dates[this.selectedDate] = {times: new_times}

            this.new_time = null;
            this.selectedDate = null
          }

        } else {
          this.$refs.newTime.setError('Заполните поле в формате времени (от 00:00 до 23:59)')
        }
      } else {
        this.$refs.newTime.setError('Заполните поле в формате времени (от 00:00 до 23:59)')
      }
    },
    removeTime(date, i) {
      this.$delete(this.schedule_dates[date].times, i)
    },
    setDate(date) {

      if (this.schedule_dates[date]) {
        this.$delete(this.schedule_dates, date)
      } else {
        let times = !this.data.excursion.excursion_time_type ? this.schedule.times[0].map((i) => i = i.h + ':' + i.m) : this.schedule.times[moment(new Date(date)).isoWeekday()-1].map((i) => i = i.h + ':' + i.m);
        this.schedule_dates[date] = {times: times}
      }
    },

    createCalendar() {

      this.schedule.calendar = [];

      let sm = parseInt(moment(this.data.excursion.excursion_start_date).format('M'));
      let em = parseInt(moment(this.data.excursion.excursion_end_date).format('M'));
      let sd = parseInt(moment(this.data.excursion.excursion_start_date).format('DD'));
      let ed = parseInt(moment(this.data.excursion.excursion_end_date).format('DD'));
      let y = parseInt(moment(this.data.excursion.excursion_end_date).format('YYYY'));

      for (let i = 0; i <= (em - sm); i++) {
        let mount = sm + i;
        let mountDate = moment().month(mount - 1).startOf('month');
        let days = [];
        let countDays = mountDate.daysInMonth();
        let firstDay = moment().month(mount - 1).startOf('month').isoWeekday() - 2

        for (let i = 0; i <= countDays + firstDay ; i++) {

          let day = i - firstDay;
          let times = !this.data.excursion.excursion_time_type ? this.schedule.times[0] :
              this.schedule.times[moment().month(mount - 1).startOf('month').add(day - 1, 'days').isoWeekday()-1];
          let time = [];


          $.each(times, function (k, v) {
            if ((v.h + v.m > 0)) {
              time[k] = v;
            }
          })

          if (!time.length) {
            time = false
          }

          days[i] = {
            day: day >= 0 ? day : false,
            date: day >= 0 ? moment().month(mount - 1).startOf('month').add(day - 1, 'days').format('YYYY-MM-DD') : false,
            weekend: (moment().month(mount - 1).startOf('month').add(day-1, 'days').isoWeekday() > 5),
            times: (mount === sm && mount === em) ? ((day <= ed && day >= sd) ? time : false) : (time && day > 0 && ((day >= sd && mount === sm) || (day <= ed && mount === em ) || (mount < em && mount > sm))) ? time : false
          }
        }

        this.schedule.calendar[i] = {
          mount: this.schedule.mount[mount - 1],
          year: y,
          days: days
        }
      }

    },
    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {
        this.subdivision = result.response;
      }
    },

    setTab(tab) {
      this.tab = tab;
    },

    removeImage(i) {
      this.data.excursion.excursion_images.splice(i, 1);
    },

    async prepareInputImages(event) {

      if (event.target.files) {
        this.imgLoader = true;

        let formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('file_category', 'main');
        formData.append('file_name', new Date().getTime());

        let list = [];

        list.push(Ajax.post(`/settings/excursion/upload/`, formData));

        let [response] = await Promise.all(list);

        if (response && response.response) {

          if (!this.data.excursion.excursion_images) {
            this.data.excursion.excursion_images = [];
          }
          this.data.excursion.excursion_images[0] = response.response.filename;
          this.imgLoader = false;
        }

      }
    },


    async prepareInputImages2(event) {

      if (event.target.files) {
        this.imgLoader2 = true;

        $.each(event.target.files, async function (i) {

          let formData = new FormData();
          formData.append('file', event.target.files[i]);
          formData.append('file_category', 'photo');

          let list = [];

          list.push(Ajax.post(`/settings/excursion/upload/`, formData));

          let [response] = await Promise.all(list);

          if (response && response.response) {

            this.data.excursion.excursion_images[this.data.excursion.excursion_images.length] = response.response.filename;

            this.uploadDone();
          }

        }.bind(this));

      }
    },

    uploadDone() {
      this.imgLoader2 = false;
      this.imgLoader2 = true;
      setTimeout(function () {
        this.imgLoader2 = false;
      }.bind(this), 300)
    },

    changeObjectIds(val) {
      this.data.excursion.object_id = val.map(({value}) => value);
    },

    changeExcursionIds(val) {
      this.data.excursion.excursion_link = val.map(({value}) => value);
    },

    changePlaceIds(val) {
      this.data.excursion.excursion_place = val.map(({value}) => value);
    },

    changeCategoryIds(val) {
      this.data.excursion.category_id = val.map(({value}) => value);

      let url = this.data.excursion.excursion_alias ? this.data.excursion.excursion_alias.split('/') : ''

      if (this.data.excursion.category_id.length) {
        let category_url = this.data.category.find(c => c.category_id === this.data.excursion.category_id[0]).category_url
        url = category_url + '/' + (url.length > 1 ? url[1] : url[0]);
        this.data.excursion.excursion_alias = url;
      }
    },

    changeCategoryFreeIds(val) {
      this.data.excursion.category_id_free = val.map(({value}) => value);
    },

    async getCategory() {
      let result = await Ajax.get('/category/all');
      if (result.response) {
        this.data.category = result.response;

        this.load = true
      }
    },

    async fetchData() {
      let urls = {
        'excursion': `/excursion/${this.$route.params.id}`,
        'category': `/category/all`
      };

      this.data = await Ajax.getBulk(urls);

      this.data.excursion.place_time = this.data.excursion.place_time ? this.data.excursion.place_time : {}

      this.data.excursion.excursion_disable_promo = this.data.excursion.excursion_disable_promo === 1;

      this.data.excursion.excursion_start_date = this.data.excursion.excursion_start_date && moment(this.data.excursion.excursion_start_date, 'YYYY-MM-DD').format('YYYY-MM-DD')
      this.data.excursion.excursion_end_date = this.data.excursion.excursion_end_date && moment(this.data.excursion.excursion_end_date, 'YYYY-MM-DD').format('YYYY-MM-DD')

      this.data.excursion.excursion_time_type = this.data.excursion.excursion_time_type === 'true';

      this.data.excursion.excursion_price_children_free2_allow = this.data.excursion.excursion_price_children_free2_allow === 'true';
      this.data.excursion.excursion_prepay_allow = this.data.excursion.excursion_prepay_allow === 'true';
      this.data.excursion.excursion_prepay_children_free2_allow = this.data.excursion.excursion_prepay_children_free2_allow === 'true';

      this.ex_tabs = this.data.excursion.excursion_tabs || this.ex_tabs;

      this.duration.d =  Math.floor(this.data.excursion.excursion_duration / 60 / 60 / 24);
      this.duration.h = Math.floor(this.data.excursion.excursion_duration / 60 / 60) - (this.duration.d * 24);
      this.duration.m = Math.floor(this.data.excursion.excursion_duration / 60) - (this.duration.h * 60) - (this.duration.d * 60 * 24);

      this.schedule.times[0] = this.data.excursion.excursion_time1 && this.data.excursion.excursion_time1.map((i) => i = {h: i.split(':')[0], m: i.split(':')[1]}) || this.schedule.times[0]
      this.schedule.times[1] = this.data.excursion.excursion_time2 && this.data.excursion.excursion_time2.map((i) => i = {h: i.split(':')[0], m: i.split(':')[1]}) || this.schedule.times[1]
      this.schedule.times[2] = this.data.excursion.excursion_time3 && this.data.excursion.excursion_time3.map((i) => i = {h: i.split(':')[0], m: i.split(':')[1]}) || this.schedule.times[2]
      this.schedule.times[3] = this.data.excursion.excursion_time4 && this.data.excursion.excursion_time4.map((i) => i = {h: i.split(':')[0], m: i.split(':')[1]}) || this.schedule.times[3]
      this.schedule.times[4] = this.data.excursion.excursion_time5 && this.data.excursion.excursion_time5.map((i) => i = {h: i.split(':')[0], m: i.split(':')[1]}) || this.schedule.times[4]
      this.schedule.times[5] = this.data.excursion.excursion_time6 && this.data.excursion.excursion_time6.map((i) => i = {h: i.split(':')[0], m: i.split(':')[1]}) || this.schedule.times[5]
      this.schedule.times[6] = this.data.excursion.excursion_time7 && this.data.excursion.excursion_time7.map((i) => i = {h: i.split(':')[0], m: i.split(':')[1]}) || this.schedule.times[6]

      this.data.excursion.excursion_seo_title = this.data.excursion.excursion_seo_title ? this.data.excursion.excursion_seo_title : this.data.excursion.excursion_title;
      this.data.excursion.excursion_seo_desc = this.data.excursion.excursion_seo_desc ? this.data.excursion.excursion_seo_desc : (this.data.excursion.excursion_desc ? this.data.excursion.excursion_desc.replace(/<(.|\n)*?>/g, '').slice(0, 155) : '');

      $.each(this.data.excursion.dates, function (k, date) {

        let d = date.split(' ');
        let t = d[1].split(':');
        d = d[0];
        t = t[0] + ':' + t[1]

        if (this.schedule_dates[d]) {
          let new_times = this.schedule_dates[d].times;
          new_times.push(t)
          new_times.sort()
          this.schedule_dates[d] = {times: new_times}
        //  this.$set(this.schedule_dates, d, {times: new_times})

        } else {
          this.schedule_dates[d] = {times: [t]}
         // this.$set(this.schedule_dates, d, {times: [t]})
        }

      }.bind(this));

      this.createCalendar()

      if (this.$route.path.indexOf('copy') > -1) {
        this.data.excursion.excursion_id = null
        this.data.excursion.excursion_title = this.data.excursion.excursion_title + ' - копия'
        this.data.excursion.excursion_alias = this.data.excursion.excursion_alias + '_copy'
      }


      this.load = true
    },


    addFaq() {
      this.ex_tabs.push({
        title: '',
        desc: ''
      })
    },

    removeFaq(i) {
      this.ex_tabs.splice(i, 1);
    },

    async save() {
      this.clearError();

      this.data.excursion.excursion_duration = (this.duration.d * 24 * 3600) + (this.duration.h * 3600) +  (this.duration.m * 60);

      this.data.excursion.excursion_time1 = this.schedule.times[0].map((i) => i = i.h + ':' + i.m);
      this.data.excursion.excursion_time2 = this.schedule.times[1].map((i) => i = i.h + ':' + i.m);
      this.data.excursion.excursion_time3 = this.schedule.times[2].map((i) => i = i.h + ':' + i.m);
      this.data.excursion.excursion_time4 = this.schedule.times[3].map((i) => i = i.h + ':' + i.m);
      this.data.excursion.excursion_time5 = this.schedule.times[4].map((i) => i = i.h + ':' + i.m);
      this.data.excursion.excursion_time6 = this.schedule.times[5].map((i) => i = i.h + ':' + i.m);
      this.data.excursion.excursion_time7 = this.schedule.times[6].map((i) => i = i.h + ':' + i.m);

      this.data.excursion.excursion_city = this.data.excursion.city_data && this.data.excursion.city_data.length ? this.data.excursion.city_data.map(i => {return i.value}) : 'null'

      this.data.excursion.excursion_place = this.data.excursion.place_data && this.data.excursion.place_data.length ? this.data.excursion.place_data.map(i => {return i.value}) : 'null'
      this.data.excursion.object_id = this.data.excursion.object_data && this.data.excursion.object_data.length ? this.data.excursion.object_data.map(i => {return i.value}) : 'null'
      this.data.excursion.category_id = this.data.excursion.category_data && this.data.excursion.category_data.length ? this.data.excursion.category_data.map(i => {return i.value}) : 'null'
      this.data.excursion.category_id_free = this.data.excursion.category_free_data && this.data.excursion.category_free_data.length ? this.data.excursion.category_free_data.map(i => {return i.value}) : 'null'

      this.data.excursion.excursion_link = this.data.excursion.link_data && this.data.excursion.link_data.length ? this.data.excursion.link_data.map(i => {return i.value}) : 'null'


      let data = this.data.excursion;

      data.excursion_disable_promo = this.data.excursion.excursion_disable_promo ? 1 : 0;


      if (data.place_time.length) {
        $.each(data.place_time, function (k) {
          data.place_time.splice(k, 1)
        })
      }

      let times = {}
      $.each(Object.keys(data.place_time), function (k, v) {
        times[v] = Object.values(data.place_time)[k]
      })

      data.place_time = times

      this.data.excursion.excursion_tabs = this.ex_tabs;

      let list = [];

      delete data['excursion_status'];

      let dates = [];

      $.each(this.schedule_dates, function (k, date) {
        $.each(date.times, function (i, time) {
          dates.push(k + ' ' + time + ':00')
        })
      })


      data.dates = dates

      list.push(Ajax.post(`/settings/excursion/save/${(this.$route.params.id && this.$route.path.indexOf('copy') === -1) ? this.$route.params.id : 0}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }



      if (response.response) {
        if (!this.$route.params.id || this.$route.path.indexOf('copy') > -1) {

          response.response.excursion_disable_promo = response.response.excursion_disable_promo === 1;
          this.data.excursion = response.response;

          this.$router.push({ path: '/adm/settings/excursions/edit/' + response.response.excursion_id });
          this.$toast.success({content:`Экскурсия "${this.data.excursion.excursion_title}" добавлена.`});

        } else {

          this.$toast.success({content:`Экскурсия "${this.data.excursion.excursion_title}" обновлена.`});
          this.fetchData()
        }
      }

    },

  }
}
</script>

<style lang="scss">
  @import "@/scss/vue2-editor.css";
  @import '~quill/dist/quill.core.css';
  @import '~quill/dist/quill.bubble.css';
  @import '~quill/dist/quill.snow.css';

  .images-list_item {
    position: relative;
    min-height: 100px;
    background: #f9f9fc;

    .remove-image {
      position: absolute;
      z-index: 9;
      width: 26px;
      height: 26px;
      right: 10px;
      top: 10px;
      border-radius: 5px;
      background: rgba(135, 46, 46, 1);
      opacity: 0;

      &:after,
      &:before {
        content: '';
        position: absolute;
        width: 14px;
        height: 2px;
        background: #fff;
        left: 50%;
        top: 50%;
        margin: -1px 0 0 -7px;
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }

    &:hover .remove-image {
      opacity: 1;
    }
  }

  .label-loader {
    position: relative;

    &:after {
      content: "Загружаю...";
      position: absolute;
      left: 5px;
      top: 5px;
      right: 5px;
      bottom: 5px;
      line-height: 27px;
      background: #fff;
    }
  }

</style>

