import { createWebHistory, createRouter } from "vue-router";
import user from "./user";
import Error404 from "./pages/Error404";
import Login from "./pages/auth/Login";
import Forgot from "./pages/auth/Forgot";
import Reset from "./pages/auth/Reset";

import DevExecutor from "./pages/dev/Executor";
import DevEngines from "./pages/dev/Engines";

import AdmSettings from "./pages/adm/settings/Index";
import AdmSettingsEdit from "./pages/adm/settings/Edit";

import AdmFlights from "./pages/adm/flights/tabs/All";
import AdmFlightsFollow from "./pages/adm/flights/tabs/Followed";

import AdmOrders from "./pages/adm/orders/Index";
import AdmOrderShow from "./pages/adm/orders/Show";

import AdmDelivery from "./pages/adm/delivery/Index";
import AdmDeliveryShow from "./pages/adm/delivery/Show";
import AdmDeliveryProduct from "./pages/adm/delivery_product/Index";
import AdmDeliveryProductShow from "./pages/adm/delivery_product/Show";

import AdmSale from "./pages/adm/sale/Index";
import AdmSaleShow from "./pages/adm/sale/Show";

import AdmAnalytics from "./pages/adm/analytics/Index";

import AdmFinance from "./pages/adm/finance/Index";

import AdmClients from "./pages/adm/clients/Index";
import AdmClientShow from "./pages/adm/clients/Show";

import AdmPartners from "./pages/adm/partners/Index";
import AdmPartnersOut from "./pages/adm/partners/Out";

import AdmPartnersOut2 from "./pages/adm/partners/Out2";

import AdmPartnerShow from "./pages/adm/partners/Show";

import ManagerPartners from "./pages/manager/partners/Index";
import ManagerPartnerShow from "./pages/manager/partners/Show";
import ManagerFinance from "./pages/manager/finance/Index";
import ManagerStats from "./pages/manager/stats/Index";
import Profile from "./pages/default/Profile";


import AdmSite from "./pages/adm/site/Index";
import AdmSiteEdit from "./pages/adm/site/Edit";

import AdmQ from "./pages/adm/q/Index";
import AdmQEdit from "./pages/adm/q/Show";

import AdmPrint from "./pages/adm/print/Index";

export default async function() {
    await user.loadCurrentUser();

    const router = createRouter({
        history: createWebHistory(),
        base: __dirname,
        routes: [


            { path: '/adm/delivery', component: AdmDelivery },
            { path: '/adm/delivery/:id', component: AdmDeliveryShow },

            { path: '/adm/delivery_product', component: AdmDeliveryProduct },
            { path: '/adm/delivery_product/:id', component: AdmDeliveryProductShow },

            { path: '/manager/stats', component: ManagerStats },
            { path: '/manager/partners', component: ManagerPartners },

            { path: '/manager/partners/:id', component: ManagerPartnerShow },
            { path: '/manager/partners/:id/:tab', component: ManagerPartnerShow },
            { path: '/manager/partners/:id/:tab/:campaign_id', component: ManagerPartnerShow },

            { path: '/manager/finance', component: ManagerFinance },
            { path: '/profile', component: Profile },

            { path: '/dev/executor', component: DevExecutor },
            { path: '/dev/engines', component: DevEngines },

            { path: '/adm/settings', component: AdmSettings },
            { path: '/adm/settings/:tab', component: AdmSettings },

            { path: '/adm/settings/:tab/:tab2', component: AdmSettings },

            { path: '/adm/settings/:tab/edit', component: AdmSettingsEdit },
            { path: '/adm/settings/:tab/edit/:id', component: AdmSettingsEdit },
            { path: '/adm/settings/:tab/copy/:id', component: AdmSettingsEdit },
            { path: '/adm/settings/:tab/edit/:id/:tab2', component: AdmSettingsEdit },

            { path: '/adm/print', component: AdmPrint },

            { path: '/adm/flights', component: AdmFlights },
            { path: '/adm/flights/all', component: AdmFlights },
            { path: '/adm/flights/followed', component: AdmFlightsFollow },

            { path: '/adm/orders', component: AdmOrders },
            { path: '/adm/orders/:id', component: AdmOrderShow },

            { path: '/adm/q', component: AdmQ },
            { path: '/adm/q/:id', component: AdmQEdit },

            { path: '/adm/sale', component: AdmSale },
            { path: '/adm/sale/:id', component: AdmSaleShow },

            { path: '/adm/analytics', component: AdmAnalytics },
            { path: '/adm/analytics/:tab', component: AdmAnalytics },

            { path: '/adm/finance', component: AdmFinance },
            { path: '/adm/finance/:tab', component: AdmFinance },

            { path: '/adm/clients', component: AdmClients },
            { path: '/adm/clients/:id/:tab', component: AdmClientShow },

            { path: '/adm/partners', component: AdmPartners },
            { path: '/adm/partners_out', component: AdmPartnersOut },
            { path: '/adm/partners_out2', component: AdmPartnersOut2 },
            { path: '/adm/partners/:id', component: AdmPartnerShow },
            { path: '/adm/partners/:id/:tab', component: AdmPartnerShow },
            { path: '/adm/partners/:id/:tab/:campaign_id', component: AdmPartnerShow },

            { path: '/adm/site', component: AdmSite },
            { path: '/adm/site/:tab', component: AdmSite },

            { path: '/adm/site/:tab/edit', component: AdmSiteEdit },
            { path: '/adm/site/:tab/edit/:id', component: AdmSiteEdit },
            { path: '/adm/site/:tab/edit/:id/:tab2', component: AdmSiteEdit },

            { path: '/auth/login', component: Login },
            { path: '/logout', component: Login },
            { path: '/auth/forgot', component: Forgot },
            { path: '/auth/reset', component: Reset },

            { path: '/', component: Error404,
                beforeEnter(to, from, next) {
                    next(user.getHomepage());
                }
            }

        ]
    });

    router.beforeEach((to, from, next) => {

        window.scrollTo(0, 0)
        if ((to.path.indexOf('/auth/') === 0) && (user.isLoggedIn())) {
            next(user.getHomepage());
        }

        if ((to.path.indexOf('/auth/') === 0)) {
            return next();
        }

        if (['/logout'].indexOf(to.path) > -1) {
            user.logout()
            if (user.isImpersonate()) {
                window.location.reload()
            } else {
                setTimeout(function () {
                    next('/auth/login');
                }, 1000)
            }

            //next('/auth/login');
            //window.location.reload()
        }


        if (!user.isLoggedIn()) {
            next({path: '/auth/login'});
            //window.location.reload()
        } else {

            if (to.path.indexOf(`/docs`) === 0) {
                return next();
            }

            if (to.path.indexOf(`/${user.currentUser.user.role_type}`) === 0) {
               // return next();
            }

            if ((user.currentUser.user.role_type === 'adm' || user.currentUser.user.role_type === 'staff') && ((to.path.indexOf(`/adm`) === 0) || (to.path.indexOf(`/manager`) === 0) || (to.path.indexOf(`/dev`) === 0))) {
                return next();
            }

            if (user.currentUser.user.role_type !== 'adm' || user.currentUser.user.role_type !== 'staff') {
                //user.logout()
                setTimeout(function () {
                    next('/auth/login');
                }, 1000)

            } else {
                next({path: user.getHomepage()});
            }
        }


    });

    return router;
}
