<template>
    <div class="content">
          <div class="content-header">
            <h1 class="content-title">
              Статистика
            </h1>
          </div>

      <div class="peoples" v-if="amount && amount.summary">
        <div class="prices-item">
          <b>{{formatRUB(amount.summary.seller_summary.sum_salary - amount.summary.seller_summary.sum_salary_freeze)}}</b>
            Баланс
        </div>

        <div class="prices-item">
          <b>{{formatRUB(amount.summary.seller_summary.sum_salary_freeze)}}</b>
            Ожидает зачисления
        </div>

        <div class="prices-item">
          <b>{{formatRUB(amount.summary.seller_summary.sum_collection_award)}}</b>
            Выведено за всё время
        </div>
      </div>



    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';

import User from "@/user";

export default {
  mixins: [mixins],

  components: {

  },

  data() {
    return {
      amount: {},
      account_id: User.currentUser.account.account_id || 0,
    }
  },

  created() {
    this.loadAmount()
  },

  methods: {
    async loadAmount() {
      let result = await Ajax.report(`/manager/balance/`, {order: {field: 'collection_id', order: 'desc'}, filters: {'c.account_id': 'eq|' + this.account_id}});
      if (result.response) {
        this.amount = result.response;
      }
    }


  }
}
</script>
