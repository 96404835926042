<template>
  <div>
    <Report ref="report" url="site/category/report" :def-params="{order: {field: 'category_id', order: 'desc'}, filters: { category_type: 'eq|free_service' }}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="category_id"  width="50" />
              <OrderTd text="Название" field="category_title" />
              <OrderTd text="Дата обновления" field="ts" />
              <OrderTd text="Статус" field="category_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="category_id" />
              <InputFilter name="category_title" filter-type="like" />
              <PeriodPickerFilter name="ts" />
              <SelectFilter name="category_status" :options="[['active', 'Активна'], ['paused', 'Заблокирована']]" track-by="0" label="1" />
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="6">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.category_id}}</td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.category_title">
                    <div>
                      <router-link :to="'/adm/site/free_service/edit/' + row.category_id">{{row.category_title}}</router-link>
                    </div>
                  </div>
                </td>
                <td>
                  {{convertDate(row.ts)}}
                </td>
                <td>
                  <div class="status" v-bind:class="`status_${row.category_status}`">
                    {{data.status[row.category_status]}}
                  </div>
                </td>
                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <router-link :to="'/adm/site/free_service/edit/' + row.category_id">
                        <template v-if="ac('site.site_free_service.edit')">
                          Редактировать
                        </template>
                        <template v-else>
                          Просмотр
                        </template>
                      </router-link>
                      <a href="#" v-on:click.prevent="changeStatus(row.category_id, 'active')" v-if="row.category_status !== 'active' && ac('site.site_free_service.status')">Разблокировать</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.category_id, 'paused')" v-if="row.category_status !== 'paused' && ac('site.site_free_service.status')">Заблокировать</a>
                      <a href="#" v-if="ac('site.site_free_service.delete')" v-on:click.prevent="changeStatus(row.category_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';

import PeriodPickerFilter from '../../../../components/report/PeriodPickerFilter';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    PeriodPickerFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },
      },
      error: null,
      report: null
    }
  },

  methods: {

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/site/category/save/${id}`, {
            category_id: id,
            category_status: status
          });
        }
      } else {
        await Ajax.post(`/site/category/save/${id}`, {
          category_id: id,
          category_status: status
        });
      }

      this.getReport().reload();
    },
  }
}
</script>
