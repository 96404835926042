<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/site/free_service'">Бесплатные услуги</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.category.category_title }} <span>{{ data.category.category_id }}</span>
      </template>

      <template v-else>
        Новая тематика
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <a v-on:click="setTab('general')" v-bind:class="{current: tab === 'general'}">Общая информация</a>
    </div>

    <div class="form">

      <template v-if="tab === 'general'">
        <div class="form-left">
          <Input name="category_title" text="Название" required="true" v-model.trim="data.category.category_title" />
        </div>
      </template>



      <div class="form-submit" v-if="ac('site.site_free_service.edit')">
        <input type="hidden" name="category_id" v-model.number="data.category.category_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>

<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";

export default {
  mixins: [mixins],

  components: {
    Input,
  },

  created() {
    if (this.$route.params.id) {
      this.fetchData();
    }
  },

  data() {
    return {
      tab: 'general',
      data: {
        category: {
          category_id: null,
          category_title: null,
          category_page_title:  '--',
          category_page_subtitle: null,
          category_url: '--',
          category_type: 'free_service',
          category_seo_title: '--',
          category_seo_desc: null,
          category_seo_keyword: null
        },
      },
      error: null,
      report: null
    }
  },

  methods: {
    setTab(tab) {
      this.tab = tab;
    },
    async fetchData() {
      let urls = {
        'category': `/category/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);
    },

    async save() {
      this.clearError();

      let data = this.data.category;

      let list = [];

      delete data['category_status'];

      list.push(Ajax.post(`/site/category/save/${this.data.category.category_id || '0'}`, data));


      let [response] = await Promise.all(list);

      if (response.error) {

        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/site/free_service/edit/' + response.response.category_id });
          this.$toast.success({content:`Бесплатная услуга "${this.data.category.category_title}" добавлена.`});
        } else {
          this.$toast.success({content:`Бесплатная услуга "${this.data.category.category_title}" обновлена.`});
        }
      }
    },
  }
}
</script>
