<template>
  <div>
    <h1 class="content-title">
      <router-link :to="'/adm/settings/staff/bonus'">Бонусы</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.bonus.excursion_title }} <span>{{ data.bonus.bonus_id }}</span>
      </template>

      <template v-else>
        Новый бонус
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>

    <div class="form">
      <div class="form-left">
        <PeriodPicker
            name="bonus_start_date"
            text="Дата начала начислений"
            add-time="true"
            @update:modelValue="(res) => {data.bonus.bonus_start_date = res}"
            v-model="data.bonus.bonus_start_date"
            v-if="loaded"
        />
        <PeriodPicker
            name="bonus_end_date"
            text="Дата окончания начислений"
            add-time="true"
            @update:modelValue="(res) => {data.bonus.bonus_end_date = res}"
            v-model="data.bonus.bonus_end_date"
            v-if="loaded"
        />

        <AutosuggestMulti
            :value="data.bonus.excursion_data"
            v-model="data.bonus.excursion_data"
            :data="data.bonus.excursion_data"
            ref="excursion_id"
            :multiple="false"
            name="excursion_id"
            text="Экскурсия"
            url="/misc/excursions"
            placeholder="Выбрать экскурсию"
            v-if="loaded"
        />

        <div class="input-group">
          <div>
            <Input type="number" name="bonus_amount_adult_o" :icon2="data.bonus.bonus_amount_type_o === 'percent' ? '%' : '₽'" text="Полный тариф" required="true" v-model.trim.number="data.bonus.bonus_amount_adult_o" />
            <Input type="number" name="bonus_amount_children_1_o" :icon2="data.bonus.bonus_amount_type_o === 'percent' ? '%' : '₽'" :text="`Детский тариф 1 - 6-12`" required="true" v-model.trim.number="data.bonus.bonus_amount_children_1_o" />
            <Input type="number" name="bonus_amount_children_2_o" :icon2="data.bonus.bonus_amount_type_o === 'percent' ? '%' : '₽'" :text="`Детский тариф 2 - 0-6`" required="true" v-model.trim.number="data.bonus.bonus_amount_children_2_o" />
            <Input type="number" name="bonus_amount_children_3_o" :icon2="data.bonus.bonus_amount_type_o === 'percent' ? '%' : '₽'" :text="`Детский тариф 3 - 0-6 б/м`" required="true" v-model.trim.number="data.bonus.bonus_amount_children_3_o" />
          </div>
          <div>
            <Select track-by="0" label="1" :options="priceType" name="bonus_amount_type_o" text="" required="true" v-model.trim="data.bonus.bonus_amount_type_o" />
          </div>
        </div>

        <Input type="number" name="bonus_amount_count_e" icon2="мест" text="При продаже Х мест на рейс, начислить мега бонус" required="true" v-model.trim.number="data.bonus.bonus_amount_count_e" />

        <div class="input-group" v-if="data.bonus.bonus_amount_count_e > 1">
          <div>
            <Input type="number" name="bonus_amount_adult_e" :icon2="data.bonus.bonus_amount_type_e === 'percent' ? '%' : '₽'" text="Полный тариф" required="true" v-model.trim.number="data.bonus.bonus_amount_adult_e" />
            <Input type="number" name="bonus_amount_children_1_e" :icon2="data.bonus.bonus_amount_type_e === 'percent' ? '%' : '₽'" :text="`Детский тариф 1 - 6-12`" required="true" v-model.trim.number="data.bonus.bonus_amount_children_1_e" />
            <Input type="number" name="bonus_amount_children_2_e" :icon2="data.bonus.bonus_amount_type_e === 'percent' ? '%' : '₽'" :text="`Детский тариф 2 - 0-6`" required="true" v-model.trim.number="data.bonus.bonus_amount_children_2_e" />
            <Input type="number" name="bonus_amount_children_3_e" :icon2="data.bonus.bonus_amount_type_e === 'percent' ? '%' : '₽'" :text="`Детский тариф 3 - 0-6 б/м`" required="true" v-model.trim.number="data.bonus.bonus_amount_children_3_e" />
          </div>
          <div>
            <Select track-by="0" label="1" :options="priceType" name="bonus_amount_type_e" text="" required="true" v-model.trim="data.bonus.bonus_amount_type_e" />
          </div>
        </div>

      </div>

      <div class="form-submit" v-if="ac('settings.settings_bonus.edit')">
        <input type="hidden" name="bonus_id" v-model.number="data.bonus.bonus_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import AutosuggestMulti from "@/components/AutosuggestMulti";
import PeriodPicker from "@/components/PeriodPicker";
import moment from "moment/moment";


export default {
  mixins: [mixins],

  components: {
    Input,
    PeriodPicker,
    Select,
    AutosuggestMulti
  },


  created() {
    if (this.$route.params.id) {
      this.fetchData();
    } else {
      this.loaded = true
    }
  },

  data() {
    return {
      loaded: false,
      priceType: [
        ['percent', '%'],
        ['amount', 'сумма']
      ],
      data: {
        bonus: {
          bonus_id: null,
          excursion_id: null,
          bonus_start_date: null,
          bonus_end_date: null,
          bonus_amount_type_o: 'percent',
          bonus_amount_adult_o: 0,
          bonus_amount_children_1_o: 0,
          bonus_amount_children_2_o: 0,
          bonus_amount_children_3_o: 0,
          bonus_amount_count_e: 0,
          bonus_amount_type_e: 'percent',
          bonus_amount_adult_e: 0,
          bonus_amount_children_1_e: 0,
          bonus_amount_children_2_e: 0,
          bonus_amount_children_3_e: 0,
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null
    }
  },

  methods: {

    async fetchData() {
      let urls = {
        'bonus': `/bonus/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);

      this.data.bonus.bonus_start_date = this.data.bonus.bonus_start_date && moment(this.data.bonus.bonus_start_date).format('YYYY-MM-DD HH:mm:ss')
      this.data.bonus.bonus_end_date = this.data.bonus.bonus_end_date && moment(this.data.bonus.bonus_end_date).format('YYYY-MM-DD HH:mm:ss')

      this.loaded = true
    },

    async save() {
      this.clearError();

      let data = this.data.bonus;

      this.data.bonus.bonus_start_date = this.data.bonus.bonus_start_date && moment(this.data.bonus.bonus_start_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:00');
      this.data.bonus.bonus_end_date = this.data.bonus.bonus_end_date && moment(this.data.bonus.bonus_end_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:00');


      data.excursion_id = this.data.bonus.excursion_data.value || data.excursion_id

      let list = [];

      delete data['bonus_status'];

      list.push(Ajax.post(`/settings/bonus/save/${this.data.bonus.bonus_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/bonus/edit/' + response.response.bonus_id });
          this.data.bonus = response.response
          this.$toast.success({content:`Бонус "${this.data.bonus.bonus_id}" добавлен.`});
        } else {
          this.$toast.success({content:`Бонус "${this.data.bonus.bonus_id}" обновлён.`});
        }
      }

    },

  }
}
</script>
