<template>
  <div class="content">

    <h1 class="content-title">
      <router-link :to="'/adm/delivery_product'">Продукция</router-link>
      <i></i>

      <template v-if="$route.params.id > 0">
        {{ data.delivery_product.delivery_product_title }}
      </template>
      <template v-else>
        новый продукт
      </template>

    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="getTabLink('general')" v-bind:class="{current: isTab('general')}">Общая информация</router-link>
    </div>

    <div class="line-tab-con">


      <div class="form">
        <div class="form-left">
          <Input required="true" name="delivery_product_title" v-model="data.delivery_product.delivery_product_title" text="Наименование" />
        </div>
      </div>

    </div>

    <div class="form-submit">
      <input type="hidden" name="delivery_product_id" v-model.number="data.delivery_product.delivery_product_id" />
      <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
    </div>

  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Input from "@/components/Input";


export default {
  mixins: [mixins],

  components: {
    Input
  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab || 'general';
      this.fetchData();
    }
  },

  created() {

    if (this.$route.params.id > 0) {
      this.fetchData();
    }

  },



  data() {
    return {
      data: {

        delivery_product: {
          delivery_product_id: null,
          delivery_product_title: null
        },
      },
      tab: this.$route.params.tab || 'general',
      error: null,
      report: null
    }
  },

  methods: {

    async save() {
      this.clearError();

      let data = this.data.delivery_product;

      let list = [];

      delete data['delivery_product_status'];

      list.push(Ajax.post(`/delivery_product/save/${this.data.delivery_product.delivery_product_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }
      if (response.response) {
        this.data.delivery_product = response.response;
        if (this.$route.params.id === 'new') {
          this.$router.push({ path: '/adm/delivery_product/' + response.response.delivery_product_id });
          this.$toast.success({content:`Продукт "${response.response.delivery_product_id}" добавлен.`});
        } else {
          this.$toast.success({content:`Продукт "${response.response.delivery_product_id}" обновлён.`});
        }
      } else {
        this.$toast.error({content:`Произошла какая-то ошибка:(`});
      }

    },

    getTabLink(tab) {
      return `/adm/delivery_product/${this.$route.params.id}/${tab}`;
    },

    isTab(tab) {
      return this.tab === tab;
    },

    async fetchData() {
      let urls = {
        'delivery_product': `/delivery_product/${this.$route.params.id}`
      };

      this.data = await Ajax.getBulk(urls);
    },

  }
}
</script>
