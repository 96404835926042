<template>
  <div>
    <Report ref="report" url="finance/guide/report" :def-params="{order: {field: 'schedule_date', order: 'asc'}, limit: 500}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />

      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>

        <div class="table-filter">
          <div>
            <label class="input-placeholder">Гид</label>
            <SelectFilter
                model="sd"
                name="ft.flight_guide_id"
                :options="data.subdivision"
                track-by="value"
                label="label"

                class="m-0" />
          </div>
        </div>
        <div class="table-scrollable">
          <table class="table bonus-report" cellpadding="0" cellspacing="0" width="100%">
            <thead>

            <tr>
              <td style="width: 20px">№</td>
              <OrderTd text="Дата" field="schedule_date" style="width: 40px" />
              <OrderTd text="Рейс" field="flight_id" style="width: auto" />
              <OrderTd text="Автобус" field="transport_id" style="width: 40px" />
              <OrderTd text="Люди" field="total_people" style="width: 40px" />
              <td style="width: auto">Расшифровка</td>
              <td style="width: 60px">Приход</td>
              <td style="width: 60px">ЗП</td>
            </tr>

            </thead>
            <tbody v-if="report">
              <tr v-if="report.rows_count === 0">
                <td colspan="7">
                  Не найдено.
                </td>
              </tr>
            </tbody>
            <template v-if="report && report.rows_count > 0">
              <tbody>

                <tr v-for="(row, index) in report.rows" v-bind:key="index">
                  <td>{{index+1}}</td>
                  <td v-html="convertDate(row.schedule_date, '<b>DD.MM</b><br>HH:mm')">
                  </td>
                  <td v-html="row.excursion_title">

                  </td>
                  <td>
                    {{row.transport_number}}
                  </td>

                  <td>
                    {{row.total_people}}
                  </td>

                  <td></td>
                  <td></td>
                  <td></td>
                </tr>


              </tbody>

            </template>

          </table>
        </div>

        <Pager />
      </div>
    </Report>

    <Modal ref="flightSeat" class="mw1200">
      <template v-if="flightSeats">
        <h2>
          Посадки рейса — {{flightSeats.flight_id}} — {{convertDate(flightSeats.schedule_date, 'DD.MM.YYYY HH:mm')}}
        </h2>

        <div style="max-height: 80vh; overflow: auto;">

          <div class="flight_list" v-if="flightSeats.transport_data.length">

            <table class="table" cellspacing="0" cellpadding="0" width="100%">
              <thead>
              <tr>
                <td>
                  <b>Транспорт</b>
                </td>
                <td>
                  <b>Экскурсовод</b>
                </td>
                <td>
                  <b>Кол-во мест</b>
                </td>
                <td>
                  <b>Люди</b>
                </td>
                <td>
                  <b>Село мест</b>
                </td>

              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in flightSeats.transport_data" v-bind:key="index">
                <td>
                  <div class="radio">
                    <label>
                      <input type="checkbox" name="showAll" v-model="row.show">
                      <span></span>
                      <div>{{ row.transport_title }}</div>
                    </label>
                  </div>
                </td>

                <td>
                  {{ row.guide_title.join(', ') }}
                </td>

                <td>
                  {{ row.total_seats }}
                </td>
                <td>
                  {{ row.total_people }}
                </td>
                <td>
                  {{ row.total_checkin }}
                </td>

              </tr>
              </tbody>
              <tbody>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>{{ flightSeats.transport_total_seats }}</b>
                </td>
                <td>
                  <b>{{ flightSeats.transport_total_people }}</b>
                </td>
                <td>
                  <b>{{ flightSeats.transport_total_seats_checkin }}</b>
                </td>
              </tr>
              </tbody>
            </table>

          </div>

          <div class="flight_list" v-if="flightSeats.flights">

            <table class="table" cellspacing="0" cellpadding="0" width="100%">
              <thead>
              <tr>
                <td>
                  <b>Рейс</b>
                </td>
                <td>
                  <b>Транспорт</b>
                </td>
                <td>
                  <b>Кол-во мест</b>
                </td>
                <td>
                  <b>Люди</b>
                </td>
                <td>
                  <b>Село мест</b>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in flightSeats.flights" v-bind:key="index">
                <td>
                  <div class="radio">
                    <label>
                      <input type="checkbox" name="showAll" v-model="row.show">
                      <span></span>
                      <div>{{ row.flight_id }} - {{ row.flight_title }}</div>
                    </label>
                  </div>
                </td>
                <td>
                  <div v-for="(transport, index) in flightSeats.transport_data.filter(i => i.excursion_id.indexOf(row.excursion_id) > -1)" v-bind:key="index">
                    <b> {{ transport.transport_title }} </b> -
                    {{ transport.guide_title.join(', ') }}
                  </div>
                </td>
                <td>
                  {{ row.total_seats }}
                </td>
                <td>
                  {{ row.total_people }}
                </td>
                <td>
                  {{ row.total_checkin }}
                </td>
              </tr>
              </tbody>
              <tbody>
              <tr>

                <td></td>
                <td></td>

                <td>
                  <b>{{ flightSeats.total_seats }}</b>
                </td>
                <td>
                  <b>{{ flightSeats.total_people }}</b>
                </td>
                <td>
                  <b>{{ flightSeats.total_seats_checkin }}</b>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
          <table class="table" cellspacing="0" cellpadding="0" width="100%">
            <thead>
            <tr>
              <td>
                <b>Время</b>
              </td>
              <td>
                <b>Точка посадки</b>
              </td>
              <td>
                <b>Места</b>
              </td>
              <td>
                <b>Люди</b>
              </td>
              <td>
                <b>Село мест</b>
              </td>
              <td>
                Время посадок
              </td>
            </tr>
            </thead>

            <tbody v-for="(row, index) in flightSeats.response" v-bind:key="index">
            <tr
                class="place_row"
                :class="{order_show: row.show}"
                v-on:click="row.show = !row.show"
                v-if="flightSeats.flights.find(i => row.flight_ids.indexOf(i.flight_id) > -1 && i.show) && (!flightSeats.transport_data.length || flightSeats.transport_data.find(i => row.transport_ids.indexOf(i.transport_id) > -1 && i.show))"
            >
              <td>
                {{row.order_place_time}}
              </td>
              <td>
                {{row.place_title}}
              </td>
              <td>
                {{ row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length ||  flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_adult) + parseInt(i.order_children_1) + parseInt(i.order_children_2)) : 0), 0 ) }}
              </td>
              <td>
                {{ row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_adult) + parseInt(i.order_children_1) + parseInt(i.order_children_2) + parseInt(i.order_children_free)) : 0), 0 ) }}
              </td>
              <td>
                {{ row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_checkin_adult) + parseInt(i.order_checkin_children)) : 0), 0 ) }}
              </td>

              <td>
                <div v-for="(i, k) in Object.entries(row.times)" v-bind:key="k">
                  <template v-if="flightSeats.transport_data && flightSeats.transport_data.find(fl => i[1][0].transport_id === fl.transport_id).show">
                    <b>
                      {{ i[1][0].transport_title }}
                      <span v-if="index > 0 && flightSeats.response[index-1].times && flightSeats.response[index-1].times[i[0]] && flightSeats.response[index-1].times[i[0]].length">
                         - {{ prepareDuration(diff( i[1][0].ts, flightSeats.response[index-1].times[i[0]][flightSeats.response[index-1].times[i[0]].length-1].ts ).seconds) }}
                      </span>
                    </b>
                    <div style="white-space: nowrap">
                      {{ convertDate(i[1][0].ts, 'HH:mm:ss') }} - {{ convertDate(i[1][i[1].length-1].ts, 'HH:mm:ss') }}
                    </div>
                  </template>
                </div>
              </td>
            </tr>
            <template v-if="row.show && flightSeats.flights.find(i => row.flight_ids.indexOf(i.flight_id) > -1 && i.show) && (!flightSeats.transport_data.length || flightSeats.transport_data.find(i => row.transport_ids.indexOf(i.transport_id) > -1 && i.show))">
              <tr class="order_row" v-for="(order, i) in row.order_data" v-bind:key="i">
                <template v-if="flightSeats.flights.find(i => order.flight_id === i.flight_id && i.show) && (!flightSeats.transport_data.length || flightSeats.transport_data.find(i => order.transport_id === i.transport_id && i.show))">
                  <td>
                    {{order.order_id}}
                  </td>
                  <td>
                    {{order.order_title}}


                    <div class="seats_info">
                      <div>
                        <label class="input-placeholder">
                          Точка продажи, кассир
                        </label>
                        <div>
                          {{ order.point_title }}<br>
                          {{ order.seller_title }}
                        </div>
                      </div>
                      <div>
                        <label class="input-placeholder">
                          Клиент
                        </label>
                        <div>
                          {{ order.order_client_title }}<br>
                          {{ formatPhone(order.order_client_phone) }}
                        </div>
                      </div>
                      <div>
                        <label class="input-placeholder">
                          Места
                        </label>
                        <div v-if="order.transport_place_title && order.transport_place_title.length">
                          {{ order.order_tickets.split(',').map(i => {return order.transport_place_title[parseInt(i)]}).join(',') }}
                        </div>
                        <div v-else>
                          {{ order.order_tickets }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ parseInt(order.order_adult) + parseInt(order.order_children_1) + parseInt(order.order_children_2) }}
                  </td>
                  <td>
                    {{ parseInt(order.order_adult) + parseInt(order.order_children_1) + parseInt(order.order_children_2) + parseInt(order.order_children_free) }}
                  </td>
                  <td>
                    {{parseInt(order.order_checkin_adult) + parseInt(order.order_checkin_children)}}
                  </td>

                  <td>
                    {{ order.transport_title }}
                    <template v-if="parseInt(order.order_checkin_adult) + parseInt(order.order_checkin_children) > 0">
                      - {{ convertDate(order.ts, 'HH:mm:ss') }}
                    </template>
                  </td>
                </template>
              </tr>
            </template>
            </tbody>

            <tbody>
            <tr>
              <td></td>
              <td></td>
              <td>
                <b>
                  {{ flightSeats.response.reduce((sumTotal, row) => sumTotal + (row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show  && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_adult) + parseInt(i.order_children_1) + parseInt(i.order_children_2)) : 0), 0 )), 0) }}
                </b>
              </td>
              <td>
                <b>
                  {{ flightSeats.response.reduce((sumTotal, row) => sumTotal + (row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_adult) + parseInt(i.order_children_1) + parseInt(i.order_children_2) + parseInt(i.order_children_free)) : 0), 0 )), 0) }}
                </b>
              </td>
              <td>
                <b>
                  {{ flightSeats.response.reduce((sumTotal, row) => sumTotal + (row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_checkin_adult) + parseInt(i.order_checkin_children)) : 0), 0 )), 0) }}
                </b>
              </td>
            </tr>
            </tbody>

          </table>
        </div>

        <div class="form-submit">
          <a href="#" class="btn btn-middle" v-on:click.prevent="hideFlightSeat">Закрыть</a>
        </div>

      </template>
    </Modal>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';

import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import OrderTd from '../../../../components/report/OrderTd';

import SelectFilter from '../../../../components/report/SelectFilter';

import Modal from '../../../../components/Modal';
export default {
  mixins: [mixins],

  components: {
    OrderTd,
    Loading,
    Modal,
    Pager,
    SelectFilter,
    Report
  },

  created() {
    this.loadSubdivision();
  },

  data() {
    return {
      flightSeats: null,
      sd: null,
      data: {
        subdivision: [],
      },

      error: null,
      report: null
    }
  },

  methods: {
    hideFlightSeat(){
      this.$refs.flightSeat.hide()
      this.flightSeats = null
    },
    diff(date1, date2) {
      let a = new Date(date1).getTime(),
          b = new Date(date2).getTime(),
          diff = {};

      diff.milliseconds = a > b ? a % b : b % a;
      diff.seconds = diff.milliseconds / 1000;
      diff.minutes = diff.seconds / 60;
      diff.hours = diff.minutes / 60;
      diff.days = diff.hours / 24;
      diff.weeks = diff.days / 7;

      return diff;
    },
    async showFlightSeats(flight_id, date) {
      let res = await Ajax.get(`/ad/flight/${flight_id}`);

      let total_seats = 0
      let total_people = 0
      let total_seats_checkin = 0

      if (res.response) {

        res.response.forEach(i => {
          i.times = {}
          total_seats = total_seats + (i.cnt_adult + i.cnt_children_1 + i.cnt_children_2)
          total_people = total_people + (i.cnt_adult + i.cnt_children_1 + i.cnt_children_2 + i.cnt_children_free)
          total_seats_checkin = total_seats_checkin + i.order_data.reduce((sum, i) => sum + (parseInt(i.order_checkin_adult) + parseInt(i.order_checkin_children)), 0 )

          i.order_data.map(o => {
            if((o.order_checkin_adult + o.order_checkin_children) > 0) {
              if (i.times['t' + o.transport_id]) {
                i.times['t' + o.transport_id].push(o)
              } else {
                i.times['t' + o.transport_id] = [o]
              }

              i.times['t' + o.transport_id].sort((a,b) => new Date(a.ts).getTime() - new Date(b.ts).getTime())
            }
          })

        })

        this.flightSeats = res;

        res.response[0].flights_data.forEach(i => {
          i.show = true;
          i.total_seats = 0;
          i.total_people = 0;
          i.total_checkin = 0;

          res.response.forEach(p => {
            p.order_data.forEach(o => {
              if (o.flight_id === i.flight_id) {
                i.total_seats = i.total_seats + (o.order_adult + o.order_children_1 + o.order_children_2);
                i.total_people = i.total_people + (o.order_adult + o.order_children_1 + o.order_children_2 + o.order_children_free);
                i.total_checkin = i.total_checkin + (o.order_checkin_adult + o.order_checkin_children);
              }
            })
          })


        })

        let transport_list = [];

        let transport_total_seats = 0
        let transport_total_people = 0
        let transport_total_seats_checkin = 0

        if (res.response[0].transport_data) {
          res.response[0].transport_data.forEach(i => {


            if (transport_list.find(t => t.transport_id === i.transport_id)) {
              transport_list.map((te, key) => {
                if (te.transport_id === i.transport_id) {
                  transport_list[key].excursion_id.push(i.excursion_id)
                  if (transport_list[key].guide_id.indexOf(i.guide_id) === -1) {
                    transport_list[key].guide_id.push(i.guide_id)
                    transport_list[key].guide_title.push(i.guide_title)
                  }
                }
              })
            } else {
              let transport_row = i;
              transport_row.excursion_id = [transport_row.excursion_id]
              transport_row.guide_id = [transport_row.guide_id]
              transport_row.guide_title = [transport_row.guide_title]
              transport_row.show = true;

              transport_row.total_seats = 0;
              transport_row.total_people = 0;
              transport_row.total_checkin = 0;

              res.response.forEach(p => {
                p.order_data.forEach(o => {
                  if (o.transport_id === i.transport_id) {
                    transport_row.total_seats =   transport_row.total_seats + (o.order_adult + o.order_children_1 + o.order_children_2);
                    transport_row.total_people =  transport_row.total_people + (o.order_adult + o.order_children_1 + o.order_children_2 + o.order_children_free);
                    transport_row.total_checkin = transport_row.total_checkin + (o.order_checkin_adult + o.order_checkin_children);
                  }
                })
              })

              transport_total_seats = transport_total_seats + transport_row.total_seats
              transport_total_people = transport_total_people + transport_row.total_people
              transport_total_seats_checkin = transport_total_seats_checkin + transport_row.total_checkin

              transport_list.push(transport_row)
            }

          })
        }

        this.flightSeats.transport_data = transport_list

        this.flightSeats.flights = res.response[0].flights_data

        this.flightSeats.flight_id = flight_id;
        this.flightSeats.schedule_date = date
        this.flightSeats.total_seats = total_seats
        this.flightSeats.total_people = total_people
        this.flightSeats.total_seats_checkin = total_seats_checkin

        this.flightSeats.transport_total_seats = transport_total_seats
        this.flightSeats.transport_total_people = transport_total_people
        this.flightSeats.transport_total_seats_checkin = transport_total_seats_checkin

      }
      this.$refs.flightSeat.show()
    },
    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    async loadSubdivision() {
      let result = await Ajax.get('/accounts/suggest?type=staff&post=11&limit=60');
      if (result) {

        this.data.subdivision = result;

      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }
    },
  }
}
</script>

<style>
.pager {
  display: none;
}
@media print {
  .line-tabs,.content-title {
    display: none;
  }
  .table-wrapper {
    padding: 30px;
  }
}
</style>
