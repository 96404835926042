<template>
  <div id="app" v-bind:class="{ 'left-nav-on': loggedIn, 'impersonate-app': isImpersonate }">
    <VueNotificationContainer class="toast"/>
    <div class="impersonate" v-if="isImpersonate">

      <iframe :class="{
        desktop: account_data.user.role_id === 6
      }" :src="`https://${account_data.user.role_id === 6 ? 'partner' : 'apti'}.vizit.tours`"></iframe>
      <router-link to="/logout">
        <span>
          {{ account_data.user.account_title }}
        </span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V15H5V19H19V5H5V9H3V5C3 3.9 3.89 3 5 3ZM11.5 17L10.09 15.59L12.67 13H3V11H12.67L10.09 8.41L11.5 7L16.5 12L11.5 17Z" fill="currentColor"/>
        </svg>
      </router-link>
    </div>
    <template v-if="loggedIn">
      <MenuAdm v-if="role == 'adm' || role == 'staff'"/>

      <section class="main">
          <router-view></router-view>
      </section>
    </template>

    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>

  import User from '../user';
  import MenuAdm from '../components/roles/adm/Menu';


  export default {
    components: {
      MenuAdm
    },

    data () {
      return {
        account_data: User.currentUser || {},
        loggedIn: User.isLoggedIn(),
        isImpersonate: User.isImpersonate(),
        role: ''
      }
    },

    created () {
      User.on('change.app', () => {
        this.updateUser();
      });

      User.on('refresh.app', () => {
        if (this.$route.path !== User.getHomepage()) {
          this.$router.replace(User.getHomepage());
        }
        this.updateUser();
      });

      this.updateUser();

      this.sockets.unsubscribe('updateScheduleReport')
      this.sockets.unsubscribe('updateReservedSeats')

      this.sockets.subscribe('updateScheduleReport', () => {
        this.emitter.emit('updateScheduleReport')
      })
      this.sockets.subscribe('updateReservedSeats', () => {
        this.emitter.emit('updateReservedSeats')
      })
    },



    methods: {
      updateUser() {
        this.loggedIn = User.isLoggedIn();
        this.isImpersonate = User.isImpersonate();
        this.role = (this.loggedIn) ? User.currentUser.user.role_type : '';
      }
    }
  }
</script>

<style lang="scss">
  @import "../scss/common";
</style>