<template>
  <div>
    <Report ref="report" url="settings/price_type/report" :def-params="{order: {field: 'price_type_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="price_type_id"  width="50" />
              <OrderTd width="100%" text="Наименование" field="price_type_title" />
              <OrderTd text="Статус" field="price_type_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="price_type_id" />
              <InputFilter name="price_type_title" filter-type="like" />
              <SelectFilter name="price_type_status" :options="[['active', 'Активен'], ['paused', 'Заблокирован']]" track-by="0" label="1" />
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="4">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.price_type_id}}</td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.price_type_title">
                    <div>
                      <router-link :to="'/adm/settings/price_type/edit/' + row.price_type_id">{{row.price_type_title}}</router-link>
                    </div>
                  </div>
                </td>

                <td>
                  <div class="status" v-bind:class="`status_${row.price_type_status}`">
                    {{data.status[row.price_type_status]}}
                  </div>
                </td>

                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <router-link :to="'/adm/settings/price_type/edit/' + row.price_type_id">
                        <template v-if="ac('settings.settings_price_type.edit')">
                          Редактировать
                        </template>
                        <template v-else>
                          Просмотр
                        </template>
                      </router-link>
                      <a href="#" v-on:click.prevent="changeStatus(row.price_type_id, 'active')" v-if="row.price_type_status !== 'active' && ac('settings.settings_price_type.status')">Разблокировать</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.price_type_id, 'paused')" v-if="row.price_type_status !== 'paused' && ac('settings.settings_price_type.status')">Заблокировать</a>
                      <a href="#" v-if="ac('settings.settings_price_type.delete')" v-on:click.prevent="changeStatus(row.price_type_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },
        subdivision: [],
        subdivisionTitle: []
      },
      error: null,
      report: null
    }
  },

  methods: {

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/settings/price_type/save/${id}`, {
            price_type_id: id,
            price_type_status: status
          });
        }
      } else {
        await Ajax.post(`/settings/price_type/save/${id}`, {
           price_type_id: id,
           price_type_status: status
        });
      }

      this.getReport().reload();
    },
  }
}
</script>
