<template>
  <div>
    <Report ref="report" url="settings/promo/report" :def-params="{order: {field: 'promo_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="promo_id"  width="50" />
              <OrderTd text="Код" field="promo_code"  />
              <OrderTd text="Наименование" field="promo_title" />
              <OrderTd text="Сотрудник" field="account_id" />

              <OrderTd text="Экскурсия" field="flights_id" />
              <OrderTd text="Скидка" field="promo_sale" />

              <OrderTd text="Дата начала" field="promo_start_date" />
              <OrderTd text="Дата окончания" field="promo_end_date" />

              <OrderTd text="Количество активаций" field="promo_limit" />

              <OrderTd text="Статус" field="promo_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="promo_id" />
              <InputFilter name="promo_code" filter-type="like" />
              <InputFilter name="promo_title" filter-type="like" />

              <InputFilter name="account_id" filter-type="like" />
              <InputFilter name="flights_id" filter-type="like" />

              <InputFilter name="promo_sale" filter-type="like" />

              <PeriodPickerFilter :allow-future="true" name="promo_start_date" />
              <PeriodPickerFilter :allow-future="true" name="promo_end_date" />

              <td></td>


              <SelectFilter name="promo_status" :options="[['active', 'Активен'], ['paused', 'Заблокирован']]" track-by="0" label="1" />
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="10">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.promo_id}}</td>
                <td><b>{{row.promo_code}}</b></td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.promo_title">
                    <div>
                      <router-link :to="'/adm/settings/promo/edit/' + row.promo_id">{{row.promo_title}}</router-link>
                    </div>
                  </div>
                </td>

                <td>{{row.account_id ? row.account_id.length : '-'}}</td>
                <td>{{row.flights_id ? row.flights_id.length : '-'}}</td>

                <td>
                  {{row.promo_sale}}{{data.saleType[row.promo_type]}}
                  <div class="row-desc">
                    {{data.summed[row.promo_summed]}}, {{data.whom[row.promo_whom]}}, {{data.type2[row.promo_type2]}}
                  </div>
                </td>

                <td>
                  {{row.promo_start_date ? convertDate(row.promo_start_date) : '-'}}
                </td>

                <td>
                  {{row.promo_end_date ? convertDate(row.promo_end_date) : '-'}}
                </td>

                <td>0 / {{row.promo_limit ? row.promo_limit : '∞'}}</td>

                <td>
                  <div class="status" v-bind:class="`status_${row.promo_status}`">
                    {{data.status[row.promo_status]}}
                  </div>
                </td>

                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <router-link :to="'/adm/settings/promo/edit/' + row.promo_id">
                        <template v-if="ac('settings.settings_promo.edit')">
                          Редактировать
                        </template>
                        <template v-else>
                          Просмотр
                        </template>
                      </router-link>
                      <a href="#" v-on:click.prevent="changeStatus(row.promo_id, 'active')" v-if="row.promo_status !== 'active' && ac('settings.settings_promo.status')">Разблокировать код</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.promo_id, 'paused')" v-if="row.promo_status !== 'paused' && ac('settings.settings_promo.status')">Заблокировать код</a>
                      <a href="#" v-if="ac('settings.settings_promo.delete')" v-on:click.prevent="changeStatus(row.promo_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';

import PeriodPickerFilter from '../../../../components/report/PeriodPickerFilter';

export default {
  mixins: [mixins],

  components: {
    PeriodPickerFilter,
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },


  data() {
    return {
      data: {
        status: {
          'active': 'Активен',
          'paused': 'Заблокирован'
        },
        saleType: {
          'percent': '%',
          'sum': '₽'
        },
        summed: {
          'on': 'Суммируется',
          'off': 'Не суммируется'
        },
        type2: ['', 'сумма заказа', 'предоплата', 'остаточный платёж'],
        whom: {
          'all': 'все',
          'children_all': 'все дети',
          'children': 'дети с местом',
          'children_free': 'дети без места',
          'adults': 'взрослые'
        },
        loading: false

      },
      error: null,
      report: null
    }
  },

  methods: {

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/settings/promo/save/${id}`, {
            promo_id: id,
            promo_status: status
          });
        }
      } else {
        await Ajax.post(`/settings/promo/save/${id}`, {
          promo_id: id,
          promo_status: status
        });
      }

      this.getReport().reload();
    },
  }
}
</script>
