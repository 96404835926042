<template>
    <div class="window-wrap show" v-bind:class="{'show-loader': loading}" v-if="shown">
        <div class="window" ref="content">
            <slot></slot>
        </div>
    </div>
</template>


<script>
    import $ from 'jquery';

    export default {
        name: 'Modal',

        data () {
            return {
                shown: false,
                loading: false
            };
        },

        methods: {
            showLoading(show = true) {
                this.loading = show;
            },

            show() {
                $(document).off('.modal').on('keydown.modal', this.keydown);
                this.shown = true;

                this.$nextTick(() => {
                    $(this.$refs.content).find('.js-dismiss').off('click').click(this.hide);
                });
            },

            hide(e) {
                if (e && e.preventDefault) {
                    e.preventDefault();
                }

                $(document).off('.modal');
                this.shown = false;
                this.loading = false;
            },

            dataToChildren(data, index = 0) {
                if (this.$root.$children.length < index + 1) {
                    this.$nextTick(() => {
                        this.dataToChildren(data, index);
                    });

                    return;
                }

                if (typeof this.$root.$children[index].setData === 'function') {
                    this.$root.$children[index].setData(data);
                }
            },

            keydown(e) {
                if (e.keyCode === 27) {
                    this.hide();
                }
            }

        },

        beforeDestroy() {
            $(document).off('.modal');
        }
    }
</script>