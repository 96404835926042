<template>
    <div class="content">
      <div class="content-header">
        <h1 class="content-title">
          Заявки на доставку
        </h1>
        <div class="content-header_misc">
          <div>
            <router-link to="/adm/delivery/new" class="btn btn-primary">Новая заявка</router-link>
          </div>
        </div>
      </div>

      <div class="line-tabs" id="tabs">
        <router-link to="/adm/delivery" class="current">Заявки на доставку</router-link>
        <router-link to="/adm/delivery_product">Продукция</router-link>
      </div>

        <Report ref="report" url="delivery/report" :def-params="{order: {field: 'delivery_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <Loading />
        <div v-if="error">
          {{error}}
        </div>
        <div class="full-width-content table-wrapper" v-else>
          <div class="table-scrollable">
            <table class="table" cellpadding="0" cellspacing="0" width="100%">
              <thead>
              <tr>
                <OrderTd text="№" field="delivery_id"  width="50" />
                <OrderTd text="Точка продажи" field="point_title" />
                <OrderTd text="Кассир" field="account_title" />

                <td>
                  Состав заявки
                </td>

                <OrderTd text="Дата создания" field="delivery_create_date" />
                <OrderTd text="Дата вручения" field="delivery_done_date" />
                <OrderTd text="Планируемая дата вручения" field="delivery_plan_date" />
                <OrderTd text="Обновлено" field="ts" />
                <OrderTd text="Статус" field="delivery_status" width="100" />
                <td></td>
              </tr>
              <tr class="filter-row">
                <InputFilter name="delivery_id" />
                <InputFilter name="point_title" filter-type="like" />
                <InputFilter name="account_title" filter-type="like" />

                <td></td>

                <PeriodPickerFilter name="delivery_create_date" />
                <PeriodPickerFilter name="delivery_done_date" />
                <PeriodPickerFilter name="delivery_plan_date" />
                <PeriodPickerFilter name="ts" />

                <SelectFilter name="delivery_status" :options="[['new', 'Новая'], ['processed', 'В сборке'], ['delivery', 'В доставке'], ['done', 'Выполнен']]" track-by="0" label="1" />
                <td></td>
              </tr>
              </thead>
              <tbody v-if="report">
              <tr v-if="report.rows_count === 0">
                <td colspan="8">
                  Не найдено.
                </td>
              </tr>

              <template v-else>
                <tr v-for="(row, index) in report.rows" v-bind:key="index">
                  <td>{{row.delivery_id}}</td>
                  <td>
                    <router-link :to="`/adm/delivery/${row.delivery_id}`">
                      {{row.point_title}}
                    </router-link>
                  </td>
                  <td>
                    {{row.account_title}}
                  </td>
                  <td>
                   <div class="ptr-item" v-for="(item, i) in row.items" v-bind:key="i">
                     <div>
                       {{item.delivery_product_title}}
                     </div>
                     <div>
                       {{item.delivery_item_count}}шт.
                     </div>
                     <div>
                       {{statuses[item.delivery_item_status]}}
                     </div>
                   </div>
                  </td>

                  <td>{{row.delivery_create_date ? convertDate(row.delivery_create_date) : '-'}}</td>
                  <td>{{row.delivery_done_date ? convertDate(row.delivery_done_date) : '-'}}</td>
                  <td>{{row.delivery_plan_date ? convertDate(row.delivery_plan_date) : '-'}}</td>
                  <td>{{convertDate(row.ts)}}</td>

                  <td>
                    <div class="status" v-bind:class="`status_${row.delivery_status}`">
                      {{data.status[row.delivery_status]}}
                    </div>
                  </td>


                  <td>
                    <div class="nav-right">
                      <a class="nav"></a>
                      <div class="nav-con">
                        <router-link :to="'/adm/q/' + row.q_id">
                          <template v-if="ac('q.edit')">
                            Редактировать
                          </template>
                          <template v-else>
                            Просмотр
                          </template>
                        </router-link>
                        <a href="#" v-on:click.prevent="changeStatus(row.delivery_id, 'new')" v-if="row.delivery_status !== 'new'">Изменить статус на "новый"</a>
                        <a href="#" v-on:click.prevent="changeStatus(row.delivery_id, 'processed')" v-if="row.delivery_status !== 'processed'">Изменить статус на "в сборке"</a>
                        <a href="#" v-on:click.prevent="changeStatus(row.delivery_id, 'delivery')" v-if="row.delivery_status !== 'delivery'">Изменить статус на "в доставке"</a>
                        <a href="#" v-on:click.prevent="changeStatus(row.delivery_id, 'done')" v-if="row.delivery_status !== 'done'">Изменить статус на "выполнен"</a>
                      </div>
                    </div>
                  </td>
                </tr>

              </template>
              </tbody>
            </table>
          </div>

          <Pager />
        </div>
      </Report>
    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Loading from '../../../components/report/Loading';
import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import InputFilter from '../../../components/report/InputFilter';
import SelectFilter from '../../../components/report/SelectFilter';
import PeriodPickerFilter from '../../../components/report/PeriodPickerFilter';
import OrderTd from '../../../components/report/OrderTd';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    PeriodPickerFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  data() {
    return {
      statuses: {
      new: 'Ожидает',
      done: 'Собран',
      },
      data: {
        status: {
          'new': 'Новая',
          'processed': 'В сборке',
          'delivery': 'В доставке',
          'done': 'Выполнен'
        },
      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/delivery/save/${id}`, {
            delivery_id: id,
            delivery_status: status
          });
        }
      } else {
        await Ajax.post(`/delivery/save/${id}`, {
          delivery_id: id,
          delivery_status: status
        });
      }

      this.getReport().reload();

    },

  }
}
</script>

<style>
.ptr-item {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  border-bottom: 1px solid #cccc;
}
.ptr-item:last-child {
  border-bottom: none;
}
.ptr-item div {
  padding: 2px;
  border-right: 1px solid #cccc;
}
.ptr-item div:first-child {
  width: 100%;
}
.ptr-item div:last-child {
  border-right: none;
}
</style>