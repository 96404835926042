<template>
  <div>
    <Report ref="report" url="salary/report" :def-params="{order: {field: 'salary_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />

      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>

        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>

            <tr>
              <OrderTd text="№" field="salary_id" width="100" />
              <OrderTd text="№ заказа" field="order_id" width="100" />
              <OrderTd text="Продавец" field="account_title" />
              <OrderTd text="Рейс" field="flight_id" />
              <OrderTd class="ar" text="Приход" field="trx_income" />
              <OrderTd class="ar" text="Списание" field="trx_outcome" />
              <OrderTd class="ar" text="Дата" field="ts" />
              <td></td>
            </tr>
            <tr>
              <InputFilter name="salary_id" />
              <InputFilter name="order_id"  />
              <InputFilter name="account_id" />
              <InputFilter name="flight_id" />
              <td></td>
              <td></td>
              <PeriodPickerFilter name="ts" />
              <td></td>
            </tr>

            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="16">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="ar">
                  <b>{{formatRUB(report.summary.trx_income)}}</b>
                </td>
                <td class="ar">
                  <b>{{formatRUB(report.summary.trx_outcome)}}</b>
                </td>
                <td class="ar">
                  Итого: <b>{{formatRUB(report.summary.income)}}</b>
                </td>
                <td></td>
              </tr>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">

                <td>
                  {{row.salary_id}}
                </td>
                <td>
                  {{row.order_id}}
                </td>
                <td>
                  {{row.account_title}} — {{row.account_id}}
                  <div class="row-desc">
                    {{ types[row.account_type] }}
                  </div>
                </td>
                <td>
                  {{row.order_title}} — {{row.flight_id}}
                </td>
                <td class="ar">
                  {{formatRUB(row.trx_income)}}
                </td>
                <td class="ar">
                  {{formatRUB(row.trx_outcome)}}
                </td>
                <td class="ar">
                  {{convertDate(row.ts)}}
                </td>
                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <a v-on:click.prevent="editAmount(row)">
                          Изменить сумму
                      </a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>

    <Modal ref="editSalary">
      <h2>
        Изменение начисления №{{ salary.salary_id }}
      </h2>

      <Input name="account_title" text="Продавец" v-model="salary.account_title" readonly="true" />

      <Input type="number" name="order_id" text="Заказ" v-model="salary.order_id" />

      <Input type="number" name="salary_amount" text="Сумма" v-model="salary.salary_amount" icon2="₽" />

      <div class="form-submit">
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
        <a href="#" class="btn btn-middle" v-on:click.prevent="$refs.editSalary.hide()">Закрыть</a>
      </div>

    </Modal>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';

import PeriodPickerFilter from "@/components/report/PeriodPickerFilter";
import InputFilter from "@/components/report/InputFilter";

import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import OrderTd from '../../../../components/report/OrderTd';
import Modal from "@/components/Modal";
import Input from "@/components/Input";

export default {
  mixins: [mixins],

  components: {
    OrderTd,
    PeriodPickerFilter,
    InputFilter,
    Modal,
    Input,
    Loading,
    Pager,
    Report
  },


  data() {
    return {
      types: {
        staff: 'Кассир',
        partner: 'Партнёр'
      },
      salary: {
        salary_id: null,
        salary_amount: 0
      },
      subdivision: [
        {value: 3, label: 'Анапа'},
        {value: 4, label: 'Проспект'}
      ],

      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: [],
        subdivision_values: [],
        account: [],

      },
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null
    }
  },

  methods: {

    async save() {
      let res = await Ajax.post(`/salary/save/${this.salary.salary_id}`, this.salary);

      if (res.response) {
        this.$toast.success({content: `Обновлено`});
        this.$refs.editSalary.hide()
        this.$refs.report.reload()
      }
      if (res.error) {
        this.$toast.error({content: `Ошибка`});
        this.setError(res.error);
      }

    },


    editAmount(salary){
      this.salary = salary;
      this.$refs.editSalary.show()
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivision_values.push({value: item.subdivision_id, label: item.subdivision_title});
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
