<template>
  <div class="content">

    <h1 class="content-title">
      <router-link :to="'/adm/partners'">Партнёры</router-link>
      <i></i>

      <template v-if="$route.params.id > 0">
        {{ data.account.account_title }} <span>{{ data.account.account_id }}</span>

        <template v-if="$route.params.campaign_id > 0">
          <i></i>
          <router-link :to="`/adm/partners/${$route.params.id}/campaign`">Кампании</router-link>
          <i></i>
          {{ $route.params.campaign_id }}
        </template>
      </template>
      <template v-else>
        Новый партнёр
      </template>

    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="getTabLink('general')" v-bind:class="{current: isTab('general')}">Общая информация</router-link>
      <router-link v-if="$route.params.id > 0" :to="getTabLink('orders')" v-bind:class="{current: isTab('orders')}">Заказы</router-link>
      <router-link v-if="$route.params.id > 0" :to="getTabLink('campaign')" v-bind:class="{current: isTab('campaign')}">Кампании</router-link>
    </div>

    <div class="line-tab-con">
      <General v-if="tab === 'general'" />
      <Orders v-if="tab === 'orders'" />
      <Campaign v-if="tab === 'campaign' && !$route.params.campaign_id" />
      <CampaignView v-if="tab === 'campaign' && $route.params.campaign_id" />
    </div>

  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';

import General from './tabs/General';
import Orders from './tabs/Orders';
import Campaign from './tabs/Campaign';
import CampaignView from './tabs/CampaignView';

export default {
  mixins: [mixins],

  components: {
    General,
    Campaign,
    Orders,
    CampaignView
  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab || 'general';
      this.fetchData();
    }
  },

  created() {

    if (this.$route.params.id > 0) {
      this.fetchData();
    }

  },



  data() {
    return {
      data: {
        campaign: {
          campaign_title: null
        },
        account: {
          account_id: null,
          account_title: null,
        },
      },
      tab: this.$route.params.tab || 'general',
      error: null,
      report: null
    }
  },

  methods: {

    getTabLink(tab) {
      return `/adm/partners/${this.$route.params.id}/${tab}`;
    },

    isTab(tab) {
      return this.tab === tab;
    },

    async fetchData() {
      let urls = {
        'account': `/accounts/${this.$route.params.id}`
      };

      this.data = await Ajax.getBulk(urls);
    },

  }
}
</script>
