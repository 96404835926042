<template>
  <div class="content">
    <h1 class="content-title">
      Рейсы
    </h1>

    <div class="line-tabs" id="tabs">
      <router-link to="/adm/flights/all" class="current">Все рейсы</router-link>
      <router-link to="/adm/flights/followed">Отслеживаемые <i class="count">{{fav.length}}</i></router-link>
    </div>

    <div class="line-tab-con">
      <Report key="report"
              v-if="data.subdivision && data.subdivision.length"
              ref="report"
              url="flights/report"
              :def-params="{order: {field: 'excursion_id', order: 'desc'}, filters: {schedule_date: 'bw|' + period, excursion_publication: 'eq|enabled'}, limit: 500}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <Loading />
        <div v-if="error">
          {{error}}
        </div>
        <div class="full-width-content table-wrapper" v-else>

          <div class="table-filter">
            <div>
              <label class="input-placeholder">Подразделение</label>
              <SelectFilter name="subdivision_id" :options="data.subdivision" track-by="subdivision_id" label="subdivision_title" class="m-0" />
            </div>
            <div>
              <label class="input-placeholder">Экскурсия</label>
              <InputFilter name="excursion_title" filter-type="like" />
            </div>
            <div>
              <label class="input-placeholder">Период</label>
              <PeriodPickerFilter2 :allow-future="true" name="schedule_date" v-model="period" />
            </div>
            <div>
              <label class="input-placeholder">Показать</label>
              <SelectFilter name="excursion_publication" :options="[['enabled', 'Экскурсии'], ['disabled', 'Допы']]" track-by="0" label="1" class="m-0" />
            </div>
            <div style="margin-left: auto">
              <label class="input-placeholder"> </label>
              <div class="radio">
                <label>
                  <input type="checkbox" name="showAll" v-model="hideAll">
                  <span></span>
                  <div>Свернуть все совмещённые рейсы</div>
                </label>
              </div>
            </div>
          </div>

          <div class="table-scrollable scroll-on">
            <table class="table" cellpadding="0" cellspacing="0" width="100%">
              <thead class="sticky">

              <tr>
                <td width="30"></td>
                <OrderTd text="Экскурсия" field="excursion_title" />

                <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                  <b>{{convertDate(date, 'DD')}}</b><br>
                  {{days[convertDate(date, 'd')]}}, {{convertDate(date, 'MMM')}}
                </td>

                <td width="100%"></td>
              </tr>

              </thead>
              <tbody v-if="report">
              <tr v-if="report.rows_count === 0">
                <td colspan="16">
                  Не найдено.
                </td>
              </tr>

              <template v-else>
                <template v-for="(row, index) in report.rows">
                  <template v-if="row.excursion_linked === row.excursion_id">
                    <tr
                        v-if="row.excursion_linked === row.excursion_id"
                        v-bind:key="index"
                        :class="{
                        show: !hideAll || row.show || getShowLinked(row.excursion_id),
                        linked_row_start: row.excursion_linked === row.excursion_id,
                        linked_row: row.excursion_link || row.excursion_linked
                      }"
                    >

                      <td>
                        <div class="checkbox">
                          <label>
                            <input v-model="fav" type="checkbox" :id="row.excursion_id" :value="row.excursion_id">
                            <span></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="row-wrap" v-bind:data-title="row.excursion_title">
                          <div :data-sub="row.subdivision_id">
                            <a v-on:click.prevent="row.excursion_linked === row.excursion_id ? row.show = !row.show : false">
                              {{row.excursion_title}}
                            </a>

                            <a class="edit-link" target="_blank" :href="`/adm/settings/excursions/edit/${row.excursion_id}`">
                              <i class="rui rui-settings"></i>
                            </a>

                          </div>
                        </div>
                      </td>

                      <td v-for="(date, index) in dates" v-bind:key="index" class="center">

                        <template v-if="row.preparedDates && row.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                          <div class="nav nav_flight">
                            <div v-for="(d, index) in row.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index" class="table-date">
                              <i v-on:click.prevent="showFlight(row, d.date)">{{convertDate(d.date, 'HH:mm')}}</i>
                              <b v-on:click.prevent="showFlight(row, d.date)" :class="{active: d.count, error: row.dates[d.date].tickets.indexOf(0) > -1 || row.dates[d.date].tickets.length !== d.count}">
                                {{d.count}}
                                <template v-if="row.dates[d.date].count_total >= 0">
                                  –
                                  <div>
                                    {{row.dates[d.date].count_total}}
                                  </div>
                                </template>
                                – {{d.limit}}
                              </b>
                              <span v-on:click.prevent="showFlightSeats(row.dates[d.date].flight_id, d.date)">?</span>

                            </div>
                          </div>
                          <div class="nav-con">
                            <a v-on:click.prevent="showNewFlight(row, date)">Редактировать расписание</a>
                          </div>

                          <template v-for="(row2, index) in compareSubdivision(row.excursion_id)">
                            <span v-if="row.excursion_id !== row2.excursion_id" v-bind:key="index">
                               <template v-if="row2.preparedDates && row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                                  <div class="nav nav_flight">


                                    <div v-for="(d, index) in row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index" class="table-date">
                                      <template v-if="!row.preparedDates[convertDate(date, 'YYYY-MM-DD')].find(i => i.date === d.date)">
                                          <i v-on:click.prevent="showFlight(row2, d.date)">{{convertDate(d.date, 'HH:mm')}}</i>
                                          <b v-on:click.prevent="showFlight(row2, d.date)" :class="{active: d.count, error: row2.dates[d.date].tickets.indexOf(0) > -1 || row2.dates[d.date].tickets.length !== d.count}">
                                            {{d.count}} - {{d.limit}}
                                          </b>
                                      </template>
                                    </div>
                                  </div>
                                </template>
                            </span>
                          </template>
                        </template>
                        <template v-else>

                          <template v-for="(row2, index) in compareSubdivision(row.excursion_id)">
                            <span v-if="row.excursion_id !== row2.excursion_id" v-bind:key="index">
                               <template v-if="row2.preparedDates && row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                                  <div class="nav nav_flight">
                                    <div v-for="(d, index) in row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index" class="table-date">
                                      <i v-on:click.prevent="showFlight(row2, d.date)">{{convertDate(d.date, 'HH:mm')}}</i>
                                      <b v-on:click.prevent="showFlight(row2, d.date)" :class="{active: d.count, error: row2.dates[d.date].tickets.indexOf(0) > -1 || row2.dates[d.date].tickets.length !== d.count}">
                                        {{d.count}} - {{d.limit}}
                                      </b>
                                    </div>
                                  </div>
                                </template>
                            </span>
                          </template>

                          <div class="table-date" v-on:click.prevent="showNewFlight(row, date)" >+</div>
                        </template>
                      </td>

                      <td></td>
                    </tr>

                    <template v-for="(row2, index) in compareSubdivision(row.excursion_id)">
                      <tr
                          v-if="row.excursion_id !== row2.excursion_id"
                          v-bind:key="index"
                          :class="{
                          show: !hideAll || row2.show || getShowLinked(row2.excursion_id),
                          linked_row: true,
                          linked_row_end: report.rows.filter(i => i.excursion_linked === row.excursion_id).length === index + 1
                        }"
                      >

                        <td>
                          <div class="checkbox">
                            <label>
                              <input v-model="fav" type="checkbox" :id="row2.excursion_id" :value="row2.excursion_id">
                              <span></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="row-wrap" v-bind:data-title="row2.excursion_title">
                            <div :data-sub="row2.subdivision_id">
                                {{row2.excursion_title}}
                              <a class="edit-link" target="_blank" :href="`/adm/settings/excursions/edit/${row2.excursion_id}`">
                                <i class="rui rui-settings"></i>
                              </a>
                            </div>
                          </div>
                        </td>

                        <td v-for="(date, index) in dates" v-bind:key="index" class="center">

                          <template v-if="row2.preparedDates && row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                            <div class="nav nav_flight">
                              <div v-for="(d, index) in row2.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index" class="table-date">
                                <i v-on:click.prevent="showFlight(row2, d.date)">{{convertDate(d.date, 'HH:mm')}}</i>
                                <b v-on:click.prevent="showFlight(row2, d.date)" :class="{active: d.count, error: row2.dates[d.date].tickets.length !== d.count}">
                                  {{d.count}}
                                  <template v-if="row2.dates[d.date].count_total >= 0">
                                    –
                                    <div>
                                      {{row2.dates[d.date].count_total}}
                                    </div>
                                  </template>
                                  – {{d.limit}}
                                </b>
                                <span v-on:click.prevent="showFlightSeats(row2.dates[d.date].flight_id, d.date)">?</span>
                              </div>
                            </div>
                            <div class="nav-con">
                              <a v-on:click.prevent="showNewFlight(row2, date)">Редактировать расписание</a>
                            </div>
                          </template>
                          <div class="table-date" v-on:click.prevent="showNewFlight(row2, date)" v-else>+</div>
                        </td>

                        <td></td>
                      </tr>
                    </template>
                  </template>


                </template>

              </template>




              </tbody>
            </table>
          </div>

          <Pager />
        </div>
      </Report>

      <Modal ref="addFlight">
        <template v-if="newFlightDate">
          <h2>
            Добавить рейс — {{newFlightDate.excursion_title}} — {{convertDate(newFlightDate.date, 'DD.MM.YYYY')}}
          </h2>

          <div class="form">
            <div class="form-left">
              <a href="#" class="btn btn-middle mb-20" v-on:click.prevent="addTime(newFlightDate.excursion_time_type === 'true' ? newFlightDate.day : 0)">Добавить</a>

              <label class="input-placeholder">Время отправления</label>
              <template v-if="newFlightDate.excursion_time_type === 'true'">
                <div class="input-group schedule_time" v-for="(time, i) in schedules.times[newFlightDate.day]" v-bind:key="i" :class="i > 0 ? 'schedule_time-middle' : ''">

                  <Input
                      style="display: none"
                      readonly
                      disabled="disabled"
                      type="text"
                      name="schedule_flight_id"
                      text=" "
                      required="true"
                      v-if="newFlightDate.dates"
                      v-model="schedules.times[newFlightDate.day][i].flight_id"
                  />

                  <Input :min="0" type="number" :max="23" name="schedule_h" :icon2="declination(schedules.times[newFlightDate.day][i].h, duration.h_title)" text=" " required="true" v-model="schedules.times[newFlightDate.day][i].h" />
                  <Input :min="0" type="number" :max="59" name="schedule_m" :icon2="declination(schedules.times[newFlightDate.day][i].m, duration.m_title)" text=" " required="true" v-model="schedules.times[newFlightDate.day][i].m" />

                  <div>
                    <label class="input-placeholder"></label>
                    <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="removeTime(i, newFlightDate.day)">-</a>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="input-group schedule_time" v-for="(time, i) in schedules.times[0]" v-bind:key="i" :class="i > 0 ? 'schedule_time-middle' : ''">

                  <Input
                      style="display: none"
                      readonly
                      disabled="disabled"
                      type="text"
                      name="schedule_flight_id"
                      text=""
                      required="true"
                      v-model="schedules.times[0][i].flight_id"
                      v-if="newFlightDate.dates"
                  />

                  <Input :min="0" type="number" :max="23" name="schedule_h" :icon2="declination(schedules.times[0][i].h, duration.h_title)" text=" " required="true" v-model="schedules.times[0][i].h" />
                  <Input :min="0" type="number" :max="59" name="schedule_m" :icon2="declination(schedules.times[0][i].m, duration.m_title)" text=" " required="true" v-model="schedules.times[0][i].m" />
                  <div>
                    <label class="input-placeholder"></label>
                    <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="removeTime(i, 0)">-</a>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="form-submit">
            <a href="#" class="btn btn-primary" v-on:click.prevent="saveSchedule">Сохранить</a>
            <a href="#" class="btn btn-middle" v-on:click.prevent="hideNewFlight">Закрыть</a>
          </div>

        </template>
      </Modal>

      <Modal ref="editFlight" class="mw1300">
        <template v-if="flight">
          <h2 class="d-flex flex-space_between flex-align-center">
            <div>
              {{flight.ex_title}} <span>{{flight.flight_id}}</span>
              <small>Отправление: {{convertDate(flight.date)}}</small>
            </div>
          </h2>

          <div class="form">
            <div class="form-left">

              <div class="peoples">
                <div>
                  <b>{{flight.data.count - flight.data.order_children_free}}</b>
                  Мест
                </div>

                <div>
                  <b>{{flight.data.order_adult}}</b>
                  Взрослые
                </div>
                <div>
                  <b>{{flight.data.order_children_1}}</b>
                  Дети 6-12 лет
                </div>
                <div>
                  <b>{{flight.data.order_children_2 ? flight.data.order_children_2 : 0}}</b>
                  Дети 0-6 лет<br>с местом
                </div>
                <div style="margin: 0 0 0 auto;">
                  <b>{{flight.data.order_children_free}}</b>
                  Дети 0-6 лет<br>без места
                </div>
              </div>

              <template v-if="flight.data.checkin_count">
                <hr />
                <div class="peoples">
                  <div>
                    <b>{{flight.data.checkin_count}}</b>
                    Всего село
                  </div>
                  <div>
                    <b>{{flight.data.order_checkin_adult}}</b>
                    Взрослые
                  </div>
                  <div>
                    <b>{{flight.data.order_checkin_children}}</b>
                    Дети
                  </div>
                  <div>
                    <b>{{flight.data.order_checkin_children_free}}</b>
                    Дети без места
                  </div>
                </div>
              </template>

              <div class="msg-error mb-20" v-if="seatsMessage">
                {{seatsMessage}}
              </div>

              <div v-if="!flight.transport || !flight.transport.length || !flight.transport[0].floor" class="input-group order height_wrap height_wrap_fix_2" :class="{height_wrap_fix: flight.data.checkin_count}">
                <div>
                  <div class="place">
                    <div>
                      <template v-for="(index) in flight.max_limit">
                        <label
                            v-if="(index) % 4 && (index+1) % 4"
                            class="place-item"
                            :class="orderTickets[index] ? `place-sub place-sub_color${getOrderPeople(orderTickets[index]).place_sub_id}` : ''"
                            v-bind:key="index"
                        >
                          <input
                              type="checkbox"
                              v-model="newTickets[index]"
                              :disabled="orderTickets[index] || (flightData.reserved.rs && flightData.reserved.rs.indexOf(index) > -1)"
                              :value="index"
                              :id="'p1' + index"
                          />
                          <i
                              class="place-item_cont"
                              :style="{'border-color': orderColors[orderTickets[index]]}"
                              :class="{
                                order: orderTickets[index],
                                select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] == orderTickets[index])
                            }"
                              v-on:click.prevent="
                              orderTickets[index] ?
                              (flight.showTransfer ? selectManualTransfer(orders.find(i => i[0] == orderTickets[index])) :
                              editOrder(orderTickets[index])) : setManualSeats(index)"
                          >

                            <template v-if="flightData.flight.transport_data && flightData.flight.transport_data.length && flightData.flight.transport_data[0].transport_place_title && flightData.flight.transport_data[0].transport_place_title.length">
                              <small style="display: block;width: 100%;text-align: center;">{{index}}</small>
                              {{flightData.flight.transport_data[0].transport_place_title[index]}}
                            </template>
                            <template v-else>
                              {{index}}
                            </template>
                          </i>
                          <b
                              :class="{select_order: (orderOriginal && orderOriginal.order.order_id === orderTickets[index])}"
                              :style="{'background': orderColors[orderTickets[index]]}"
                          ></b>
                          <div class="place-item_tooltip" v-if="orderTickets[index] && getOrderPeople(orderTickets[index])">
                            <b>Заказ №{{orderTickets[index]}} - {{getOrderPeople(orderTickets[index]).order_title}}</b><br>
                            <b>{{data.subdivisionTitle[getOrderPeople(orderTickets[index]).place_sub_id]}}</b><br>
                            {{getOrderPeople(orderTickets[index]).place_title}}<br>
                            <div>
                              <template v-if="getOrderPeople(orderTickets[index]).order_adult">
                                <div>
                                  <b>{{ getOrderPeople(orderTickets[index]).order_adult }}</b> взр.
                                </div>
                              </template>
                              <template v-if="getOrderPeople(orderTickets[index]).order_children_free">
                                <div>
                                  <b>{{ getOrderPeople(orderTickets[index]).order_children_free }}</b> без места
                                </div>
                              </template>
                              <template v-if="getOrderPeople(orderTickets[index]).order_children_1 || getOrderPeople(orderTickets[index]).order_children_2">
                                <div>
                                  <b>{{ getOrderPeople(orderTickets[index]).order_children_1 + getOrderPeople(orderTickets[index]).order_children_2 }}</b> с местом
                                </div>
                              </template>
                              <template v-if="getOrderPeople(orderTickets[index]).order_children_age && getOrderPeople(orderTickets[index]).order_children_age.length">
                                <div>
                                  <b>{{ getOrderPeople(orderTickets[index]).order_children_age.join(', ') }}</b> возраст
                                </div>
                              </template>
                            </div>
                          </div>
                        </label>
                      </template>
                    </div>
                    <div>
                      <template v-for="(index) in flight.max_limit">
                        <label
                            v-if="(index-1) % 4 && (index-2) % 4"
                            class="place-item"
                            :class="orderTickets[index] ? `place-sub place-sub_color${getOrderPeople(orderTickets[index]).place_sub_id}` : ''"
                            v-bind:key="index"
                        >
                          <input
                              type="checkbox"
                              v-model="newTickets[index]"
                              :disabled="orderTickets[index] || (flightData.reserved.rs && flightData.reserved.rs.indexOf(index) > -1)"
                              :value="index"
                              :id="'p1' + index"
                          />
                          <i
                              class="place-item_cont"
                              :style="{'border-color': orderColors[orderTickets[index]]}"
                              :class="{
                                order: orderTickets[index],
                                select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] == orderTickets[index])
                            }"
                              v-on:click.prevent="
                              orderTickets[index] ?
                              (flight.showTransfer ? selectManualTransfer(orders.find(i => i[0] == orderTickets[index])) :
                              editOrder(orderTickets[index])) : setManualSeats(index)"
                          >
                            <template v-if="flightData.flight.transport_data && flightData.flight.transport_data.length && flightData.flight.transport_data[0].transport_place_title && flightData.flight.transport_data[0].transport_place_title.length">
                              <small style="display: block;width: 100%;text-align: center;">{{index}}</small>
                              {{flightData.flight.transport_data[0].transport_place_title[index]}}
                            </template>
                            <template v-else>
                              {{index}}
                            </template>
                          </i>
                          <b :class="{select_order: (orderOriginal && orderOriginal.order.order_id === orderTickets[index])}" :style="{'background': orderColors[orderTickets[index]]}"></b>
                          <div class="place-item_tooltip" v-if="orderTickets[index] && getOrderPeople(orderTickets[index])">
                            <b>Заказ №{{orderTickets[index]}} - {{getOrderPeople(orderTickets[index]).order_title}}</b><br>
                            <b>{{data.subdivisionTitle[getOrderPeople(orderTickets[index]).place_sub_id]}}</b><br>
                            {{getOrderPeople(orderTickets[index]).place_title}}<br>
                            <div>
                              <template v-if="getOrderPeople(orderTickets[index]).order_adult">
                                <div>
                                  <b>{{ getOrderPeople(orderTickets[index]).order_adult }}</b> взр.
                                </div>
                              </template>
                              <template v-if="getOrderPeople(orderTickets[index]).order_children_free">
                                <div>
                                  <b>{{ getOrderPeople(orderTickets[index]).order_children_free }}</b> без места
                                </div>
                              </template>
                              <template v-if="getOrderPeople(orderTickets[index]).order_children_1 || getOrderPeople(orderTickets[index]).order_children_2">
                                <div>
                                  <b>{{ getOrderPeople(orderTickets[index]).order_children_1 + getOrderPeople(orderTickets[index]).order_children_2 }}</b> с местом
                                </div>
                              </template>
                              <template v-if="getOrderPeople(orderTickets[index]).order_children_age && getOrderPeople(orderTickets[index]).order_children_age.length">
                                <div>
                                  <b>{{ getOrderPeople(orderTickets[index]).order_children_age.join(', ') }}</b> возраст
                                </div>
                              </template>
                            </div>
                          </div>
                        </label>
                      </template>
                    </div>
                  </div>
                </div>
              </div>


              <div v-if="flight.transport && flight.transport.length && newTicketsTransport.length" class="transfer input-group order height_wrap height_wrap_fix_2">
                <div v-for="(row, index) in flight.transport" v-bind:key="index">
                  <div :class="{
                      ml20: index > 0
                    }"
                  >
                    <div class="peoples">
                      <div>
                        <b>{{row.label}}</b>
                        {{row.floor}} {{declination(row.floor, ['этаж', 'этажа', 'этажей'])}}
                      </div>
                    </div>
                    <div class="input-group">
                      <div v-if="row.floor === 2">
                        <div class="place">
                          <div>
                            <template v-for="(index) in row.place2">
                              <label
                                  v-if="(index) % 4 && (index+1) % 4"
                                  class="place-item"
                                  :class="{
                                      exit_margin: row.separator.find(s => ((s.value === index + 3 || s.value === index + 4 ) && s.type === 'exit')),
                                      reverse: row.separator.find(s => ((s.value === index + 3 || s.value === index + 4 ) && s.type === 'table')),
                                      table_m: row.separator.find(s => s.value === index && s.type === 'table'),
                                      sofa_part1: row.separator.find(s => s.value === (index) && s.type === 'sofa'),
                                      sofa_part2: row.separator.find(s => s.value === (index)-1 && s.type === 'sofa'),
                                      sofa_part5: row.separator.find(s => s.value === (index)-4 && s.type === 'sofa'),
                                      exit: row.separator.find(s => s.value === index && s.type === 'exit')
                                    }"
                                  v-bind:key="index"
                              >
                                <input
                                    type="checkbox"
                                    :value="index"
                                    v-model="newTicketsTransport[row.value][index]"
                                    :id="`tt_${row.value}_2_${index}`"
                                    :disabled="findOrder(index, row.value).order_id"
                                />
                                <i
                                    class="place-item_cont"
                                    :style="{'border-color': orderColors[ findOrder(index, row.value).order_id  ]}"
                                    :class="{
                                    order: findOrder(index, row.value).order_id,
                                    select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] === findOrder(index, row.value).order_id )
                                  }"
                                >
                                  {{row.transport_place_title ? row.transport_place_title[index] : index}}
                                </i>
                                <b :style="{ 'background': orderColors[ findOrder(index, row.value).order_id  ] }"></b>
                              </label>
                            </template>
                          </div>
                          <div>

                            <template v-for="(index) in row.place2">
                              <label
                                  v-if=" (index-1) % 4 && (index-2) % 4"
                                  class="place-item"
                                  :class="{
                                    exit_margin: row.separator.find(s => (( s.value === index + 3 || s.value === index + 4 ) && s.type === 'exit')),
                                    reverse: row.separator.find(s => (( s.value === index + 3 || s.value === index + 4 ) && s.type === 'table')),
                                    table_m: row.separator.find(s => s.value === index && s.type === 'table'),
                                    sofa_part3: row.separator.find(s => s.value === ( index )-2 && s.type === 'sofa'),
                                    sofa_part4: row.separator.find(s => s.value === ( index )-3 && s.type === 'sofa'),
                                    sofa_part5: row.separator.find(s => s.value === ( index )-4 && s.type === 'sofa'),
                                    exit: row.separator.find(s => s.value === index && s.type === 'exit')
                                    }"
                                  v-bind:key="index"
                              >
                                <input
                                    type="checkbox"
                                    :value="index"
                                    :id="`tt_${row.value}_2_${index}`"
                                    v-model="newTicketsTransport[row.value][index]"
                                    :disabled="findOrder(index, row.value).order_id"
                                />
                                <i
                                    class="place-item_cont"
                                    :style="{'border-color': orderColors[ findOrder(index, row.value).order_id  ]}"
                                    :class="{
                                    order: findOrder(index, row.value).order_id,
                                    select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] === findOrder(index, row.value).order_id )
                                  }"
                                >
                                  {{row.transport_place_title ? row.transport_place_title[index] : index}}
                                </i>
                                <b :style="{ 'background': orderColors[ findOrder(index, row.value).order_id ] }"></b>
                              </label>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div>

                        <div class="place">
                          <div>
                            <template v-for="(index) in row.place1">
                              <label
                                  v-if="(index) % 4 && (index+1) % 4"
                                  class="place-item"
                                  :class="{
                                      sofa_part1: row.separator.find(s => s.value === ( index ) + (row.floor > 1 ? row.place2 : 0) && s.type === 'sofa'),
                                      sofa_part2: row.separator.find(s => s.value === ( index ) + (row.floor > 1 ? row.place2 : 0)-1 && s.type === 'sofa'),
                                      sofa_part5: row.separator.find(s => s.value === ( index ) + (row.floor > 1 ? row.place2 : 0)-4 && s.type === 'sofa'),
                                      exit_margin: row.separator.find(s => ((s.value === index + (row.floor > 1 ? row.place2 : 0) + 3 || s.value === index + (row.floor > 1 ? row.place2 : 0) + 4 ) && s.type === 'exit')),
                                      reverse: row.separator.find(s => ((s.value === index + (row.floor > 1 ? row.place2 : 0) + 3 || s.value === index + (row.floor > 1 ? row.place2 : 0) + 4 ) && s.type === 'table')),
                                      table_m: row.separator.find(s => s.value === index + (row.floor > 1 ? row.place2 : 0) && s.type === 'table'),
                                      exit: row.separator.find(s => s.value === index + (row.floor > 1 ? row.place2 : 0) && s.type === 'exit')
                                  }"
                                  v-bind:key="index+row.place2"
                              >
                                <input
                                    type="checkbox"
                                    :disabled="findOrder(index + row.place2, row.value).order_id"
                                    :value="index+row.place2"
                                    v-model="newTicketsTransport[row.value][index + row.place2]"
                                    :id="`tt_${row.value}_1_${index + row.place2}`"
                                    :checked="findOrder(index + row.place2, row.value).order_id"
                                />
                                <i
                                    class="place-item_cont"
                                    :style="{'border-color': orderColors[ findOrder(index + row.place2, row.value).order_id  ]}"
                                    :class="{
                                    order: findOrder(index + row.place2, row.value).order_id,
                                    select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] === findOrder(index + row.place2, row.value).order_id )
                                  }"
                                >
                                  {{row.transport_place_title ? row.transport_place_title[index + row.place2] : index + row.place2}}
                                </i>
                                <b :style="{ 'background': orderColors[ findOrder(index + row.place2, row.value).order_id  ] }"></b>
                              </label>
                            </template>
                          </div>
                          <div>
                            <template v-for="(index) in row.place1">
                              <label
                                  v-if="(index-1) % 4 && (index-2) % 4"
                                  class="place-item"
                                  :class="{
                                    sofa_part3: row.separator.find(s => s.value === (!row.revers ? index : index - 2) + (row.floor > 1 ? row.place2 : 0)-2 && s.type === 'sofa'),
                                    sofa_part4: row.separator.find(s => s.value === (!row.revers ? index : index - 2) + (row.floor > 1 ? row.place2 : 0)-3 && s.type === 'sofa'),

                                    exit_margin: row.separator.find(s => ((s.value === index + (row.floor > 1 ? row.place2 : 0) + 3 || s.value === index + (row.floor > 1 ? row.place2 : 0) + 4 ) && s.type === 'exit')),
                                    reverse: row.separator.find(s => ((s.value === index + (row.floor > 1 ? row.place2 : 0) + 3 || s.value === index + (row.floor > 1 ? row.place2 : 0) + 4 ) && s.type === 'table')),
                                    table_m: row.separator.find(s => s.value === index + (row.floor > 1 ? row.place2 : 0) && s.type === 'table'),
                                    exit: row.separator.find(s => s.value === index + (row.floor > 1 ? row.place2 : 0) && s.type === 'exit')
                                  }"
                                  v-bind:key="index+row.place2"
                              >
                                <input
                                    type="checkbox"
                                    :disabled="findOrder(index + row.place2, row.value).order_id"
                                    :value="index+row.place2"
                                    v-model="newTicketsTransport[row.value][index + row.place2]"
                                    :id="`tt_${row.value}_1_${index+row.place2}`"
                                    :checked="findOrder(index + row.place2, row.value).order_id "
                                />
                                <i
                                    class="place-item_cont"
                                    :style="{'border-color': orderColors[ findOrder(index + row.place2, row.value).order_id  ]}"
                                    :class="{
                                    order: findOrder(index + row.place2, row.value),
                                    select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] === findOrder(index + row.place2, row.value).order_id )
                                  }"
                                >
                                  {{row.transport_place_title ? row.transport_place_title[index + row.place2] : index + row.place2}}
                                </i>
                                <b :style="{ 'background': orderColors[ findOrder(index + row.place2, row.value).order_id  ] }"></b>
                              </label>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <template v-if="newOrder">
              <div class="form-right-window">
                <h2>
                  <template v-if="orderOriginal">
                    <div class="d-flex flex-align-center">
                      Редактирование заказа №{{orderOriginal.order.order_id}}

                      <b class="ml10" :class="`status status_${orderOriginal.order.order_status}`">{{status[orderOriginal.order.order_status]}}</b>

                      <b class="ml10 position-relative" v-if="ac('orders.edit') || ac('orders.status') || ac('orders.view')">
                        <a class="nav"></a>
                        <div class="nav-con">
                          <a v-if="orderOriginal.order.order_resp_id" :href="domain+'/i/'+orderOriginal.order.order_resp_id" target="_blank">Посмотреть билет</a>

                          <template v-if="ac('orders.status')">
                            <a href="#" v-if="!orderOriginal.order.order_pay_resp_id && ['cancel', 'cancel_success'].indexOf(orderOriginal.order.order_status) === -1" v-on:click.prevent="changeStatus(orderOriginal.order, 'cancel')">Отменить заказ</a>
                            <a href="#" v-if="orderOriginal.order.order_pay_resp_id && ['order'].indexOf(orderOriginal.order.order_status) > -1" v-on:click.prevent="changeStatus(orderOriginal.order, 'cancel')">Отменить заказ и вернуть деньги</a>
                          </template>
                        </div>
                      </b>
                    </div>
                    <small>Дата оформления: {{convertDate(orderOriginal.order.order_date)}}</small>
                  </template>
                  <template v-else>
                    Новый заказ
                  </template>
                </h2>

                <div class="msg-error mb-20" v-if="convertDate(flight.date, 'YYYYMMDDHHmm') < convertDate(new Date(), 'YYYYMMDDHHmm')">
                  Внимание! Рейс уже уехал.
                </div>
                <div class="msg-error mb-20" v-if="seatsMessage">
                  {{ seatsMessage }}
                </div>

                <div class="height_wrap_right">
                  <div class="input-group">
                    <Input name="user_phone" mask="+#(###)###-##-##" text="Телефон" required="true" v-model="order.order_client_phone" />
                    <Input name="account_title" text="ФИО" required="true" v-model.trim="order.order_client_title" />
                  </div>

                  <div class="input-group">
                    <Input name="order_adult" text="Взрослые" required="true" v-model.number.trim="order.order_adult" />

                    <div class="d-flex flex-align-center count-btn">
                      <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.order_adult > 0 ? order.order_adult-- : false">-</a>
                      <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.order_adult++">+</a>
                    </div>

                    <Input name="order_children" text="Дети" required="true" v-model.number.trim="order.order_children"  v-on:input="setChildrenCount" />

                    <div class="d-flex flex-align-center count-btn">
                      <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.order_children > 0 ? order.order_children-- : false">-</a>
                      <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="order.order_children++">+</a>
                    </div>
                  </div>

                  <div class="kids_row input-group" v-for="n in order.order_children" v-bind:key="n">
                    <div class="ar pt-10">
                      <label>Возраст {{n}} ребёнка</label>
                    </div>
                    <div>
                      <Select
                          class="mb-0"
                          name="order_age[]"
                          required="true"
                          :options="[[0, 'Нет'], [1, '1 год'], [2, '2 года'], [3, '3 года'], [4, '4 года'], [5, '5 лет'], [6, '6 лет'], [7, '7 лет'], [8, '8 лет'], [9, '9 лет'], [10, '10 лет'], [11, '11 лет']]"
                          track-by="0"
                          label="1"
                          v-model="order.childrenValues[n-1]"
                          v-on:change="setChildrenCount"
                      />

                      <div class="pb-14" v-if="flightData.excursion.excursion_price_children_free2_allow === 'true' && order.childrenValues[n-1] > 0 && order.childrenValues[n-1] <= flightData.excursion.excursion_age_children_free2_e">

                        <div class="checkbox">
                          <label>
                            <input
                                type="checkbox"
                                name="age_place[]"
                                value="true"
                                v-model="order.childrenValuesPlace[n-1]"
                                v-on:change="setChildrenCount();setNewTickets()"
                            >
                            <span>Без места</span>
                          </label>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div>
                    <Select
                        text="Подразделение"
                        name="order_subdivision"
                        v-model="order.order_subdivision"
                        :options="data.subdivision"
                        trackBy="subdivision_id"
                        label="subdivision_title"
                    />
                  </div>
                  <div v-show="order.order_subdivision === 3 || order.order_subdivision === 4">
                    <AutosuggestMulti
                        :multiple="false"
                        ref="excursion_point"
                        v-model="order.point_data"
                        :value="order.point_data"
                        name="excursion_point"
                        text="Точка продажи"
                        url="/misc/point"
                        v-on:input="changePointIds"
                    />
                  </div>
                  <div v-show="order.order_subdivision > 4">

                    <AutosuggestMulti
                        v-if="order.order_subdivision > 4"
                        :multiple="false"
                        ref="excursion_point"
                        v-model="order.order_seller_data"
                        :value="order.order_seller_data"
                        name="excursion_point"
                        :text="`Кассир компании - ${data.subdivision && data.subdivision.filter(i => i.subdivision_id === order.order_subdivision)[0].subdivision_title}`"
                        :url="`/accounts/suggest?type=staff&sub=${order.order_subdivision}`"
                        v-on:input="changeSellerIds"
                    />
                  </div>

                  <div>

                    <AutosuggestMulti
                        :multiple="false"
                        ref="excursion_place"
                        v-model="order.place_data"
                        :value="order.place_data"
                        name="excursion_place"
                        text="Место посадки"
                        url="/misc/place"
                        v-on:input="changePlaceIds"
                    />
                    <div class="msg-error mb-20" v-if="!order.place_data">
                      Укажите точку посадки
                    </div>
                  </div>

                  <Input name="order_place_time" mask="##:##" text="Время посадки" required="true" v-model.trim="order.order_place_time" />

                  <Input name="order_address" text="Адрес" required="true" v-model.trim="order.order_address" />

                  <Textarea name="order_comment" text="Примечание" required="true" v-model.trim="order.order_comment" />
                  <div class="tags">
                    <i v-for="( t, i ) in tags" v-bind:key="i" v-on:click="order.order_comment = order.order_comment + t + ', '">{{ t }}</i>
                  </div>

                </div>
              </div>

              <div class="form-sum">
                <div class="prices">
                  <div>
                    <b>
                      {{ formatRUB(
                        Math.ceil(getSum(
                            order.allSum
                        ))
                    )}}
                    </b>
                    Итого
                  </div>
                  <div>
                    <b>
                      <template v-if="order.allSumPrepay === order.allSum">
                        {{ formatRUB(
                          Math.ceil(getSum(
                              order.allSum
                          ))
                      )}}
                      </template>
                      <template v-if="order.allSumPrepay < order.allSum">
                        {{ formatRUB(
                          Math.ceil(getSalePrepay(
                              order.allSumPrepay,
                              order.allSum
                          ))
                      )}}
                      </template>

                    </b>
                    К оплате
                  </div>
                  <div>
                    <b>
                      {{ formatRUB(
                        Math.ceil(getSum(
                            order.allSum
                        ) - Math.ceil(getSalePrepay(
                            order.allSumPrepay,
                            order.allSum
                        )))
                    )}}
                    </b>
                    Гиду
                  </div>
                  <div>
                    <b>{{formatRUB(order.order_sum_sale)}}</b>
                    Скидка
                  </div>
                  <hr />
                  <div>

                    <b>
                      <template v-if="newTickets.find(x => x === true)">
                        {{newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).join(', ') }}
                      </template>
                      <template v-else>
                        Места не выбраны
                      </template>
                    </b>

                    <label
                        class="status input-placeholder mt14"
                        :class="`status_${newTicketsCount === newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).length ? 'order' : newTicketsCount < newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).length ? 'waiting_for_capture' : 'pending'}`">
                      Выбранные места ({{newTickets.reduce((a, e, i) => {if (e) {a.push(i); } return a; }, [] ).length}} / {{newTicketsCount}})
                    </label>

                  </div>
                </div>

                <Input type="text" text="Промокод" v-model="order.order_promo" name="order_promo" />

                <div class="msg-error mb-20" v-if="promoData && !promoData.length">
                  Промокод не найден
                </div>

                <div class="msg-success mb-20" v-if="promoData && promoData.length && promoData[0].promo_title">
                  Промокод активирован
                </div>

                <button class="btn btn-primary mb-20" :disabled="!order.order_promo || order.order_promo.length < 3" v-on:click="checkPromo">
                  Активировать
                </button>


                <div class="input-group">
                  <Input name="sale_amount" text="Скидка" required="true" v-model.trim.number="order.sale_amount" />
                  <Select
                      id="sale_type"
                      text=""
                      name="sale_type"
                      required="true"
                      :options="[['percent', '%'], ['sum', '₽']]"
                      track-by="0"
                      label="1"
                      def="percent"
                      v-model="order.sale_type"
                  />
                </div>
                <template v-if="flightData.excursion.excursion_prepay_allow">
                  <Select
                      id="excursion_sale_prepay_type"
                      text="Скидка распространяется на:"
                      name="excursion_sale_type"
                      required="true"
                      :options="[['prepay', 'предоплату'], ['other', 'на остаточный платёж']]"
                      track-by="0"
                      label="1"
                      def="prepay"
                      v-model="order.sale_prepay_type"
                  />
                </template>

                <Select
                    text="Оплата"
                    class="mb-0"
                    id="pay_type"
                    name="pay_type"
                    required="true"
                    :options="[['cash', 'Наличными кассиру'], ['card', 'Картой кассиру'], ['online', 'Онлайн']]"
                    track-by="0"
                    label="1"
                    v-model="order.pay_type"
                />

              </div>

            </template>

            <template v-if="flight.showTransfer">
              <div class="form-right-window">

                <h2 class="d-flex flex-align-center">
                  Пересадка
                  <div class="ml20">
                    <div class="radio">
                      <label>
                        <div>автоматическая</div>
                        <input type="checkbox" name="transport_revers" value="Y" v-model="manualTransfer">
                        <span></span>
                        <div>ручная</div>
                      </label>
                    </div>
                  </div>
                </h2>
                <div class="transfer input-group">
                  <div v-for="(row, index) in flight.transport" v-bind:key="index">
                    <div :class="{
                      ml20: index > 0,
                      revers_on: row.revers
                    }"
                    >
                      <div class="peoples">
                        <div>
                          <b>{{row.label}}</b>
                          {{row.floor}} {{declination(row.floor, ['этаж', 'этажа', 'этажей'])}}
                        </div>
                      </div>
                      <div class="input-group">
                        <div v-if="row.floor === 2">
                          <Input v-if="!manualTransfer" :name="`limit_auto_floor_${row.value}`" v-on:input="autoTransfer(false)" text="Лимит выдаваемых мест" v-model.number.trim="transferTickets[`t_${row.value}_2`].limit" />
                          <div class="place">
                            <div>
                              <template v-for="(index) in row.place2">
                                <label
                                    v-if="row.revers ? (index-1) % 4 && (index-2) % 4 : (index) % 4 && (index+1) % 4"
                                    class="place-item"
                                    :class="{
                                      free: (!selectManualTransferCurrent || !manualTransfer),
                                      exit_margin: row.separator.find(s => ((s.value === index + 3 || s.value === index + 4 ) && s.type === 'exit')),
                                      reverse: row.separator.find(s => ((s.value === index + 3 || s.value === index + 4 ) && s.type === 'table')),
                                      table_m: row.separator.find(s => s.value === index && s.type === 'table'),
                                      sofa_part1: row.separator.find(s => s.value === (!row.revers ? index : index - 2) && s.type === 'sofa'),
                                      sofa_part2: row.separator.find(s => s.value === (!row.revers ? index : index - 2)-1 && s.type === 'sofa'),
                                      sofa_part5: row.separator.find(s => s.value === (!row.revers ? index : index - 2)-4 && s.type === 'sofa'),
                                      exit: row.separator.find(s => s.value === index && s.type === 'exit')
                                    }"
                                    v-bind:key="index"
                                    :data-alt="transferTickets[`t_${row.value}_2`].ticket[index]"
                                >
                                  <input
                                      type="checkbox"
                                      v-on:change="updateManualTicket(index, `t_${row.value}_2`)"
                                      :disabled="(transferTickets[`t_${row.value}_2`].ticket[index] && !manualTransfer) || (!selectManualTransferCurrent || !manualTransfer) || (!(selectManualTransferCurrent[0] === transferTickets[`t_${row.value}_2`].ticket[index]) && transferTickets[`t_${row.value}_2`].ticket[index])"
                                      :value="index"
                                      :id="`t_${row.value}_2_${index}`"
                                  />
                                  <i
                                      class="place-item_cont"
                                      :style="{'border-color': orderColors[transferTickets[`t_${row.value}_2`].ticket[index]]}"
                                      :class="{
                                    order: transferTickets[`t_${row.value}_2`].ticket[index],
                                    select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] === transferTickets[`t_${row.value}_2`].ticket[index])
                                  }"
                                  >
                                    {{row.transport_place_title ? row.transport_place_title[index] : index}}
                                  </i>
                                  <b :style="{'background': orderColors[transferTickets[`t_${row.value}_2`].ticket[index]]}"></b>
                                </label>
                              </template>
                            </div>
                            <div>
                              <template v-for="(index) in row.place2">
                                <label
                                    v-if="row.revers ? (index) % 4 && (index+1) % 4 : (index-1) % 4 && (index-2) % 4"
                                    class="place-item"
                                    :class="{
                                    free: (!selectManualTransferCurrent || !manualTransfer),
                                    exit_margin: row.separator.find(s => (( s.value === index + 3 || s.value === index + 4 ) && s.type === 'exit')),
                                    reverse: row.separator.find(s => (( s.value === index + 3 || s.value === index + 4 ) && s.type === 'table')),
                                    table_m: row.separator.find(s => s.value === index && s.type === 'table'),
                                    sofa_part3: row.separator.find(s => s.value === (!row.revers ? index : index - 2)-2 && s.type === 'sofa'),
                                    sofa_part4: row.separator.find(s => s.value === (!row.revers ? index : index - 2)-3 && s.type === 'sofa'),
                                    sofa_part5: row.separator.find(s => s.value === (!row.revers ? index : index - 2)-4 && s.type === 'sofa'),
                                    exit: row.separator.find(s => s.value === index && s.type === 'exit')
                                    }"
                                    v-bind:key="index"
                                    :data-alt="transferTickets[`t_${row.value}_2`].ticket[index]"
                                >
                                  <input
                                      type="checkbox"
                                      v-on:change="updateManualTicket(index, `t_${row.value}_2`)"
                                      :disabled="(transferTickets[`t_${row.value}_2`].ticket[index] && !manualTransfer) || (!selectManualTransferCurrent || !manualTransfer) || (!(selectManualTransferCurrent[0] === transferTickets[`t_${row.value}_2`].ticket[index]) && transferTickets[`t_${row.value}_2`].ticket[index])"
                                      :value="index"
                                      :id="`t_${row.value}_2_${index}`"
                                      :checked="transferTickets[`t_${row.value}_2`].ticket[index]"
                                  />
                                  <i
                                      class="place-item_cont"
                                      :style="{'border-color': orderColors[transferTickets[`t_${row.value}_2`].ticket[index]]}"
                                      :class="{
                                    order: transferTickets[`t_${row.value}_2`].ticket[index],
                                    select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] === transferTickets[`t_${row.value}_2`].ticket[index])
                                  }"
                                  >
                                    {{row.transport_place_title ? row.transport_place_title[index] : index}}
                                  </i>
                                  <b :style="{'background': orderColors[transferTickets[`t_${row.value}_2`].ticket[index]]}"></b>
                                </label>
                              </template>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Input v-if="!manualTransfer" :name="`limit_auto_floor_${row.value}`" v-on:input="autoTransfer(false)" text="Лимит выдаваемых мест" v-model.number.trim="transferTickets[`t_${row.value}_1`].limit" />
                          <div class="place">
                            <div>
                              <template v-for="(index) in row.place1">
                                <label
                                    v-if="row.revers ? (index-1) % 4 && (index-2) % 4 : (index) % 4 && (index+1) % 4"
                                    class="place-item"
                                    :class="{
                                    free: (!selectManualTransferCurrent || !manualTransfer),
                                      sofa_part1: row.separator.find(s => s.value === (!row.revers ? index : index - 2) + (row.floor > 1 ? row.place2 : 0) && s.type === 'sofa'),
                                      sofa_part2: row.separator.find(s => s.value === (!row.revers ? index : index - 2) + (row.floor > 1 ? row.place2 : 0)-1 && s.type === 'sofa'),
                                      sofa_part5: row.separator.find(s => s.value === (!row.revers ? index : index - 2) + (row.floor > 1 ? row.place2 : 0)-4 && s.type === 'sofa'),
                                      exit_margin: row.separator.find(s => ((s.value === index + (row.floor > 1 ? row.place2 : 0) + 3 || s.value === index + (row.floor > 1 ? row.place2 : 0) + 4 ) && s.type === 'exit')),
                                      reverse: row.separator.find(s => ((s.value === index + (row.floor > 1 ? row.place2 : 0) + 3 || s.value === index + (row.floor > 1 ? row.place2 : 0) + 4 ) && s.type === 'table')),
                                      table_m: row.separator.find(s => s.value === index + (row.floor > 1 ? row.place2 : 0) && s.type === 'table'),
                                      exit: row.separator.find(s => s.value === index + (row.floor > 1 ? row.place2 : 0) && s.type === 'exit')
                                  }"
                                    v-bind:key="index+row.place2"
                                    :data-alt="transferTickets[`t_${row.value}_1`].ticket[index+row.place2]"
                                >
                                  <input
                                      type="checkbox"
                                      v-on:change="updateManualTicket(index+row.place2, `t_${row.value}_1`)"
                                      :disabled="(transferTickets[`t_${row.value}_1`].ticket[index+row.place2] && !manualTransfer) || (!selectManualTransferCurrent || !manualTransfer) || (!(selectManualTransferCurrent[0] === transferTickets[`t_${row.value}_1`].ticket[index+row.place2]) && transferTickets[`t_${row.value}_1`].ticket[index+row.place2])"
                                      :value="index+row.place2"
                                      :id="`t_${row.value}_1_${index+row.place2}`"
                                      :checked="transferTickets[`t_${row.value}_1`].ticket[index+row.place2]"
                                  />
                                  <i
                                      class="place-item_cont"
                                      :style="{'border-color': orderColors[transferTickets[`t_${row.value}_1`].ticket[index+row.place2]]}"
                                      :class="{
                                      order: transferTickets[`t_${row.value}_1`].ticket[index+row.place2],
                                      select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] === transferTickets[`t_${row.value}_1`].ticket[index+row.place2])
                                    }"
                                  >
                                    {{row.transport_place_title ? row.transport_place_title[index+row.place2] : index+row.place2}}
                                  </i>
                                  <b :style="{'background': orderColors[transferTickets[`t_${row.value}_1`].ticket[index+row.place2]]}"></b>
                                </label>
                              </template>
                            </div>
                            <div>
                              <template v-for="(index) in row.place1">
                                <label
                                    v-if="row.revers ? (index) % 4 && (index+1) % 4 : (index-1) % 4 && (index-2) % 4"
                                    class="place-item"
                                    :class="{
                                    free: (!selectManualTransferCurrent || !manualTransfer),
                                    sofa_part3: row.separator.find(s => s.value === (!row.revers ? index : index - 2) + (row.floor > 1 ? row.place2 : 0)-2 && s.type === 'sofa'),
                                    sofa_part4: row.separator.find(s => s.value === (!row.revers ? index : index - 2) + (row.floor > 1 ? row.place2 : 0)-3 && s.type === 'sofa'),

                                    exit_margin: row.separator.find(s => ((s.value === index + (row.floor > 1 ? row.place2 : 0) + 3 || s.value === index + (row.floor > 1 ? row.place2 : 0) + 4 ) && s.type === 'exit')),
                                    reverse: row.separator.find(s => ((s.value === index + (row.floor > 1 ? row.place2 : 0) + 3 || s.value === index + (row.floor > 1 ? row.place2 : 0) + 4 ) && s.type === 'table')),
                                    table_m: row.separator.find(s => s.value === index + (row.floor > 1 ? row.place2 : 0) && s.type === 'table'),
                                    exit: row.separator.find(s => s.value === index + (row.floor > 1 ? row.place2 : 0) && s.type === 'exit')
                                  }"
                                    v-bind:key="index+row.place2"
                                    :data-alt="transferTickets[`t_${row.value}_1`].ticket[index+row.place2]"
                                >
                                  <input
                                      type="checkbox"
                                      v-on:change="updateManualTicket(index+row.place2, `t_${row.value}_1`)"
                                      :disabled="(transferTickets[`t_${row.value}_1`].ticket[index+row.place2] && !manualTransfer) || (!selectManualTransferCurrent || !manualTransfer) || (!(selectManualTransferCurrent[0] === transferTickets[`t_${row.value}_1`].ticket[index]) && transferTickets[`t_${row.value}_1`].ticket[index])"
                                      :value="index+row.place2"
                                      :id="`t_${row.value}_1_${index+row.place2}`"
                                      :checked="transferTickets[`t_${row.value}_1`].ticket[index+row.place2]"
                                  />

                                  <i
                                      class="place-item_cont"
                                      :style="{'border-color': orderColors[transferTickets[`t_${row.value}_1`].ticket[index+row.place2]]}"
                                      :class="{
                                      order: transferTickets[`t_${row.value}_1`].ticket[index+row.place2],
                                      select_order: (selectManualTransferCurrent && selectManualTransferCurrent[0] === transferTickets[`t_${row.value}_1`].ticket[index+row.place2])
                                    }"
                                  >

                                    {{row.transport_place_title ? row.transport_place_title[index+row.place2] : index+row.place2}}
                                  </i>
                                  <b :style="{'background': orderColors[transferTickets[`t_${row.value}_1`].ticket[index+row.place2]]}"></b>
                                </label>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="form-sum">
                  <div class="transfer-result">
                    <template v-if="manualTransfer">
                      <div>
                        <div class="">
                          <div class="transfer-result_transport d-flex flex-align-center flex-space_between">
                            Заказы
                            <a class="btn btn-primary ml20" v-on:click.prevent="autoTransfer(true)">Рассадить автоматически</a>
                          </div>
                        </div>

                        <div class="msg-error" v-if="orders.length !== flightData.order.rows_count">
                          Потерялся заказ
                        </div>

                        <div
                            class="transfer-result_item"
                            :class="{
                                 current: selectManualTransferCurrent && order[0] === selectManualTransferCurrent[0],
                                 error_item: order[2] && order[1][0] < order[2][0]
                          }"
                            v-for="(order, i) in orders"
                            v-bind:key="i"
                            v-on:click.prevent="selectManualTransfer(order)"
                        >
                          <b>
                            <a class="dashed">{{order[0]}} - {{order[1].length}} {{declination(order[1].length, ['место', 'места', 'мест'])}}</a>
                            <span :style="{background: orderColors[order[0]]}">
                            <div v-if="order[1].filter(i => orders.filter(t => t[0] !== order[0] && t[1].indexOf(i) > -1).length > 0).length">
                              ПНБ Дубль
                            </div>
                          </span>
                          </b>

                          <div class="msg-error mb14 error_count" v-if="getOrderPeople(order[0]).order_status !== 'order' ">
                            {{ status[getOrderPeople(order[0]).order_status] }}
                          </div>

                          <div class="transfer-result_ex">
                            {{getOrderPeople(order[0]).order_title}}
                          </div>

                          <div class="transfer-result_comment">
                            {{ getOrderComment(order[0]) }}
                          </div>
                          <div class="transfer-result_text">

                            <div :class="'place-sub place-sub_color'+getOrderPeople(order[0]).place_sub_id" :data-alt="data.subdivisionTitle[getOrderPeople(order[0]).place_sub_id]">
                              {{ getOrderPeople(order[0]).place_title }}
                            </div>
                          </div>
                          <div class="transfer-result_text">
                            <template v-if="getOrderPeople(order[0]).order_adult">
                              <div>
                                <span>{{ getOrderPeople(order[0]).order_adult }}</span> взр.
                              </div>
                            </template>
                            <template v-if="getOrderPeople(order[0]).order_children_free">
                              <div>
                                <span>{{ getOrderPeople(order[0]).order_children_free }}</span> без места
                              </div>
                            </template>
                            <template v-if="getOrderPeople(order[0]).order_children_1 || getOrderPeople(order[0]).order_children_2">
                              <div>
                                <span>{{ getOrderPeople(order[0]).order_children_1 + getOrderPeople(order[0]).order_children_2 }}</span> с местом
                              </div>
                            </template>
                            <template v-if="getOrderPeople(order[0]).order_children_age && getOrderPeople(order[0]).order_children_age.length">
                              <div>
                                <span>{{ getOrderPeople(order[0]).order_children_age.join(', ') }}</span> возраст
                              </div>
                            </template>
                          </div>

                          <div class="transfer-result_ticket">
                            <div>
                              {{ getOrderPeople(order[0]).order_tickets_first }}
                            </div>
                            <template v-if="order[2]">
                              <div> → </div>
                              <div :class="{error_count: getOrderPeople(order[0]).people_count !== order[2].length}">
                                <template v-if="getOrderTransport(order[0]) && getOrderTransport(order[0]).transport_place_title && getOrderTransport(order[0]).transport_place_title.length">
                                  {{ order[2].map(i => {return getOrderTransport(order[0]).transport_place_title[i]}).join(', ') }}
                                </template>
                                <template v-else>
                                  {{order[2].join(', ') }}
                                </template>
                              </div>
                            </template>
                          </div>

                          <div v-if="selectManualTransferCurrent && selectManualTransferCurrent[0] === order[0]">
                            <label
                                class="status input-placeholder mt14"
                                :class="`status_${getOrderPeople(order[0]).people_count === (selectManualTransferCurrent[2] ? selectManualTransferCurrent[2].length : 0) ? 'order' : getOrderPeople(order[0]).people_count < (selectManualTransferCurrent[2] ? selectManualTransferCurrent[2].length : 0) ? 'waiting_for_capture' : 'pending'}`">
                              Выбранные места ({{(selectManualTransferCurrent[2] ? selectManualTransferCurrent[2].length : 0)}} / {{ getOrderPeople(order[0]).people_count }})
                            </label>
                          </div>

                        </div>
                      </div>
                    </template>

                    <template v-else>
                      <div v-for="(row, index) in transferTickets.result" v-bind:key="index">
                        <div class="transfer-result_transport">{{row.title}}</div>
                        <div
                            class="transfer-result_item"
                            :class="{
                            error_item: order[1].oldTicket[0] < order[1].newTicket[0]
                          }"
                            v-for="(order, i) in Object.entries(row.orders)"
                            v-bind:key="i">
                          <b>
                            {{order[0].replace('t', '')}} - {{order[1].oldTicket.length}} {{declination(order[1].oldTicket.length, ['место', 'места', 'мест'])}}
                            <span :style="{background: orderColors[order[0].replace('t', '')]}"></span>
                          </b>
                          <div class="transfer-result_comment">
                            {{ getOrderComment(order[0].replace('t', '')) }}
                          </div>
                          <div class="transfer-result_ticket">
                            <div>
                              {{order[1].oldTicket.join(', ')}}
                            </div>
                            <div> → </div>
                            <div>
                              <template v-if="row.transport_place_title && row.transport_place_title.length">
                                {{ order[1].newTicket.map(i => {return row.transport_place_title[i]}).join(', ') }}
                              </template>
                              <template v-else>
                                {{order[1].newTicket.join(', ') }}
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>



                  </div>
                </div>

              </div>
            </template>

            <template v-if="!newOrder && !flight.showTransfer">
              <div class="form-right-window">
                <h2>Настройки рейса</h2>

                <div class="height_wrap_right">
                  <button class="btn btn-primary" v-on:click.prevent="showNewOrder">
                    Добавить заказ
                  </button>

                  <a v-if="flight.transport.length" href="#" class="btn btn-primary ml20" v-on:click.prevent="showTransfer">
                    Пересадка
                  </a>

                  <hr>

                  <div class="input-group">
                    <Input name="count_sets" text="Максимальное кол-во продаваемых мест" required="true" v-model.number="flight.flight_limit" />
                    <div class="d-flex flex-align-center count-btn">
                      <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="flight.flight_limit > 0 ? flight.flight_limit-- : false">-</a>
                      <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="flight.flight_limit++">+</a>
                    </div>
                  </div>

                  <div class="checkbox mb-20">
                    <label>
                      <input v-model="flight.flight_limit_stop" type="checkbox" name="stop_sale" value="1">
                      <span>При продаже {{flight.flight_limit}} {{declination(flight.flight_limit, ['места', 'мест', 'мест'])}} остановить продажи</span>
                    </label>
                  </div>

                  <AutosuggestMulti
                      v-if="flight"
                      ref="transport_id"
                      :multiple="true"
                      name="transport_id"
                      text="Транспорт"
                      url="/misc/transport"
                      v-model="flight.transport"
                      :value="flight.transport"
                  />

                  <template v-if="flight.transport && flight.transport.length">

                    <div class="transport-item" v-for="(row, index) in flight.transport" v-bind:key="index">
                      <div class="transport-item_title">{{row.label}}</div>
                      <template v-if="flightData.excursion.link_data">
                        <AutosuggestMulti
                            :value="flight.transport[index].guide"
                            v-model="flight.transport[index].guide"
                            ref="guide"
                            :multiple="true"
                            name="guide[]"
                            text="Экскурсовод"
                            url="/accounts/suggest?type=staff&post=11&limit=60"
                            placeholder="Выбрать экскурсовода"
                        />
                        <template v-if="flight.transport[index].guide && flight.transport[index].guide.length">

                          <AutosuggestMulti
                              v-for="(row, i) in flight.transport[index].guide" v-bind:key="i"
                              :data="[{
                              value: flightData.excursion.excursion_id,
                              label: flightData.excursion.excursion_title
                            }].concat(flightData.excursion.link_data)"
                              :value="flight.transport[index].guide[i].excursion_data"
                              v-model="flight.transport[index].guide[i].excursion_data"
                              ref="excursion"
                              :multiple="true"
                              name="ex[]"
                              :text="`Экскурсии - ${row.label}`"
                              placeholder="Выбрать экскурсию"
                          />
                        </template>
                      </template>
                      <template v-else>
                        <AutosuggestMulti :value="flight.transport[index].guide" v-model="flight.transport[index].guide" ref="guide" :multiple="true" name="guide[]" text="Экскурсовод" url="/accounts/suggest?type=staff&post=11&limit=60" placeholder="Выбрать экскурсовода" />
                      </template>

                      <AutosuggestMulti :value="flight.transport[index].driver" v-model="flight.transport[index].driver" ref="driver" :multiple="true" name="driver[]" text="Водитель" url="/accounts/suggest?type=staff&post=5&limit=60" placeholder="Выбрать водителя" />
                    </div>

                  </template>

                  <Textarea name="order_comment" text="Комментарий к рейсу" required="true" v-model.trim="flight.flight_comment" />

                </div>

              </div>
              <div class="form-sum">
                <div class="prices">
                  <div>
                    <b>{{formatRUB(flight.data.order_sum_all)}}</b>
                    Всего
                  </div>
                  <div>
                    <b>{{formatRUB(flight.data.order_pay_sum)}}</b>
                    Оплачено
                  </div>
                  <div>
                    <b>{{formatRUB(flight.data.order_sum_all - flight.data.order_sum_pre)}}</b>
                    Гиду
                  </div>
                  <div>
                    <b>{{formatRUB(flight.data.order_sum_sale)}}</b>
                    Скидки
                  </div>
                </div>
              </div>
            </template>

          </div>

          <div class="form-submit">

            <template v-if="newOrder && !flight.showTransfer">
              <button
                  :disabled="!order.place_data || (flight.flight_limit_stop && newTicketsCount > flight.flight_limit - flight.data.count)"
                  class="btn btn-primary"
                  v-on:click.prevent="saveOrder"
              >
                <template v-if="orderOriginal">
                  Сохранить заказ
                </template>
                <template v-else>
                  Добавить заказ
                </template>
              </button>
              <a href="#" class="btn btn-middle" v-on:click.prevent="cancelOrder">Отменить</a>
            </template>

            <template v-else-if="!newOrder && flight.showTransfer">
              <a href="#" class="btn btn-primary" v-on:click.prevent="saveTransfer">Сохранить</a>
              <a href="#" class="btn btn-middle" v-on:click.prevent="flight.showTransfer = false">Закрыть</a>
            </template>


            <template v-else>
              <a href="#" class="btn btn-primary" v-on:click.prevent="saveFlight">Сохранить</a>
              <a href="#" class="btn btn-middle" v-on:click.prevent="cancelFlight">Закрыть</a>
            </template>

          </div>
        </template>
      </Modal>

      <Modal ref="flightSeat" class="mw1200">
        <template v-if="flightSeats">
          <h2>
            Посадки рейса — {{flightSeats.flight_id}} — {{convertDate(flightSeats.schedule_date, 'DD.MM.YYYY HH:mm')}}
          </h2>

          <div style="max-height: 80vh; overflow: auto;">

            <div class="flight_list" v-if="flightSeats.transport_data.length">

              <table class="table" cellspacing="0" cellpadding="0" width="100%">
                <thead>
                <tr>
                  <td>
                    <b>Транспорт</b>
                  </td>
                  <td>
                    <b>Экскурсовод</b>
                  </td>
                  <td>
                    <b>Кол-во мест</b>
                  </td>
                  <td>
                    <b>Люди</b>
                  </td>
                  <td>
                    <b>Село мест</b>
                  </td>

                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in flightSeats.transport_data" v-bind:key="index">
                  <td>
                    <div class="radio">
                      <label>
                        <input type="checkbox" name="showAll" v-model="row.show">
                        <span></span>
                        <div>{{ row.transport_title }}</div>
                      </label>
                    </div>
                  </td>

                  <td>
                    {{ row.guide_title.join(', ') }}
                  </td>

                  <td>
                    {{ row.total_seats }}
                  </td>
                  <td>
                    {{ row.total_people }}
                  </td>
                  <td>
                    {{ row.total_checkin }}
                  </td>

                </tr>
                </tbody>
                <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <b>{{ flightSeats.transport_total_seats }}</b>
                  </td>
                  <td>
                    <b>{{ flightSeats.transport_total_people }}</b>
                  </td>
                  <td>
                    <b>{{ flightSeats.transport_total_seats_checkin }}</b>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>

            <div class="flight_list" v-if="flightSeats.flights">

              <table class="table" cellspacing="0" cellpadding="0" width="100%">
                <thead>
                <tr>
                  <td>
                    <b>Рейс</b>
                  </td>
                  <td>
                    <b>Транспорт</b>
                  </td>
                  <td>
                    <b>Кол-во мест</b>
                  </td>
                  <td>
                    <b>Люди</b>
                  </td>
                  <td>
                    <b>Село мест</b>
                  </td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in flightSeats.flights" v-bind:key="index">
                  <td>
                    <div class="radio">
                      <label>
                        <input type="checkbox" name="showAll" v-model="row.show">
                        <span></span>
                        <div>{{ row.flight_id }} - {{ row.flight_title }}</div>
                      </label>
                    </div>
                  </td>
                  <td>
                    <div v-for="(transport, index) in flightSeats.transport_data.filter(i => i.excursion_id.indexOf(row.excursion_id) > -1)" v-bind:key="index">
                     <b> {{ transport.transport_title }} </b> -
                      {{ transport.guide_title.join(', ') }}
                    </div>
                  </td>
                  <td>
                    {{ row.total_seats }}
                  </td>
                  <td>
                    {{ row.total_people }}
                  </td>
                  <td>
                    {{ row.total_checkin }}
                  </td>
                </tr>
                </tbody>
                <tbody>
                <tr>

                  <td></td>
                  <td></td>

                  <td>
                    <b>{{ flightSeats.total_seats }}</b>
                  </td>
                  <td>
                    <b>{{ flightSeats.total_people }}</b>
                  </td>
                  <td>
                    <b>{{ flightSeats.total_seats_checkin }}</b>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
            <table class="table" cellspacing="0" cellpadding="0" width="100%">
              <thead>
              <tr>
                <td>
                  <b>Время</b>
                </td>
                <td>
                  <b>Точка посадки</b>
                </td>
                <td>
                  <b>Места</b>
                </td>
                <td>
                  <b>Люди</b>
                </td>
                <td>
                  <b>Село мест</b>
                </td>
                <td>
                  Время посадок
                </td>
              </tr>
              </thead>

              <tbody v-for="(row, index) in flightSeats.response" v-bind:key="index">
              <tr
                  class="place_row"
                  :class="{order_show: row.show}"
                  v-on:click="row.show = !row.show"
                  v-if="flightSeats.flights.find(i => row.flight_ids.indexOf(i.flight_id) > -1 && i.show) && (!flightSeats.transport_data.length || flightSeats.transport_data.find(i => row.transport_ids.indexOf(i.transport_id) > -1 && i.show))"
              >
                <td>
                  {{row.order_place_time}}
                </td>
                <td>
                  {{row.place_title}}
                </td>
                <td>
                  {{ row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length ||  flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_adult) + parseInt(i.order_children_1) + parseInt(i.order_children_2)) : 0), 0 ) }}
                </td>
                <td>
                  {{ row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_adult) + parseInt(i.order_children_1) + parseInt(i.order_children_2) + parseInt(i.order_children_free)) : 0), 0 ) }}
                </td>
                <td>
                  {{ row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_checkin_adult) + parseInt(i.order_checkin_children)) : 0), 0 ) }}
                </td>

                <td>
                  <div v-for="(i, k) in Object.entries(row.times)" v-bind:key="k">
                    <template v-if="flightSeats.transport_data && flightSeats.transport_data.find(fl => i[1][0].transport_id === fl.transport_id).show">
                      <b>
                        {{ i[1][0].transport_title }}
                        <span v-if="index > 0 && flightSeats.response[index-1].times && flightSeats.response[index-1].times[i[0]] && flightSeats.response[index-1].times[i[0]].length">
                         - {{ prepareDuration(diff( i[1][0].ts, flightSeats.response[index-1].times[i[0]][flightSeats.response[index-1].times[i[0]].length-1].ts ).seconds) }}
                      </span>
                      </b>
                      <div style="white-space: nowrap">
                        {{ convertDate(i[1][0].ts, 'HH:mm:ss') }} - {{ convertDate(i[1][i[1].length-1].ts, 'HH:mm:ss') }}
                      </div>
                    </template>
                  </div>
                </td>
              </tr>
              <template v-if="row.show && flightSeats.flights.find(i => row.flight_ids.indexOf(i.flight_id) > -1 && i.show) && (!flightSeats.transport_data.length || flightSeats.transport_data.find(i => row.transport_ids.indexOf(i.transport_id) > -1 && i.show))">
                <tr class="order_row" v-for="(order, i) in row.order_data" v-bind:key="i">
                  <template v-if="flightSeats.flights.find(i => order.flight_id === i.flight_id && i.show) && (!flightSeats.transport_data.length || flightSeats.transport_data.find(i => order.transport_id === i.transport_id && i.show))">
                    <td>
                      {{order.order_id}}
                    </td>
                    <td>
                      {{order.order_title}}


                      <div class="seats_info">
                        <div>
                          <label class="input-placeholder">
                            Точка продажи, кассир
                          </label>
                          <div>
                            {{ order.point_title }}<br>
                            {{ order.seller_title }}
                          </div>
                        </div>
                        <div>
                          <label class="input-placeholder">
                            Клиент
                          </label>
                          <div>
                            {{ order.order_client_title }}<br>
                            {{ formatPhone(order.order_client_phone) }}
                          </div>
                        </div>
                        <div>
                          <label class="input-placeholder">
                            Места
                          </label>
                          <div v-if="order.transport_place_title && order.transport_place_title.length">
                            {{ order.order_tickets.split(',').map(i => {return order.transport_place_title[parseInt(i)]}).join(',') }}
                          </div>
                          <div v-else>
                            {{ order.order_tickets }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {{ parseInt(order.order_adult) + parseInt(order.order_children_1) + parseInt(order.order_children_2) }}
                    </td>
                    <td>
                      {{ parseInt(order.order_adult) + parseInt(order.order_children_1) + parseInt(order.order_children_2) + parseInt(order.order_children_free) }}
                    </td>
                    <td>
                      {{parseInt(order.order_checkin_adult) + parseInt(order.order_checkin_children)}}
                    </td>

                    <td>
                      {{ order.transport_title }}
                      <template v-if="parseInt(order.order_checkin_adult) + parseInt(order.order_checkin_children) > 0">
                        - {{ convertDate(order.ts, 'HH:mm:ss') }}
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              </tbody>

              <tbody>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>
                    {{ flightSeats.response.reduce((sumTotal, row) => sumTotal + (row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show  && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_adult) + parseInt(i.order_children_1) + parseInt(i.order_children_2)) : 0), 0 )), 0) }}
                  </b>
                </td>
                <td>
                  <b>
                    {{ flightSeats.response.reduce((sumTotal, row) => sumTotal + (row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_adult) + parseInt(i.order_children_1) + parseInt(i.order_children_2) + parseInt(i.order_children_free)) : 0), 0 )), 0) }}
                  </b>
                </td>
                <td>
                  <b>
                    {{ flightSeats.response.reduce((sumTotal, row) => sumTotal + (row.order_data.reduce((sum, i) => sum + (flightSeats.flights.find(fl => i.flight_id === fl.flight_id).show && (!flightSeats.transport_data.length || flightSeats.transport_data.find(fl => i.transport_id === fl.transport_id).show) ? (parseInt(i.order_checkin_adult) + parseInt(i.order_checkin_children)) : 0), 0 )), 0) }}
                  </b>
                </td>
              </tr>
              </tbody>

            </table>
          </div>

          <div class="form-submit">
            <a href="#" class="btn btn-middle" v-on:click.prevent="hideFlightSeat">Закрыть</a>
          </div>

        </template>
      </Modal>
    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';

import Modal from '../../../../components/Modal';

import Input from '../../../../components/Input';
import AutosuggestMulti from '../../../../components/AutosuggestMulti';
import Textarea from '../../../../components/Textarea';
import Select from '../../../../components/Select';



import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';
import PeriodPickerFilter2 from "@/components/report/PeriodPickerFilter";
import $ from "jquery";

import moment from 'moment';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    AutosuggestMulti,
    Textarea,
    SelectFilter,
    OrderTd,
    Select,
    PeriodPickerFilter2,
    Loading,
    Input,
    Modal,
    Pager,
    Report
  },


  mounted() {
    this.emitter.off("updateScheduleReport")
    this.emitter.on("updateScheduleReport", () => {
      if (this.$refs.report) {
        if (!this.openFlight && !this.flightSeats) {
          this.getReport().reload()
        }

      }
    })
  },

  created() {
    this.emitter.off("updateScheduleReport")

    this.loadSubdivision();

    let dateArray = [];
    let currentDate = new Date();
    let i = 0;

    while (i < 14) {
      dateArray.push(new Date (currentDate));
      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }

    this.dates = dateArray;
    this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');

  },

  watch: {
    manualTransfer(){
      if (this.manualTransfer) {

        Object.entries(this.transferTickets).find(i => {
          if (i[1].ticket) {
            i[1].ticket = []
          }
        })
      } else {
        this.autoTransfer(false)
      }
    },

    async 'order.order_client_phone'() {
      let clearPhone = this.order.order_client_phone.replace(/[^0-9]/g, '')

      if (clearPhone.length === 11) {
        let result = await Ajax.get(`/accounts/find-by-phone?type=all&phone=${clearPhone}`);
        if (result.length) {
          this.order.order_client_title = result[0].account_title
          this.order.account_id = result[0].id
        } else {
          this.order.account_id = null
        }
      }
    },

    'order.sale_prepay_type'() {
      this.setChildrenCount()
      this.setNewTickets()
    },
    'order.sale_amount'() {
      this.setChildrenCount()
      this.setNewTickets()
    },
    'order.sale_type'() {
      this.setChildrenCount()
      this.setNewTickets()
    },
    'order.childrenValuesPlace'() {
      this.setChildrenCount()
      this.setNewTickets()
    },
    'order.childrenValues'() {
      this.setChildrenCount()
      this.setNewTickets()
    },
    'order.order_adult'() {
      this.setChildrenCount()
      this.setNewTickets()
    },
    'order.order_children'() {
      this.setChildrenCount()
      this.setNewTickets()
    },

    fav() {
      localStorage.fav = this.fav;
      this.$emit('update');
    },

    period() {

      if (!this.period) {
        let dateArray = [];
        let currentDate = new Date();
        let i = 0;

        while (i < 14) {
          dateArray.push(new Date (currentDate));
          currentDate = moment(currentDate).add(1, 'days');
          i++;
        }

        this.dates = dateArray;
        this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');
      }

      let period = this.period.split(';')
      let currentDate = new Date();
      let countDays = 14;

      if (period.length) {
        currentDate = new Date(period[0]);

        let endDate = new Date(period[1]);
        countDays = moment(endDate).diff(moment(currentDate), 'days') + 1
      }

      let dateArray = [];
      let i = 0;

      while (i < countDays) {
        dateArray.push(new Date(currentDate));
        currentDate = moment(currentDate).add(1, 'days');

        i++;
      }

      this.dates = dateArray;

    }
  },

  data() {
    return {
      flightSeats: null,
      hideAll: true,
      hideFreeFlight: false,
      places: [],
      tags: [
        'Инвалид колясочник',
        'Только у окна',
        'Задний диван',
        'Поближе'
      ],
      loadReserved: false,
      loadNewSeats: false,
      reserved: [],
      seatsMessage: null,
      promoData: null,
      pos: [],
      selectManualTransferCurrent: null,
      orders: {},
      manualTransfer: false,
      service_place: [],
      transferTickets: null,
      newTicketsCount: 1,
      flightData: null,
      orderOriginal: null,
      orderColors: [],
      order: {
        order_seller_data: null,
        order_seller: null,
        order_subdivision: 3,
        order_promo: null,
        account_id: null,
        order_client_title: '',
        order_client_phone: '',
        order_adult: 1,
        order_children: 0,
        childrenValues: [],
        childrenValuesCount1: 0,
        childrenValuesCount2: 0,
        childrenValuesCount3: 0,
        childrenValuesPlace: [],
        place_data: null,
        point_data: [],
        order_place: null,
        order_point: null,
        order_comment: '',
        order_address: '',
        pay_type: 'cash',
        sale_prepay_type: 'prepay',
        sale_amount: 0,
        sale_type: 'percent',
        transport_id: null
      },
      newOrder: false,
      newFlightDate: null,
      newTickets: [],
      newTicketsTransport: [],
      orderTickets: [],
      duration: {
        d: 0,
        d_title: ['день', 'дня', 'дней'],
        h: 0,
        h_title: ['час', 'часа', 'часов'],
        m: 0,
        m_title: ['минута', 'минуты', 'минут']
      },
      schedules: {
        times: [
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ]
        ],
      },

      flight: null,
      fav: localStorage.fav ? localStorage.fav.split(',') : [],
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: []

      },
      period: '',
      schedule: null,
      dates: [],
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован',
        'order': 'Оплачен',
        'cancel': 'Отменён',
        'cancel_success': 'Возврат',
        'waiting_for_capture': 'Оплачен, не подтверждён',
        'pending': 'Ожидает оплаты'
      },
      statusOption: [
        {
          id: 'order',
          label: 'Подтвердить оплату'
        },
        {
          id: 'cancel',
          label: 'Отменить заказ'
        }
      ],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null,
      tr: null
    }
  },

  methods: {

    compareSubdivision(id) {
     return this.report.rows.filter(i => i.excursion_linked === id).sort((a, b) => a.excursion_sort_a - b.excursion_sort_a);
    },

    diff(date1, date2) {
      let a = new Date(date1).getTime(),
          b = new Date(date2).getTime(),
          diff = {};

      diff.milliseconds = a > b ? a % b : b % a;
      diff.seconds = diff.milliseconds / 1000;
      diff.minutes = diff.seconds / 60;
      diff.hours = diff.minutes / 60;
      diff.days = diff.hours / 24;
      diff.weeks = diff.days / 7;

      return diff;
    },

    async showFlightSeats(flight_id, date) {
      let res = await Ajax.get(`/ad/flight/${flight_id}`);

      let total_seats = 0
      let total_people = 0
      let total_seats_checkin = 0

      if (res.response) {

        res.response.forEach(i => {
          i.times = {}
          total_seats = total_seats + (i.cnt_adult + i.cnt_children_1 + i.cnt_children_2)
          total_people = total_people + (i.cnt_adult + i.cnt_children_1 + i.cnt_children_2 + i.cnt_children_free)
          total_seats_checkin = total_seats_checkin + i.order_data.reduce((sum, i) => sum + (parseInt(i.order_checkin_adult) + parseInt(i.order_checkin_children)), 0 )

          i.order_data.map(o => {
            if((o.order_checkin_adult + o.order_checkin_children) > 0) {
              if (i.times['t' + o.transport_id]) {
                i.times['t' + o.transport_id].push(o)
              } else {
                i.times['t' + o.transport_id] = [o]
              }

              i.times['t' + o.transport_id].sort((a,b) => new Date(a.ts).getTime() - new Date(b.ts).getTime())
            }
          })

        })

        this.flightSeats = res;

        res.response[0].flights_data.forEach(i => {
          i.show = true;
          i.total_seats = 0;
          i.total_people = 0;
          i.total_checkin = 0;

          res.response.forEach(p => {
            p.order_data.forEach(o => {
              if (o.flight_id === i.flight_id) {
                i.total_seats = i.total_seats + (o.order_adult + o.order_children_1 + o.order_children_2);
                i.total_people = i.total_people + (o.order_adult + o.order_children_1 + o.order_children_2 + o.order_children_free);
                i.total_checkin = i.total_checkin + (o.order_checkin_adult + o.order_checkin_children);
              }
            })
          })


        })

        let transport_list = [];

        let transport_total_seats = 0
        let transport_total_people = 0
        let transport_total_seats_checkin = 0

        if (res.response[0].transport_data) {
          res.response[0].transport_data.forEach(i => {


            if (transport_list.find(t => t.transport_id === i.transport_id)) {
              transport_list.map((te, key) => {
                if (te.transport_id === i.transport_id) {
                  transport_list[key].excursion_id.push(i.excursion_id)
                  if (transport_list[key].guide_id.indexOf(i.guide_id) === -1) {
                    transport_list[key].guide_id.push(i.guide_id)
                    transport_list[key].guide_title.push(i.guide_title)
                  }
                }
              })
            } else {
              let transport_row = i;
              transport_row.excursion_id = [transport_row.excursion_id]
              transport_row.guide_id = [transport_row.guide_id]
              transport_row.guide_title = [transport_row.guide_title]
              transport_row.show = true;

              transport_row.total_seats = 0;
              transport_row.total_people = 0;
              transport_row.total_checkin = 0;

              res.response.forEach(p => {
                p.order_data.forEach(o => {
                  if (o.transport_id === i.transport_id) {
                    transport_row.total_seats =   transport_row.total_seats + (o.order_adult + o.order_children_1 + o.order_children_2);
                    transport_row.total_people =  transport_row.total_people + (o.order_adult + o.order_children_1 + o.order_children_2 + o.order_children_free);
                    transport_row.total_checkin = transport_row.total_checkin + (o.order_checkin_adult + o.order_checkin_children);
                  }
                })
              })

              transport_total_seats = transport_total_seats + transport_row.total_seats
              transport_total_people = transport_total_people + transport_row.total_people
              transport_total_seats_checkin = transport_total_seats_checkin + transport_row.total_checkin

              transport_list.push(transport_row)
            }

          })
        }

        this.flightSeats.transport_data = transport_list

        this.flightSeats.flights = res.response[0].flights_data

        this.flightSeats.flight_id = flight_id;
        this.flightSeats.schedule_date = date
        this.flightSeats.total_seats = total_seats
        this.flightSeats.total_people = total_people
        this.flightSeats.total_seats_checkin = total_seats_checkin

        this.flightSeats.transport_total_seats = transport_total_seats
        this.flightSeats.transport_total_people = transport_total_people
        this.flightSeats.transport_total_seats_checkin = transport_total_seats_checkin

      }
      this.$refs.flightSeat.show()
    },

    getShowLinked(eid) {
      let r = this.report.rows.find(i => i.excursion_link && i.excursion_link.indexOf(eid) > -1 );

      return r && r.show
    },

    getOrderTransport(order_id) {
      if (this.transferTickets && this.transferTickets.result) {
        return this.transferTickets.result.find(i => {
          if (i.orders[`t${order_id}`]) {
            return i
          }
        })
      } else {
        return false;
      }
    },

    updateFav() {
      this.fav = localStorage.fav ? localStorage.fav.split(',') : [];
    },

    setManualSeats(i) {
      if (this.newOrder) {
        if (this.flightData.reserved.rs && this.flightData.reserved.rs.indexOf(i) > -1) {
          console.log('stop manual selected')
        } else {
          if (!this.orderOriginal) {
            this.newTickets[i] = !this.newTickets[i]
          } else {
            this.newTickets[i] = !this.newTickets[i]
          }
        }
      }

    },

    getOrderComment(order_id) {
      return this.flightData.order.rows.find(i => i.order_id === parseInt(order_id)).order_comment
    },
    getOrderPeople(order_id) {
      return this.flightData.order.rows.find(i => i.order_id === parseInt(order_id))
    },

    findOrder(index, transport_id) {
      let order = this.flightData.order.rows.find(i => i.transport_id === transport_id && i.order_tickets.split(',').find(e => parseInt(e) === index));

      return order || {order_id: false}
    },

    async checkPromo() {
      let list = [];
      list.push(Ajax.get('/promo?query=' + this.order.order_promo + '&ex=' + this.flight.ex_id));

      let [promoResult] = await Promise.all(list);

      if (promoResult) {
        this.promoData = promoResult;
        this.updateOrderPrices()
      }
    },


    updateManualTicket(ticket, transport) {
      if (!this.manualTransfer || (this.manualTransfer && !this.selectManualTransferCurrent)) {
        return;
      }
      let newTickets = []

      if (this.selectManualTransferCurrent[2]) {
        if (this.transferTickets[transport].ticket[ticket]) {
          this.selectManualTransferCurrent[2].splice(this.selectManualTransferCurrent[2].indexOf(ticket), 1)
          newTickets = this.selectManualTransferCurrent[2]
        } else {
          newTickets = newTickets.concat(this.selectManualTransferCurrent[2], [ticket])
        }
      } else {
        newTickets = [ticket]
      }

      if (this.transferTickets[transport].ticket[ticket]) {
        delete this.transferTickets[transport].ticket[ticket]
      } else {
        this.transferTickets[transport].ticket[ticket] = this.selectManualTransferCurrent[0]
      }
      this.selectManualTransferCurrent[2] = newTickets


      let res = []
      let key = 0
      for (let t of Object.values(this.transferTickets)) {
        if (t.title) {

          res[key] = {
            title: t.title,
            transport_id: t.transport_id,
            transport_place_title: t.transport_place_title,
            floor: t.floorCount,
            place: t.place,
            orders: []
          }

          if (t.ticket) {
            for (let i = 0; i < t.ticket.length; i++) {
              if (t.ticket && t.ticket[i]) {

                let oldTicket = this.orders.find(f => f[0] === t.ticket[i])

                if ( res[key].orders['t'+t.ticket[i]] ) {
                  res[key].orders['t'+t.ticket[i]].newTicket = res[key].orders['t'+t.ticket[i]].newTicket.concat([i])
                } else {
                  res[key].orders['t'+t.ticket[i]] = {
                    newTicket: [i],
                    oldTicket:  oldTicket ? oldTicket[1] : []
                  }
                }
              }
            }
            /*
            if (res[key].floor !== 2 && res[key].floor) {
              for (let q of Object.values(res[key].orders)) {
                q.newTicket = q.newTicket.map(e => {
                  console.log(e + res[key-1].place)
                  return e + res[key-1].place
                })
              }
            }
            */
          }
        }
        key++
      }

      this.transferTickets['result'] = res

    },

    selectManualTransfer(order) {
      if (this.manualTransfer) {
        this.selectManualTransferCurrent = order
      }
    },

    async saveTransfer(){

      let transfer = []

      this.transferTickets.result.forEach((v) => {
        Object.entries(v.orders).find(i => {
          transfer.push({
            order_id: i[0].replace('t', ''),
            transport_id: v.transport_id,
            ticket: i[1].newTicket.join(',')
          })
        })
      })

      let data = {
        transfer: transfer,
      }

      await Ajax.post(`/flight/transfer/${this.flight.flight_id}`, data);
      this.$toast.success({content:`Новые места назначены`});
      this.showFlight(this.openFlight, this.openDate)
    },

    autoTransfer(save) {

      if (!save) {
        Object.entries(this.transferTickets).find(i => {
          if (i[1].ticket) {
            i[1].ticket = []
          }
        })

        this.orders.find(item => {
          if (item[2]) {
            item.splice(2, 1)
          }
        })

      }

      for (let [order, ticket] of this.orders) {
        for (let [transport, data] of Object.entries(this.transferTickets)) {

          let reverse = transport === 1;

          Object.entries(this.transferTickets).find(i => {
            if (i[1].ticket && i[1].ticket.indexOf(order) !== -1) {
              reverse = true
            }
          })

          if (reverse) {
            break
          }

          let occupiedPlaces = []

          data.ticket.find(i => {
            if (i) {
              occupiedPlaces.push(i)
            }
          })

          occupiedPlaces = occupiedPlaces.length

          if (occupiedPlaces <= data.limit - ticket.length ) {
            let fixFirst = 0;
            for (let i = 1; i < data.place; i++) {
              fixFirst++

              if (!data.ticket[i] && ((data.reserve && data.reserve.indexOf(i) === -1) || !data.reserve)) {
                let type = i % 2 === 0;

                //1 человек

                if (ticket.length === 1) {
                  //дать место если текущее чётное и соседнее место занято

                  if (fixFirst === 0) {
                    if (type) {
                      data.ticket[i] = order
                      break;
                    }
                  } else {
                    if (type && data.ticket[i-1]) {
                      data.ticket[i] = order
                      break;
                    }
                  }

                  //дать место если текущее нечётное и соседнее место занято
                  if (!type && data.ticket[i+1]) {
                    data.ticket[i] = order
                    break;
                  }
                  //дать место - если текущее нечётное, место рядом свбодное и так же свободен следующий ряд
                  if (!type && !data.ticket[i+1] && !data.ticket[i+4] && !data.ticket[i+5]) {
                    data.ticket[i] = order
                    break;
                  }
                  //дать место - если текущее чётное, место рядом свбодное и так же свободен следующий ряд
                  if (type && !data.ticket[i-1] && !data.ticket[i+3] && !data.ticket[i+4]) {
                    data.ticket[i] = order
                    break;
                  }
                }
                //более 1 человека
                if (ticket.length > 1) {

                  let free = [];
                  let tmp = 0;
                  let tmpIndex = 0;

                  let nextLastNumber = i + (ticket.length - 1) + parseInt(ticket.length / 2) * 2;

                  if (ticket.length + occupiedPlaces >= data.limit) {
                    if (!data.ticket[i]) {
                      while (
                          tmp <= data.limit +
                          (data.limit < (ticket.length + occupiedPlaces) ?
                              (ticket.length + occupiedPlaces) - data.limit : 0)) {
                        if (!data.ticket[tmp] && tmp) {
                          free[tmpIndex++] = tmp
                        }
                        tmp++;
                      }
                    }
                  } else {
                    if (ticket.length >= 6) {

                      if (!data.ticket[i]) {
                        while (tmp < ticket.length) {
                          let r = i + tmp;
                          if (tmp !== 0) {
                            r = (tmp);
                            if (free.length && r < free[tmpIndex - 1]) {
                              r = free[tmpIndex - 1] + 1;

                              if (ticket.length % 2 !== 0 && r-1 === free[0] && free[0] % 2 === 0) {
                                r = free[tmpIndex - 1] + 3;
                              }
                            } else {
                              if (free.length && r - 3 === free[tmpIndex - 3] && free[tmpIndex - 1] - 3 > 0 && !free[free[tmpIndex - 1] - 3]) {
                                if (ticket.length % 2 !== 0) {
                                  r = free[tmpIndex - 1] + 1;
                                } else {
                                  r = free[tmpIndex - 1] + 1;
                                }
                              }
                            }
                          } else {
                            //если четное первое место, и четное кол-во мест, то пропускать тройку
                            if (ticket.length % 2 !== 0 && r - 3 > 0) {
                              //r = r - 3
                            }
                          }
                          if (!data.ticket[r]) {
                            free[tmpIndex++] = r;
                          }
                          tmp++;
                        }
                      }
                    } else {
                      if (
                          !((
                              !type && ticket.length % 2 !== 0 &&
                              !data.ticket[i] &&
                              !data.ticket[nextLastNumber] &&
                              data.ticket[nextLastNumber + 3] &&
                              data.ticket[nextLastNumber + 4]
                          ) || (
                              type && ticket.length % 2 !== 0 &&
                              !data.ticket[i] &&
                              !data.ticket[i-1] &&
                              data.ticket[nextLastNumber + 2] &&
                              data.ticket[nextLastNumber + 3]
                          ))
                      ) {
                        while (tmp < ticket.length) {
                          let r = i + tmp;
                          if (!type) {

                            if (tmp !== 0) {
                              r = (tmp) % 2 === 0 ? (i + tmp) + tmp : (i + tmp);
                              if (free.length && r < free[tmpIndex - 1]) {
                                r = free[tmpIndex - 1] + 1;
                              } else {
                                if (free.length && r - 2 === free[tmpIndex - 2]) {
                                  r = r + 2;
                                }
                              }
                            }

                          } else {
                            if (tmp !== 0) {
                              r = (tmp) % 2 === 0 ? (i + tmp) : (i + tmp) + (tmp > 3 ? 0 : 2);
                              if (free.length && r < free[tmpIndex - 1]) {
                                if (free[tmpIndex - 1] % 2 === 0) {
                                  r = free[tmpIndex - 1] + 3
                                } else {
                                  r = free[tmpIndex - 1] + 1
                                }
                              } else {
                                if (free.length && r - 2 === free[tmpIndex - 2]) {
                                  r = r + 2;
                                }
                              }
                            } else {

                              //если четное первое место, и четное кол-во мест, то пропускать тройку

                              if (ticket.length % 2 === 0) {
                                r = r + 7
                              }
                            }
                          }

                          if (!data.ticket[r]) {
                            free[tmpIndex++] = r;
                          }

                          tmp++;
                        }
                      }
                    }
                  }

                  if (free.length === ticket.length) {
                    let resCount = 0;
                    while (resCount < free.length) {
                      data.ticket[free[resCount++]] = order
                    }
                    break;
                  }
                }
              }
            }
          }
        }
      }

      let res = []
      let key = 0
      for (let t of Object.values(this.transferTickets)) {
        if (t.title) {

          res[key] = {
            title: t.title,
            transport_id: t.transport_id,
            transport_place_title: t.transport_place_title,
            floor: t.floorCount,
            place: t.place,
            orders: []
          }

          if (t.ticket) {
            for (let i = 0; i < t.ticket.length; i++) {
              if (t.ticket && t.ticket[i]) {

                let oldTicket = this.orders.find(f => f[0] === t.ticket[i])

                if ( res[key].orders['t'+t.ticket[i]] ) {
                  res[key].orders['t'+t.ticket[i]].newTicket = res[key].orders['t'+t.ticket[i]].newTicket.concat([i])
                } else {
                  res[key].orders['t'+t.ticket[i]] = {
                    newTicket: [i],
                    oldTicket:  oldTicket ? oldTicket[1] : []
                  }
                }
                // add [2] to this.orders and remove else !save
                if (save) {
                  this.orders.find(item => {
                    if (item[0] === t.ticket[i]) {
                      item[2] = res[key].orders['t'+t.ticket[i]].newTicket
                    }
                  })
                }
              }
            }
            if (res[key].floor !== 2 && res[key].floor) {
              for (let q of Object.values(res[key].orders)) {
                q.newTicket = q.newTicket.map(e => {
                  return e + res[key-1].place
                })
              }
            }
          }
        }
        key++
      }

      if (save) {
        this.transferTickets[`update_${new Date().getTime()}`] = true
      }
      this.transferTickets['result'] = res
    },

    updateOrderPrices(){
      if (this.flightData && this.flightData.excursion && this.order) {
        this.order.allSum = (
            (this.flightData.excursion.excursion_price_adult * this.order.order_adult) +
            (this.flightData.excursion.excursion_price_children_free * this.order.childrenValuesCount1) +
            (this.flightData.excursion.excursion_price_children * this.order.childrenValuesCount2) +
            (this.flightData.excursion.excursion_price_children_free2 * this.order.childrenValuesCount3)
        )

        if (this.flightData.excursion.excursion_prepay_allow === 'true') {
          this.order.allSumPrepay = (
              (this.flightData.excursion.excursion_prepay_adult * this.order.order_adult) +
              (this.flightData.excursion.excursion_prepay_children_free * this.order.childrenValuesCount1) +
              (this.flightData.excursion.excursion_prepay_children * this.order.childrenValuesCount2) +
              (this.flightData.excursion.excursion_prepay_children_free2 * this.order.childrenValuesCount3)
          )
        } else {
          this.order.allSumPrepay = (
              (this.flightData.excursion.excursion_price_adult * this.order.order_adult) +
              (this.flightData.excursion.excursion_price_children_free * this.order.childrenValuesCount1) +
              (this.flightData.excursion.excursion_price_children * this.order.childrenValuesCount2) +
              (this.flightData.excursion.excursion_price_children_free2 * this.order.childrenValuesCount3)
          )
        }

        this.order.order_sum_sale = Math.floor(this.getSaleSum(this.order.allSum))
      }
    },

    getRandomColor() {
      let letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    cancelFlight(){
      this.$refs.editFlight.hide()
      this.openFlight = null
      this.openDate = null
    },

    hideFlightSeat(){
      this.$refs.flightSeat.hide()
      this.flightSeats = null
    },

    async saveFlight() {


      let data = {
        flight_limit: this.flight.flight_limit,
        flight_comment: this.flight.flight_comment,
        flight_limit_stop: this.flight.flight_limit_stop ? 'Y' : 'N',
        transport: [],
        link_flight: []
      }

      if (this.flight.transport && this.flight.transport.length) {
        $.each(this.flight.transport, function (k, v) {

          let guideCount = v.guide ? v.guide.length : 0
          let driverCount = v.driver ? v.driver.length : 0

          let transport = {
            flight_transport_id: v.value
          }

          if (v.guide && v.guide.length) {
            if (v.guide.length === 1) {
              transport.flight_guide_id = v.guide[0].value

              if (v.guide[0].excursion_data) {
                transport.flight_excursion_id = v.guide[0].excursion_data.map(i => i.value) || 0
              }

            }
          }

          if (v.driver && v.driver.length) {
            if (v.driver.length === 1) {
              transport.flight_driver_id = v.driver[0].value
            }
          }

          if (guideCount > 1 || driverCount > 1) {
            let totalRows = guideCount > driverCount ? guideCount : driverCount;

            for (let i = 0; i < totalRows; i++) {

              let transport = {
                flight_transport_id: v.value
              }

              if (guideCount > i) {
                transport.flight_guide_id = v.guide[i].value

                if (v.guide[i].excursion_data) {
                  transport.flight_excursion_id = v.guide[i].excursion_data.map(i => i.value) || 0
                }
              }

              if (driverCount > i) {
                transport.flight_driver_id = v.driver[i].value
              } else {
                transport.flight_driver_id = v.driver[driverCount-1].value
              }

              data.transport.push(transport)
            }
          } else {
            data.transport.push(transport)
          }
        }.bind(this))
      }


      let newTransportSaveData = [];

      data.transport.forEach(i => {
        if (i.flight_excursion_id) {
          i.flight_excursion_id.forEach(t => {
            newTransportSaveData.push({
              flight_driver_id: i.flight_driver_id,
              flight_excursion_id: t,
              flight_guide_id: i.flight_guide_id,
              flight_transport_id: i.flight_transport_id
            })
          })
        }
      })

      data.transport = newTransportSaveData;

      if (this.flightData.flight.linked_flight_ex) {
        newTransportSaveData.forEach(i => {
          this.flightData.flight.linked_flight_ex.forEach(linked =>  {

            if (linked[i.flight_excursion_id]) {
              if (data.link_flight.find(l => l.flight_id === linked[i.flight_excursion_id])) {
                data.link_flight.find(l => l.flight_id === linked[i.flight_excursion_id]).transport.push(i)
              } else {
                data.link_flight.push({
                  flight_id: linked[i.flight_excursion_id],
                  transport: [i]
                })
              }
            }

          })
        })


        this.flightData.flight.linked_flight_ex.forEach(linked =>  {
          if (!data.link_flight.find(i => i.flight_id === Object.entries(linked)[0][1])) {
            data.link_flight.push({
              flight_id:  Object.entries(linked)[0][1],
              transport: []
            })
          }
        })
      }

      // get flight ids and transport ids
      // for remove other settings in orders

      data.flight_ids = [this.flight.flight_id].concat(data.link_flight.map(i => { return i.flight_id }))

      data.transport_ids = data.transport.map(i => { return i.flight_transport_id })

      let response = await Ajax.post(`/flight/save/${this.flight.flight_id}`, data);

      if (response.error) {
        this.setError(response.error);
      }

      if (response.response) {
        this.$toast.success({content:`Рейс обновлён`});
        this.showFlight(this.openFlight, this.openDate)
      }

    },

    getCountPart(all, part) {

      let tmp = all / 2
      let part1 = tmp % 4 === 0 ? tmp : tmp - tmp % 4
      let part2 = tmp % 4 === 0 ? tmp : tmp + tmp % 4

      if (part2 - part1 >= 4) {
        part1 = part1 + 4
        part2 = part2 - 4
      }

      if (part === 1) {
        if (all <= 4) {
          return all
        }

        return part1
      }
      if (part === 2) {
        if (all <= 4) {
          return 0
        }
        return part2
      }
    },

    async cancelOrder() {
      if (this.orderOriginal) {
        let counter = 0;
        this.orderTickets = this.orderTickets.map(i => {
          if (i === false) {
            counter++
            return this.orderOriginal.order.order_id;
          } else {
            return i
          }
        })

        this.flight.data.count = this.flight.data.count + counter
      }

      this.newOrder = false
      this.orderOriginal = null
      this.newTicketsCount = 0
      this.newTickets = []


      this.order = {
        order_seller_data: null,
        order_seller: null,
        order_subdivision: 3,
        order_client_title: '',
        order_client_phone: '',
        order_adult: 0,
        order_children: 0,
        childrenValues: [],
        childrenValuesCount1: 0,
        childrenValuesCount2: 0,
        childrenValuesCount3: 0,
        childrenValuesPlace: [],
        place_data: null,
        point_data: [],
        order_place: null,
        order_point: null,
        order_comment: '',
        order_address: '',
        pay_type: 'cash',
        transport_id: null
      }

      if (this.tr) {
        await Ajax.get(`/flights/clear_seats/${this.tr}`);
        this.tr = null
      }
      this.createNewTicketsTransport()

    },

    async editOrder(order) {
      await this.cancelOrder()

      let urls = {
        'order': `/order/${order}`,
      };
      this.orderOriginal = await Ajax.getBulk(urls);

      this.order.order_seller_data = this.orderOriginal.order.order_seller_data ? this.orderOriginal.order.order_seller_data[0] : null
      this.order.order_seller = this.orderOriginal.order.order_seller
      this.order.order_subdivision = this.orderOriginal.order.order_subdivision


      this.order.order_date_ot = this.orderOriginal.order.order_date_ot
      this.order.place_time = this.orderOriginal.order.place_time
      this.order.order_place_time = this.orderOriginal.order.order_place_time
      this.order.order_client_title = this.orderOriginal.order.order_client_title
      this.order.order_client_phone = this.orderOriginal.order.order_client_phone
      this.order.order_adult = this.orderOriginal.order.order_adult
      this.order.order_children = (this.orderOriginal.order.order_children_1 + this.orderOriginal.order.order_children_2 + this.orderOriginal.order.order_children_free) || 0
      this.order.childrenValues = []
      this.order.childrenValuesCount1 = this.orderOriginal.order.order_children_1
      this.order.childrenValuesCount2 = this.orderOriginal.order.order_children_2
      this.order.childrenValuesPlace = []
      this.order.place_data = this.orderOriginal.order.place_data_set
      this.order.point_data = this.orderOriginal.order.point_data || []
      this.order.order_place = this.orderOriginal.order.order_place
      this.order.order_point = null
      this.order.order_comment = this.orderOriginal.order.order_comment
      this.order.order_address = this.orderOriginal.order.order_address
      this.order.pay_type = this.orderOriginal.order.order_pay_type

      if (this.order.order_place) {
        if (!this.order.order_place_time) {
          this.changePlaceIds({value: this.order.order_place})
        }
      } else {
        this.order.order_place_time = this.order.order_place_time ? this.order.order_place_time : moment(this.order.order_date_ot).format('HH:mm')
      }

      if (!this.orderOriginal.order.order_children_age && this.order.order_children > 0) {
        for(let i=0; i<this.order.order_children; i++) {
          this.order.childrenValues.push(1)
        }
      } else {
        this.order.childrenValues = this.orderOriginal.order.order_children_age
      }
      if (this.orderOriginal.order.order_children_free > 0) {
        for(let i=0; i<this.orderOriginal.order.order_children_free; i++) {
          this.order.childrenValuesPlace.push(true)
        }
      }

      if (!this.order.childrenValues) {
        this.order.childrenValues = []
      }
      if (!this.order.childrenValuesPlace) {
        this.order.childrenValuesPlace = []
      }

      this.orderTickets = this.orderTickets.map(i => {
        if (i === order) {
          return false
        } else {
          return i
        }
      })

      this.newTickets = []
      this.createNewTicketsTransport()

      this.orderOriginal.order.order_tickets.split(',').map(i => {
        this.newTickets[parseInt(i)] = true;
        this.newTicketsCount++
      })

      this.flight.data.count = this.flight.data.count - this.orderOriginal.order.order_tickets.split(',').length

      this.newOrder = true;

    },

    showNewOrder() {
      this.orderOriginal = null
      this.newOrder = true


      this.order = {
        order_seller_data: null,
        order_seller: null,
        order_subdivision: 3,
        order_client_title: '',
        order_client_phone: '',
        order_adult: 1,
        order_children: 0,
        childrenValues: [],
        childrenValuesCount1: 0,
        childrenValuesCount2: 0,
        childrenValuesCount3: 0,
        childrenValuesPlace: [],
        place_data: null,
        point_data: [],
        order_place: null,
        order_point: null,
        order_comment: '',
        order_address: '',
        pay_type: 'cash',
        transport_id: null
      }

      this.order.place_time = this.flightData.excursion.place_time
      this.order.order_date_ot = this.flightData.flight.schedule_date
      this.order.order_place_time = moment(this.flightData.flight.schedule_date).format('HH:mm')


      // this.tr = new Date().getTime();
      // this.setNewTickets()
    },

    showTransfer() {
      this.transferTickets = {}

      this.orders = {}

      this.flightData.order.rows.forEach(o => {
        this.orders[o.order_id] = o.order_tickets.split(',').map(i => parseInt(i))
      })

      /*
      this.orderTickets.map((order_id, ticket) => {
        if (this.orders[order_id]) {
          this.orders[order_id] = this.orders[order_id].concat([ticket])
        } else {
          this.orders[order_id] = [ticket]
        }
      })
*/



      this.orders = Object.entries(this.orders).sort((aa, ab) => {
        if (aa[1][0] < ab[1][0]) {
          return -1
        }
        if (aa[1][0] > ab[1][0]) {
          return 1
        }
        return 0
      })

      this.manualTransfer = true


      Object.entries(this.transferTickets).find(i => {
        if (i[1].ticket) {
          i[1].ticket = []
        }
      })


      for (let i = 0; i < this.flight.transport.length; i++) {

        if (this.flight.transport[i].floor === 2) {
          this.transferTickets[`t_${this.flight.transport[i].value}_2`] = {
            transport_id: this.flight.transport[i].value,
            transport_place_title: this.flight.transport[i].transport_place_title,
            title: this.flight.transport[i].label + ' — 2 этаж',
            place: this.flight.transport[i].place2,
            limit: this.flight.transport[i].place2,
            reserve: this.flight.transport[i].place2_reserve,
            floorCount: 2,
            ticket: []
          }
        }


        this.transferTickets[`t_${this.flight.transport[i].value}_1`] = {
          title: this.flight.transport[i].label + (this.flight.transport[i].floor === 2 ? ' — 1 этаж' : ''),
          transport_id: this.flight.transport[i].value,
          transport_place_title: this.flight.transport[i].transport_place_title,
          place: this.flight.transport[i].place1,
          limit: this.flight.transport[i].place1,
          reserve: this.flight.transport[i].place1_reserve,
          floorCount: this.flight.transport[i].floor === 2,
          ticket: []
        }
      }



      /*
      Object.entries(this.transferTickets).find(i => {
          if (i[1].ticket) {
            i[1].ticket = []
          }
        })
      * */

      // this.autoTransfer(false)

      this.flight.showTransfer = true;

      setTimeout(() => {
        for (let i = 0; i < this.flight.transport.length; i++) {
          this.flightData.order.order_tickets.forEach(d => {
            if (this.flight.transport[i] && this.flight.transport[i].value && d.transport_id === this.flight.transport[i].value) {
              this.selectManualTransferCurrent = this.orders.find(i => i[0] == d.order_id)

              this.updateManualTicket(d.ticket, `t_${d.transport_id}_${this.flight.transport[i].floor === 2 && this.flight.transport[i].place2 >= d.ticket ? '2' : '1'}`)

            }
          })
        }

        this.selectManualTransferCurrent = null
      }, 300)

    },

    async changeStatus(order, status) {
      order.status = status;
      await Ajax.post(`/order/cancel/${order.order_id}`, order);
      this.$toast.success({content:`Заказ № ${order.order_id} отменён`});
    },

    async setNewTickets() {

      this.seatsMessage = null

      if (this.flight && !this.loadNewSeats) {
        this.loadNewSeats = true;
        this.updateOrderPrices()

        let flight_id = this.flight.flight_id
        this.newTicketsCount =
            this.order.order_adult +
            this.order.childrenValuesCount1 +
            this.order.childrenValuesCount2 +
            this.order.childrenValuesCount3 - (this.order.childrenValuesPlace.filter(x => x === true).length)

        if (!this.tr) {
          this.tr = new Date().getTime()
        }

        if (flight_id && this.tr && this.newTicketsCount && (!this.newTickets.length || this.newTickets.length && this.newTickets.filter(i => i === true).length !== this.newTicketsCount)) {
          let seats = await Ajax.get(`/orders/seats/?fl=${flight_id}&c=${this.newTicketsCount}&tr=${this.tr}&socket=${this.$socket.id}`);
          if (seats.response) {
            if (seats.response.error) {
              this.seatsMessage = seats.response.error;
              this.loadNewSeats = false
              return;
            }

            this.newTickets = []
            this.createNewTicketsTransport()

            seats.response.seats.split(',').map(i => {
              this.newTickets[parseInt(i)] = true

              if (seats.response.transport_id) {
                this.newTicketsTransport[seats.response.transport_id][parseInt(i)] = true
              }
            })

            this.order.transport_id = seats.response.transport_id || null


            let maxLimit = seats.response.seats.split(',')[seats.response.seats.split(',').length-1]

            if (maxLimit > this.flight.flight_limit && !seats.response.transport_id) {
              this.flight.max_limit = maxLimit;
            }
          }
        }
        setTimeout(() => {this.loadNewSeats = false}, 200)
      }

    },

    generatePassword() {
      let length = 8,
          charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
          retVal = "";

      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },

    async saveOrder() {

      this.clearError()

      if (!this.order.account_id) {
        let password = this.generatePassword()

        let data = {
          user_phone: this.order.order_client_phone !== '' ? this.order.order_client_phone.match(/[\d]/g).join('') : null,
          user_login: new Date().getTime() + '@vizit.tours',
          user_password: password,
          confirm_password: password,
          account_title: this.order.order_client_title,
          account_status: 'active'
        };

        let list = [];
        list.push(Ajax.post(`/account/save/0`, data));

        let [userResult] = await Promise.all(list);

        if (userResult.error) {
          if (userResult.error.message) {

            this.$toast.error({content: userResult.error.message})
          } else {
            this.setError(userResult.error);
          }

        }
        if (userResult.response) {
          this.order.account_id = userResult.response.account_id
          this.saveOrder()
        }

        return false
      }

      let orderData = this.order

      if (this.order.order_subdivision > 4) {
        orderData.order_seller_false = true;
      }

      orderData.order_client_phone = orderData.order_client_phone.match(/[\d]/g).join('')
      orderData.cartSum = Math.ceil(this.getSum(this.order.allSum))
      orderData.cartSumSale = this.order.order_sum_sale
      orderData.cartSumPrepay =
          this.order.allSumPrepay === this.order.allSum ?
              Math.ceil(this.getSum(this.order.allSum)) :
              Math.ceil(this.getSalePrepay(this.order.allSumPrepay, this.order.allSum))

      orderData.cartSumFull = Math.ceil(this.getSum(this.order.allSum))
      orderData.excursion_id = this.flightData.excursion.excursion_id
      orderData.excursion_title = this.flightData.excursion.excursion_title
      orderData.excursion_date = this.flight.date
      orderData.flight_id = this.flight.flight_id
      orderData.order_tickets = this.newTickets.reduce((a, e, i) => {if (e) {a.push(i) } return a }, [] ).join(',')
      orderData.order_status = (this.order.pay_type === 'online' ? 'pending' : 'order')
      orderData.order_pay_type = this.order.pay_type
      orderData.order_place = this.order.order_place ? this.order.order_place : this.order.place_data[0] ? this.order.place_data[0].value : this.order.place_data.value
      if (this.orderOriginal && this.orderOriginal.order && this.orderOriginal.order.order_transfer) {
        orderData.order_transfer = orderData.order_tickets
      }

      let list = [];

      let orderId = this.orderOriginal ? this.orderOriginal.order.order_id : 0

      if (orderId > 0) {
        orderData.order_id = orderId
      }

      list.push(Ajax.post(`/order/save_staff/${orderId}`, orderData));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
        this.setError(orderResult.error);
      }

      if (orderResult.response) {

        if (this.orderOriginal) {
          this.$toast.success({content:`Заказ № ${this.orderOriginal.order.order_id} успешно обновлён`});
          this.orderOriginal = null
        } else {
          this.$toast.success({content:`Заказ успешно добавлен`});
        }
        this.cancelOrder();
        this.showFlight(this.openFlight, this.openDate)

      }
    },

    setChildrenCount() {

      this.order.childrenValuesCount1 = 0;
      this.order.childrenValuesCount2 = 0;
      this.order.childrenValuesCount3 = 0;


      if (this.order.childrenValues) {
        this.order.childrenValues.forEach((e, k) => {
          if (e <= this.flightData.excursion.excursion_age_children_free_e && k < this.order.order_children) {
            if (this.order.childrenValuesPlace[k] && !!this.order.childrenValuesPlace[k]) {
              this.order.childrenValuesCount3++
            } else {
              this.order.childrenValuesCount1++
            }
          }
        });
        this.order.childrenValues.forEach((e, k) => {
          if (e > this.flightData.excursion.excursion_age_children_s && k < this.order.order_children) {
            this.order.childrenValuesCount2++
          }
        });
      }

      this.setNewTickets()

    },

    getSale(sum, prepay = 0) {

      if (this.flightData.excursion.excursion_sale_amount > 0) {
        if (this.flightData.excursion.excursion_sale_type === 'percent') {
          if (this.flightData.excursion.excursion_sale_prepay_type === 'all') {
            return sum - (sum / 100 * this.flightData.excursion.excursion_sale_amount)
          }
          if (this.flightData.excursion.excursion_sale_prepay_type === 'prepay') {
            return sum - (sum / 100 * this.flightData.excursion.excursion_sale_amount)
          }
          if (this.flightData.excursion.excursion_sale_prepay_type === 'other') {
            return sum - ((sum - prepay) / 100 * this.flightData.excursion.excursion_sale_amount)
          }
        } else {
          return sum - this.flightData.excursion.excursion_sale_amount
        }
      } else {
        return sum
      }
    },

    getSaleSum(sum, prepay = 0) {

      if (this.order.sale_amount >= 0) {
        this.promoData = [{
          promo_sale: this.order.sale_amount,
          promo_summed: 'off',
          promo_type: this.order.sale_type,
          promo_type2: this.order.sale_prepay_type
        }]
      }

      if (this.flightData.excursion) {
        if (this.promoData && this.promoData.length) {
          if (this.promoData[0].promo_type2 === 2 && this.promoData[0].promo_summed === 'off') {
            return this.order.allSumPrepay - this.getSalePrepay(this.order.allSumPrepay)
          }
          if (this.promoData[0].promo_type === 'percent') {
            if (this.promoData[0].promo_summed === 'on') {
              // add sale
              if (this.flightData.excursion.excursion_sale_amount > 0) {
                if (this.flightData.excursion.excursion_sale_type === 'percent') {
                  if (this.flightData.excursion.excursion_sale_prepay_type === 'all') {
                    return sum / 100 * (this.flightData.excursion.excursion_sale_amount + this.promoData[0].promo_sale)
                  }
                  if (this.flightData.excursion.excursion_sale_prepay_type === 'prepay') {
                    return sum / 100 * (this.flightData.excursion.excursion_sale_amount + this.promoData[0].promo_sale)
                  }
                  if (this.flightData.excursion.excursion_sale_prepay_type === 'other') {
                    return ((sum - prepay) / 100 * (this.flightData.excursion.excursion_sale_amount + this.promoData[0].promo_sale))
                  }
                } else {
                  return (sum / 100 * this.promoData[0].promo_sale) + this.flightData.excursion.excursion_sale_amount
                }
              } else {
                sum = sum / 100 * this.promoData[0].promo_sale
              }
            } else {
              sum = sum / 100 * this.promoData[0].promo_sale
            }
          } else {
            if (this.promoData[0].promo_summed === 'on') {
              // add sale
              if (this.flightData.excursion.excursion_sale_amount > 0) {
                if (this.flightData.excursion.excursion_sale_type === 'percent') {
                  if (this.flightData.excursion.excursion_sale_prepay_type === 'all') {
                    return sum / 100 * (this.flightData.excursion.excursion_sale_amount) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                  if (this.flightData.excursion.excursion_sale_prepay_type === 'prepay') {
                    return sum / 100 * (this.flightData.excursion.excursion_sale_amount) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                  if (this.flightData.excursion.excursion_sale_prepay_type === 'other') {
                    return ((sum - prepay) / 100 * (this.flightData.excursion.excursion_sale_amount)) + this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
                  }
                } else {
                  return (this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)) + this.flightData.excursion.excursion_sale_amount
                }
              } else {
                sum = this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
              }

            } else {
              sum = this.promoData[0].promo_sale
            }
          }
        } else {
          if (this.flightData.excursion.excursion_sale_amount > 0) {
            if (this.flightData.excursion.excursion_sale_type === 'percent') {
              if (this.flightData.excursion.excursion_sale_prepay_type === 'all') {
                return sum / 100 * this.flightData.excursion.excursion_sale_amount
              }
              if (this.flightData.excursion.excursion_sale_prepay_type === 'prepay') {
                return sum / 100 * this.flightData.excursion.excursion_sale_amount
              }
              if (this.flightData.excursion.excursion_sale_prepay_type === 'other') {
                return ((sum - prepay) / 100 * this.flightData.excursion.excursion_sale_amount)
              }
            } else {
              return this.flightData.excursion.excursion_sale_amount
            }
          } else {
            return 0
          }
        }
      }

      return sum
    },

    getSalePrepay(sum, price = 0) {

      let promoSale = 0;

      if (this.promoData && this.promoData.length) {
        if (this.promoData[0].promo_type2 === 2) {
          if (this.promoData[0].promo_type === 'percent') {
            if (this.promoData[0].promo_summed === 'on') {
              promoSale = (this.order.allSum / 100 * this.promoData[0].promo_sale)
            } else {
              return sum - (this.order.allSum / 100 * this.promoData[0].promo_sale)
            }
          } else {
            if (this.promoData[0].promo_summed === 'on') {
              promoSale = this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
            } else {
              return sum - this.promoData[0].promo_sale * (this.order.order_adult + this.order.order_children)
            }
          }
        }
      }

      if (this.flightData.excursion.excursion_sale_amount > 0) {
        if (this.flightData.excursion.excursion_sale_type === 'percent') {
          if (this.flightData.excursion.excursion_sale_prepay_type === 'all') {
            return sum - promoSale
          }
          if (this.flightData.excursion.excursion_sale_prepay_type === 'prepay') {
            return sum - (price / 100 * this.flightData.excursion.excursion_sale_amount) - promoSale
          }
          if (this.flightData.excursion.excursion_sale_prepay_type === 'other') {
            return sum - promoSale
          }
        } else {
          if (this.flightData.excursion.excursion_sale_prepay_type === 'prepay') {
            return sum - this.flightData.excursion.excursion_sale_amount - promoSale
          } else {
            return sum - promoSale
          }
        }
      } else {
        return sum - promoSale
      }
    },

    getSum(sum) {
      return Math.floor(this.order.allSum - this.getSaleSum(sum))
    },

    changePlaceIds(val){
      if(val){
        this.order.order_place = val && val.value ? val.value : val;

        if ( val && val.value && this.order.place_time && this.order.place_time['t' + val.value]) {
          let i = this.order.place_time['t' + val.value];
          let time = 0
          if (i.indexOf('-') > -1) {
            time = moment(this.order.order_date_ot).subtract(i.replace('-', ''), 'minutes').format('HH:mm')
          } else {
            time = moment(this.order.order_date_ot).add(i, 'minutes').format('HH:mm')
          }

          this.order.order_place_time = time
        }
      }
    },

    changeSellerIds(val){
      if (val) {
        this.order.order_seller = val && val.value ? val.value : val;
      }
    },

    changePointIds(val){
      if (val) {
        this.order.order_point = val && val.value ? val.value : val;
        if (val && val.place) {
          this.order.place_data = val.place[0]
        } else {
          this.order.place_data = null
        }
      }
    },

    changeTransportIds(val){
      this.flightData.flight.transport_id = val && val.value ? val.value : val;
    },

    async saveSchedule() {

      let data = {};
      let dates = [];
      let list = [];

      this.schedules.times[this.newFlightDate.excursion_time_type === 'true' ? this.newFlightDate.day : 0].forEach((time) => {
        dates.push({
          date: moment(this.newFlightDate.date).format('YYYY-MM-DD') + ' ' + time.h + ':' + time.m + ':00',
          flight_id: time.flight_id
        })
      })

      data.excursion_id = this.newFlightDate.excursion_id
      data.dates = dates

      if (!data.dates.length) {
        data.remove_date = moment(this.newFlightDate.date).format('YYYY-MM-DD')
      }

      list.push(Ajax.post(`/settings/excursion/schedule/${this.newFlightDate.excursion_id}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }

      if (response.response) {
        this.$toast.success({content:`Расписание обновлено`});
        this.hideNewFlight();
      }
    },

    removeTime(i, day) {
      if ( this.schedules.times[day].length === 1 ) {
        this.schedules.times[day] = []
      } else {
        this.schedules.times[day].splice(i, 1)
      }

    },

    getFlightID(dataObject) {
      return dataObject && dataObject.flight_id ? dataObject.flight_id : ''
    },

    addTime(day) {
      let newTimes = this.schedules.times[day];
      newTimes.push({
        h: '',
        m: '00',
        flight_id: ''
      })
      this.schedules.times[day] = newTimes
    },

    updateDate() {
      console.log(333)
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      let place = await Ajax.get('/misc/place?query=');

      if (place) {

        place.forEach((item) => {
          this.places[item.value] = item.label;
        });
      }

      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response && report.response.rows) {

        $.each(report.response.rows, function (k, v) {

          let fxd = null

          report.response.rows[k].excursion_sort = (report.response.rows[k].excursion_publication === 'disabled' ? 1000000 : 999999);

          if (v.dates) {

            if (v.dates && v.dates.length) {
              let newDates = {}

              $.each(v.dates, (t, date) => {
                let keys = Object.keys(date[0]);

                if (keys && keys.length) {
                  if (newDates[keys[0]]) {
                    newDates[keys[0]].tickets = newDates[keys[0]].tickets.concat(date[0][keys[0]].tickets)

                    newDates[keys[0]].count = newDates[keys[0]].count + date[0][keys[0]].count
                  } else {
                    newDates[keys[0]] = date[0][keys[0]]
                    if (date[0][keys[0]].tickets[0] === 0) {
                      newDates[keys[0]].tickets = []
                    }

                    //newDates[keys[0]].count = newDates[keys[0]].tickets ? newDates[keys[0]].tickets.length : 0
                  }

                }
              })

              v.dates = newDates
            }


            let date = {}


            $.each(v.dates, function (k2, v2) {

              if (typeof k2 !== 'string') {
                let t = Object.entries(v2[0])
                k2 = t[0][0]
                v2 = t[0][1]

                if (!fxd) {
                  fxd = {}
                }
                fxd[k2] = v2
              }

              if (date[k2.split(' ')[0]]) {
                date[k2.split(' ')[0]].push({
                  date: k2,
                  count: v2.count,
                  limit: v2.limit
                } )
              } else {
                date[k2.split(' ')[0]] = [{
                  date: k2,
                  count: v2.count,
                  limit: v2.limit
                }]
              }

              if (report.response.rows[k].excursion_sort >= 999999) {
                let minDate = parseInt(moment(this.dates[0]).format('YYYYMMDD'));
                let maxDate = parseInt(moment(this.dates[this.dates.length-1]).format('YYYYMMDD'));
                let currentDate = parseInt(k2.split(' ')[0].replace(/[^0-9]/g, ''))

                if (currentDate >= minDate && currentDate <= maxDate) {
                  report.response.rows[k].excursion_sort =  (report.response.rows[k].excursion_publication === 'disabled' ? 1000000000 : parseInt(k2.split(' ')[1].replace(/[^0-9]/g, '')));
                }
              }

            }.bind(this))
            if (fxd) {
              report.response.rows[k].dates = fxd;
            }
            report.response.rows[k].preparedDates = date;


          }

          if (v.excursion_link && v.excursion_link.length) {
            v.excursion_linked = []

            $.each(report.response.rows, function (k, i) {
              if (v.excursion_link.indexOf(i.excursion_id) > -1) {
                i.excursion_linked = v.excursion_id;
              } else if (v.excursion_id === i.excursion_id) {
                i.excursion_linked = v.excursion_id;
              }
            })
          }

        }.bind(this))

        report.response.rows = report.response.rows.sort((a, b) => {
          if (a.excursion_sort >= 999999) {
            return -1;
          }
          if (b.excursion_sort >= 999999) {
            return -1;
          }
            return a.excursion_sort - b.excursion_sort;
        })


       report.response.rows = report.response.rows.sort((a, b) => a.excursion_sort - b.excursion_sort)

        report.response.rows.forEach(i => {
          if (i.excursion_link && i.dates) {
            Object.entries(i.dates).forEach(d => {
              d[1].count_total = d[1].count;
              i.excursion_link.forEach(linked => {
                let count = report.response.rows.find(e => e.excursion_id === linked)
                if (count && count.dates && count.dates[d[0]]) {
                  d[1].count_total = d[1].count_total + (count.dates[d[0]].count || 0);
                }
              })
            })
          }
        })

        this.report = report.response;

        this.error = null;
      }

    },

    showNewFlight(ex_data, date) {

      let editDates = ex_data.dates ? Object.keys(ex_data.dates) : []
      let tmpEditDates = []

      for (let d = 0, i = editDates.length; d < i; d++) {
        if (editDates[d].split(' ')[0] === moment(date).format('YYYY-MM-DD')) {
          tmpEditDates[tmpEditDates.length] = editDates[d]
        }
      }

      editDates = tmpEditDates

      if (editDates.length) {
        for (let day = 0, len = 7; day < len; day++) {
          this.schedules.times[day] = editDates.map((i) => i = {
            h: i.split(' ')[1].split(':')[0],
            m: i.split(' ')[1].split(':')[1],
            flight_id: this.getFlightID(ex_data.dates ? ex_data.dates[ i ] : null)
          })
        }

        ex_data.excursion_time_type = 'true'

      } else {
        for (let day = 0, len = 7; day < len; day++) {

          this.schedules.times[day] = ex_data['excursion_time' + (day+1)] && ex_data['excursion_time' + (day+1)].map((i) => i = {
            h: i.split(':')[0],
            m: i.split(':')[1],
            flight_id: this.getFlightID(ex_data.dates ? ex_data.dates[ i ] : null)
          }) || this.schedule.times[day]
        }
      }

      ex_data.day = moment(date).isoWeekday() - 1
      ex_data.date = date
      this.newFlightDate = ex_data

      this.$refs.addFlight.show()
    },

    hideNewFlight() {
      this.newFlightDate = null
      this.$refs.addFlight.hide()
    },

    async getReserved(flight_id) {
      if (this.flightData && !this.loadReserved) {
        this.loadReserved = true
        let result = await Ajax.get(`/flights/reserved_seats?fl=${flight_id}&tr=${this.tr || new Date().getTime()}`);
        if (result.response) {
          this.flightData.reserved = result.response;
          setTimeout(() => {this.loadReserved = false}, 100)
        }
      }
    },


    createNewTicketsTransport() {
      if (this.flightData && this.flightData.flight && this.flightData.flight.transport_data && this.flightData.flight.transport_data.length) {
        this.flightData.flight.transport_data.forEach(i => {
          this.newTicketsTransport[i.value] = []
        })
      } else {
        this.newTicketsTransport = []
      }
    },


    async showFlight(flight, date) {

      this.emitter.off("updateReservedSeats")
      this.emitter.on("updateReservedSeats", () => {
        this.getReserved(flight.dates[date].flight_id)
      })

      this.flightData = null
      this.flight = null
      this.openFlight = flight
      this.openDate = date


      let flightData = await Ajax.get(`/flight/${flight.dates[date].flight_id}`);

      if (flightData.response) {
        let urls = {
          'reserved': `/flights/reserved_seats?fl=${flight.dates[date].flight_id}&tr=${this.tr || new Date().getTime()}`,
          'excursion': `/excursion/${flight.excursion_id}`,
          'order': `/order/report?filters[flight_id]=${flightData.response.linked_flight && flightData.response.linked_flight.length ? 'ins|'+flightData.response.linked_flight.join()+','+flightData.response.flight_id : 'eq|'+flightData.response.flight_id}&filters[order_status]=ins|'order','waiting_for_capture','reserved','pending'&order[field]=order_id&order[order]=desc&page=1&limit=500`
        };


        if (flightData.response.transport_data) {
          let newTransportData = []
          flightData.response.transport_data.forEach(i => {
            if (newTransportData.find(t => i.value === t.value)) {

              let targetRow = newTransportData.find(t => i.value === t.value);

              targetRow.excursion_data = targetRow.excursion_data.concat(i.excursion_data)

              if (i.guide) {
                i.guide.map(ng => {
                  if (targetRow.guide.find(y => ng.value === y.value)) {
                    let newGuide = targetRow.guide.find(y => ng.value === y.value);
                    if (newGuide.excursion_data) {
                      if (ng.excursion_data) {
                        targetRow.guide.find(y => ng.value === y.value).excursion_data = ng.excursion_data.concat(newGuide.excursion_data)
                      } else {
                        targetRow.guide.find(y => ng.value === y.value).excursion_data = newGuide.excursion_data
                      }
                    }
                  } else {
                    targetRow.guide.push(ng)
                  }
                })
              }
            } else {
              newTransportData.push(i)
            }
          })
          flightData.response.transport_data = newTransportData
        }

        this.flightData = await Ajax.getBulk(urls);

        if (this.flightData.order && this.flightData.order.rows) {
          this.flightData.flight = flightData.response

          this.order.place_data = this.flightData.excursion.place_data
          this.order.point_data = []

          let order_adult = 0;
          let order_children_1 = 0;
          let order_children_free = 0;
          let order_children_2 = 0;

          let order_sum_all = 0;
          let order_pay_sum = 0;
          let order_sum_sale = 0;

          let order_checkin_adult = 0;
          let order_checkin_children = 0;
          let order_checkin_children_free = 0;

          let order_tickets = [];
          this.orderColors = []

          $.each(this.flightData.order.rows, function (k ,v) {


            this.orderColors[v.order_id] = this.getRandomColor()


            order_adult = order_adult + v.order_adult
            order_children_1 = order_children_1 + v.order_children_1
            order_children_free = order_children_free + v.order_children_free
            order_children_2 = order_children_2 + v.order_children_2

            order_checkin_adult = order_checkin_adult + v.order_checkin_adult
            order_checkin_children = order_checkin_children + v.order_checkin_children
            order_checkin_children_free = order_checkin_children_free + v.order_checkin_children_free

            order_sum_all = order_sum_all + parseInt(v.order_sum_all)
            order_pay_sum = order_pay_sum + parseInt(v.order_pay_sum)
            order_sum_sale = order_sum_sale + parseInt(v.order_sum_sale)

            if (v.order_tickets) {

              let tmpSets = v.order_tickets.replace(/\s/g, '').split(',')
              $.each(tmpSets, function (k, item) {
                tmpSets[k] = {
                  ticket: parseInt(item),
                  order_id: v.order_id,
                  transport_id: v.transport_id,
                  excursion_id: v.excursion_id
                }
              })

              order_tickets = order_tickets.concat(tmpSets)
            }
          }.bind(this))

          this.newTickets = []
          this.createNewTicketsTransport()
          this.orderTickets = []


          for (let i = 1; i < 200; i++) {
            if (order_tickets.filter(item => item.ticket === i).length) {
              this.orderTickets[i] = order_tickets.filter(item => item.ticket === i)[0].order_id
            }
          }

          let tmpTransport = []

          if (this.flightData.flight.transport_data) {
            $.each(this.flightData.flight.transport_data, function (k, v) {


              if (v.separator) {
                v.separator = v.separator.map((d) => {
                  if (d.type !== 'service') {
                    d.value = parseInt(d.value)
                    return d
                  } else {
                    return false
                  }
                })
                this.service_place = v.separator.find((d) => {
                  if (d.type === 'service' && d.value && d.value.length) {
                    return d
                  }
                })
                this.service_place = this.service_place ? this.service_place.value : []
              }

              if (tmpTransport.find(i => i.value === v.value)) {

                if (v.guide) {
                  tmpTransport.map((i) => {
                    if (i.value === v.value) {
                      i.guide = i.guide.concat(v.guide);
                    }
                  })
                }

                if (v.driver) {
                  tmpTransport.map((i) => {
                    if (i.value === v.value) {
                      i.driver = i.driver.concat(v.driver);
                    }
                  })
                }
              } else {
                tmpTransport.push(v)
              }
            }.bind(this))
          }

          this.flight = {
            flight_comment: this.flightData.flight.flight_comment,
            showTransfer: false,
            flight_id: flight.dates[date].flight_id,
            ex_id: flight.excursion_id,
            ex_title: flight.excursion_title,
            date: date,
            transport: tmpTransport,
            flight_limit: this.flightData.flight.flight_limit || 100,
            flight_limit_stop: this.flightData.flight.flight_limit_stop === 'Y',
            max_limit: this.flightData.flight.flight_limit || 100
          }

          this.flight.data = this.flightData.order;

          this.flight.data.order_tickets = order_tickets;

          this.flight.data.order_adult = order_adult;
          this.flight.data.order_children_1 = order_children_1;
          this.flight.data.order_children_2 = order_children_2;
          this.flight.data.order_children_free = order_children_free;

          this.flight.data.order_checkin_adult = order_checkin_adult;
          this.flight.data.order_checkin_children = order_checkin_children;
          this.flight.data.order_checkin_children_free = order_checkin_children_free;

          this.flight.data.order_sum_all = order_sum_all
          this.flight.data.order_pay_sum = order_pay_sum
          this.flight.data.order_sum_sale = order_sum_sale

          this.flight.data.count = order_adult + order_children_1 + order_children_free + order_children_2;

          this.flight.data.checkin_count = order_checkin_adult + order_checkin_children;

          if (this.flight.data.order_tickets.length) {

            let maxSeats = this.flight.data.order_tickets.reduce(function(max, obj) {
              return obj.ticket > max.ticket ? obj : max;
            });

            if (maxSeats && maxSeats.ticket && maxSeats.ticket > this.flight.flight_limit) {
              this.flight.max_limit = maxSeats.ticket
            }
          }

          this.$refs.editFlight.show();
        }
      }

    },
  }
}
</script>
