<template>
<div>
  <Report
      ref="report"
      url="campaign/report"
      :def-params="{
        order: {
          field: 'campaign_id',
          order: 'desc'
        },
        filters: {
          account_id: 'eq|' + $route.params.id
        }
      }"
      v-on:loading="reportLoading"
      v-on:done="reportLoaded"
  >
    <Loading />
    <div v-if="error">
      {{error}}
    </div>
    <div class="full-width-content table-wrapper" v-else>
      <div class="table-scrollable">
        <table class="table" cellpadding="0" cellspacing="0" width="100%">
          <thead>
          <tr>
            <OrderTd text="№" field="campaign_id"  width="50" />
            <OrderTd text="Название" field="campaign_title" />
            <OrderTd text="Дата создания" field="campaign_date" />
            <OrderTd text="Дата обновления" field="ts" />

            <OrderTd text="Визиты" field="cnt_visit" />
            <OrderTd text="Заказы" field="cnt_order" />
            <OrderTd text="CR" field="cnt_cr" />

            <OrderTd text="Статус" field="campaign_status" width="100" />
          </tr>
          <tr class="filter-row">
            <InputFilter name="campaign_id" />
            <InputFilter name="campaign_title" filter-type="like" />
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <SelectFilter name="campaign_status" :options="[['active', 'Активна'], ['paused', 'Заблокирована']]" track-by="0" label="1" />
          </tr>
          </thead>
          <tbody v-if="report">
          <tr v-if="report.rows_count === 0">
            <td colspan="9">
              Не найдено.
            </td>
          </tr>

          <template v-else>
            <tr v-for="(row, index) in report.rows" v-bind:key="index">
              <td>{{row.campaign_id}}</td>
              <td>
                <router-link :to="`/adm/partners/${$route.params.id}/campaign/${row.campaign_id}`">
                  {{row.campaign_title}}
                </router-link>
              </td>

              <td>
                {{convertDate(row.campaign_date, 'DD.MM.YYYY HH:mm')}}
              </td>

              <td>
                {{convertDate(row.ts, 'DD.MM.YYYY HH:mm')}}
              </td>

              <td>
                {{ row.cnt_visit }}
              </td>
              <td>
                {{ row.cnt_order }}
              </td>
              <td>
                {{ row.cnt_cr }}%
              </td>


              <td>
                <div class="status" v-bind:class="`status_${row.campaign_status}`">
                  {{data.status[row.campaign_status]}}
                </div>
              </td>
            </tr>

          </template>
          </tbody>
        </table>
      </div>

      <Pager />
    </div>
  </Report>
</div>
</template>



<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';


export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },
  data() {
    return {
      data: {
        status: {
          'active': 'Активен',
          'paused': 'Заблокирован',
          'order': 'Оплачен',
          'cancel': 'Отменён',
          'cancel_success': 'Возврат',
          'waiting_for_capture': 'Оплачен, не подтверждён',
          'pending': 'Ожидает оплаты'
        },


        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: [],
        places: [],
        places_f: [],
        point: [],
        point_f: [],

      },

      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }
    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/campaign/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
