<template>
    <div class="content">
      <div class="content-header">
        <h1 class="content-title">
          Продажи
        </h1>
        <div class="content-header_misc">
          <router-link to="/adm/sale/0" class="btn btn-primary">Нонвая продажа</router-link>
        </div>
      </div>

      <Report ref="report" url="sale/report" :def-params="{order: {field: 'sale_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <Loading />
        <div v-if="error">
          {{error}}
        </div>
        <div class="full-width-content table-wrapper" v-else>
          <div class="table-scrollable">
            <table class="table" cellpadding="0" cellspacing="0" width="100%">
              <thead>
              <tr>
                <OrderTd text="№" field="sale_id"  width="50" />
                <OrderTd text="Наименование" field="sale_product_name" />
                <OrderTd text="Продал" field="sale_seller_id" />
                <OrderTd text="Кол-во" field="sale_count" />
                <OrderTd text="Стоимость" field="sale_price_total" />
                <OrderTd text="Дата мероприятия" field="sale_event_date" />
                <OrderTd text="Дата создания" field="sale_create_date" />
                <OrderTd text="Обновлено" field="ts" />
                <OrderTd text="Статус" field="sale_status" width="100" />
                <td></td>
              </tr>
              <tr class="filter-row">
                <InputFilter name="sale_id" />
                <InputFilter name="sale_product_name" filter-type="like" />
                <td></td>
                <td></td>
                <td></td>
                <PeriodPickerFilter name="sale_event_date" />
                <PeriodPickerFilter name="sale_create_date" />
                <PeriodPickerFilter name="ts" />
                <SelectFilter name="account_status" :options="[['order', 'Оплачена'], ['canceled', 'Отменена']]" track-by="0" label="1" />
                <td></td>
              </tr>
              </thead>
              <tbody v-if="report">
              <tr v-if="report.rows_count === 0">
                <td colspan="8">
                  Не найдено.
                </td>
              </tr>

              <template v-else>
                <tr v-for="(row, index) in report.rows" v-bind:key="index">
                  <td>{{row.sale_id}}</td>
                  <td>
                    <div class="row-wrap" v-bind:data-title="row.sale_name">
                      <div>
                        <router-link :to="'/adm/sale/' + row.sale_id">{{row.product_title}}</router-link>
                      </div>
                    </div>
                  </td>

                  <td>
                    <router-link :to="'/adm/settings/staff/edit/' + row.sale_seller_id">{{row.account_title}}</router-link>
                  </td>
                  <td>
                    {{ row.sale_count }}
                  </td>
                  <td>
                    {{ formatRUB(row.sale_price_total) }}
                  </td>
                  <td>{{convertDate(row.sale_event_date)}}</td>
                  <td>{{convertDate(row.sale_create_date)}}</td>
                  <td>{{convertDate(row.ts)}}</td>

                  <td>
                    <div class="status" v-bind:class="`status_${row.sale_status}`">
                      {{data.status[row.sale_status]}}
                    </div>
                  </td>


                  <td>
                    <div class="nav-right">
                      <a class="nav"></a>
                      <div class="nav-con">
                        <router-link :to="'/adm/sale/' + row.sale_id">
                          <template v-if="ac('sale.edit')">
                            Редактировать
                          </template>
                          <template v-else>
                            Просмотр
                          </template>
                        </router-link>
                        <a href="#" v-on:click.prevent="changeStatus(row.sale_id, 'new')" v-if="row.sale_status !== 'order' && ac('sale.status')">Оплачен</a>
                        <a href="#" v-on:click.prevent="changeStatus(row.sale_id, 'showed')" v-if="row.sale_status !== 'canceled' && ac('sale.status')">Отменён</a>

                        <a href="#" v-if="ac('q.delete')"  v-on:click.prevent="changeStatus(row.sale_id, 'deleted')">Удалить</a>
                      </div>
                    </div>
                  </td>
                </tr>

              </template>
              </tbody>
            </table>
          </div>

          <Pager />
        </div>
      </Report>
    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';
import Loading from '../../../components/report/Loading';
import Report from '../../../components/report/Report';
import Pager from '../../../components/Pager';
import InputFilter from '../../../components/report/InputFilter';
import SelectFilter from '../../../components/report/SelectFilter';
import PeriodPickerFilter from '../../../components/report/PeriodPickerFilter';
import OrderTd from '../../../components/report/OrderTd';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    PeriodPickerFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  data() {
    return {
      data: {
        status: {
          'order': 'Оплачен',
          'canceled': 'Отменён',
        },
      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/sale/save/${id}`, {
            sale_id: id,
            sale_status: status
          });
        }
      } else {
        await Ajax.post(`/sale/save/${id}`, {
          sale_id: id,
          sale_status: status
        });
      }

      this.getReport().reload();

    },

  }
}
</script>
