<template>
  <div>
    <Report ref="report" url="a/report_people_cash" :def-params="{order: {field: 'sum_current_cash', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div class="table-filter">
        <div>
          <AutosuggestMulti
              v-if="data.subdivision_values.length"
              ref="subdivision_id"
              v-model="subdivision"
              :value="subdivision"
              name="subdivision_id"
              text="Подразделение"
              url=""
              :data="data.subdivision_values"
              v-on:input="changeSubdivisionIds"
          />
        </div>
        <div>
          <AutosuggestMulti
              ref="account_id"
              v-model="data.account"
              :value="data.account"
              name="account_id"
              text="Кассир"
              url="/accounts/suggest?type=staff&post=2&limit=40"
              v-on:input="changeAccountIds"
          />
        </div>
      </div>

      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>

        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>

            <tr>
              <OrderTd text="№" field="account_id" width="100" />
              <OrderTd text="Кассир" field="account_title" />
              <td>Сумма инкассаций</td>
              <td>Выплачено ЗП</td>
              <td>Текущий баланс ЗП</td>
              <td>Итого ЗП</td>
              <td>Всего продаж за нал</td>
              <td>Баланс кассы</td>
            </tr>

            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="16">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>{{formatRUB(report.summary.sum_collection)}}</b>
                </td>
                <td>
                  <b>{{formatRUB(report.summary.sum_collection_award)}}</b>
                </td>
                <td></td>
                <td></td>
                <td>
                  <b>{{formatRUB(report.summary.sum_order_pay_sum)}}</b>
                </td>
                <td>
                  <b>{{formatRUB(report.summary.sum_current_cash)}}</b>
                </td>
              </tr>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">

                <td>
                  {{row.account_id}}
                </td>
                <td>
                  {{row.account_title}}
                </td>
                <td>
                  {{formatRUB(row.sum_collection)}}
                </td>
                <td>
                  {{formatRUB(row.sum_collection_award)}}
                </td>
                <td>
                  {{formatRUB(row.sum_salary - row.sum_collection_award)}}
                </td>
                <td>
                  {{formatRUB(row.sum_salary)}}
                </td>
                <td>
                  {{formatRUB(row.sum_order_pay_sum)}}
                </td>
                <td>
                  <b>{{formatRUB(row.sum_current_cash)}}</b>
                </td>



              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import AutosuggestMulti from '../../../../components/AutosuggestMulti';
import OrderTd from '../../../../components/report/OrderTd';

import moment from 'moment';

export default {
  mixins: [mixins],

  components: {

    OrderTd,
    Loading,
    AutosuggestMulti,
    Pager,
    Report
  },


  created() {
    this.loadSubdivision();

    let dateArray = [];
    let currentDate = new Date();
    let i = 0;

    while (i < 14) {
      dateArray.push(new Date (currentDate));
      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }
    this.dates = dateArray;

  },

  data() {
    return {
      subdivision: [
        {value: 3, label: 'Анапа'},
        {value: 4, label: 'Проспект'}
      ],

      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: [],
        subdivision_values: [],
        account: [],

      },
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null
    }
  },

  methods: {


    changeSubdivisionIds() {

    },
    changeAccountIds() {

    },
    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivision_values.push({value: item.subdivision_id, label: item.subdivision_title});
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
