<template>

  <div class="mb14">
    <label class="input-placeholder mb14">Права доступа</label>

    <div class="list-access-wrap">

      <div class="checkbox list-access" v-for="(row, key, i) in values" v-bind:key="i">
        <label>
          <input :ref="key" type="checkbox" name="" @change="setChildren(key)" v-on:change="getValue()">
          <span><b>{{titles[key]}}</b></span>
        </label>

        <div v-for="(row2, key2, i) in row" v-bind:key="i">
          <label>
            <input v-if="['delete', 'view', 'edit', 'status', 'view_orders'].indexOf(key2) > -1" type="checkbox" v-model="values[key][key2]" v-on:change="getValue()">
            <input :ref="key2 + '_' + key" v-else type="checkbox" @change="setChildren(key2, key)" v-on:change="getValue()">
            <span>{{titles[key2]}}</span>
          </label>

          <div v-for="(row3, key3, i) in row2" v-bind:key="i">
            <label>
              <input type="checkbox" name="" v-model="values[key][key2][key3]" @change="getValue()" v-on:change="getValue()">
              <span>{{titles[key3]}}</span>
            </label>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>



    export default {
        name: 'Access',
      components: {

      },
      props: {

        },

        computed: {

        },

        watch: {

        },

        data () {
            return {
              titles: {
                view: 'Просмотр',
                edit: 'Редактирование',
                status: 'Смена статуса',
                delete: 'Удаление',
                view_orders: 'Просмотр заказов',
                flights: 'Рейсы',
                orders: 'Заказы',
                sales: 'Продажи',
                analytics: 'Аналитика',
                analytics_people: 'Количество людей',
                analytics_schedule: 'Расписание',
                analytics_income: 'Прибыль',
                analytics_costs: 'Расходы',
                analytics_kickbacks: 'Откаты',
                analytics_time: 'Время активных продаж',
                analytics_weekly: 'Недельный план',
                finance: 'Финансы',
                finance_salaries: 'Заработные платы',
                finance_costs: 'Расходы',
                partners: 'Партнёры',
                clients: 'Клиенты',
                settings: 'Настройки',
                settings_staff: 'Сотрудники',
                settings_posts: 'Должности',
                settings_subdivision: 'Подразделения',
                settings_transport: 'Транспорт',
                settings_promo: 'Промокоды',
                settings_excursions: 'Экскурсии',
                settings_objects: 'Объекты',
                settings_place: 'Места посадки',
                settings_point: 'Точки продаж',
                site: 'Настройки сайта',
                site_blog: 'Блог',
                site_blog_category: 'Категории блога',
                site_direction: 'Направления',
                site_category: 'Тематики',
              },
              values: {
                site: {
                  site_blog: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  site_blog_category: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  site_direction: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  site_category: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                },
                flights: {
                  view: false,
                  edit: false,
                  status: false
                },
                sales: {
                  view: false,
                  edit: false,
                  status: false
                },
                orders: {
                  view: false,
                  edit: false,
                  status: false
                },
                clients: {
                  view: false,
                  edit: false,
                  delete: false,
                  status: false,
                  view_orders: false
                },
                analytics: {
                  analytics_people: {
                    view: false
                  },
                  analytics_schedule: {
                    view: false
                  },
                  analytics_income: {
                    view: false
                  },
                  analytics_costs: {
                    view: false
                  },
                  analytics_kickbacks: {
                    view: false
                  },
                  analytics_time: {
                    view: false
                  },
                  analytics_weekly: {
                    view: false
                  },
                },
                finance: {
                  finance_salaries: {
                    view: false,
                    edit: false
                  },
                  finance_costs: {
                    view: false,
                    edit: false
                  },
                },

                partners: {
                  view: false,
                  edit: false,
                  status: false,
                  delete: false,
                  view_orders: false
                },

                settings: {
                  settings_staff: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  settings_posts: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  settings_subdivision: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  settings_transport: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  settings_promo: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  settings_excursions: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  settings_objects: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  settings_place: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                  settings_point: {
                    view: false,
                    edit: false,
                    delete: false,
                    status: false,
                  },
                },
              }
            }
        },

        mounted() {


        },

        methods: {

          getValue() {
            this.$emit('input', this.values);
          },

          setValue(value) {
            if (value) {
              this.values = value
            }
          },


          setChildren(key, main = false) {

            if (this.values[key] && typeof this.values[key]['view'] === 'boolean') {

              Object.keys(this.values[key]).map(function(k) {
                this.values[key][k] = this.$refs[key][0].checked;
              }.bind(this));

            } else {

              if (main) {

                Object.keys(this.values[main][key]).map(function(k) {
                  this.values[main][key][k] = this.$refs[key + '_' + main][0].checked;
                }.bind(this));

              } else {

                Object.keys(this.values[key]).map(function(k) {
                  this.$refs[k + '_' + key][0].checked = this.$refs[key][0].checked;

                  Object.keys(this.values[key][k]).map(function(i) {
                    this.values[key][k][i] = this.$refs[key][0].checked;
                  }.bind(this));

                }.bind(this));

              }
            }
          },
        }
    }
</script>