<template>
    <div class="content">
      <div class="content-header">
        <h1 class="content-title">
          Сайт
        </h1>
        <div class="content-header_misc">
          <BlogHeader v-if="tab === 'blog' && ac('site.site_blog')" />
          <BlogCategoryHeader v-if="tab === 'blog-category' && ac('site.site_blog_category')" />
          <PageHeader v-if="tab === 'page'" />
          <DirectionHeader v-if="tab === 'direction' && ac('site.site_direction')" />
          <CategoryHeader v-if="tab === 'category' && ac('site.site_category')" />
          <FreeServiceHeader v-if="tab === 'free_service' && ac('site.site_free_service')" />
        </div>
      </div>

      <div class="line-tabs" id="tabs">
        <router-link v-if="ac('site.site_blog')" :to="getTabLink('blog')" v-bind:class="{current: isTab('blog')}">Блог</router-link>
        <router-link v-if="ac('site.site_blog_category')" :to="getTabLink('blog-category')" v-bind:class="{current: isTab('blog-category')}">Категории блога</router-link>
        <router-link v-if="ac('site.site_direction')" :to="getTabLink('direction')" v-bind:class="{current: isTab('direction')}">Направления</router-link>
        <router-link v-if="ac('site.site_category')" :to="getTabLink('category')" v-bind:class="{current: isTab('category')}">Тематики</router-link>
        <router-link v-if="ac('site.site_free_service')" :to="getTabLink('free_service')" v-bind:class="{current: isTab('free_service')}">Бесплатные услуги</router-link>
        <router-link v-if="ac('site.site_free_service')" :to="getTabLink('comment')" v-bind:class="{current: isTab('comment')}">Комментарии</router-link>
      </div>

      <div class="line-tab-con">
        <Blog v-if="tab === 'blog' && ac('site.site_blog')" />
        <e403 v-if="tab === 'blog' && !ac('site.site_blog')" />
        <BlogCategory v-if="tab === 'blog-category' && ac('site.site_blog_category')" />
        <e403 v-if="tab === 'blog-category' && !ac('site.site_blog_category')" />
        <Page v-if="tab === 'page'" />
        <Direction v-if="tab === 'direction' && ac('site.site_direction')" />
        <e403 v-if="tab === 'direction' && !ac('site.site_direction')" />
        <Category v-if="tab === 'category' && ac('site.site_category')" />
        <e403 v-if="tab === 'category' && !ac('site.site_category')" />
        <FreeService v-if="tab === 'free_service' && ac('site.site_free_service')" />
        <Comment v-if="tab === 'comment' && ac('site.site_free_service')" />
        <e403 v-if="tab === 'free_service' && !ac('site.site_free_service')" />
      </div>
    </div>
</template>


<script>
    import mixins from '../../../helpers/mixins.js'

    import e403 from '../../../components/e403';
    import Blog from './tabs/Blog';
    import BlogCategory from './tabs/BlogCategory';
    import Page from './tabs/Page';
    import Direction from './tabs/Direction';
    import Category from './tabs/Category';
    import Comment from './tabs/Comment.vue';
    import FreeService from './tabs/FreeService';

    import BlogHeader from './tabs_header/Blog';
    import BlogCategoryHeader from './tabs_header/BlogCategory';
    import PageHeader from './tabs_header/Page';

    import DirectionHeader from './tabs_header/Direction';
    import CategoryHeader from './tabs_header/Category';
    import FreeServiceHeader from './tabs_header/FreeService';


    export default {
        mixins: [mixins],

        components: {
          e403,
          Comment,
          Blog,
          BlogCategory,
          Page,
          Direction,
          Category,
          FreeService,
          FreeServiceHeader,
          BlogHeader,
          BlogCategoryHeader,
          PageHeader,
          DirectionHeader,
          CategoryHeader
        },

        watch: {
            '$route'() {
                this.tab = this.$route.params.tab || 'blog';
            }
        },

        data () {
            return {
                tab: this.$route.params.tab || 'blog',
                data: null,
            }
        },

        methods: {
            getTabLink(tab) {
                return `/adm/site/${tab}`;
            },

            isTab(tab) {
                return this.tab === tab;
            },
        }
    }
</script>
