<template>
  <div>
    <Report ref="report" url="settings/object_price/report" :def-params="{order: {field: 'object_price_id', order: 'desc'}, filters: { object_id: `eq|${this.$route.params.id}` }}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="object_price_id"  width="50" />
              <OrderTd text="Наименование" field="object_price_title" />

              <OrderTd text="Полный тариф" field="object_price_adult" />
              <OrderTd text="Детский тариф" field="object_price_children" />
              <OrderTd text="Детский (без места) тариф" field="object_price_children_free" />

              <OrderTd text="Статус" field="object_price_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="object_price_id" />
              <InputFilter name="object_price_title" filter-type="like" />

              <td></td>
              <td></td>
              <td></td>

              <SelectFilter name="object_price_status" :options="[['active', 'Активна'], ['paused', 'Заблокированна']]" track-by="0" label="1" />
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="10">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.object_price_id}}</td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.object_price_title">
                    <div>
                      <a v-if="ac('settings.settings_objects.edit')" v-on:click="openEditService(row.object_price_id)">
                        {{row.object_price_title}}
                      </a>
                      <div v-else>{{row.object_price_title}}</div>
                    </div>
                  </div>
                </td>

                <td>
                  {{formatRUB(row.object_price_adult)}}
                </td>
                <td>
                  {{formatRUB(row.object_price_children)}}
                </td>
                <td>
                  {{formatRUB(row.object_price_children_free)}}
                </td>

                <td>
                  <div class="status" v-bind:class="`status_${row.object_price_status}`">
                    {{data.status[row.object_price_status]}}
                  </div>
                </td>

                <td>
                  <div class="nav-right" v-if="ac('settings.settings_objects.edit')">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <a v-on:click="openEditService(row.object_price_id)">Редактировать</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.object_price_id, 'active')" v-if="row.object_price_status !== 'active'">Разблокировать услугу</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.object_price_id, 'paused')" v-if="row.object_price_status !== 'paused'">Заблокировать услугу</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.object_price_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
    <Modal ref="newService">
      <h2>
        <template v-if="data.newService.object_price_id > 0">
          Редактирование услуги <span>{{data.newService.object_price_id}}</span>
        </template>
        <template v-else>
          Новая услуга
        </template>
      </h2>

      <Input name="object_price_title" text="Наименование" required="true" v-model.trim="data.newService.object_price_title" />
      <div class="input-group">
        <div>
          <Input name="object_price_adult" icon2="₽" text="Полный тариф" required="true" v-model.trim.number="data.newService.object_price_adult" />
          <Select track-by="value" label="label" :options="priceType" name="object_price_adult_type" required="true" v-model.trim.number="data.newService.object_price_adult_type" />
        </div>
        <div>
          <Input name="object_price_children" icon2="₽" text="Детский тариф" required="true" v-model.trim.number="data.newService.object_price_children" />
          <Select track-by="value" label="label" :options="priceType" name="object_price_children_type" text="" required="true" v-model.trim.number="data.newService.object_price_children_type" />
        </div>
        <div>
          <Input name="object_price_children_free" icon2="₽" text="Детский (без места) тариф" required="true" v-model.trim.number="data.newService.object_price_children_free" />
          <Select track-by="value" label="label" :options="priceType" name="object_price_children_free_type" text="" required="true" v-model.trim.number="data.newService.object_price_children_free_type" />
        </div>
      </div>



      <div class="form-submit" v-if="ac('settings.settings_objects.edit')">
        <input type="hidden" name="object_price_id" v-model.number="data.newService.object_price_id" />
        <input type="hidden" name="object_id" v-model.number="data.newService.object_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
        <a href="#" class="btn btn-middle" v-on:click.prevent="cancel">Закрыть</a>
      </div>

    </Modal>
  </div>
</template>


<script>
import mixins from '../../../../../helpers/mixins.js';
import Ajax from '../../../../../helpers/ajax';
import Loading from '../../../../../components/report/Loading';
import Report from '../../../../../components/report/Report';
import Pager from '../../../../../components/Pager';
import InputFilter from '../../../../../components/report/InputFilter';
import SelectFilter from '../../../../../components/report/SelectFilter';
import OrderTd from '../../../../../components/report/OrderTd';
import Input from '../../../../../components/Input';
import Select from "@/components/Select";

import Modal from '../../../../../components/Modal';


export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Select,
    Modal,
    Loading,
    Pager,
    Input,
    Report
  },


  created() {
    this.loadSubdivision()
  },
  data() {
    return {
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокированна'
        },
        saleType: {
          'percent': '%',
          'sum': '₽'
        },
        newService: {
          object_id: this.$route.params.id,
          object_price_id: 0,
          object_price_title: '',
          object_price_adult: 0,
          object_price_children: 0,
          object_price_children_free: 0,
          object_price_adult_type: 1,
          object_price_children_type: 2,
          object_price_children_free_type: 3
        },
      },

      priceType: null,
      error: null,
      report: null
    }
  },

  methods: {

    async loadSubdivision() {
      let price_type = await Ajax.get('/misc/price_type?query=');
      if (price_type) {
        this.priceType = price_type;
      }
    },

    async save() {

      this.newServiceClearError();

      let data = this.data.newService;

      let list = [];

      delete data['object_price_status'];

      list.push(Ajax.post(`/settings/object_price/save/${data.object_price_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {

        this.$root.$children.forEach(item => {

          if (response.error.elements[item.name]) {
            if (item.setError) {
              item.setError(response.error.elements[item.name])
            } else {
              alert(response.error.elements[item.name])
            }
          }
        });


        this.setError(response.error.elements);
      }

      if (response.response) {
        if (!this.$route.params.id) {
          this.$toast.success({content:`Услуга "${data.object_price_title}" добавлена.`});
        } else {
          this.$toast.success({content:`Услуга "${data.object_price_title}" обновлёна.`});
        }
        this.$refs.newService.hide();
        this.getReport().reload();
      }
    },
    cancel() {
      this.$refs.newService.hide();
    },

    newServiceClearValue() {
      if (!this.$refs.newService) {
        return;
      }

      if (!this.$root.$children.length) {
        this.$nextTick(() => {
          this.newServiceClearValue();
        });

        return;
      }

      this.$root.$children.forEach(item => {
        this.data.newService[item.name] = '';

        if (typeof item.clear === 'function') {
          item.clear();
        }
      });
    },

    newServiceClearError() {
      if (!this.$refs.newService) {
        return;
      }

      if (!this.$root.$children.length) {
        this.$nextTick(() => {
          this.newServiceClearError();
        });

        return;
      }

      this.$root.$children.forEach(item => {
        if (typeof item.clearError === 'function') {
          item.clearError();
        }
      });
    },

    openNewService() {

      this.data.newService.object_price_id = 0;

      this.newServiceClearError();
      this.newServiceClearValue();
      this.$refs.newService.show();
    },

    async openEditService(id) {

      let result = await Ajax.get(`/object_price/${id}`);
      if (result.response) {
        this.data.newService = result.response;

        this.$refs.newService.show();
      }

    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/settings/object_price/save/${id}`, {
            object_price_id: id,
            object_price_status: status
          });
        }
      } else {
        await Ajax.post(`/settings/object_price/save/${id}`, {
          object_price_id: id,
          object_price_status: status
        });
      }


      this.getReport().reload();
    },
  }
}
</script>
