<template>
  <div class="form">
    <div class="form-left">
      <Input name="object_title" text="Наименование" required="true" v-model.trim="data.object.object_title" />

      <Select
          id="subdivision_id"
          text="Подразделение"
          name="subdivision_id"
          required="true"
          :options="subdivision"
          track-by="subdivision_id"
          label="subdivision_title"
          v-model="data.object.subdivision_id"
      />

      <Input name="object_geo_city" v-model="data.object.object_geo_city" text="Город" />

      <Input name="object_geo_address" text="Адрес" required="true" v-model.trim="data.object.object_geo_address" />

      <Input name="object_geo_gps" text="Координаты" required="true" v-model="data.object.object_geo_gps" />

    </div>

    <div class="form-submit" v-if="ac('settings.settings_objects.edit')">
      <input type="hidden" name="object_id" v-model.number="data.object.object_id" />
      <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
    </div>
  </div>
</template>


<script>
import mixins from '../../../../../helpers/mixins.js';
import Ajax from '../../../../../helpers/ajax';

import Input from "../../../../../components/Input";
import Select from "../../../../../components/Select";

export default {
  mixins: [mixins],

  components: {
    Input,
    Select
  },

  created() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    this.loadSubdivision();
  },

  data() {
    return {
      data: {
        object: {
          object_id: null,
          object_title: null,
          object_geo_city: null,
          object_geo_gps: [],
          object_geo_address: null,
          subdivision_id: null
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      subdivision: [],
      error: null,
      report: null
    }
  },

  methods: {

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {
        this.subdivision = result.response;
      }
    },

    async fetchData() {
      let urls = {
        'object': `/object/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);
    },

    async save() {
      this.clearError();

      let gps = this.data.object.object_geo_gps;
      this.data.object.object_geo_gps = (typeof gps === 'string') ? gps.split(',') : gps;

      let data = this.data.object;

      let list = [];

      delete data['object_status'];

      list.push(Ajax.post(`/settings/object/save/${this.data.object.object_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }

      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/objects/edit/' + response.response.object_id });
          this.$toast.success({content:`Объект "${this.data.object.object_title}" добавлен.`});
        } else {
          this.$toast.success({content:`Объект "${this.data.object.object_title}" обновлён.`});
        }
      }

    },

  }
}
</script>
