<template>
  <div>
    <h1 class="content-title">
      <router-link :to="'/adm/settings/promo'">Промокоды</router-link>
      <i></i>
      <template v-if="$route.params.id">
        {{ data.promo.promo_title }} <span>{{ data.promo.promo_id }}</span>
      </template>

      <template v-else>
        Новый промокод
      </template>
    </h1>

    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>

    <div class="form">
      <div class="form-left">
        <Input name="promo_code" text="Код" required="true" v-model.trim="data.promo.promo_code" />
        <Input name="promo_title" text="Наименование" required="true" v-model.trim="data.promo.promo_title" />

        <AutosuggestMulti
            ref="account_id"
            v-model="data.promo.account_data"
            :value="data.promo.account_data"
            name="account_id"
            text="Сотрудник"
            url="/accounts/suggest?type=staff&post=2&limit=40"
            v-on:input="changeAccountIds"
        />

        <div class="mb-20">
          <div v-for="(sub, key) in subdivision" v-bind:key="key">
            <div class="radio">
              <label>
                <input type="checkbox" name="font_size" v-model="subdivision_selected[sub.subdivision_id]">
                <span></span>
                <div>{{ sub.subdivision_title}}</div>
              </label>
            </div>
          </div>
        </div>

        <button v-on:click="setAccountData" class="btn btn-primary mb-20" v-if="subdivision_selected.map((i, k) =>  {return i ? k :  null} ).filter(i => i).length">Добавить</button>

        <AutosuggestMulti
            ref="flights_id"
            v-model="data.promo.flights_data"
            :value="data.promo.flights_data"
            name="flights_id"
            text="Экскурсия"
            url="/misc/excursions"
            v-on:input="changeFlightsIds"
        />

        <Input name="promo_limit" text="Лимит активаций" required="true" v-model.trim.number="data.promo.promo_limit" />

        <PeriodPicker
            name="promo_start_date"
            add-time="true"
            text="Дата начала"
            @apply="(res) => {data.promo.promo_start_date = res}"
            v-model="data.promo.promo_start_date"
        />

        <PeriodPicker
            name="promo_end_date"
            add-time="true"
            text="Дата окончания"
            @apply="(res) => {data.promo.promo_end_date = res}"
            v-model="data.promo.promo_end_date"
        />

      </div>

      <div class="form-right">

        <div class="input-group input-group-small">
          <Input name="promo_sale" text="Скидка" required="true" v-model.trim="data.promo.promo_sale" />
          <Select
              id="promo_type"
              text=""
              name="promo_type"
              required="true"
              :options="[['percent', '%'], ['sum', '₽']]"
              track-by="0"
              label="1"
              v-model="data.promo.promo_type"
          />
        </div>

        <Select
            id="promo_whom"
            text="Распространяется на"
            name="promo_whom"
            required="true"
            :options="[['all', 'всех'], ['children_all', 'всех детей'], ['children', 'детей с местом'], ['children_free', 'на детей без места'], ['adults', 'взрослых']]"
            track-by="0"
            label="1"
            v-model="data.promo.promo_whom"
        />

        <Select
            id="promo_summed"
            text="Суммируется с остальными скидками"
            name="promo_summed"
            required="true"
            :options="[['off', 'нет'], ['on', 'да']]"
            track-by="0"
            label="1"
            v-model="data.promo.promo_summed"
        />

        <Select
            id="promo_type2"
            text="Применяется к"
            name="promo_type2"
            required="true"
            :options="[[1, 'сумме заказа'], [2, 'предоплате'], [3, 'остаточному платежу']]"
            track-by="0"
            label="1"
            v-model="data.promo.promo_type2"
        />


      </div>
      <div class="form-submit" v-if="ac('settings.settings_promo.edit')">
        <input type="hidden" name="promo_id" v-model.number="data.promo.promo_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>

        <div class="checkbox">
          <label>
            <input ref="promo_send" type="checkbox" name="promo_send" value="1">
            <span>Отправить промокод выбранным кассирам</span>
          </label>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import PeriodPicker from "../../../../components/PeriodPicker";

import AutosuggestMulti from '../../../../components/AutosuggestMulti';
import moment from "moment";

export default {
  mixins: [mixins],

  components: {
    Input,
    Select,
    PeriodPicker,
    AutosuggestMulti
  },

  created() {
    this.loadSubdivision();
    if (this.$route.params.id) {
      this.fetchData();
    }

  },

  data() {
    return {
      subdivision_selected: [],
      subdivision: [],
      data: {
        promo: {
          promo_id: null,
          promo_title: null,
          promo_code: null,
          account_id: [],
          flights_id: [],
          flights_data: null,
          account_data: null,
          promo_limit: null,
          promo_start_date: null,
          promo_end_date: null,
          promo_sale: 0,
          promo_type: 'percent',
          promo_summed: 'off',
          promo_type2: 1,
          promo_whom: 'all'
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null
    }
  },

  methods: {
    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.subdivision = result.response;
      }
    },

    async setAccountData() {
      let ids = this.subdivision_selected.map((i, k) =>  {return i ? k :  null} ).filter(i => i).join()
      let result = await Ajax.get('/accounts/suggest?type=staff&post=2&limit=500&sub=' + ids);
      if (result) {
        this.data.promo.account_data = result;
      }
    },

    changeAccountIds(val) {
      this.data.promo.account_id = val.map(({value}) => value);
    },

    changeFlightsIds(val) {
      this.data.promo.flights_id = val.map(({value}) => value);
    },

    async fetchData() {
      let urls = {
        'promo': `/promo/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);

    },

    async save() {
      this.clearError();

      this.data.promo.promo_start_date = this.data.promo.promo_start_date && moment(this.data.promo.promo_start_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm');
      this.data.promo.promo_end_date = this.data.promo.promo_end_date && moment(this.data.promo.promo_end_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm');

      let data = this.data.promo;

      let list = [];

      delete data['promo_status'];

      list.push(Ajax.post(`/settings/promo/save/${this.data.promo.promo_id || '0'}`, data));


      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }

      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/promo/edit/' + response.response.promo_id });
          this.$toast.success({content:`Промокод "${this.data.promo.promo_title}" добавлен.`});
        } else {
          this.$toast.success({content:`Промокод "${this.data.promo.promo_title}" обновлён.`});
        }
      }

    },

  }
}
</script>
