<template>
  <div class="content">

      <h1 class="content-title">
        Аналитика
      </h1>

      <div class="line-tabs" id="tabs">
        <router-link :to="getTabLink('people')" v-bind:class="{current: isTab('people')}">Количество людей</router-link>
      </div>

      <div class="line-tab-con">
        <People v-if="tab === 'people'" />
      </div>
  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js'
import People from './tabs/People';

export default {
  mixins: [mixins],

  components: {
    People
  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab || 'people';
    }
  },

  data () {
    return {
      tab: this.$route.params.tab || 'people',
      data: null,
    }
  },

  methods: {
    getTabLink(tab) {
      return `/adm/analytics/${tab}`;
    },

    isTab(tab) {
      return this.tab === tab;
    },


  }
}
</script>
