<template>
  <div>

    <div class="line-tab-con tat">
      <Report key="report"
              ref="report"
              url="/a/report_people_day"
              :def-params="{order: {field: 'dates', order: 'asc'}, filters: {dates: 'bw|' + period}, limit: 500}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <Loading />
        <div v-if="error">
          {{error}}
        </div>
        <div class="full-width-content table-wrapper" v-else>

          <div class="table-filter">

            <div>
              <label class="input-placeholder">Период</label>
              <PeriodPickerFilter2 :allow-future="true" name="dates" v-model="period" />
            </div>


          </div>

          <div class="table-scrollable scroll-on">
            <table class="table" cellpadding="0" cellspacing="0" width="100%">
              <thead class="sticky">
              <tr>
                <td>
                  <b></b>
                </td>
                <td>
                  <b>Год</b>
                </td>

                <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                </td>
                <td width="100%"></td>
              </tr>

              </thead>
              <tbody v-if="report">
              <tr v-if="report.rows_count === 0">
                <td colspan="16">
                  Не найдено.
                </td>
              </tr>

              <template v-else>
                <tr>
                  <td style="min-width: 30px">
                  </td>
                  <td style="text-align: right;vertical-align: top">
                    <b>2024</b>
                    <div class="row-desc" style=" margin-top: 40px; ">
                      Набор
                    </div>
                    <div class="row-desc">
                      Продажа
                    </div>
                    <div class="row-desc">
                      Итого за неделю
                    </div>
                    <div class="row-desc">
                      Набор
                    </div>
                    <div class="row-desc">
                      Продажа
                    </div>
                  </td>
                  <td v-for="(row, index) in report.rows" v-bind:key="index" style="vertical-align: top" :class="{week: isSunday(row.c_date)}">
                    <div style="border-bottom: 5px solid var(--tableBorder);padding: 0px 12px 6px;margin: 0px -12px 14px;">
                      <b :class="{weekend: isWeekend(row.c_date)}">{{convertDate(row.c_date, 'DD')}}, {{days[convertDate(row.c_date, 'd')]}}</b><br>
                      {{convertDate(row.c_date, 'MMM')}}
                    </div>

                   <div class="wrtr">
                     <b>{{ row.c_period || 0 }}</b>
                     <div>
                       {{ row.c_bay || 0 }}
                     </div>
                   </div>

                    <div style="border-top: 1px solid var(--tableBorder);padding: 2px 0px 2px;margin: 18px 0px 2px;"  v-if="isSunday(row.c_date)">
                   <div class="wrtr">
                     <b>
                       {{ getWeekTotal(row.c_date, 'c_period') }}
                     </b>
                     <div>
                       {{ getWeekTotal(row.c_date, 'c_bay') }}
                     </div>
                   </div>
                    </div>

                  </td>

                  <td style="vertical-align: top">
                    <div style="border-bottom: 5px solid var(--tableBorder);padding: 0px 12px 6px;margin: 0px -12px 14px;">
                      <b >Итого</b><br>
                     за выбранный период
                    </div>
                   <div class="wrtr">
                     <b>{{ report.summary.total_c_period || 0 }}</b>
                     <div>
                       {{ report.summary.total_c_bay || 0 }}
                     </div>
                   </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right;vertical-align: top">
                    <b>2023</b>
                    <div class="row-desc" style=" margin-top: 40px; ">
                      Набор
                    </div>
                    <div class="row-desc">
                      Продажа
                    </div>
                    <div class="row-desc">
                      Итого за неделю
                    </div>

                    <div class="row-desc">
                      Набор
                    </div>
                    <div class="row-desc">
                      Продажа
                    </div>
                  </td>

                  <td style="vertical-align: top" v-for="(row, index) in report.rows" v-bind:key="index" :class="{week: isSunday(row.c_date2)}">
                    <div style="border-bottom: 5px solid var(--tableBorder);padding: 0px 12px 6px;margin: 0px -12px 14px;">
                      <b :class="{weekend: isWeekend(row.c_date2)}">{{convertDate(row.c_date2, 'DD')}}, {{days[convertDate(row.c_date2, 'd')]}}</b><br>
                      {{convertDate(row.c_date2, 'MMM')}}
                    </div>

                    <div class="wrtr">
                      <b>{{ row.y2023 || 0 }}</b>
                      <div>
                        {{ row.b2023 || 0 }}
                      </div>
                    </div>


                    <div style="border-top: 1px solid var(--tableBorder);padding: 2px 0px 2px;margin: 18px 0px 2px;" v-if="isSunday(row.c_date2)">
                    <div class="wrtr">
                      <b>
                        {{ getWeekTotal(row.c_date2, 'y2023', 'c_date2') }}
                      </b>
                      <div>
                        {{ getWeekTotal(row.c_date2, 'b2023', 'c_date2') }}
                      </div>
                    </div>
                    </div>
                  </td>

                  <td style="vertical-align: top">
                    <div style="border-bottom: 5px solid var(--tableBorder);padding: 0px 12px 6px;margin: 0px -12px 14px;">
                      <b >Итого</b><br>
                      за выбранный период
                    </div>
                    <div class="wrtr">
                      <b>{{ report.summary.total_y2023 || 0 }}</b>
                      <div>
                        {{ report.summary.total_b2023 || 0 }}
                      </div>
                    </div>
                  </td>

                </tr>


              </template>




              </tbody>
            </table>
          </div>

          <Pager />
        </div>
      </Report>


    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';

import PeriodPickerFilter2 from "@/components/report/PeriodPickerFilter";

import moment from 'moment';

export default {
  mixins: [mixins],

  components: {

    PeriodPickerFilter2,
    Loading,
    Pager,
    Report
  },


  mounted() {
    this.emitter.off("updateScheduleReport")
    this.emitter.on("updateScheduleReport", () => {
      if (this.$refs.report) {
        this.getReport().reload()
      }
    })
  },

  created() {
    this.emitter.off("updateScheduleReport")

    this.loadSubdivision();

    let dateArray = [];
    let currentDate = new Date();
    let i = 0;

    while (i < 14) {
      dateArray.push(new Date (currentDate));
      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }

    this.dates = dateArray;
    this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');

  },

  watch: {

    period() {

      if (!this.period) {
        let dateArray = [];
        let currentDate = new Date();
        let i = 0;

        while (i < 14) {
          dateArray.push(new Date (currentDate));
          currentDate = moment(currentDate).add(1, 'days');
          i++;
        }

        this.dates = dateArray;
        this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');
      }

      let period = this.period.split(';')
      let currentDate = new Date();
      let countDays = 14;

      if (period.length) {
        currentDate = new Date(period[0]);

        let endDate = new Date(period[1]);
        countDays = moment(endDate).diff(moment(currentDate), 'days') + 1
      }

      let dateArray = [];
      let i = 0;

      while (i < countDays) {
        dateArray.push(new Date(currentDate));
        currentDate = moment(currentDate).add(1, 'days');

        i++;
      }

      this.dates = dateArray;

    }
  },

  data() {
    return {
      flightSeats: null,
      hideAll: true,
      hideFreeFlight: false,
      places: [],
      tags: [
        'Инвалид колясочник',
        'Только у окна',
        'Задний диван',
        'Поближе'
      ],
      loadReserved: false,
      loadNewSeats: false,
      reserved: [],
      seatsMessage: null,
      promoData: null,
      pos: [],
      selectManualTransferCurrent: null,
      orders: {},
      manualTransfer: false,
      service_place: [],
      transferTickets: null,
      newTicketsCount: 1,
      flightData: null,
      orderOriginal: null,
      orderColors: [],
      order: {
        order_seller_data: null,
        order_seller: null,
        order_subdivision: 3,
        order_promo: null,
        account_id: null,
        order_client_title: '',
        order_client_phone: '',
        order_adult: 1,
        order_children: 0,
        childrenValues: [],
        childrenValuesCount1: 0,
        childrenValuesCount2: 0,
        childrenValuesCount3: 0,
        childrenValuesPlace: [],
        place_data: null,
        point_data: [],
        order_place: null,
        order_point: null,
        order_comment: '',
        order_address: '',
        pay_type: 'cash',
        sale_prepay_type: 'prepay',
        sale_amount: 0,
        sale_type: 'percent',
        transport_id: null
      },
      newOrder: false,
      newFlightDate: null,
      newTickets: [],
      newTicketsTransport: [],
      orderTickets: [],
      duration: {
        d: 0,
        d_title: ['день', 'дня', 'дней'],
        h: 0,
        h_title: ['час', 'часа', 'часов'],
        m: 0,
        m_title: ['минута', 'минуты', 'минут']
      },
      schedules: {
        times: [
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ],
          [
            {
              h: '00',
              m: '00',
              flight_id: null
            }
          ]
        ],
      },

      flight: null,
      fav: localStorage.fav ? localStorage.fav.split(',') : [],
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: []

      },
      period: '',
      schedule: null,
      dates: [],
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован',
        'order': 'Оплачен',
        'cancel': 'Отменён',
        'cancel_success': 'Возврат',
        'waiting_for_capture': 'Оплачен, не подтверждён',
        'pending': 'Ожидает оплаты'
      },
      statusOption: [
        {
          id: 'order',
          label: 'Подтвердить оплату'
        },
        {
          id: 'cancel',
          label: 'Отменить заказ'
        }
      ],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null,
      tr: null
    }
  },

  methods: {

    getWeekTotal(date, key, dateKey = 'c_date') {
      let total = 0;

      if (this.report && this.report.rows && this.report.rows.length) {
        let dateArray = [];
        let i = 0;

        while (i < 7) {
          dateArray.push(moment(date).subtract(i, 'days').format('YYYY-MM-DD'));
          i++;
        }

        this.report.rows.forEach(item => {
          if (dateArray.indexOf(item[dateKey]) > -1) {
            total = total + item[key]
          }
        })
      }

      return total
    },

    isWeekend(date) {
      return moment(date).day() % 6 === 0
    },
    isSunday(date) {
      return moment(date).day() === 0
    },

    diff(date1, date2) {
      let a = new Date(date1).getTime(),
          b = new Date(date2).getTime(),
          diff = {};

      diff.milliseconds = a > b ? a % b : b % a;
      diff.seconds = diff.milliseconds / 1000;
      diff.minutes = diff.seconds / 60;
      diff.hours = diff.minutes / 60;
      diff.days = diff.hours / 24;
      diff.weeks = diff.days / 7;

      return diff;
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      let place = await Ajax.get('/misc/place?query=');

      if (place) {

        place.forEach((item) => {
          this.places[item.value] = item.label;
        });
      }

      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response && report.response.rows) {
        this.report = report.response;
        this.error = null;
      }

    },


  }
}
</script>

<style>
.weekend {
  color: #fd0101;
}
.week {
  border-right: 16px var(--tableBorder) solid !important;
}

.wrtr {
  font-size: 14px;
  line-height: 32px;
}
.tat .table .row-desc {
  opacity: 0.6;
  padding: 14px 0 0 0;
  font-size: 12px;
}
</style>