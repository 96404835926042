<template>
  <div>
    <Report ref="report" url="settings/bonus/report" :def-params="{order: {field: 'bonus_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="bonus_id"  width="50" />
              <OrderTd text="Экскурсия" field="excursion_title" />

              <OrderTd text="Дата начала начислений" field="bonus_start_date" />
              <OrderTd text="Дата окончания начислений" field="bonus_end_date" />

              <OrderTd text="Суммы бонусов" field="bonus_amount_adult_o" />
              <OrderTd text="Суммы мега бонусов" field="bonus_amount_adult_e" />

              <OrderTd text="Статус" field="bonus_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="bonus_id" />
              <InputFilter name="excursion_title" filter-type="like" />

              <PeriodPickerFilter name="bonus_start_date" />
              <PeriodPickerFilter name="bonus_end_date" />
              <td></td>
              <td></td>

              <SelectFilter name="bonus_status" :options="[['active', 'Активен'], ['paused', 'Заблокирован']]" track-by="0" label="1" />

              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="10">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.bonus_id}}</td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.excursion_title">
                    <div>
                      <router-link :to="'/adm/settings/bonus/edit/' + row.bonus_id">{{row.excursion_title}}</router-link>
                    </div>
                  </div>
                </td>


                <td>
                  {{ convertDate(row.bonus_start_date) }}
                </td>
                <td>
                  {{ convertDate(row.bonus_end_date) }}
                </td>

                <td v-if="row.bonus_amount_type_o === 'amount'">
                  {{ formatRUB(row.bonus_amount_adult_o) }}<br>
                  {{ formatRUB(row.bonus_amount_children_1_o) }}<br>
                  {{ formatRUB(row.bonus_amount_children_2_o) }}<br>
                </td>
                <td v-else>
                  {{ row.bonus_amount_adult_o }}%<br>
                  {{ row.bonus_amount_children_1_o }}%<br>
                  {{ row.bonus_amount_children_2_o }}%<br>
                </td>

               <template v-if="row.bonus_amount_count_e > 1">
                 <td v-if="row.bonus_amount_type_e === 'amount'">
                   <b> за {{ row.bonus_amount_count_e }} и более мест на 1  рейс</b><br>
                   {{ formatRUB(row.bonus_amount_adult_o) }}<br>
                   {{ formatRUB(row.bonus_amount_children_1_o) }}<br>
                   {{ formatRUB(row.bonus_amount_children_2_o) }}<br>
                 </td>
                 <td v-else>
                   <b>за {{ row.bonus_amount_count_e }} и более мест на 1  рейс</b><br>
                   {{ row.bonus_amount_adult_o }}%<br>
                   {{ row.bonus_amount_children_1_o }}%<br>
                   {{ row.bonus_amount_children_2_o }}%<br>
                 </td>
               </template>
                <td v-else></td>
                <td>
                  <div class="status" v-bind:class="`status_${row.bonus_status}`">
                    {{status[row.bonus_status]}}
                  </div>
                </td>
                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <router-link :to="'/adm/settings/bonus/edit/' + row.bonus_id">
                        <template v-if="ac('settings.settings_staff.edit')">
                          Редактировать
                        </template>
                        <template v-else>
                          Просмотр
                        </template>
                      </router-link>
                      <a href="#" v-on:click.prevent="changeStatus(row.bonus_id, 'active')" v-if="row.bonus_status !== 'active' && ac('settings.settings_staff.status')">Разблокировать</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.bonus_id, 'paused')" v-if="row.bonus_status !== 'paused' && ac('settings.settings_staff.status')">Заблокировать</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../../helpers/mixins.js';
import Ajax from '../../../../../helpers/ajax';
import Loading from '../../../../../components/report/Loading';
import Report from '../../../../../components/report/Report';
import Pager from '../../../../../components/Pager';
import InputFilter from '../../../../../components/report/InputFilter';
import SelectFilter from '../../../../../components/report/SelectFilter';
import OrderTd from '../../../../../components/report/OrderTd';
import User from "@/user";
import PeriodPickerFilter from "@/components/report/PeriodPickerFilter";

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    PeriodPickerFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  created() {
    this.loadPosts();
    this.loadSubdivision();
  },

  data() {
    return {
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      tab: 'list',
      data: {
        posts: [],
        postsTitle: [],
        subdivision: [],
        subdivisionTitle: []
      },
      loading: false,
      error: null,
      report: null
    }
  },

  methods: {

    impersonate(user_id) {
      User.impersonate(user_id)
      window.location.reload()
    },

    isTab(tab) {
      return this.tab === tab;
    },

    async loadPosts() {
      let result = await Ajax.get('/account/get-post');
      if (result.response) {

        this.data.posts = result.response;

        result.response.forEach((item) => {
          this.data.postsTitle[item.post_id] = item.post_title;
        });

      }
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/settings/bonus/save/${id}`, {
            bonus_id: id,
            bonus_status: status
          });
        }
      } else {
        await Ajax.post(`/settings/bonus/save/${id}`, {
          bonus_id: id,
          bonus_status: status
        });
      }

      this.getReport().reload();
    },
  }
}
</script>
