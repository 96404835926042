<template>
  <div class="content">

    <h1 class="content-title">
      <router-link :to="'/adm/sale'">Продажи</router-link>
      <i></i>

      <template v-if="$route.params.id != 0">
        Продажа №{{ data.sale.sale_id }} от <span>{{ convertDate(data.sale.sale_create_date) }}</span>
      </template>

      <template v-else>
        Новая продажа
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>

    <div class="form">
      <div class="form-left">

        <AutosuggestMulti
            :multiple="false"
            ref="product_id"
            v-model="data.sale.product_data"
            :value="data.sale.product_data ? data.sale.product_data : null"
            name="product_id"
            text="Продукт"
            url="/misc/product"
            v-on:input="changeProductIds"
        />

        <AutosuggestMulti
            :multiple="false"
            ref="sale_seller_id"
            v-model="data.account_data"
            :value="data.sale.account_data ? data.sale.account_data[0] : null"
            name="sale_seller_id"
            text="Продавец"
            url="/accounts/suggest?type=staff"
            v-on:input="changeSellerId"
        />

        <PeriodPicker
            name="sale_event_date"
            text="Дата мероприятия"
            add-time="true"
            @update:modelValue="(res) => {data.sale.sale_event_date = res}"
            v-model="data.sale.sale_event_date"
        />


        <div class="input-group">
          <Input name="order_adult" text="Взрослые" required="true" v-model.number.trim="data.sale.sale_count_adult" />

          <div class="d-flex flex-align-center count-btn">
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="data.sale.sale_count_adult > 0 ? data.sale.sale_count_adult-- : false">-</a>
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="data.sale.sale_count_adult++">+</a>
          </div>

        </div>

        <div class="input-group">
          <Input name="order_adult" text="Дети 6-12 лет" required="true" v-model.number.trim="data.sale.sale_count_children" />

          <div class="d-flex flex-align-center count-btn">
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="data.sale.sale_count_children > 0 ? data.sale.sale_count_children-- : false">-</a>
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="data.sale.sale_count_children++">+</a>
          </div>
        </div>

        <div class="input-group">
          <Input name="order_adult" text="Дети 0-6 лет" required="true" v-model.number.trim="data.sale.sale_count_children_free" />

          <div class="d-flex flex-align-center count-btn">
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="data.sale.sale_count_children_free > 0 ? data.sale.sale_count_children_free-- : false">-</a>
            <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="data.sale.sale_count_children_free++">+</a>
          </div>
        </div>



      </div>
      <div class="form-right">

        <div class="peoples">
          <div>
            <b>{{ data.sale.sale_count }}</b>
            Всего
          </div>
          <div>
            <b>{{ data.sale.sale_count_adult }}</b>
            Взрослые
          </div>
          <div>
            <b>{{ data.sale.sale_count_children }}</b>
            Дети 6-12 лет
          </div>
          <div>
            <b>{{ data.sale.sale_count_children_free }}</b>
            Дети 0-6 лет
          </div>
        </div>

        <hr />

        <div class="peoples">
          <div>
            <b>{{ formatRUB(data.sale.sale_price_total) }}</b>
            Итого
          </div>
          <div>
            <b>{{ formatRUB(data.sale.sale_price_adult) }}</b>
            Взрослые
          </div>
          <div>
            <b>{{ formatRUB(data.sale.sale_price_children) }}</b>
            Дети 6-12 лет
          </div>
          <div>
            <b>{{ formatRUB(data.sale.sale_price_children_free) }}</b>
            Дети 0-6 лет
          </div>
        </div>
      </div>

      <div class="form-submit" v-if="ac('settings.settings_sale.edit')">
        <input type="hidden" name="sale_id" v-model.number="data.sale.sale_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';

import Input from "../../../components/Input";
import AutosuggestMulti from "@/components/AutosuggestMulti";
import PeriodPicker from "@/components/PeriodPicker";

import moment from "moment";


export default {
  mixins: [mixins],

  components: {
    Input,
    PeriodPicker,
    AutosuggestMulti
  },


  created() {
    this.fetchData();
  },

  watch: {
    'data.sale.sale_count_adult'() {
      this.updateCountAndPrice()
    },
    'data.sale.sale_count_children'() {
      this.updateCountAndPrice()
    },
    'data.sale.sale_count_children_free'() {
      this.updateCountAndPrice()
    }
  },


  data() {
    return {
      data: {
        sale: {
          sale_id: null,
          sale_create_date: null,
          sale_count: 0,
          sale_price_adult: 0,
          sale_price_children: 0,
          sale_price_children_free: 0,
          sale_price_total: 0,
          sale_count_adult : 0,
          sale_count_children: 0,
          sale_count_children_free: 0,
          sale_seller_id: null,
          product_data: null,
          sale_event_date: moment().format('YYYY-MM-DD HH:mm:ss')
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null
    }
  },

  methods: {

    updateCountAndPrice() {
      this.data.sale.sale_count =
          this.data.sale.sale_count_adult +
          this.data.sale.sale_count_children +
          this.data.sale.sale_count_children_free;


      if (this.data.sale.product) {

        this.data.sale.sale_price_adult = this.data.sale.sale_count_adult * this.data.sale.product.product_price_adult
        this.data.sale.sale_price_children = this.data.sale.sale_count_children * this.data.sale.product.product_price_children
        this.data.sale.sale_price_children_free = this.data.sale.sale_count_children_free * this.data.sale.product.product_price_children_free

        this.data.sale.sale_price_total =
            this.data.sale.sale_price_adult +
            this.data.sale.sale_price_children +
            this.data.sale.sale_price_children_free;
      }

    },

    changeProductIds(val) {
      if (val && val.value) {
        this.data.sale.product_id = val.value;
        this.data.sale.product = this.data.product.rows.find(i => i.product_id === val.value)
      }

      this.updateCountAndPrice()
    },
    changeSellerId(val) {
      if (val && val.value) {
        this.data.sale.sale_seller_id = val.value;
      }
    },

    async fetchData() {
      let urls = {
        'product': `/settings/product/report`
      };
      let saleTemp = this.data.sale

      if (this.$route.params.id != 0) {
        urls.sale = `/sale/${this.$route.params.id}`
      }
      this.data = await Ajax.getBulk(urls);

      if (this.$route.params.id == 0) {
       this.data.sale = saleTemp
      } else {
        this.data.sale.sale_event_date = moment(this.data.sale.sale_event_date).format('YYYY-MM-DD HH:mm:ss')
      }
    },

    async save() {
      this.clearError();

      let data = this.data.sale;

      let list = [];

      delete data['sale_status'];

      list.push(Ajax.post(`/sale/save/${this.data.sale.sale_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }
      if (response.response) {
        this.data.sale = response.response;
        if (this.$route.params.id < 1) {
          this.$router.push({ path: '/adm/sale/' + response.response.sale_id });
          this.$toast.success({content:`Продажа "${response.response.sale_id}" добавлена.`});
        } else {
          this.$toast.success({content:`Продажа "${response.response.sale_id}" обновлена.`});
        }
      } else {
        this.$toast.error({content:`Произошла какая-то ошибка:(`});
      }

    },

  }
}
</script>
