<template>
  <div>
    <Report ref="report" url="/comment/report" :def-params="{order: {field: 'comment_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="comment_id"  width="50" />
              <OrderTd text="Экскурсия" field="excursion_title" />
              <OrderTd text="Клиент" field="comment_user_text" />
              <td>Текст</td>
              <OrderTd text="Рейтинг" field="comment_rating" />
              <OrderTd text="Дата создания" field="comment_date" />
              <OrderTd text="Статус" field="comment_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="comment_id" />
              <InputFilter name="excursion_title" filter-type="like" />
              <InputFilter name="comment_user_text" filter-type="like" />
              <td></td>
              <td></td>
              <td></td>
              <SelectFilter name="comment_status" :options="[['active', 'Активен'], ['paused', 'Заблокирован']]" track-by="0" label="1" />
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="6">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>
                  {{row.comment_id}}
                </td>
                <td>
                  {{row.excursion_title}}
                </td>
                <td>
                  {{row.comment_user_text}}
                </td>
                <td>
                  {{row.comment_text}}
                </td>
                <td>
                  {{row.comment_rating}}
                </td>
                <td>
                  {{convertDate(row.comment_date)}}
                </td>
                <td>
                  <div class="status" v-bind:class="`status_${row.comment_status}`">
                    {{data.status[row.comment_status]}}
                  </div>
                </td>
                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <a href="#" v-on:click.prevent="changeStatus(row.comment_id, 'active')" v-if="row.comment_status !== 'active'">Разблокировать коммент</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.comment_id, 'paused')" v-if="row.comment_status !== 'paused'">Заблокировать коммент</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.comment_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },


  created() {
    this.loadSubdivision();
  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активен',
          'paused': 'Заблокирован'
        },

        subdivision: [],
        subdivisionTitle: []

      },
      error: null,
      report: null
    }
  },

  methods: {

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/comment/save/${id}`, {
            comment_id: id,
            comment_status: status
          });
        }
      } else {
        await Ajax.post(`/comment/save/${id}`, {
          comment_id: id,
          comment_status: status
        });
      }


      this.getReport().reload();
    },
  }
}
</script>
