<template>
  <div>
    <Report ref="report" url="settings/staff/schedule" :def-params="{order: {field: 'point_id', order: 'desc'}, filters: {point_status: 'eq|active'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>


      <div class="full-width-content table-wrapper" v-else>

        <div class="table-filter">
          <div>
            <label class="input-placeholder">Подразделение</label>
            <SelectFilter name="subdivision_id" :options="data.subdivision" track-by="subdivision_id" label="subdivision_title" class="m-0" />
          </div>
          <div>
            <label class="input-placeholder">Точка продаж</label>
            <InputFilter name="point_title" filter-type="like" />
          </div>
          <div>
            <label class="input-placeholder">Адрес точки продаж</label>
            <InputFilter name="point_address" filter-type="like" />
          </div>
          <div>
            <label class="input-placeholder">Период</label>
            <PeriodPickerFilter :allow-future="true" name="schedule_date" v-model="period" />
          </div>
        </div>

        <div class="table-scrollable scroll-on scroll_height" :class="{
          'show-setting-notification': new_schedule.schedule_date_start
        }">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead class="sticky">
            <tr>
              <OrderTd width="40" text="№" field="point_id" />
              <OrderTd text="Точка продажи" field="point_title" />
              <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                <b>{{convertDate(date, 'DD')}}</b><br>

                {{days[convertDate(date, 'd')]}}, {{convertDate(date, 'MMM')}}
              </td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="6">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>
                  {{row.point_id}}
                </td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.point_title">
                    <div>
                      <router-link :to="'/adm/settings/point/edit/' + row.point_id">{{row.point_title}}</router-link>
                    </div>
                  </div>
                  <div class="row-desc">
                    {{data.subdivisionTitle[row.subdivision_id]}} / {{row.point_address}}
                  </div>
                </td>
                <td v-for="(date, index) in dates" v-bind:key="index" class="staff_schedule" v-on:click="setDate(date, row)">
                  <div class="d-flex d-flex-wrap">
                    <template v-if="row.dates && row.dates[convertDate(date, 'YYYYMMDD')]">
                     <div
                         :data-time="convertTime(d.schedule_time_start, d.schedule_time_end)"
                         v-for="(d, i) in row.dates[convertDate(date, 'YYYYMMDD')]"
                         v-bind:key="i"
                         class="staff_schedule_item"
                         :class="{
                           join_date: (d.isNext),
                           first_day: (d.isFirst),
                           last_day: (d.isLast)
                         }"
                     >
                       {{d.account_title}}
                       <i v-on:click="removeDay(d, row.point_id)"></i>
                     </div>
                    </template>

                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
          <div class="setting-notification" v-if="new_schedule.schedule_date_start">
            <div class="item">

              <template v-if="new_schedule.point_title">
                <div class="title">
                  {{new_schedule.point_title}}
                </div>
              </template>
              <template v-if="new_schedule.schedule_date_start">
                <div class="stop_count mb-20">
                  <i>{{ convertDate(new_schedule.schedule_date_start, 'DD.MM.YYYY') }}</i>
                  <i v-if="new_schedule.schedule_date_end"> - {{ convertDate(new_schedule.schedule_date_end, 'DD.MM.YYYY') }}</i>
                </div>
              </template>
              <template v-if="new_schedule.schedule_date_end">

                <div class="pr24">
                  <Input type="text" text="Начало рабочего дня" name="schedule_time_start" v-model="new_schedule.schedule_time_start" />
                  <Input type="text" text="Конец рабочего дня" name="schedule_time_end" v-model="new_schedule.schedule_time_end" />
                  <AutosuggestMulti
                      v-model="new_schedule.account"
                      :value="new_schedule.account"
                      ref="account"
                      :multiple="false"
                      name="account"
                      text="Сотрудник"
                      url="/accounts/suggest?type=staff"
                      placeholder="Сотрудник"
                  />

                  <button
                      class="btn btn-primary"
                      :disabled="!new_schedule.account.value"
                      v-on:click="saveSchedule"
                  >Сохранить</button>
                </div>


              </template>

            </div>
          </div>
        </div>
        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../../helpers/mixins.js';
import Ajax from '../../../../../helpers/ajax';
import Loading from '../../../../../components/report/Loading';
import Report from '../../../../../components/report/Report';
import Pager from '../../../../../components/Pager';
import InputFilter from '../../../../../components/report/InputFilter';
import SelectFilter from '../../../../../components/report/SelectFilter';
import OrderTd from '../../../../../components/report/OrderTd';
import PeriodPickerFilter from "@/components/report/PeriodPickerFilter";
import moment from "moment/moment";
import Input from '../../../../../components/Input';
import AutosuggestMulti from '../../../../../components/AutosuggestMulti';


export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    PeriodPickerFilter,
    AutosuggestMulti,
    Loading,
    Input,
    Pager,
    Report
  },

  created() {
    this.loadSubdivision();


    let dateArray = [];
    let currentDate = new Date();
    let i = 0;

    while (i < 30) {
      dateArray.push(new Date (currentDate));
      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }

    this.dates = dateArray;
    this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');

  },

  watch: {
    period() {

      if (!this.period) {
        let dateArray = [];
        let currentDate = new Date();
        let i = 0;

        while (i < 30) {
          dateArray.push(new Date (currentDate));
          currentDate = moment(currentDate).add(1, 'days');
          i++;
        }

        this.dates = dateArray;
        this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');
      }

      let period = this.period.split(';')
      let currentDate = new Date();
      let countDays = 14;

      if (period.length) {
        currentDate = new Date(period[0]);

        let endDate = new Date(period[1]);
        countDays = moment(endDate).diff(moment(currentDate), 'days') + 1
      }

      let dateArray = [];
      let i = 0;

      while (i < countDays) {
        dateArray.push(new Date(currentDate));
        currentDate = moment(currentDate).add(1, 'days');

        i++;
      }

      this.dates = dateArray;

    }
  },

  data() {
    return {
      new_schedule: {
        schedule_date_start: null,
        schedule_date_end: null,
        schedule_time_start: '08:00',
        schedule_time_end: '21:00',
        point_id: null,
        point_title: null,
        account:{
          label: null,
          value: null
        }
      },
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      period: '',
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },
        subdivision: [],
        subdivisionTitle: []
      },
      error: null,
      report: null
    }
  },

  methods: {

    async removeDay(day, point_id) {
      this.new_schedule = {
        schedule_date_start: null,
        schedule_date_end: null,
        schedule_time_start: '08:00',
        schedule_time_end: '21:00',
        point_id: null,
        point_title: null,
        account:{
          label: null,
          value: null
        }
      }

      let list = [Ajax.post(`/settings/staff/save_schedule`, {
        dates: [],
        account_id: day.account_id,
        point_id: point_id,
        schedule_date: day.schedule_date
      })];

      let [result] = await Promise.all(list);

      if (result.error) {
        this.setError(result.error);
      }
      if (result.response) {
        this.$toast.success({content: `График работы обновлён.`});
        this.getReport().reload();
      }

    },
    async saveSchedule(){

      let i = 0;
      let dates = []

      while (i < 100) {
        let sd = moment(this.new_schedule.schedule_date_start).add(i, 'days').format('YYYY-MM-DD 00:00:00')
        let ed = moment(this.new_schedule.schedule_date_end).format('YYYY-MM-DD 00:00:00')

        dates.push({
          schedule_date: sd,
          schedule_time_start: this.new_schedule.schedule_time_start,
          schedule_time_end: this.new_schedule.schedule_time_end,
          point_id: this.new_schedule.point_id,
          account_id: this.new_schedule.account.value
        })

        if (sd === ed) {
         break
        }

        i++;
      }

      let list = [Ajax.post(`/settings/staff/save_schedule`, {dates: dates})];

      let [result] = await Promise.all(list);

      if (result.error) {
        this.setError(result.error);
      }
      if (result.response) {
        this.$toast.success({content:`График работы обновлён.`});
        this.getReport().reload();

        this.new_schedule = {
          schedule_date_start: null,
          schedule_date_end: null,
          schedule_time_start: '08:00',
          schedule_time_end: '21:00',
          point_id: null,
          point_title: null,
          account:{
            label: null,
            value: null
          }
        }

      }
    },

    setDate(date, point) {

      if (
          (
              this.new_schedule.schedule_date_start &&
              this.new_schedule.schedule_date_end
          ) ||
          (
              this.new_schedule.schedule_date_start >
              date
          )
      ) {
        this.new_schedule = {
          schedule_date_start: null,
          schedule_date_end: null,
          schedule_time_start: '08:00',
          schedule_time_end: '21:00',
          point_id: null,
          point_title: null,
          account:{
            label: null,
            value: null
          }
        }
        return false
      }

      if (this.new_schedule.schedule_date_start) {
        this.new_schedule.schedule_date_end = date
      } else {
        this.new_schedule.schedule_date_start = date
      }

      this.new_schedule.point_id = point.point_id
      this.new_schedule.point_title = point.point_title

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {

        report.response.rows.forEach(r => {

          if (r.dates) {
            let tmpDates = {}

            r.dates.forEach(d => {
              let keyDate = this.convertDate(d.schedule_date, 'YYYYMMDD')
              if (tmpDates[keyDate]) {
                tmpDates[keyDate].push(d)
              } else {
                tmpDates[keyDate] = [d]
              }
            })

            Object.values(tmpDates).forEach((value) => {
              value.forEach(d => {
                let nextDay = moment(d.schedule_date).add(1, 'days').format('YYYYMMDD')
                let prevDay = moment(d.schedule_date).subtract(1, 'days').format('YYYYMMDD')

                if (tmpDates[prevDay] && tmpDates[prevDay].find(i => i.account_id === d.account_id)) {
                  d.isFirst = false
                } else {
                  d.isFirst = true
                }
                if (tmpDates[nextDay] && tmpDates[nextDay].find(i => i.account_id === d.account_id)) {
                  d.isNext = true
                } else {
                  d.isLast = true
                }
              })
            })

            r.dates = tmpDates


          }
        })

        this.report = report.response;
        this.error = null;
      }

    },

  }
}
</script>
