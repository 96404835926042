<template>
  <div>
    <Report v-if="period" ref="report" url="a/report_money_day" :def-params="{order: {field: 'order_date', order: 'desc'}, filters: {order_date: 'bw|' + period}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>


        <div class="table-filter">
          <div>
            <label class="input-placeholder">Подразделение</label>
            <SelectFilter name="subdivision_id" :options="data.subdivision" track-by="subdivision_id" label="subdivision_title" class="m-0" />
          </div>
          <div>
            <label class="input-placeholder">Кассир</label>
            <InputFilter name="account_title" filter-type="like" />
          </div>
          <div>
            <label class="input-placeholder">Период</label>
            <PeriodPickerFilter2 :allow-future="true" name="order_date" v-model="period" />
          </div>
        </div>

        <div class="peoples" v-if="report && report.rows_count">
          <div>
            <b>{{ formatRUB(report.summary.order_pay_sum_total) }}</b>
            Итого
          </div>
          <div>
            <b>{{ formatRUB(report.summary.order_pay_sum_cash) }}</b>
            Наличный расчёт
          </div>
          <div>
            <b>{{ formatRUB(report.summary.order_pay_sum_card) }}</b>
            Оплата картой
          </div>
          <div>
            <b>{{ formatRUB(report.summary.order_pay_sum_online) }}</b>
            Оплата онлайн
          </div>
          <div style="margin-left: auto">
            <b>{{ formatRUB(report.summary.collection_amount_award) }}</b>
            Получено ЗП
          </div>
          <div>
            <b>{{ formatRUB(report.summary.collection_amount) }}</b>
            Инкассация
          </div>
        </div>

        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>

            <tr>
              <OrderTd text="Кассир" field="excursion_title" />

              <td v-for="(date, index) in dates" v-bind:key="index" class="text-right">
                <b>{{convertDate(date, 'DD')}}</b><br>
                {{days[convertDate(date, 'd')]}}
              </td>
              <td class="text-right">
                <b>Итог</b>
              </td>

            </tr>

            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="16">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">

                <td>
                  <div class="row-wrap" v-bind:data-title="row.excursion_title">
                    <div>
                      <router-link :to="'/adm/settings/excursions/edit/' + row.excursion_id">
                        <b>{{row.account_title}}</b>
                      </router-link>
                    </div>
                  </div>

                  <div class="amount_line left">
                    Наличный расчёт
                  </div>
                  <div class="amount_line left">
                    Оплата картой
                  </div>
                  <div class="amount_line left">
                    Оплата онлайн
                  </div>
                  <div class="amount_line left">
                    <b>Итого</b>
                  </div>
                  <div class="amount_line left">
                    <b>Инкассация</b>
                  </div>
                  <div class="amount_line left">
                    <b>Получено ЗП</b>
                  </div>
                </td>

                <td v-for="(date, index) in dates" v-bind:key="index">
                  <div class="amount_line">
                    {{ formatRUB(row.dates[convertDate(date, 'YYYY-MM-DD')] ? row.dates[convertDate(date, 'YYYY-MM-DD')].order_pay_sum_cash : 0) }}
                  </div>
                  <div class="amount_line">
                    {{ formatRUB(row.dates[convertDate(date, 'YYYY-MM-DD')] ? row.dates[convertDate(date, 'YYYY-MM-DD')].order_pay_sum_card : 0) }}
                  </div>
                  <div class="amount_line">
                    {{ formatRUB(row.dates[convertDate(date, 'YYYY-MM-DD')] ? row.dates[convertDate(date, 'YYYY-MM-DD')].order_pay_sum_online : 0) }}
                  </div>
                  <div class="amount_line">
                    <b>{{ formatRUB(row.dates[convertDate(date, 'YYYY-MM-DD')] ? row.dates[convertDate(date, 'YYYY-MM-DD')].order_pay_sum_total : 0) }}</b>
                  </div>
                  <div class="amount_line">
                    <b>{{ formatRUB(row.dates[convertDate(date, 'YYYY-MM-DD')] ? row.dates[convertDate(date, 'YYYY-MM-DD')].collection_amount : 0) }}</b>
                  </div>
                  <div class="amount_line">
                    <b>{{ formatRUB(row.dates[convertDate(date, 'YYYY-MM-DD')] ? row.dates[convertDate(date, 'YYYY-MM-DD')].collection_amount_award : 0) }}</b>
                  </div>
                </td>
                <td>
                  <div class="amount_line">
                    {{ formatRUB(row.summary.order_pay_sum_cash || 0) }}
                  </div>
                  <div class="amount_line">
                    {{ formatRUB(row.summary.order_pay_sum_card || 0) }}
                  </div>
                  <div class="amount_line">
                    {{ formatRUB(row.summary.order_pay_sum_online || 0) }}
                  </div>
                  <div class="amount_line">
                    <b>{{ formatRUB(row.summary.order_pay_sum_total || 0) }}</b>
                  </div>
                  <div class="amount_line">
                    <b>{{ formatRUB(row.summary.collection_amount || 0) }}</b>
                  </div>
                  <div class="amount_line">
                    <b>{{ formatRUB(row.summary.collection_amount_award || 0) }}</b>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';
import PeriodPickerFilter2 from "@/components/report/PeriodPickerFilter";

import moment from 'moment';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    PeriodPickerFilter2,
    Report
  },


  created() {
    this.loadSubdivision();


    let dateArray = [];
    let currentDate = new Date().setDate(new Date().getDate()-14);
    let i = 0;

    while (i < 14) {
      dateArray.push(new Date (currentDate));
      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }

    this.dates = dateArray;
    this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');

  },

  watch: {
    period() {

      if (!this.period) {
        let dateArray = [];
        let currentDate = new Date().setDate(new Date().getDate()-14);
        let i = 0;

        while (i < 14) {
          dateArray.push(new Date (currentDate));
          currentDate = moment(currentDate).add(1, 'days');
          i++;
        }

        this.dates = dateArray;
        this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');
      }

      let period = this.period.split(';')
      let currentDate = new Date();
      let countDays = 14;

      if (period.length) {
        currentDate = new Date(period[0]);

        let endDate = new Date(period[1]);
        countDays = moment(endDate).diff(moment(currentDate), 'days') + 1
      }

      let dateArray = [];
      let i = 0;

      while (i < countDays) {
        dateArray.push(new Date(currentDate));
        currentDate = moment(currentDate).add(1, 'days');

        i++;
      }

      this.dates = dateArray;

    }
  },

  data() {
    return {
      period: null,
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: []

      },
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],
      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        let newRows = []
        report.response.rows.forEach(a => {
          /*
            order_pay_sum_total
            order_pay_sum_cash
            order_pay_sum_card
            order_pay_sum_online
            collection_amount_award
            collection_amount
            *
          * */


          if (newRows.find(b => b.account_id === a.account_id)) {
            newRows.find(b => b.account_id === a.account_id).dates[this.convertDate(a.order_date, 'YYYY-MM-DD')] = a

            let summary = newRows.find(b => b.account_id === a.account_id).summary;
            newRows.find(b => b.account_id === a.account_id).summary = {
              order_pay_sum_total: summary.order_pay_sum_total + a.order_pay_sum_total,
              order_pay_sum_cash: summary.order_pay_sum_cash + a.order_pay_sum_cash,
              order_pay_sum_card: summary.order_pay_sum_card + a.order_pay_sum_card,
              order_pay_sum_online: summary.order_pay_sum_online + a.order_pay_sum_online,
              collection_amount_award: summary.collection_amount_award + a.collection_amount_award,
              collection_amount: summary.collection_amount + a.collection_amount
            }

          } else {
            let day = {}
            day[this.convertDate(a.order_date, 'YYYY-MM-DD')] = a
            newRows.push({
              account_id: a.account_id,
              account_title: a.account_title,
              dates: day,
              summary: {
                order_pay_sum_total: a.order_pay_sum_total,
                order_pay_sum_cash: a.order_pay_sum_cash,
                order_pay_sum_card: a.order_pay_sum_card,
                order_pay_sum_online: a.order_pay_sum_online,
                collection_amount_award: a.collection_amount_award,
                collection_amount: a.collection_amount
              }
            })
          }
        })
        report.response.rows = newRows

        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
