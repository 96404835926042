<template>
    <div class="content">
      <Blog v-if="tab === 'blog'" />
      <BlogCategory v-if="tab === 'blog-category'" />
      <Page v-if="tab === 'page'" />

      <Direction v-if="tab === 'direction'" />
      <Category v-if="tab === 'category'" />
      <FreeService v-if="tab === 'free_service'" />
    </div>
</template>


<script>
    import mixins from '../../../helpers/mixins.js'

    import Blog from './tabs_edit/Blog';
    import BlogCategory from './tabs_edit/BlogCategory';
    import Page from './tabs_edit/Page';

    import Direction from './tabs_edit/Direction';
    import Category from './tabs_edit/Category';
    import FreeService from "./tabs_edit/FreeService";


    export default {
        mixins: [mixins],

        components: {
          Category,
          Direction,
          FreeService,
          Blog,
          BlogCategory,
          Page
        },

        watch: {
            '$route'() {
                this.tab = this.$route.params.tab || 'blog';
            }
        },

        data () {
            return {
                tab: this.$route.params.tab || 'blog',
                data: null,
            }
        },

        methods: {
            getTabLink(tab) {
                return `/adm/site/${tab}`;
            },

            isTab(tab) {
                return this.tab === tab;
            },
        }
    }
</script>
