<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/site/blog-category'">Категории</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.category.blog_category_title }} <span>{{ data.category.blog_category_id }}</span>
      </template>

      <template v-else>
        Новая категория
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <a v-on:click="setTab('general')" v-bind:class="{current: tab === 'general'}">Общая информация</a>
      <a v-on:click="setTab('seo')" v-bind:class="{current: tab === 'seo'}">SEO</a>
    </div>

    <div class="form">

      <template v-if="tab === 'general'">
        <div class="form-left">
          <Input name="blog_category_title" text="Название" required="true" v-model.trim="data.category.blog_category_title" />
          <Input name="blog_category_page_title" text="Заголовок страницы" required="true" v-model.trim="data.category.blog_category_page_title" />
          <Textarea name="blog_category_page_subtitle" text="Описание страницы" required="true" v-model.trim="data.category.blog_category_page_subtitle" />

          <Input name="blog_category_url" text="URL" required="true" v-model.trim="data.category.blog_category_url" />
        </div>
      </template>

      <template v-if="tab === 'seo'">
        <div class="form-left">
          <Input name="blog_category_seo_title" text="SEO Title" required="true" v-model.trim="data.category.blog_category_seo_title" />
          <Textarea name="blog_category_seo_desc" text="SEO Description" v-model.trim="data.category.blog_category_seo_desc" />
          <Textarea name="blog_category_seo_keyword" text="SEO keywords" v-model.trim="data.category.blog_category_seo_keyword" />
        </div>
      </template>


      <div class="form-submit" v-if="ac('site.site_blog_category.edit')">
        <input type="hidden" name="blog_category_id" v-model.number="data.category.blog_category_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>

<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";

export default {
  mixins: [mixins],

  components: {
    Input,
    Textarea
  },

  created() {
    if (this.$route.params.id) {
      this.fetchData();
    }
  },

  data() {
    return {
      tab: 'general',
      data: {
        category: {
          blog_category_id: null,
          blog_category_title: null,
          blog_category_page_title: null,
          blog_category_page_subtitle: null,
          blog_category_url: null,
          blog_category_seo_title: null,
          blog_category_seo_desc: null,
          blog_category_seo_keyword: null
        },
      },
      error: null,
      report: null
    }
  },

  methods: {

    setTab(tab) {
      this.tab = tab;
    },

    async fetchData() {
      let urls = {
        'category': `/blog-category/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);

      this.data.category.blog_category_seo_title = this.data.category.blog_category_seo_title ? this.data.category.blog_category_seo_title : this.data.category.blog_category_title;
      this.data.category.blog_category_seo_desc =  this.data.category.blog_category_seo_desc ?  this.data.category.blog_category_seo_desc :  (this.data.category.blog_category_page_subtitle ? this.data.category.blog_category_page_subtitle.replace(/<(.|\n)*?>/g, '').slice(0, 155) : '');

    },

    async save() {
      this.clearError();

      let data = this.data.category;

      let list = [];

      delete data['blog_category_status'];

      list.push(Ajax.post(`/site/blog-category/save/${this.data.category.blog_category_id || '0'}`, data));


      let [response] = await Promise.all(list);

      if (response.error) {

        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/site/blog-category/edit/' + response.response.blog_category_id });
          this.$toast.success({content:`Категория "${this.data.category.blog_category_title}" добавлена.`});
        } else {
          this.$toast.success({content:`Категория "${this.data.category.blog_category_title}" обновлена.`});
        }
      }
    },
  }
}
</script>
