<template>
    <div class="content">
          <div class="content-header">
            <h1 class="content-title">
              Вывод средств
            </h1>

          </div>


      <Report ref="report" url="manager/balance" :def-params="{order: {field: 'collection_id', order: 'desc'}, filters: {'t.account_id': 'eq|' + cash.account_id}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <div v-if="error">
          {{error}}
        </div>
        <div v-else>

          <div class="peoples" v-if="report">
            <div>
              <b>{{formatRUB(report.summary.seller_summary.sum_salary - report.summary.seller_summary.sum_salary_freeze)}}</b>

                Доступно для вывода<br>
                <button class="btn btn-primary mb14 mt14" v-on:click="sendAward = !sendAward;sendMoney = false">Вывести</button>

            </div>
          </div>

          <template v-if="sendMoney">
            <Input text="Сумма" type="number" name="amount" icon2="₽" v-model="cash.amount" />
          </template>

          <template v-if="sendAward">
            <p>
              Введите сумму которую хотите вывести, заполните номер банковской карты и отправьте заявку на вывод средств.
            </p>
            <p>
              Заявки обрабатываются в течении 2-х рабочих дней.
            </p>
            <Input text="Сумма" type="number" name="collection_amount" :max="report.summary.seller_summary.sum_salary-report.summary.seller_summary.sum_salary_freeze" icon2="₽" v-model="award.collection_amount" />
            <Input mask="####-####-####-####" text="Номер банковской карты" type="text" name="collection_comment" v-model="award.collection_comment" />


            <template v-if="award.collection_amount > 0">
              <button class="btn btn-primary mb14" :disabled="report.summary.seller_summary.sum_salary < award.collection_amount" v-on:click="saveAward">Сохранить</button>
            </template>

          </template>

          <h3>История вывода средств</h3>

          <table class="table" cellpadding="0" cellspacing="0" width="100%"  v-if="report">
            <tbody v-if="report.rows_count === 0">
             <tr>
               <td>
                 Не найдено.
               </td>
             </tr>
            </tbody>

            <template v-else>
              <thead>
              <tr>
                <td>
                  Дата
                </td>
                <td>
                  Комментарий
                </td>
                <td>
                  Сумма
                </td>
                <td>
                  Статус
                </td>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(row, index) in report.rows"
                  v-bind:key="index"
              >
                <td>
                  {{convertDate(row.collection_date)}}
                </td>
                <td>
                  {{row.collection_comment}}
                </td>
                <td>
                  -{{formatRUB(row.collection_amount)}}
                </td>
                <td>
                  <div :class="`status out status_${row.collection_status}`">{{ status[row.collection_status] }}</div>
                </td>


              </tr>
              </tbody>

            </template>
          </table>

          <Pager />
        </div>
      </Report>


    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Input from "@/components/Input";

import User from "@/user";
import Report from "@/components/report/Report";
import Ajax from "@/helpers/ajax";


export default {
  mixins: [mixins],

  components: {
    Input,
    Report
  },

  created() {

  },

  data() {
    return {
      status: {
        pending: 'Ожидает подтверждения',
        success: 'Выполнен'
      },
      sendAward: false,
      error: null,
      report: null,
      availableBalance: 0,
      sendMoney: false,
      cash: {
        amount: 0,
        account_id: User.currentUser.account.account_id || 0
      },
      award: {
        award: true,
        collection_amount: 0,
        account_id: User.currentUser.account.account_id || 0
      }

    }
  },

  methods: {

    async saveAward() {

      let list = []

      list.push(Ajax.post(`/collection/save_partner/0`, this.award));

      let [orderResult] = await Promise.all(list);

      if (orderResult.error) {
        this.$toast.error({content: `Ошибка сохранения`});
      } else {
        this.$toast.success({content: `Информация сохранена`});

        this.sendAward = false;
        this.award.amount = 0;
        this.$refs.report.reload()
      }

    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report.response) {
        this.report = report.response;
        this.error = null;
      } else {
        this.report = null;
        this.error = 'Неизвестная ошибка.';
      }
    },

  }
}
</script>
