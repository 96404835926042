<template>
  <div>

    <div class="form">
      <div class="form-left">
        <Input name="campaign_title" text="Название" required="true" v-model.trim="data.campaign.campaign_title" />

        <template v-if="$route.params.campaign_id !== 'new' && link">
          <Input
              name="p_link"
              text="Партнёрская ссылка"
              readonly="true"
              required="true"
              v-model="link"
          />

          <div class="partner-data">
            <div>
              <label class="input-placeholder">QR код</label>
              <QRCodeVue3
                  :width="1200"
                  :height="1200"
                  :value="link"
                  image="/android-chrome-512x512.png"
                  :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                  :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 10 }"
                  :dotsOptions="{
                    type: 'rounded',
                    color: '#0b0b8f',
                    gradient: {
                      type: 'linear',
                      rotation: 0,
                      colorStops: [
                        { offset: 0, color: '#0b0b8f' },
                        { offset: 1, color: '#0b0b8f' },
                      ],
                    },
                  }"
                  :backgroundOptions="{ color: '#ffffff' }"
                  :cornersSquareOptions="{ type: 'dot', color: '#000000' }"
                  :cornersDotOptions="{ type: undefined, color: '#000000' }"
                  fileExt="png"
                  :download="true"
                  myclass="partner-qur"
                  imgclass="img-qr"
                  downloadButton="btn btn-primary download"
                  :downloadOptions="{
                  name: `partner-qr_${data.campaign.campaign_id}`,
                  extension: 'png'
              }"
              />

            </div>
          </div>

          <div class="input">
            <Select
                text="Промо материалы"
                class="mb-0"
                id="pay_type"
                name="pay_type"
                required="true"
                :options="[
                    ['', 'Выбрать...'],
                    ['a4', 'Листовка А4'],
                    ['a42', 'Листовка А4 - синяя'],
                    ['a5', 'Листовка А5'],
                    ['a52', 'Листовка А5 - синяя'],
                    ['a6', 'Стикер А6'],
                     ['a62', 'Стикер А6 - круг'],
                    ['a7', 'Стикер круглый']
                ]"
                track-by="0"
                label="1"
                v-on:change="generatePromo"
                v-model="promo"
            />
          </div>
          <div v-if="promo" class="promo-img">
            <img :src="images[promo]" />
            <a
                :download="`partner-promo_${data.campaign.campaign_id}_${promo}.png`"
                :href="images[promo]"
                class="btn btn-primary"
            >
              Скачать
            </a>
          </div>

        </template>

        <button class="btn btn-primary" v-on:click="save">
          Сохранить
        </button>
      </div>
    </div>
    <Nav />
  </div>
</template>

<script>
import mixins from '../../../../helpers/mixins.js';

import User from '../../../../user';

import Ajax from "@/helpers/ajax";

import QRCodeVue3 from "qrcode-vue3";

import Input from "@/components/Input";
import Select from "@/components/Select";

export default {
  mixins: [mixins],

  components: {
    QRCodeVue3,
    Select,
    Input
  },


  created() {
    if (this.$route.params.campaign_id !== 'new') {
      this.fetchData(this.$route.params.campaign_id);
    }
  },

  data() {
    return {
      images: {
        a4: null,
        a5: null,
        a42: null,
        a52: null,
        a6: null,
        a62: null,
        a7: null
      },
      sizes: {
        a4: {
          width: 1654,
          height: 2339,
          qr_width: 750,
          qr_height: 750,
          qr_x: 120,
          qr_y: 840
        },
        a5: {
          width: 1169,
          height: 1654,
          qr_width: 530,
          qr_height: 530,
          qr_x: 60,
          qr_y: 628
        },
        a42: {
          width: 1654,
          height: 2339,
          qr_width: 750,
          qr_height: 750,
          qr_x: 452,
          qr_y: 750
        },
        a52: {
          width: 1169,
          height: 1654,
          qr_width: 530,
          qr_height: 530,
          qr_x: 320,
          qr_y: 547
        },
        a6: {
          width: 892,
          height: 1507,
          qr_width: 610,
          qr_height: 610,
          qr_x: 138,
          qr_y: 165
        },
        a62: {
          width: 892,
          height: 1507,
          qr_width: 430,
          qr_height: 430,
          qr_x: 231,
          qr_y: 231
        },
        a7: {
          width: 1000,
          height: 1000,
          qr_width: 500,
          qr_height: 500,
          qr_x: 250,
          qr_y: 250
        }
      },
      promo: null,
      link: null,
      showOrder: null,
      user: User.currentUser.user || {},
      data: {
        campaign: {
          campaign_id: null,
          campaign_title: null,
        }
      },
    }
  },

  methods: {

    generatePromo() {
      if (this.promo && !this.images[this.promo]) {
        let c = document.createElement('canvas');
        c.width = this.sizes[this.promo].width
        c.height = this.sizes[this.promo].height

        let ctx = c.getContext("2d");
        let imageObj1 = new Image();
        let imageObj2 = new Image();


        let qr = document.getElementsByClassName('img-qr')[0].currentSrc

        imageObj1.src = `/${this.promo}.png?v=2`
        imageObj1.onload = () => {
          ctx.drawImage(
              imageObj1,
              0,
              0,
              this.sizes[this.promo].width,
              this.sizes[this.promo].height
          );
          imageObj2.src = qr;
          imageObj2.onload = () => {
            ctx.drawImage(
                imageObj2,
                this.sizes[this.promo].qr_x,
                this.sizes[this.promo].qr_y,
                this.sizes[this.promo].qr_width,
                this.sizes[this.promo].qr_height
            );

            ctx.font = "bold 16px Arial";
            ctx.fillStyle = "#b5b5b5";
            ctx.fillText(this.$route.params.campaign_id, this.sizes[this.promo].width/2-10, this.sizes[this.promo].height-30);

            this.images[this.promo] = c.toDataURL("image/png")
          }
        };
      }
    },

    async save() {
      this.clearError();

      let data = this.data.campaign;

      let list = [];

      delete data['campaign_status'];

      list.push(Ajax.post(`/campaign/save/${this.data.campaign.campaign_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }


      if (response.response) {
        if (this.$route.params.campaign_id === 'new') {
          this.$router.push({ path: '/partner/campaign/' + response.response.campaign_id });
          this.$toast.success({content:`Кампания "${this.data.campaign.campaign_title}" добавлена.`});
          this.fetchData(response.response.campaign_id)
        } else {
          this.$toast.success({content:`Кампания "${this.data.campaign.campaign_title}" обновлена.`});
        }
      }
    },
    async fetchData(id) {
      let urls = {
        'campaign': `/campaign/${id}`,
      };

      this.data = await Ajax.getBulk(urls);
      this.link = `https://vizit.tours?p=${this.data.campaign.campaign_id}`
    },
  }
}
</script>
<style>
.partner-data, .partner-qur {
  margin-bottom: 16px;
}
.img-qr {
  max-width: 300px;
}
.promo-img {
  margin: 0 0 46px;
  img {
    max-width: 100%;
    border: 1px solid var(--inputBorder);
    border-radius: 6px;
    vertical-align: top;
    box-sizing: border-box;
    margin: 0 0 16px;
  }
}

</style>