<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/settings/place_preset'">Маршруты посадок</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.place_preset.place_preset_title }} <span>{{ data.place_preset.place_preset_id }}</span>
      </template>

      <template v-else>
        Новый маршрут посадок
      </template>
    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>


    <div class="form">
      <div class="form-left">
        <Input name="place_preset_title" text="Наименование" required="true" v-model.trim="data.place_preset.place_preset_title" />

       <div class="form-submit hide-print">
         <button class="btn btn-primary" v-on:click="data.place_preset.place_preset_value_prepared.push({place: null, time: 0})">Добавить место посадки</button>
         <button class="btn btn-primary" v-on:click="sort">Сортировать по времени</button>
       </div>

        <table class="table table-place" cellpadding="0" cellspacing="0" width="100%">
          <thead>
          <tr>
            <td>
              №
            </td>
            <td>
              Место посадки
            </td>
            <td>
              Время
            </td>
            <td></td>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(row, index) in data.place_preset.place_preset_value_prepared" v-bind:key="index">
            <td>
              {{index}}
            </td>
            <td>

              <Select v-if="data.places && data.places.rows"
                  id="subdivision_id"
                  text="Подразделение"
                  name="subdivision_id"
                  required="true"
                  :options="data.places.rows"
                  track-by="place_id"
                  label="place_title"
                  v-model="data.place_preset.place_preset_value_prepared[index].place"
              />

            </td>
            <td>
              <Input name="order_comment" text="" v-model="data.place_preset.place_preset_value_prepared[index].time" />
            </td>
            <td>
              <button class="btn btn-middle" v-on:click="data.place_preset.place_preset_value_prepared.splice(index, 1)">X</button>
            </td>
          </tr>
          </tbody>


        </table>

      </div>
      <div class="form-right map-full hide-print">
        <YandexMap
            v-if="data.places"
            :key="data.place_preset.place_preset_value_prepared"
            :settings="{
                  apiKey: 'acf11884-2173-4f23-9f2f-625069ddd6ca',
                  lang: 'ru_RU',
                  coordorder: 'latlong',
                  enterprise: false,
                  version:'2.1'
              }"
            :coordinates="[44.903497, 37.321867]"
            :zoom="12"
            style="height: 100%;width: 100%"

        >

          <template v-for="(row, index) in data.place_preset.place_preset_value_prepared">
            <YandexMarker v-bind:key="index"
                          :marker-id="index"
                          v-if="row.place"
                          :coordinates="data.places.rows.find(i => i.place_id == row.place).place_geo"
                          type="Point"
                          :properties="{
                                balloonContentHeader: 'uh',
                                balloonContentBody: 'uh',
                                balloonContentFooter: 'uh',
                                hintContent: row.time + ' - ' + data.places.rows.find(i => i.place_id == row.place).place_title,
                                  iconContent: index,
                              }"
                          :options="{
                                openBalloonOnClick:true,
                                balloonContentHeader: 'uh',
                                balloonContentBody: 'uh',
                                balloonContentFooter: 'uh',
                                iconColor: '#0b0b8f'
                            }"

            >
            </YandexMarker>
          </template>
        </YandexMap>

      </div>

      <div class="form-submit" v-if="ac('settings.settings_place.edit')">
        <input type="hidden" name="place_preset_id" v-model.number="data.place_preset.place_preset_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Input from "../../../../components/Input";
import Select from "../../../../components/Select";
import { YandexMap, YandexMarker } from 'vue-yandex-maps'



export default {
  mixins: [mixins],

  components: {
    Input,
    YandexMap,
    YandexMarker,
    Select
  },


  created() {


      this.fetchData();

    this.data.place_preset.place_preset_value_prepared = [];

    Object.entries(this.data.place_preset.place_preset_value).map(i => {
      this.data.place_preset.place_preset_value_prepared.push({
        place: i[0].replace('t', ''),
        time: i[1]
      })
    })

  },

  data() {
    return {
      data: {
        place: null,
        place_preset: {
          place_preset_id: null,
          place_preset_title: null,
          place_preset_value_prepared: null,
          place_preset_value: {"t67": "25", "t69": "16", "t70": "15", "t71": "20", "t72": "25", "t73": "11", "t74": "10", "t77": "0", "t83": "30", "t85": "35", "t98": "51", "t104": "55", "t127": "30", "t131": "47", "t132": "50", "t143": "59", "t145": "60", "t149": "65", "t159": "45", "t162": "26", "t164": "40", "t165": "0", "t194": "80"}
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null
    }
  },

  methods: {

    async fetchData() {

      let place_preset = this.data.place_preset
      let urls = {
        'places': `/settings/place/report?filters%5Bplace_status%5D=eq%7Cactive&order%5Bfield%5D=place_id&order%5Border%5D=desc&page=1&limit=500`,
      };

      if (this.$route.params.id) {
        urls.place_preset = `/place_preset/${this.$route.params.id}`
      }

      this.data = await Ajax.getBulk(urls);

      if (!this.$route.params.id) {
        this.data.place_preset = place_preset
      }

      this.data.place_preset.place_preset_value_prepared = [];

      Object.entries(this.data.place_preset.place_preset_value).map(i => {
        this.data.place_preset.place_preset_value_prepared.push({
          place: i[0].replace('t', ''),
          time: i[1]
        })
      })

      this.sort()

    },


    sort() {
      let p = this.data.place_preset.place_preset_value_prepared;
      this.data.place_preset.place_preset_value_prepared = null;

      this.data.place_preset.place_preset_value_prepared = p.sort((a,b) => {
        return a.time - b.time
      })
    },


    async save() {
      this.clearError();

      let placeSaved = {}
      let placeIds = []
      this.data.place_preset.place_preset_value_prepared.map(i => {
        placeSaved['t'+i.place] = i.time
        placeIds.push(i.place)
      })


      this.data.place_preset.place_preset_value = placeSaved
      this.data.place_preset.place_preset_ids = placeIds

      let data = this.data.place_preset;

      let list = [];

      delete data['place_preset_status'];

      list.push(Ajax.post(`/settings/place_preset/save/${this.data.place_preset.place_preset_id || '0'}`, data));


      let [response] = await Promise.all(list);

      if (response.error) {

        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/place_preset/edit/' + response.response.place_preset_id });
          this.$toast.success({content:`Маршрут посадок "${this.data.place_preset.place_preset_title}" добавлен.`});
        } else {
          this.$toast.success({content:`Маршрут посадок "${this.data.place_preset.place_preset_title}" обновлён.`});
        }
      }

      this.sort()

    },

  }
}
</script>

<style>
.table-place td {
  padding: 1px 4px !important;
}
.table-place input, .table-place select, .table-place button {
  margin: 0 !important;
  height: 30px !important;
  min-height: 30px !important;
}
  .table-place .input {
    min-height: 20px;
  }
  .table-place .btn {
    min-width: 40px;
    padding: 0;
  }
  .map-full {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 110px;
    left: 710px;
    max-width: 100%;
  }

</style>