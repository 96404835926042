<template>
    <div class="content">
          <div class="content-header">
            <h1 class="content-title">
              Профиль
            </h1>
          </div>
    </div>
</template>


<script>

export default {
  components: {

  },

  data() {
    return {
      data: {},
    }
  },

  methods: {
  }
}
</script>
