<template>
    <div class="content dev-engines">

        <div class="full-width-content">
         <pre ref="console" style="overflow: auto;background: rgb(0, 0, 0);color: rgb(0, 231, 0);padding: 10px;height: calc(100vh - 240px);">{{ engines }}</pre>
        </div>

    </div>
</template>


<script>

    import Ajax from '../../helpers/ajax';
    import mixins from '../../helpers/mixins.js'

    export default {
        mixins: [mixins],

        data () {
            return {
                engines: null
            }
        },

        mounted() {
            this.fetchData();
        },


        methods: {

            async fetchData() {
                let result = await Ajax.get('/dev/engines');

                if (result.response) {
                    this.engines = result.response.res;
                }

                this.setTimeout();
            },

            setTimeout() {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                this.timeout = setTimeout(this.fetchData, 10000);
            },


        }
    }
</script>