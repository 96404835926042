<template>
    <div class="left-nav" :class="{ 'left-nav--hidden': showNav }">
      <div>

        <div class="left-nav_header">
          <div class="logo" :to="user.role_id === 5 ? '/manager/stats' : '/adm/flights'">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="32" height="32" rx="3" fill="#000046"/>
              <path d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16Z" fill="#FFE403"/>
              <path d="M18.5339 22.3056L19.4817 21.143L19.4817 21.143L18.5339 22.3056ZM18.8814 22.193L17.4285 21.8203L17.4285 21.8204L18.8814 22.193ZM3.73434 22.8682C10.7256 18.4527 16.3568 22.466 17.5861 23.4682L19.4817 21.143C17.9977 19.9332 10.8498 14.826 2.13236 20.3318L3.73434 22.8682ZM20.3344 22.5656C20.5333 21.7901 21.1806 19.5878 22.5947 17.2551C24.0083 14.9231 26.1329 12.5491 29.2587 11.262L28.1164 8.48798C24.2094 10.0967 21.6506 13.0252 20.0292 15.7C18.4082 18.3741 17.6689 20.8829 17.4285 21.8203L20.3344 22.5656ZM17.5861 23.4682C18.539 24.2451 20.0184 23.7981 20.3344 22.5656L17.4285 21.8204C17.6671 20.89 18.7705 20.5632 19.4817 21.143L17.5861 23.4682Z" fill="#000046"/>
            </svg>
            <div class="logo-name">
              <div class="logo-name-title">
                ТЭФ Визит
              </div>
              <div class="logo-name-role">
                <template v-if="user.role_id === 5">
                  Менеджер
                </template>
                <template v-if="user.role_id === 2">
                  Администратор


                  <a style="
    position: absolute;
    display: none;
    margin: 0;
    padding: 0 0 0 8px;
" href="https://docs.google.com/spreadsheets/d/14ajYh5UOxuQy6zYCr93HGn2tFJBF5z3-6yWCe8t2Fa0/edit?gid=1633424157#gid=1633424157">PL</a>


                </template>
              </div>
            </div>
          </div>
          <button class="left-nav_toggle" v-on:click="setShowNav">
            <i v-if="!showNav" class="rui rui-arrow_left rui-s24"></i>
            <i v-if="showNav" class="rui rui-arrow_right rui-s24"></i>
          </button>
        </div>


        <div class="left-nav-links" v-if="user.role_id === 2">
          <router-link v-if="ac('flights')" to="/adm/flights">
            <i class="rui rui-flight_takeoff rui-s24"></i>Рейсы
          </router-link>
          <router-link v-if="ac('orders')" to="/adm/orders">
            <i class="rui rui-menu rui-s24"></i>Заказы
          </router-link>
          <router-link v-if="ac('sale')" to="/adm/sale">
            <i class="rui rui-adm_orders rui-s24"></i>Продажи
          </router-link>
          <router-link v-if="ac('analytics')" to="/adm/analytics">
            <i class="rui rui-analytics rui-s24"></i>Аналитика
          </router-link>
          <router-link v-if="ac('finance')" to="/adm/finance">
            <i class="rui rui-attach_money rui-s24"></i>Финансы
          </router-link>


          <router-link to="/adm/delivery">
            <i class="rui rui-miscellaneous_services rui-s24"></i>Офис
          </router-link>


          <router-link v-if="ac('partners')" to="/adm/partners">
            <i class="rui rui-transfer_within_a_station rui-s24"></i>Партнёры
          </router-link>
          <router-link v-if="ac('clients')" to="/adm/clients">
            <i class="rui rui-clients rui-s24"></i>Клиенты
          </router-link>
          <router-link v-if="ac('settings')" to="/adm/settings">
            <i class="rui rui-settings rui-s24"></i>Настройки
          </router-link>
          <router-link v-if="ac('settings')" to="/adm/print">
            <i class="rui rui-local_printshop rui-s24"></i>Печатные материалы
          </router-link>
          <router-link v-if="ac('site')" to="/adm/site">
            <i class="rui rui-web rui-s24"></i>Сайт
          </router-link>
          <router-link v-if="ac('q')" to="/adm/q">
            <i class="rui rui-forum rui-s24"></i>Поддержка
          </router-link>
          <router-link v-if="ac('q')" to="/dev/engines">
            <i class="rui rui-miscellaneous_services rui-s24"></i>dev
          </router-link>
        </div>

        <div class="left-nav-links" v-if="user.role_id === 5">
          <router-link to="/manager/stats">
            <i class="rui rui-analytics rui-s24"></i>Статистика
          </router-link>
          <router-link to="/manager/partners">
            <i class="rui rui-transfer_within_a_station rui-s24"></i>Партнёры
          </router-link>
          <router-link to="/manager/finance">
            <i class="rui rui-attach_money rui-s24"></i>Вывод средств
          </router-link>
        </div>
      </div>

     <div class="profile">
       <router-link to="/profile">{{user.account_title}}</router-link>
       <router-link to="/logout">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V15H5V19H19V5H5V9H3V5C3 3.9 3.89 3 5 3ZM11.5 17L10.09 15.59L12.67 13H3V11H12.67L10.09 8.41L11.5 7L16.5 12L11.5 17Z" fill="currentColor"/>
         </svg>
       </router-link>
     </div>
    </div>
</template>

<script>
    import User from '../../../user';
    import mixins from "@/helpers/mixins";

    export default {
      mixins: [mixins],
        data () {
            return {
                showNav: (localStorage.show_nav && localStorage.show_nav === 'true') || false,
                user: User.currentUser.user || {},
                isVisible: true
            }
        },

        created () {
            User.on('change.adm_menu', () => {
                this.user = User.currentUser.user || {};
            });
        },

        beforeDestroy() {
            User.off('change.adm_menu');
        },

        methods: {
          setShowNav() {
            this.showNav = !this.showNav;
            localStorage.show_nav = this.showNav
          },
        },


    }
</script>

<style>
.logo:hover a {
  display: inline !important;
}
</style>