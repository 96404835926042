<template>
  <div>
    <h1 class="content-title">
      <router-link :to="'/adm/settings/posts'">Должности</router-link>
      <i></i>
      <template v-if="$route.params.id">
        {{ data.post.post_title }} <span>{{ data.post.post_id }}</span>
      </template>

      <template v-else>
        Новая должность
      </template>
    </h1>

    <div class="line-tabs" id="tabs">
      <router-link :to="'#global'" class="current">Общая информация</router-link>
    </div>

    <div class="form">
      <div class="form-left">
        <Input name="post_title" text="Наименование" required="true" v-model.trim="data.post.post_title" />

        <Access ref="access" v-on:input="getAccess" />
      </div>

      <div class="form-submit" v-if="ac('settings.settings_posts.edit')">
        <input type="hidden" name="post_id" v-model.number="data.post.post_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import Access from "../../../../components/Access";

export default {
  mixins: [mixins],

  components: {
    Access,
    Input,
  },


  created() {

    if (this.$route.params.id) {
      this.fetchData();
    }

  },

  data() {
    return {
      data: {
        post: {
          post_id: null,
          post_title: null,
          post_access: null,
        },
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null
    }
  },

  methods: {

    getAccess(value) {
      this.data.post.post_access = value
    },


    async fetchData() {
      let urls = {
        'post': `/post/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);
      this.$refs.access.setValue(this.data.post.post_access)



    },

    async save() {
      this.clearError();

      let data = this.data.post;



      let list = [];

      delete data['post_status'];

      list.push(Ajax.post(`/settings/post/save/${this.data.post.post_id || '0'}`, data));


      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/settings/posts/edit/' + response.response.post_id });
          this.$toast.success({content:`Должность "${this.data.post.post_title}" добавлена.`});
        } else {
          this.$toast.success({content:`Должность "${this.data.post.post_title}" обновлена.`});
        }
      }

    },

  }
}
</script>
