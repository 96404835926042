<template>
  <div class="content">

      <h1 class="content-title">
        Финансы
      </h1>

      <div class="line-tabs" id="tabs">
        <router-link v-if="ac('finance.total')" :to="getTabLink('total')" v-bind:class="{current: isTab('total')}">Продажи</router-link>
        <router-link v-if="ac('finance.salaries')" :to="getTabLink('salaries')" v-bind:class="{current: isTab('salaries')}">Наличка у кассиров</router-link>
        <router-link v-if="ac('finance.costs')" :to="getTabLink('costs')" v-bind:class="{current: isTab('costs')}">Дневной отчёт</router-link>

        <router-link v-if="ac('finance.costs')" :to="getTabLink('award')" v-bind:class="{current: isTab('award')}">Вознаграждения продавцов</router-link>
        <router-link v-if="ac('finance.costs')" :to="getTabLink('collection')" v-bind:class="{current: isTab('collection')}">Инкассация, вывод вознаграждений</router-link>
        <router-link v-if="ac('finance.costs')" :to="getTabLink('bonus')" v-bind:class="{current: isTab('bonus')}">Бонусы</router-link>
        <router-link v-if="ac('finance.costs')" :to="getTabLink('flight')" v-bind:class="{current: isTab('flight')}">Рейсы</router-link>


        <router-link v-if="ac('finance.costs')" :to="getTabLink('guide')" v-bind:class="{current: isTab('guide')}">Гиды</router-link>
      </div>

      <div class="line-tab-con">
        <Salaries v-if="tab === 'salaries' && ac('finance.salaries')" />
        <Costs v-if="tab === 'costs' && ac('finance.costs')" />
        <Total v-if="tab === 'total' && ac('finance.total')" />


        <Award v-if="tab === 'award' && ac('finance.costs')" />
        <Collection v-if="tab === 'collection' && ac('finance.total')" />

        <Bonus v-if="tab === 'bonus' && ac('finance.costs')" />
        <Flight v-if="tab === 'flight' && ac('finance.costs')" />

        <Guide v-if="tab === 'guide' && ac('finance.costs')" />

        <e403 v-if="tab === 'salaries' && !ac('finance.salaries')" />
        <e403 v-if="tab === 'costs' && !ac('finance.costs')" />
      </div>
    </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js'

import e403 from '../../../components/e403';
import Salaries from './tabs/Salaries';
import Costs from './tabs/Costs';
import Total from './tabs/Total';

import Guide from './tabs/Guide';

import Award from './tabs/Award';
import Collection from './tabs/Collection';

import Bonus from './tabs/Bonus';
import Flight from './tabs/Flight';

export default {
  mixins: [mixins],

  components: {
    Flight,
    Salaries,
    e403,
    Bonus,
    Award,
    Guide,
    Costs,
    Collection,
    Total
  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab || 'total';
    }
  },

  data () {
    return {
      tab: this.$route.params.tab || 'total',
      data: null,
    }
  },

  methods: {
    getTabLink(tab) {
      return `/adm/finance/${tab}`;
    },

    isTab(tab) {
      return this.tab === tab;
    },


  }
}
</script>
