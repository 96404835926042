<template>
    <div class="select" :class="{ 'select--empty': isEmpty, 'select--non-empty': !isEmpty, 'select--focused': isFocused, 'select--error': inputError }">

      <label class="select__label" :class="{ 'select__label--value': selectValues && selectValues.length && !isFocused, 'select__label--focused': isFocused }">{{ text }}</label>
      <Multiselect
          :disabled="disabled"
          v-model="selectValues"
          :name="name"
          :placeholder="placeholder"
          :tag-placeholder="placeholder"
          select-label="select-label"
          :id="id"
          :object="true"
          :filter-results="true"
          :options="async function(query, select$) {
              return await asyncFind(query)
          }"
          label="label"
          track-by="label"
          open-direction="bottom"
          :multiple="multiple"
          :allowAbsent="true"
          :canDeselect="false"
          :min-chars="0"
          :resolve-on-load="true"
          :delay="0"
          :mode="multiple ? 'tags' : 'single'"
          :searchable="true"
          :loading="isLoading"
          :internal-search="false"
          :clear-on-select="false"
          :close-on-select="true"
          :options-limit="300"
          :max-height="100"
          :show-no-results="false"
          :hide-selected="false"
          @select="onChange"
          @remove="onChange"
          @change="onChange"
          @clear="onChange"
          @deselect="onChange"
          @open="isFocused = true"
      >

        </Multiselect>
        <div v-if="inputError" class="msg-error">{{inputError}}</div>
    </div>
</template>

<script>
    import Ajax from '../helpers/ajax';
    import Multiselect from '@vueform/multiselect'

    export default {
        name: 'AutosuggestMulti',

        components: {
            Multiselect
        },

        props: {
            name: String,
            text: String,
            value: {},
          multiple: {
            type: Boolean,
            default: true
          },
          disabled: {
            type: Boolean,
            default: false
          },
          trackBy: {
            type: String,
            default: 'value'
          },
          placeholder: {
            type: String,
            default: 'Выбрать'
          },
          data: {
            type: Array,
            default: null
          },
            label: {
                type: String,
                default: 'label'
            },
          url: String
        },

        computed: {
            isEmpty() {
                return (!this.selectValues) || (!this.selectValues.length);
            }
        },

      watch: {
        value() {
          this.setValue(this.value)
          this.setValue(this.selectValues)
        }
      },

      created() {
        this.asyncFind('');
        if (this.value) {
          this.setValue(this.value)
        }
      },

      data () {
            return {
                isLoading: false,
                id: `inp_${this.name.toLowerCase()}`,
                inputError: '',
                isFocused: false,
                options: [],
                selectValues: this.value,
            }
        },

        methods: {
            onChange() {
                this.$nextTick(() => {
                  this.$emit('update:modelValue', this.selectValues)
                  this.$emit('input', this.selectValues);
                });
            },

            async asyncFind(query) {
              if (this.data && this.data.length) {
                return this.data;
              } else {
                let res = await Ajax.get(`${this.url}${this.url.indexOf('?') > -1 ? '&' : '?'}query=${encodeURIComponent(query || '')}`);
                this.isLoading = false;
                this.options = res;
                return res;
              }
            },

            clear () {
                this.selectValues = []
            },

            getValue() {
                return this.selectValues;
            },

            setValue(val) {
              val = this.multiple ? val : (val && val.length ? val[0] : val)
              if (val) {
                for (let i = 0; i < val.length; i++) {
                  if (!val[i].label && !val[i].value) {
                    val.splice(i, 1);
                  }
                }
              }

              this.selectValues = val;
            },

            getError() {
                return this.inputError;
            },

            setError(val) {
                this.inputError = val;
            },

            clearError() {
                this.inputError = '';
            }
        }
    }
</script>