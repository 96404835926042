<template>
  <div class="form">
    <div class="form-left">
      <Input name="account_title" text="ФИО" required="true" v-model.trim="data.account.account_title" />
      <Input name="user_phone" mask="+#(###)###-##-##" text="Телефон" required="true" v-model.trim="data.user.user_phone" />
    </div>

    <div class="form-submit" v-if="ac('clients.edit')">
      <input type="hidden" name="account_id" v-model="data.user.account_id" />
      <input type="hidden" name="user_id" :value="data.user.user_id" />
      <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
    </div>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";


export default {
  mixins: [mixins],

  components: {
    Input
  },


  created() {

    if (this.$route.params.id) {
      this.fetchData();
    }

  },

  data() {
    return {
      data: {
        account: {
          account_id: null,
          account_title: null,
        },
        user: {
          user_id: null,
          account_id: null,
          user_phone: null,
          user_password: null
        }
      },
      status: {
        'active': 'Активен',
        'paused': 'Заблокирован'
      },
      error: null,
      report: null
    }
  },

  methods: {

    async fetchData() {
      let urls = {
        'account': `/accounts/${this.$route.params.id}`,
        'user': `/users/by_account/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);
    },


    async save() {
      this.clearError();

      let data = {
        user_phone: this.data.user.user_phone.match(/[\d]/g).join(''),
        account_id: this.data.user.account_id,
        user_password: this.data.user.user_password,
        account_title: this.data.account.account_title,
      };

      let list = [];
      list.push(Ajax.post(`/settings/staff/save/${this.data.user.user_id || '0'}`, data));


      let [userResult] = await Promise.all(list);

      if (userResult.error) {

        if (userResult.error.account_id) {
          this.data.user.account_id = userResult.error.account_id;
        }
        this.setError(userResult.error);
      }

      if (userResult.response) {
        this.$toast.success({content:`Клиент "${this.data.account.account_title}" обновлён.`});
      }

    },

  }
}
</script>
