<template>
  <div class="content">

    <h1 class="content-title">
      <router-link :to="'/adm/clients'">Клиенты</router-link>
      <i></i>

      {{ data.account.account_title }} <span>{{ data.account.account_id }}</span>

    </h1>


    <div class="line-tabs" id="tabs">
      <router-link :to="getTabLink('general')" v-bind:class="{current: isTab('general')}">Общая информация</router-link>
      <router-link :to="getTabLink('orders')" v-bind:class="{current: isTab('orders')}">Заказы</router-link>
    </div>

    <div class="line-tab-con">
      <General v-if="tab === 'general'" />
      <Orders v-if="tab === 'orders'" />
    </div>

  </div>
</template>


<script>
import mixins from '../../../helpers/mixins.js';
import Ajax from '../../../helpers/ajax';

import General from './tabs/General';
import Orders from './tabs/Orders';

export default {
  mixins: [mixins],

  components: {
    General,
    Orders
  },

  watch: {
    '$route'() {
      this.tab = this.$route.params.tab || 'general';
    }
  },

  created() {

    if (this.$route.params.id) {
      this.fetchData();
    }

  },



  data() {
    return {
      data: {

        account: {
          account_id: null,
          account_title: null,
        },
      },
      tab: this.$route.params.tab || 'general',
      error: null,
      report: null
    }
  },

  methods: {

    getTabLink(tab) {
      return `/adm/clients/${this.$route.params.id}/${tab}`;
    },

    isTab(tab) {
      return this.tab === tab;
    },

    async fetchData() {
      let urls = {
        'account': `/accounts/${this.$route.params.id}`
      };

      this.data = await Ajax.getBulk(urls);
    },

  }
}
</script>
