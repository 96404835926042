<template>
    <div class="content">
        <h1>Executor</h1>

        <div class="full-width-content">
            <div class="filter">
                <div class="g-filters flex-wrap">
                    <div>
                        <Input name="js" text="JS File" v-model="fields.js" />
                    </div>

                    <div>
                        <Input name="from" text="From" numeric="true" v-model.number="fields.from" />
                    </div>

                    <div>
                        <Input name="limit" text="Limit" numeric="true" v-model.number="fields.limit" />
                    </div>

                    <div>
                        <Input name="field" text="Order field" v-model="fields.field" />
                    </div>

                    <div class="mr0">
                        <Select text="Order dir" name="order"  v-model="fields.order" :options="[['', ''], ['asc', 'ASC'], ['desc', 'DESC']]" trackBy="0" label="1" />
                    </div>

                    <div class="flex-auto mr0">
                        <Textarea name="filters" text="Filter" v-model="fields.filters" />
                    </div>
                </div>

                <div class="g2 flex-start">
                    <div>
                        <a class="btn btn-type1" v-on:click.prevent="send">Submit</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="full-width-content" v-if="loading">
            <div class="loading-report">
                Loading...
            </div>
        </div>

        <div class="full-width-content" v-else>
            <div class="filter">
                <div>
                    <h2>Used params</h2>
                    <pre>{{params}}</pre>
                </div>

                <template v-if="report.error">
                    <h3>Error:</h3>
                    <pre>{{JSON.stringify(report.error, null, 4)}}</pre>
                </template>

                <template v-else>
                    <h3>Result:</h3>
                    <pre>{{JSON.stringify(report.response, null, 4)}}</pre>
                </template>
            </div>
        </div>
    </div>
</template>


<script>
    import Ajax from '../../helpers/ajax';
    import mixins from '../../helpers/mixins.js'

    import Input from '../../components/Input';
    import Select from '../../components/Select';
    import Textarea from '../../components/Textarea';
    import $ from "jquery";

    export default {
        mixins: [mixins],

        components: {
            Input,
            Select,
            Textarea
        },

        data () {
            return {
                abortController: null,
                loading: true,
                report: null,
                params: null,
                fields: {
                    js: 'test',
                    from: 0,
                    limit: 25,
                    field: null,
                    order: null,
                    filters: null
                }
            }
        },

        mounted() {
            for(let i in this.fields) {
                if (this.$route.query[i]) {
                    this.fields[i] = this.$route.query[i];
                }
            }

            this.send();
        },

        methods: {
            async send() {
                this.loading = true;

                if (this.abortController) {
                    this.abortController.abort();
                }

                this.abortController = new AbortController();
                let result = await this.load();

                if (result !== null) {
                    this.abortController = null;
                    this.report = result;
                    this.loading = false;
                }
            },

            async load() {
                this.params = JSON.stringify(this.fields, null, 4);
                let link = `dev/executor?${$.param(this.fields)}`;

                try {
                    let resp = await Ajax.get(link, this.abortController.signal);
                    if ((resp.error) || (!resp.response)) {
                        return resp;
                    }

                    if (resp.response.reportId) {
                        return await Ajax.awaitReport(resp.response.reportId, this.abortController.signal);
                    }

                    return resp;
                } catch (e) {
                    if (e.name === 'AbortError') {
                        return null;
                    }

                    return {error: {code: 500}};
                }
            }
        }
    }
</script>