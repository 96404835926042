<template>
  <div>

    <div class="content-header">

      <h1 class="content-title">
        <router-link :to="'/adm/settings/objects'" >Объекты</router-link>
        <i></i>
        <template v-if="$route.params.id">
          {{ data.object.object_title }} <span>{{ data.object.object_id }}</span>
        </template>

        <template v-else>
          Новый объект
        </template>
      </h1>

      <div class="content-header_misc">
        <button v-if="$route.params.id && tab2 === 'price' && ac('settings.settings_objects.edit')" class="btn btn-primary" @click="$refs.service.openNewService()">Добавить</button>
        <button v-if="$route.params.id && tab2 === 'rollback' && ac('settings.settings_objects.edit')" class="btn btn-primary" @click="$refs.rollback.openNewService()">Добавить</button>
      </div>
    </div>

    <div class="line-tabs" id="tabs">
      <router-link :to="getTabLink('general')" v-bind:class="{current: isTab('general')}">Общая информация</router-link>
      <router-link v-if="$route.params.id" :to="getTabLink('price')" v-bind:class="{current: isTab('price')}">Услуги</router-link>
      <router-link v-if="$route.params.id" :to="getTabLink('rollback')" v-bind:class="{current: isTab('rollback')}">Отчёты</router-link>
    </div>

    <General v-if="tab2 === 'general'" />
    <Price ref="service" v-if="$route.params.id && tab2 === 'price'" />
    <Rollback ref="rollback" v-if="$route.params.id && tab2 === 'rollback'" />
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';

import General from "./object/General";
import Price from "./object/Price";
import Rollback from "./object/Rollback";
import Ajax from "@/helpers/ajax";

export default {
  mixins: [mixins],

  components: {
    General,
    Rollback,
    Price
  },

  created() {

    if (this.$route.params.id) {
      this.fetchData();
    }

  },

  data() {
    return {
      tab2: this.$route.params.tab2 || 'general',
      data: {
        object: {
          object_id: null,
          object_title: null
        },
      },
    }
  },

  methods: {
    getTabLink(tab) {
      return `/adm/settings/objects/edit/${this.$route.params.id}/${tab}`;
    },

    isTab(tab) {
      return this.tab2 === tab;
    },

    async fetchData() {
      let urls = {
        'object': `/object/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);

    },

  },
  watch: {
    '$route'() {
      this.tab2 = this.$route.params.tab2 || 'general';

      if (this.$route.params.id) {
        this.fetchData();
      }
    }
  },
}
</script>
