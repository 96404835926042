<template>
  <div>
    <Report ref="report" url="settings/place/report" :def-params="{order: {field: 'place_id', order: 'desc'}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="place_id"  width="50" />
              <OrderTd text="Наименование" field="place_title" />
              <OrderTd text="Адрес" field="place_address" />
              <OrderTd text="Подразделение" field="subdivision_id" />
              <OrderTd text="Статус" field="place_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="place_id" />
              <InputFilter name="place_title" filter-type="like" />
              <InputFilter name="place_address" filter-type="like" />
              <SelectFilter name="subdivision_id" :options="data.subdivision" track-by="subdivision_id" label="subdivision_title" />
              <SelectFilter name="place_status" :options="[['active', 'Активен'], ['paused', 'Заблокирован']]" track-by="0" label="1" />
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="6">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.place_id}}</td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.place_title">
                    <div>
                      <router-link :to="'/adm/settings/place/edit/' + row.place_id">{{row.place_title}}</router-link>
                    </div>
                  </div>
                </td>

                <td>
                  {{row.place_address}}
                </td>

                <td>
                  {{data.subdivisionTitle[row.subdivision_id]}}
                </td>

                <td>
                  <div class="status" v-bind:class="`status_${row.place_status}`">
                    {{data.status[row.place_status]}}
                  </div>
                </td>

                <td>
                  <div class="nav-right">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <router-link :to="'/adm/settings/place/edit/' + row.place_id">
                        <template v-if="ac('settings.settings_place.edit')">
                          Редактировать
                        </template>
                        <template v-else>
                          Просмотр
                        </template>
                      </router-link>
                      <a href="#" v-on:click.prevent="changeStatus(row.place_id, 'active')" v-if="row.place_status !== 'active' && ac('settings.settings_place.status')">Разблокировать место посадки</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.place_id, 'paused')" v-if="row.place_status !== 'paused' && ac('settings.settings_place.status')">Заблокировать место посадки</a>
                      <a href="#" v-if="ac('settings.settings_place.delete')" v-on:click.prevent="changeStatus(row.place_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Loading,
    Pager,
    Report
  },

  created() {
    this.loadSubdivision();
  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активно',
          'paused': 'Заблокировано'
        },
        subdivision: [],
        subdivisionTitle: []
      },
      error: null,
      report: null
    }
  },

  methods: {

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/settings/place/save/${id}`, {
            place_id: id,
            place_status: status
          });
        }
      } else {
        await Ajax.post(`/settings/place/save/${id}`, {
          place_id: id,
          place_status: status
        });
      }

      this.getReport().reload();
    },
  }
}
</script>
