<template>
<div>
  <Report
      ref="report"
      url="order/report"
      :def-params="{
        order: {
           field: 'order_id', order: 'desc'
        },
          filters: {
          order_seller: 'eq|' + $route.params.id
        }
      }"
      v-on:loading="reportLoading"
      v-on:done="reportLoaded"
  >
    <Loading />
    <div v-if="error">
      {{error}}
    </div>
    <div class="full-width-content table-wrapper" v-else>
      <div class="table-scrollable">
        <table class="table" cellpadding="0" cellspacing="0" width="100%">
          <thead>
          <tr>
            <OrderTd text="№" field="order_id"  width="50" />
            <OrderTd text="Рейс" field="order_title" />
            <OrderTd text="Отправление" field="order_date_ot" />
            <OrderTd text="Дата продажи" field="order_date" />
            <OrderTd text="Клиент" field="account_id" />
            <OrderTd text="Места" field="order_tickets" />
            <OrderTd text="Место посадки" field="order_place" />
            <OrderTd text="Статус" field="order_status" width="100" />
          </tr>
          <tr class="filter-row">
            <InputFilter name="order_id" />
            <InputFilter name="order_title" filter-type="like" />
            <PeriodPickerFilter name="order_date_ot" />
            <PeriodPickerFilter name="order_date" />
            <InputFilter name="account_id" filter-type="like" />
            <td></td>
            <SelectFilter name="order_place" :options="data.places_f" track-by="value" label="label" />
            <SelectFilter name="order_status" :options="[['order', 'Оплачен'], ['waiting_for_capture', 'Оплачен, не подтверждён'], ['reserved', 'Бронь'], ['cancel', 'Отменён'], ['cancel_success', 'Возврат']]" track-by="0" label="1" />
          </tr>
          </thead>
          <tbody v-if="report">
          <tr v-if="report.rows_count === 0">
            <td colspan="9">
              Не найдено.
            </td>
          </tr>

          <template v-else>
            <tr v-for="(row, index) in report.rows" v-bind:key="index">
              <td>{{row.order_id}}</td>
              <td>
                {{row.order_title}}
              </td>

              <td>
                {{convertDate(row.order_date_ot, 'DD.MM.YYYY HH:mm')}}
              </td>

              <td>
                {{convertDate(row.order_date, 'DD.MM.YYYY HH:mm')}}
              </td>

              <td>
                <router-link :to="'/adm/clients/' + row.account_id + '/general'">{{row.order_client_title || row.account_title}}</router-link>
                <div class="row-desc"> {{formatPhone(row.order_client_phone || row.user_phone)}} </div>
              </td>

              <td>
                {{row.order_tickets}}
              </td>

              <td>
                {{data.places[row.order_place]}}
              </td>

              <td>
                <div class="status" v-bind:class="`status_${row.order_status}`">
                  {{data.status[row.order_status]}}
                </div>
              </td>

            </tr>

          </template>
          </tbody>
        </table>
      </div>

      <Pager />
    </div>
  </Report>
</div>
</template>



<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';
import PeriodPickerFilter from '../../../../components/report/PeriodPickerFilter';


export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    PeriodPickerFilter,
    Loading,
    Pager,
    Report
  },


  created() {
    this.loadSubdivision();
  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активен',
          'paused': 'Заблокирован',
          'order': 'Оплачен',
          'cancel': 'Отменён',
          'cancel_success': 'Возврат',
          'waiting_for_capture': 'Оплачен, не подтверждён',
          'pending': 'Ожидает оплаты'
        },


        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: [],
        places: [],
        places_f: [],
        point: [],
        point_f: [],

      },

      error: null,
      report: null
    }
  },

  methods: {


    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      let place = await Ajax.get('/misc/place?query=');
      let point = await Ajax.get('/misc/point?query=');

      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }

      if (place) {
        this.data.places_f = place

        place.forEach((item) => {
          this.data.places[item.value] = item.label;
        });
      }

      if (point) {
        this.data.point_f = [];

        this.data.point_f.push({label: 'Сайт', value: 0})


        point.forEach((item) => {
          this.data.point_f.push(item)
          this.data.point[item.value] = item.label;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
