<template>
  <div>

    <h1 class="content-title">
      <router-link :to="'/adm/site/direction'">Направления</router-link>
      <i></i>

      <template v-if="$route.params.id">
        {{ data.category.category_title }} <span>{{ data.category.category_id }}</span>
      </template>

      <template v-else>
        Новое направление
      </template>
    </h1>

    <div class="line-tabs" id="tabs">
      <a v-on:click="setTab('general')" v-bind:class="{current: tab === 'general'}">Общая информация</a>
      <a v-on:click="setTab('seo')" v-bind:class="{current: tab === 'seo'}">SEO</a>
    </div>

    <div class="form">

      <template v-if="tab === 'general'">
        <div class="form-left">
          <Input name="category_title" text="Название" required="true" v-model.trim="data.category.category_title" />
          <Input name="category_page_title" text="Заголовок страницы" required="true" v-model.trim="data.category.category_page_title" />
          <Textarea name="category_page_subtitle" text="Описание страницы" required="true" v-model.trim="data.category.category_page_subtitle" />
          <Input name="category_url" text="URL" required="true" v-model.trim="data.category.category_url" />
        </div>
        <div class="form-right" v-if="data.category.category_id">
          <label class="input-placeholder">Привью</label>
          <img class="w100" v-if="data.category.category_image" :src="domain+'/img/excursion/list/' + data.category.category_image">

          <input type="file" id="category_image" accept=".jpg, .jpeg, .png, .webp" @change="prepareInputImage" />
          <label for="category_image" class="btn btn-middle" :class="imgLoader ? 'label-loader' : ''">Выбрать изображение</label>
        </div>
      </template>

      <template v-if="tab === 'seo'">
        <div class="form-left">
          <Input name="category_seo_title" text="SEO Title" required="true" v-model.trim="data.category.category_seo_title" />
          <Textarea name="category_seo_desc" text="SEO Description" v-model.trim="data.category.category_seo_desc" />
          <Textarea name="category_seo_keyword" text="SEO keywords" v-model.trim="data.category.category_seo_keyword" />
        </div>
      </template>

      <div class="form-submit" v-if="ac('site.site_direction.edit')">
        <input type="hidden" name="category_id" v-model.number="data.category.category_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
      </div>
    </div>

  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';

import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";

export default {
  mixins: [mixins],

  components: {
    Input,
    Textarea
  },

  created() {
    if (this.$route.params.id) {
      this.fetchData();
    }
  },

  data() {
    return {
      imgLoader: false,
      tab: 'general',
      data: {
        category: {
          category_id: null,
          category_title: null,
          category_page_title: null,
          category_page_subtitle: null,
          category_url: null,
          category_type: 'direction',
          category_seo_title: null,
          category_seo_desc: null,
          category_seo_keyword: null
        },
      },
      error: null,
      report: null
    }
  },

  methods: {
    setTab(tab) {
      this.tab = tab;
    },
    async fetchData() {
      let urls = {
        'category': `/category/${this.$route.params.id}`,
      };

      this.data = await Ajax.getBulk(urls);

      this.data.category.category_seo_title = this.data.category.category_seo_title ? this.data.category.category_seo_title : this.data.category.category_title;
      this.data.category.category_seo_desc =  this.data.category.category_seo_desc ?  this.data.category.category_seo_desc :  (this.data.category.category_page_subtitle ? this.data.category.category_page_subtitle.replace(/<(.|\n)*?>/g, '').slice(0, 155) : '');

    },

    async prepareInputImage(event) {

      if (event.target.files) {
        this.imgLoader = true;

        let formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('file_category', 'category_main');
        formData.append('file_name', this.data.category.category_id);

        let list = [];

        list.push(Ajax.post(`/settings/excursion/upload/`, formData));

        let [response] = await Promise.all(list);

        if (response && response.response) {
          this.data.category.category_image = response.response.filename;
          this.imgLoader = false;
        }

      }
    },

    async save() {
      this.clearError();

      let data = this.data.category;

      let list = [];

      delete data['category_status'];

      list.push(Ajax.post(`/site/category/save/${this.data.category.category_id || '0'}`, data));


      let [response] = await Promise.all(list);

      if (response.error) {

        this.setError(response.error);
      }
      if (response.response) {
        if (!this.$route.params.id) {
          this.$router.push({ path: '/adm/site/direction/edit/' + response.response.category_id });
          this.$toast.success({content:`Направление "${this.data.category.category_title}" добавленно.`});
        } else {
          this.$toast.success({content:`Направление "${this.data.category.category_title}" обновленно.`});
        }
      }
    },
  }
}

</script>

<style>
#category_image {
  display: none;
}
</style>
