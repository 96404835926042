<template>
  <div>
    <Report ref="report" url="settings/object_rollback/report" :def-params="{order: {field: 'object_rollback_id', order: 'desc'}, filters: { object_id: `eq|${this.$route.params.id}` }}" v-on:loading="reportLoading" v-on:done="reportLoaded">
      <Loading />
      <div v-if="error">
        {{error}}
      </div>
      <div class="full-width-content table-wrapper" v-else>
        <div class="table-scrollable">
          <table class="table" cellpadding="0" cellspacing="0" width="100%">
            <thead>
            <tr>
              <OrderTd text="№" field="object_rollback_id"  width="50" />
              <OrderTd text="Наименование" field="object_rollback_title" />

              <OrderTd text="Общий откат" field="object_rollback_amount_total" />
              <OrderTd text="Вознаграждение экскурсовода" field="object_rollback_amount_guide" />
              <OrderTd text="Единица измерения" field="object_rollback_type" />

              <OrderTd text="Статус" field="object_rollback_status" width="100" />
              <td></td>
            </tr>
            <tr class="filter-row">
              <InputFilter name="object_rollback_id" />
              <InputFilter name="object_rollback_title" filter-type="like" />

              <td></td>
              <td></td>
              <td></td>

              <SelectFilter name="object_rollback_status" :options="[['active', 'Активна'], ['paused', 'Заблокирована']]" track-by="0" label="1" />
              <td></td>
            </tr>
            </thead>
            <tbody v-if="report">
            <tr v-if="report.rows_count === 0">
              <td colspan="10">
                Не найдено.
              </td>
            </tr>

            <template v-else>
              <tr v-for="(row, index) in report.rows" v-bind:key="index">
                <td>{{row.object_rollback_id}}</td>
                <td>
                  <div class="row-wrap" v-bind:data-title="row.object_rollback_title">
                    <div>
                      <a v-if="ac('settings.settings_objects.edit')" v-on:click="openEditService(row.object_rollback_id)">
                        {{row.object_rollback_title}}
                      </a>
                      <div v-else>{{row.object_rollback_title}}</div>
                    </div>
                  </div>
                </td>

                <td v-if="row.object_rollback_amount_total_type === 'amount'">
                  {{formatRUB(row.object_rollback_amount_total)}}
                </td>
                <td v-else>
                  {{row.object_rollback_amount_total}} %
                </td>

                <td v-if="row.object_rollback_amount_guide_type === 'amount'">
                  {{formatRUB(row.object_rollback_amount_guide)}}
                </td>
                <td v-else>
                  {{row.object_rollback_amount_guide}} %
                </td>

                <td>
                  {{ row.object_rollback_type }}
                </td>

                <td>
                  <div class="status" v-bind:class="`status_${row.object_rollback_status}`">
                    {{data.status[row.object_rollback_status]}}
                  </div>
                </td>

                <td>
                  <div class="nav-right" v-if="ac('settings.settings_objects.edit')">
                    <a class="nav"></a>
                    <div class="nav-con">
                      <a v-on:click="openEditService(row.object_rollback_id)">Редактировать</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.object_rollback_id, 'active')" v-if="row.object_rollback_status !== 'active'">Разблокировать</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.object_rollback_id, 'paused')" v-if="row.object_rollback_status !== 'paused'">Заблокировать</a>
                      <a href="#" v-on:click.prevent="changeStatus(row.object_rollback_id, 'deleted')">Удалить</a>
                    </div>
                  </div>
                </td>
              </tr>

            </template>
            </tbody>
          </table>
        </div>

        <Pager />
      </div>
    </Report>
    <Modal ref="newService">
      <h2>
        <template v-if="data.newService.object_rollback_id > 0">
          Редактирование отката <span>{{data.newService.object_rollback_id}}</span>
        </template>
        <template v-else>
          Новый откат
        </template>
      </h2>

      <Input name="object_rollback_title" text="Наименование" required="true" v-model.trim="data.newService.object_rollback_title" />
      <div class="input-group">
        <div class="input-group">
          <Input name="object_rollback_amount_total" :icon2="data.newService.object_rollback_amount_total_type === 'percent' ? '%' : '₽'" text="Откат" required="true" v-model.trim.number="data.newService.object_rollback_amount_total" />
          <Select track-by="0" label="1" :options="[['percent', '%'], ['amount', '₽']]" name="object_rollback_amount_total_type" required="true" v-model="data.newService.object_rollback_amount_total_type" />
        </div>
        <div class="input-group">
          <Input name="object_rollback_amount_guide" :icon2="data.newService.object_rollback_amount_guide_type === 'percent' ? '%' : '₽'" text="Экскурсоводу" required="true" v-model.trim.number="data.newService.object_rollback_amount_guide" />

          <Select track-by="0" label="1" :options="[['percent', '%'], ['amount', '₽']]" name="object_rollback_amount_guide_type" required="true" v-model="data.newService.object_rollback_amount_guide_type" />
        </div>
        <div>
          <Select track-by="0" label="1" :options="[['count', 'Количество'], ['amount', 'Сумма']]" text="Измерение" name="object_rollback_type" required="true" v-model="data.newService.object_rollback_type" />
        </div>

      </div>



      <div class="form-submit" v-if="ac('settings.settings_objects.edit')">
        <input type="hidden" name="object_rollback_id" v-model.number="data.newService.object_rollback_id" />
        <input type="hidden" name="object_id" v-model.number="data.newService.object_id" />
        <a href="#" class="btn btn-primary" v-on:click.prevent="save">Сохранить</a>
        <a href="#" class="btn btn-middle" v-on:click.prevent="cancel">Закрыть</a>
      </div>

    </Modal>
  </div>
</template>


<script>
import mixins from '../../../../../helpers/mixins.js';
import Ajax from '../../../../../helpers/ajax';
import Loading from '../../../../../components/report/Loading';
import Report from '../../../../../components/report/Report';
import Pager from '../../../../../components/Pager';
import InputFilter from '../../../../../components/report/InputFilter';
import SelectFilter from '../../../../../components/report/SelectFilter';
import OrderTd from '../../../../../components/report/OrderTd';
import Input from '../../../../../components/Input';
import Select from "@/components/Select";

import Modal from '../../../../../components/Modal';


export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    Select,
    Modal,
    Loading,
    Pager,
    Input,
    Report
  },


  created() {

  },
  data() {
    return {
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },
        saleType: {
          'percent': '%',
          'sum': '₽'
        },
        newService: {
          object_id: this.$route.params.id,
          object_rollback_id: 0,
          object_rollback_title: '',
          object_rollback_amount_total: 0,
          object_rollback_amount_total_type: 'amount',
          object_rollback_amount_guide: 0,
          object_rollback_amount_guide_type: 'amount',
          object_rollback_type: 'amount'
        },
      },

      rollbackType: null,
      error: null,
      report: null
    }
  },

  methods: {

    async save() {

      this.newServiceClearError();

      let data = this.data.newService;

      let list = [];

      delete data['object_rollback_status'];

      list.push(Ajax.post(`/settings/object_rollback/save/${data.object_rollback_id || '0'}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {

        this.$refs.newService.$children.forEach(item => {

          if (response.error.elements[item.name]) {
            item.setError(response.error.elements[item.name])
          }
        });


        this.setError(response.error.elements);
      }

      if (response.response) {
        if (!this.$route.params.id) {
          this.$toast.success({content:`Услуга "${data.object_rollback_title}" добавлена.`});
        } else {
          this.$toast.success({content:`Услуга "${data.object_rollback_title}" обновлена.`});
        }
        this.$refs.newService.hide();
        this.getReport().reload();
      }
    },
    cancel() {
      this.$refs.newService.hide();
    },

    newServiceClearValue() {
      console.log('clear value')

    },

    newServiceClearError() {
      console.log('clear error')
    },

    openNewService() {

      this.data.newService.object_rollback_id = 0;

      this.newServiceClearError();
      this.newServiceClearValue();
      this.$refs.newService.show();
    },

    async openEditService(id) {

      let result = await Ajax.get(`/object_rollback/${id}`);
      if (result.response) {
        this.data.newService = result.response;

        this.$refs.newService.show();
      }

    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;

    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {

      if (status === 'deleted') {
        if (confirm("Вы подтверждаете удаление?")) {
          await Ajax.post(`/settings/object_rollback/save/${id}`, {
            object_rollback_id: id,
            object_rollback_status: status
          });
        }
      } else {
        await Ajax.post(`/settings/object_rollback/save/${id}`, {
          object_rollback_id: id,
          object_rollback_status: status
        });
      }


      this.getReport().reload();
    },
  }
}
</script>
