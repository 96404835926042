<template>
  <div class="content">
    <h1 class="content-title">
      Рейсы
    </h1>

    <div class="line-tabs" id="tabs">
      <router-link to="/adm/flights/all">Все рейсы</router-link>
      <router-link to="/adm/flights/followed" class="current">Отслеживаемые <i class="count">{{fav.length}}</i></router-link>
    </div>

    <div class="line-tab-con">
      <Report v-if="fav && fav.length" ref="report" url="flights/report" :def-params="{order: {field: 'excursion_id', order: 'desc'}, filters: {schedule_date: 'bw|' + period, excursion_id: 'ins|' + fav.join(',') }}" v-on:loading="reportLoading" v-on:done="reportLoaded">
        <Loading />
        <div v-if="error">
          {{error}}
        </div>
        <div class="full-width-content table-wrapper" v-else>

          <div class="table-filter">
            <div>
              <label class="input-placeholder">Подразделение</label>
              <SelectFilter name="subdivision_id" :options="data.subdivision" track-by="subdivision_id" label="subdivision_title" class="m-0" />
            </div>
            <div>
              <label class="input-placeholder">Экскурсия</label>
              <InputFilter name="excursion_title" filter-type="like" />
            </div>
            <div>
              <label class="input-placeholder">Период</label>
              <PeriodPickerFilter2 :allow-future="true" name="schedule_date" v-model="period" />
            </div>
          </div>

          <div class="table-scrollable scroll-on" :class="{'show-setting-notification': notifications.length}">
            <table class="table" cellpadding="0" cellspacing="0" width="100%">
              <thead class="sticky">

              <tr>
                <td width="30"></td>
                <OrderTd text="Экскурсия" field="excursion_title" />

                <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                  <b>{{convertDate(date, 'DD')}}</b><br>
                  {{days[convertDate(date, 'd')]}}
                </td>

              </tr>

              </thead>
              <tbody v-if="report">
              <tr v-if="report.rows_count === 0">
                <td colspan="16">
                  Не найдено.
                </td>
              </tr>

              <template v-else>
                <tr v-for="(row, index) in report.rows" v-bind:key="index">

                  <td>
                    <div class="checkbox">
                      <label>
                        <input v-model="fav" type="checkbox" :id="row.excursion_id" :value="row.excursion_id">
                        <span></span>
                      </label>
                    </div>
                  </td>

                  <td>
                    <div class="row-wrap" v-bind:data-title="row.excursion_title">
                      <div>
                        <router-link :to="'/adm/settings/excursions/edit/' + row.excursion_id">
                          {{row.excursion_title}}
                        </router-link>
                        <div class="row-desc">
                          {{data.subdivisionTitle[row.subdivision_id]}}
                          <i v-if="row.excursion_link" class="linked"></i>
                          <i v-if="row.excursion_linked" class="linked"></i>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td v-for="(date, index) in dates" v-bind:key="index" class="center">
                    <template v-if="row.preparedDates && row.preparedDates[convertDate(date, 'YYYY-MM-DD')]">
                      <div v-for="(d, index) in row.preparedDates[convertDate(date, 'YYYY-MM-DD')]" v-bind:key="index" class="table-date" v-on:click.prevent="showFlight(row, d.date, d.flight_id, d.limit)">
                        <i>{{convertDate(d.date, 'HH:mm')}}</i> <b :class="d.count ? 'active' : ''">{{d.count}} / {{d.limit}}</b>
                      </div>
                    </template>

                    <div class="table-date" v-on:click.prevent="showNewFlight(row, date)" v-else>+</div>
                  </td>

                </tr>

              </template>
              </tbody>
            </table>

            <div class="setting-notification" v-if="notifications.length">
              <div v-for="(item, index) in notifications" v-bind:key="index" class="item">
                <div class="nav-right">
                  <a class="nav"></a>
                  <div class="nav-con">
                    <a href="#" v-on:click.prevent="editNotification(item)">
                      Редактировать
                    </a>
                    <a href="#" v-on:click.prevent="removeNotification(item)">
                      Удалить
                    </a>
                  </div>
                </div>
                <div class="title">
                  <span>{{item.flight_id}}</span> {{item.title}}
                </div>
                <div class="date">
                  {{convertDate(item.date)}}
                </div>
                <div class="stop_count">
                  <i>{{item.current_count}} / {{item.count}}</i>
                  <b v-if="item.stop_sale"> — остановить продажи</b>
                </div>
              </div>
            </div>
          </div>


          <Pager />
        </div>
      </Report>
      <h2 v-else>Рейсы не выбраны</h2>
      <Modal ref="addFlight">
        <template v-if="newFlightDate">
          <h2>
            Добавить рейс — {{newFlightDate.excursion_title}} — {{convertDate(newFlightDate.date, 'DD.MM.YYYY')}}
          </h2>
          <div class="form">
            <div class="form-left">
              <label class="input-placeholder">Время отправления</label>

              <template v-if="newFlightDate.excursion_time_type === 'true'">
                <div class="input-group schedule_time" v-for="(time, i) in schedules.times[newFlightDate.day]" v-bind:key="i" :class="i > 0 ? 'schedule_time-middle' : ''">
                  <Input
                      style="display: none"
                      readonly
                      disabled="disabled"
                      type="text"
                      name="schedule_flight_id"
                      text=" "
                      required="true"
                      v-if="newFlightDate.dates"
                      v-model="schedules.times[newFlightDate.day][i].flight_id"
                  />
                  <Input :min="0" type="number" :max="23" name="schedule_h" :icon2="declination(schedules.times[newFlightDate.day][i].h, duration.h_title)" text=" " required="true" v-model="schedules.times[newFlightDate.day][i].h" />
                  <Input :min="0" type="number" :max="59" name="schedule_m" :icon2="declination(schedules.times[newFlightDate.day][i].m, duration.m_title)" text=" " required="true" v-model="schedules.times[newFlightDate.day][i].m" />
                  <div>
                    <label class="input-placeholder"></label>
                    <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="changeTime(i, newFlightDate.day)">{{i === 0 ? '+' : '-'}}</a>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="input-group schedule_time" v-for="(time, i) in schedules.times[0]" v-bind:key="i" :class="i > 0 ? 'schedule_time-middle' : ''">
                  <Input
                      style="display: none"
                      readonly
                      disabled="disabled"
                      type="text"
                      name="schedule_flight_id"
                      text=""
                      required="true"
                      v-model="schedules.times[0][i].flight_id"
                      v-if="newFlightDate.dates"
                  />
                  <Input :min="0" type="number" :max="23" name="schedule_h" :icon2="declination(schedules.times[0][i].h, duration.h_title)" text=" " required="true" v-model="schedules.times[0][i].h" />
                  <Input :min="0" type="number" :max="59" name="schedule_m" :icon2="declination(schedules.times[0][i].m, duration.m_title)" text=" " required="true" v-model="schedules.times[0][i].m" />
                  <div>
                    <label class="input-placeholder"></label>
                    <a href="#" class="btn btn-middle btn-add" v-on:click.prevent="changeTime(i, 0)">{{i === 0 ? '+' : '-'}}</a>
                  </div>
                </div>
              </template>

            </div>
          </div>

          <div class="form-submit">
            <a href="#" class="btn btn-primary" v-on:click.prevent="saveSchedule">Сохранить</a>
            <a href="#" class="btn btn-middle" v-on:click.prevent="hideNewFlight">Закрыть</a>
          </div>

        </template>
      </Modal>

      <Modal ref="editFlight">
        <template v-if="flight">
          <h2>
            Рейс — {{flight.excursion_title}} — {{convertDate(flight.date)}}
          </h2>

          <div class="form">
            <div class="form-left">
             <div class="input-group">

               <Input
                   :min="0"
                   type="number"
                   :max="9999"
                   name="notification_count"
                   :icon2="declination(notification_count, ['места', 'мест', 'мест'])"
                   text="Сообщить мне при продаже"
                   required="true"
                   v-model="notification_count"
               />

               <div>

               </div>

             </div>
              <div class="checkbox mb-20">
                <label>
                  <input v-model="stop_sale" type="checkbox" name="stop_sale" value="1">
                  <span>При продаже {{notification_count}} {{declination(notification_count, ['места', 'мест', 'мест'])}} остановить продажи</span>
                </label>
              </div>
            </div>

          </div>

          <div class="form-submit">
            <a href="#" class="btn btn-primary" v-on:click.prevent="saveNotification">Сохранить</a>
            <a href="#" class="btn btn-middle" v-on:click.prevent="$refs.editFlight.hide()">Закрыть</a>
          </div>
        </template>
      </Modal>

      <Modal ref="notificationWindow">
        <template v-if="notification">
          <h2>
            Рейс №{{notification.flight_id}} — {{notification.excursion_title}} — {{convertDate(notification.date)}}
          </h2>

          <h3>Рейс почти заполнен, занято {{notification.current_count}} из {{notification.count}} мест</h3>


          <div class="form-submit">
            <a href="#" class="btn btn-middle" v-on:click.prevent="$refs.notificationWindow.hide()">Закрыть</a>
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import Input from '../../../../components/Input';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';
import PeriodPickerFilter2 from "@/components/report/PeriodPickerFilter";

import Modal from '../../../../components/Modal';

import $ from "jquery";

import moment from 'moment';

export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    PeriodPickerFilter2,
    Loading,
    Modal,
    Pager,
    Report,
    Input
  },


  mounted() {
    this.emitter.off("updateScheduleReport")
    this.emitter.on("updateScheduleReport", () => {
      if (this.$refs.report) {
        this.$refs.report.reload()

        this.updateNotification()
      }
    })
  },

  created() {

    this.emitter.off("updateScheduleReport")

    this.loadSubdivision();

    let dateArray = [];
    let currentDate = new Date();
    let i = 0;

    while (i < 14) {
      dateArray.push(new Date (currentDate));
      currentDate = moment(currentDate).add(1, 'days');

      i++;
    }

    this.dates = dateArray;
    this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');

  },


  watch: {

    'stop_sale'() {
      if (this.stop_sale) {
        //let is = confirm('Я полностью в своем уме. Я осознаю, что хочу остановить продажи!')
      }
    },

    fav() {
      localStorage.fav = this.fav;
      this.$emit('update');
      this.getReport().changeReportParams({
        filters: {
          excursion_id: 'ins|' + this.fav
        }
      }, false);
    },

    period() {

      this.updateNotification()

      if (!this.period) {
        let dateArray = [];
        let currentDate = new Date();
        let i = 0;

        while (i < 14) {
          dateArray.push(new Date (currentDate));
          currentDate = moment(currentDate).add(1, 'days');
          i++;
        }

        this.dates = dateArray;
        this.period = moment(dateArray[0]).format('YYYY-MM-DD 00:00:00') + ';' + moment(dateArray[dateArray.length-1]).format('YYYY-MM-DD 23:59:59');
      }

      let period = this.period.split(';')
      let currentDate = new Date();
      let countDays = 14;

      if (period.length) {
        currentDate = new Date(period[0]);

        let endDate = new Date(period[1]);
        countDays = moment(endDate).diff(moment(currentDate), 'days') + 1
      }

      let dateArray = [];
      let i = 0;

      while (i < countDays) {
        dateArray.push(new Date(currentDate));
        currentDate = moment(currentDate).add(1, 'days');

        i++;
      }

      this.dates = dateArray;

    }
  },

  data() {
    return {
      notification: null,
      notifications: localStorage.flightNotification ? JSON.parse(localStorage.flightNotification) : [],
      notification_count: 100,
      stop_sale: false,
      fav: localStorage.fav ? localStorage.fav.split(',') : null,
      data: {
        status: {
          'active': 'Активна',
          'paused': 'Заблокирована'
        },

        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        subdivision: [],
        subdivisionTitle: []

      },

      duration: {
        d: 0,
        d_title: ['день', 'дня', 'дней'],
        h: 0,
        h_title: ['час', 'часа', 'часов'],
        m: 0,
        m_title: ['минута', 'минуты', 'минут']
      },
      schedules: {
        times: [
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ],
          [
            {
              h: '00',
              m: '00'
            }
          ]
        ],
      },

      flight: null,
      newFlightDate: null,
      period: '',
      schedule: null,
      dates: [],
      days: [
        'Вс',
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб'
      ],


      error: null,
      report: null
    }
  },

  methods: {
    async updateNotification() {
      this.notifications = localStorage.flightNotification ? JSON.parse(localStorage.flightNotification) : [];

      let flight_ids = this.notifications.map(e => e.flight_id).join(',')
      if (flight_ids) {
        let result = await Ajax.get('/flights/notification_report?filters[flight_id]=ins|' + flight_ids);
        if (result.response && result.response.rows) {
          result.response.rows.forEach((item) => {
            this.notifications.forEach((i) => {
              if (i.flight_id === item.flight_id) {
                i.current_count = item.count

                if (item.count + 10 >= i.count) {
                  this.notification = {
                    excursion_title: i.title,
                    date: i.date,
                    flight_id: i.flight_id,
                    current_count: item.count,
                    count: i.count
                  }

                  this.$refs.notificationWindow.show()
                }


              }
            })
          });

          localStorage.flightNotification = JSON.stringify(this.notifications)
        }
      }

    },
    saveNotification() {

      let data = localStorage.flightNotification ? JSON.parse(localStorage.flightNotification) : []
      let newData = []
      let tmpData = []

      if (data.length) {
        $.each(data, function(k, v) {
          if (v.id === this.flight.excursion_id && v.date === this.flight.date) {
            console.log(2)
          } else {
            tmpData.push(v)
          }
        }.bind(this))
      }

      newData.push({
        flight_id: this.flight.flight_id,
        id: this.flight.excursion_id,
        title: this.flight.excursion_title,
        date: this.flight.date,
        count: this.notification_count,
        stop_sale: this.stop_sale,
        current_count: 0
      })
      newData = newData.concat(tmpData)

      localStorage.flightNotification = JSON.stringify(newData)

      this.notifications = newData;


      this.$refs.editFlight.hide();

      this.updateNotification()

    },

    getFlightID(dataObject) {
      return dataObject && dataObject.flight_id ? dataObject.flight_id : ''
    },

    async saveSchedule() {

      let data = {};
      let dates = [];
      let list = [];

      this.schedules.times[this.newFlightDate.excursion_time_type === 'true' ? this.newFlightDate.day : 0].forEach((time) => {
        dates.push({
          date: moment(this.newFlightDate.date).format('YYYY-MM-DD') + ' ' + time.h + ':' + time.m + ':00',
          flight_id: time.flight_id
        })
      })

      data.excursion_id = this.newFlightDate.excursion_id
      data.dates = dates

      if (!data.dates.length) {
        data.remove_date = moment(this.newFlightDate.date).format('YYYY-MM-DD')
      }

      list.push(Ajax.post(`/settings/excursion/schedule/${this.newFlightDate.excursion_id}`, data));

      let [response] = await Promise.all(list);

      if (response.error) {
        this.setError(response.error);
      }

      if (response.response) {
        this.$toast.success({content:`Расписание обновлено`});
        this.hideNewFlight();
      }
    },

    changeTime(i, day) {
      if (i === 0) {
        let newTimes = this.schedules.times[day];
        newTimes.push({h: '', m: '00'})
        this.schedules.times[day]= newTimes
      } else {
        this.schedules.times[day].splice(i, 1)
      }
    },

    hideNewFlight(){
      this.newFlightDate = null
      this.$refs.addFlight.hide()
    },

    showNewFlight(ex_data, date) {

      let editDates = ex_data.dates ? Object.keys(ex_data.dates) : []
      let tmpEditDates = []

      for (let d = 0, i = editDates.length; d < i; d++) {
        if (editDates[d].split(' ')[0] === moment(date).format('YYYY-MM-DD')) {
          tmpEditDates[tmpEditDates.length] = editDates[d]
        }
      }

      editDates = tmpEditDates

      if (editDates.length) {
        for (let day = 0, len = 7; day < len; day++) {
          this.schedules.times[day] = editDates.map((i) => i = {
            h: i.split(' ')[1].split(':')[0],
            m: i.split(' ')[1].split(':')[1],
            flight_id: this.getFlightID(ex_data.dates ? ex_data.dates[ i ] : null)
          })
        }

        ex_data.excursion_time_type = 'true'

      } else {
        for (let day = 0, len = 7; day < len; day++) {

          this.schedules.times[day] = ex_data['excursion_time' + (day+1)] && ex_data['excursion_time' + (day+1)].map((i) => i = {
            h: i.split(':')[0],
            m: i.split(':')[1],
            flight_id: this.getFlightID(ex_data.dates ? ex_data.dates[ i ] : null)
          }) || this.schedule.times[day]
        }
      }

      ex_data.day = moment(date).isoWeekday() - 1
      ex_data.date = date
      this.newFlightDate = ex_data

      this.$refs.addFlight.show()
    },

    showFlight(ex, date, flight_id, limit = 100) {
      let data = ex
      data.date = date
      data.flight_id = flight_id
      this.notification_count = limit
      this.stop_sale = false
      this.flight = data
      this.$refs.editFlight.show();
    },

    editNotification(item) {
      let data = {
        date: item.date,
        excursion_id: item.id,
        excursion_title: item.title,
        flight_id: item.flight_id,
      }

      this.notification_count = item.count
      this.stop_sale = item.stop_sale
      this.flight = data
      this.$refs.editFlight.show();
    },

    removeNotification(item) {
      let data = localStorage.flightNotification ? JSON.parse(localStorage.flightNotification) : []
      let newData = []

      if (data.length) {
        $.each(data, function(k, v) {
          if (v.flight_id !== item.flight_id) {
            newData.push(v)
          }
        })
      }

      localStorage.flightNotification = JSON.stringify(newData);

      this.notifications = newData;
    },

    updateDate() {
      console.log(333)
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }
    },

    reportLoaded(report) {
      if (report && report.response) {

        $.each(report.response.rows, function (k, v) {

          if (v.excursion_link) {
            v.excursion_linked = []
            $.each(report.response.rows, function (k, i) {
              if (v.excursion_link.indexOf(i.excursion_id) > -1) {
                i.excursion_linked = v.excursion_id
              }
            })
          }

          if (v.dates) {

            if (v.dates && v.dates.length) {
              let newDates = {}

              $.each(v.dates, (t, date) => {
                let keys = Object.keys(date[0]);

                if (keys && keys.length) {
                  if (newDates[keys[0]]) {
                    newDates[keys[0]].tickets = newDates[keys[0]].tickets.concat(date[0][keys[0]].tickets)
                    newDates[keys[0]].count = newDates[keys[0]].tickets.length
                  } else {
                    newDates[keys[0]] = date[0][keys[0]]
                    if (date[0][keys[0]].tickets[0] === 0) {
                      newDates[keys[0]].tickets = []
                    }
                    newDates[keys[0]].count = newDates[keys[0]].tickets ? newDates[keys[0]].tickets.length : 0
                  }
                }
              })

              v.dates = newDates
            }

            let date = {}
            $.each(v.dates, function (k, v) {
              if (date[k.split(' ')[0]]) {
                date[k.split(' ')[0]].push( {date: k, count: v.count, flight_id: v.flight_id, limit: v.limit} )
              } else {
                date[k.split(' ')[0]] = [{date: k, count: v.count, flight_id: v.flight_id, limit: v.limit}]
              }
            })

            report.response.rows[k].preparedDates = date;
          }
        })

        this.report = report.response;

        this.error = null;
      }

    },
  }
}
</script>
