<template>
  <Report v-if="ac('orders')" ref="report" url="order/report" :def-params="{order: {field: 'order_id', order: 'desc'}, filters: {account_id: 'eq|' + $route.params.id}}" v-on:loading="reportLoading" v-on:done="reportLoaded">
    <Loading />
    <div v-if="error">
      {{error}}
    </div>
    <div class="full-width-content table-wrapper" v-else>
      <div class="table-scrollable">
        <table class="table" cellpadding="0" cellspacing="0" width="100%">
          <thead>
          <tr>
            <OrderTd text="№" field="order_id"  width="50" />
            <OrderTd text="Рейс" field="order_title" />
            <OrderTd text="Отправление" field="order_date_ot" />
            <OrderTd text="Транспорт" field="transport_number" />
            <OrderTd text="Дата продажи" field="order_date" />

            <OrderTd text="Места" field="order_tickets" />

            <OrderTd text="Точка продажи" field="order_title" />
            <OrderTd text="Место посадки" field="order_place" />
            <OrderTd text="Промокод" field="order_promo" />

            <td>Итого</td>

            <OrderTd text="Статус" field="order_status" width="100" />
            <td></td>
          </tr>
          <tr class="filter-row">
            <InputFilter name="order_id" />
            <InputFilter name="order_title" filter-type="like" />
            <PeriodPickerFilter :allow-future="true" name="order_date_ot" />

            <InputFilter name="transport_number" filter-type="like" />

            <PeriodPickerFilter name="order_date" />

            <td></td>
            <SelectFilter name="order_point" :options="data.point_f" track-by="value" label="label" />
            <SelectFilter name="order_place" :options="data.places_f" track-by="value" label="label" />
            <InputFilter name="order_promo" filter-type="like" />
            <td></td>
            <SelectFilter name="order_status" :options="[['order', 'Оплачен'], ['waiting_for_capture', 'Оплачен, не подтверждён'], ['reserved', 'Бронь'], ['cancel', 'Отменён'], ['cancel_success', 'Возврат']]" track-by="0" label="1" />

            <td></td>
          </tr>
          </thead>
          <tbody v-if="report">
          <tr v-if="report.rows_count === 0">
            <td colspan="10">
              Не найдено.
            </td>
          </tr>

          <template v-else>
            <tr v-for="(row, index) in report.rows" v-bind:key="index">
              <td>{{row.order_id}}</td>
              <td>
                <router-link :to="'/adm/orders/'+row.order_id">{{row.order_title}}</router-link>
              </td>
              <td>
                {{convertDate(row.order_date_ot, 'DD.MM.YYYY HH:mm')}}
              </td>
              <td>
                {{row.transport_number}}
                <div class="row-desc">{{row.transport_title}}</div>
              </td>

              <td>
                {{convertDate(row.order_date, 'DD.MM.YYYY HH:mm')}}
              </td>

              <td>
                {{row.order_tickets}}
              </td>

              <td>
                сайт
              </td>
              <td>
                {{data.places[row.order_place]}}
              </td>

              <td>
                {{row.order_promo}}
              </td>

              <td v-if="row.order_partial_pay && row.order_pay_sum != row.order_partial_sum">
                <b style="white-space: nowrap">{{formatRUB(row.order_partial_sum)}} из {{formatRUB(row.order_pay_sum)}}</b><br>
                {{formatRUB(row.order_sum_all)}}
              </td>

              <td v-else>
                <b>{{formatRUB(row.order_pay_sum)}}</b><br>
                {{formatRUB(row.order_sum_all)}}
              </td>

              <td>
                <div v-if="row.order_partial_pay && row.order_pay_sum != row.order_partial_sum" class="status status_paused">
                  Ожидает полной оплаты
                </div>
                <div v-else class="status" v-bind:class="`status_${row.order_status}`">
                  {{data.status[row.order_status]}}
                </div>

              </td>

              <td>
                <div class="nav-right" v-if="ac('orders.edit') || ac('orders.status') || ac('orders.view')">
                  <a class="nav"></a>
                  <div class="nav-con">
                    <a v-if="row.order_resp_id" :href="domain+'/i/'+row.order_resp_id" target="_blank">Посмотреть билет</a>
                    <router-link :to="'/adm/orders/'+row.order_id">
                      <template v-if="ac('orders.edit')">
                        Редактировать
                      </template>
                      <template v-else>
                        Просмотр
                      </template>
                    </router-link>

                    <template v-if="ac('orders.status')">
                      <router-link :to="'/adm/orders/'+row.order_id" v-if="row.order_status === 'waiting_for_capture'">Подтвердить заказ</router-link>
                      <a href="#" v-if="row.order_pay_resp_id && ['cancel', 'cancel_success', 'order'].indexOf(row.order_status) === -1" v-on:click.prevent="changeStatus(row, 'cancel')">Отменить заказ</a>
                      <a href="#" v-if="row.order_pay_resp_id && ['order'].indexOf(row.order_status) > -1" v-on:click.prevent="changeStatus(row, 'cancel')">Отменить заказ и вернуть деньги</a>
                    </template>

                  </div>
                </div>
              </td>
            </tr>

          </template>
          </tbody>
        </table>
      </div>

      <Pager />
    </div>
  </Report>
  <e403 v-else />

</template>


<script>
import mixins from '../../../../helpers/mixins.js';
import Ajax from '../../../../helpers/ajax';
import Loading from '../../../../components/report/Loading';
import Report from '../../../../components/report/Report';
import Pager from '../../../../components/Pager';
import InputFilter from '../../../../components/report/InputFilter';
import SelectFilter from '../../../../components/report/SelectFilter';
import OrderTd from '../../../../components/report/OrderTd';
import PeriodPickerFilter from '../../../../components/report/PeriodPickerFilter';


export default {
  mixins: [mixins],

  components: {
    InputFilter,
    SelectFilter,
    OrderTd,
    PeriodPickerFilter,
    Loading,
    Pager,
    Report
  },


  created() {
    this.loadSubdivision();
  },

  data() {
    return {
      data: {
        status: {
          'active': 'Активен',
          'paused': 'Заблокирован',
          'order': 'Оплачен',
          'cancel': 'Отменён',
          'cancel_success': 'Возврат',
          'waiting_for_capture': 'Оплачен, не подтверждён',
          'pending': 'Ожидает оплаты'
        },
        subdivision: [],
        subdivisionTitle: [],
        object: ['объект', 'объекта', 'объектов'],
        place: ['точка', 'точки', 'точек'],
        places: [],
        places_f: [],
        point: [],
        point_f: []
      },

      error: null,
      report: null
    }
  },

  methods: {

    async loadSubdivision() {
      let result = await Ajax.get('/subdivision/get-list');
      let place = await Ajax.get('/misc/place?query=');
      let point = await Ajax.get('/misc/point?query=');

      if (result.response) {

        this.data.subdivision = result.response;

        result.response.forEach((item) => {
          this.data.subdivisionTitle[item.subdivision_id] = item.subdivision_title;
        });
      }

      if (place) {
        this.data.places_f = place

        place.forEach((item) => {
          this.data.places[item.value] = item.label;
        });
      }

      if (point) {
        this.data.point_f = [];

        this.data.point_f.push({label: 'Сайт', value: 0})


        point.forEach((item) => {
          this.data.point_f.push(item)
          this.data.point[item.value] = item.label;
        });
      }
    },

    getReport() {
      return this.$refs.report;
    },

    pageChanged(page, limit) {
      this.getReport().pageChanged(page, limit);
    },

    reportLoading() {
      this.error = null;
    },

    reportLoaded(report) {
      if (report && report.response) {
        this.report = report.response;
        this.error = null;
      }

    },

    async changeStatus(id, status) {
      await Ajax.excursion(`/excursion/${id}/status`, {status: status});
      this.getReport().reload();
    },
  }
}
</script>
